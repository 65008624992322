import { observable, makeObservable } from 'mobx';
import * as Yup from 'yup';

import  { BaseModel, ClassDescription, FieldMeta, parseMapValues, isNotUndefined } from "./core"; // eslint-disable-line no-unused-vars





export const objectLinkClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.ObjectLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "rel": Yup.string(),
            "href": Yup.string(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 40, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "rel", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "rel", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "href", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "href", "auto_created": true},
        ],

    }
);

export class ObjectLink extends BaseModel{

    $classDescription = objectLinkClassDescription;
    
    id = null; 
    rel = null; 
    href = null; 

    constructor(parent, { id,rel,href }){
        super(parent,{  });
        
        this.id = id ? id : null;
        this.rel = rel ? rel : null;
        this.href = href ? href : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            id: observable,  
            rel: observable,  
            href: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ObjectLink(null,data);
    }
}

export const sourceClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.Source",
        verboseName: "None",
        validationSchemas: {
        
            "name": Yup.string().max(200),
            "id": Yup.string().max(125),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 200, "blank": false, "verbose_name": "name", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "name", "auto_created": true},
            {"max_length": 125, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
        ],

    }
);

export class Source extends BaseModel{

    $classDescription = sourceClassDescription;
    
    name = null; 
    id = null; 

    constructor(parent, { name,id }){
        super(parent,{  });
        
        this.name = name ? name : null;
        this.id = id ? id : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
            id: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Source(null,data);
    }
}

export const metaClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.Meta",
        verboseName: "None",
        validationSchemas: {
        
            "owner": Yup.string().max(120),
            "creator": Yup.string().max(120),
            "creationTime": Yup.date(),
            "modificator": Yup.string().max(120),
            "modificationTime": Yup.date(),
            "version": Yup.number().integer().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 120, "blank": false, "verbose_name": "owner", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "owner", "auto_created": true},
            {"max_length": 120, "blank": false, "verbose_name": "creator", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "creator", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "creationTime", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.DateTimeField", "choices": [], "meta": null, "name": "creationTime", "auto_created": true},
            {"max_length": 120, "blank": false, "verbose_name": "modificator", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "modificator", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "modificationTime", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.DateTimeField", "choices": [], "meta": null, "name": "modificationTime", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "version", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.IntegerField", "choices": [], "meta": null, "name": "version", "auto_created": true},
        ],

    }
);

export class Meta extends BaseModel{

    $classDescription = metaClassDescription;
    
    owner = null; 
    creator = null; 
    creationTime = null; 
    modificator = null; 
    modificationTime = null; 
    version = null; 

    constructor(parent, { owner,creator,creationTime,modificator,modificationTime,version }){
        super(parent,{  });
        
        this.owner = owner ? owner : null;
        this.creator = creator ? creator : null;
        this.creationTime = creationTime ? creationTime : null;
        this.modificator = modificator ? modificator : null;
        this.modificationTime = modificationTime ? modificationTime : null;
        this.version = version ? version : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            owner: observable,  
            creator: observable,  
            creationTime: observable,  
            modificator: observable,  
            modificationTime: observable,  
            version: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Meta(null,data);
    }
}

export const localizedContentClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.LocalizedContent",
        verboseName: "None",
        validationSchemas: {
        
            "language": Yup.string().max(2).required(),
            "translated": Yup.string().max(10).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 2, "blank": false, "verbose_name": {"en": "Language", "nl": "Taal"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [["nl", {"nl": "Nederlands"}], ["de", {"nl": "Duits"}], ["en", {"nl": "Engels"}], ["fr", {"nl": "Frans"}], ["fy", {"nl": "Fries"}]], "meta": null, "name": "language", "auto_created": false},
            {"max_length": 10, "blank": true, "verbose_name": {"en": "Translated", "nl": "Vertaald"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "translated", "auto_created": false},
        ],

    }
);

export class LocalizedContent extends BaseModel{

    $classDescription = localizedContentClassDescription;
    
    language = null; 
    translated = null; 

    constructor(parent, { language,translated }){
        super(parent,{  });
        
        this.language = language ? language : null;
        this.translated = translated ? translated : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            language: observable,  
            translated: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new LocalizedContent(null,data);
    }
}

export const nameClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.Name",
        verboseName: "None",
        validationSchemas: {
        
            "language": Yup.string().max(2).required(),
            "translated": Yup.string().max(10).nullable(),
            "value": Yup.string().max(80).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 2, "blank": false, "verbose_name": {"en": "Language", "nl": "Taal"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [["nl", {"nl": "Nederlands"}], ["de", {"nl": "Duits"}], ["en", {"nl": "Engels"}], ["fr", {"nl": "Frans"}], ["fy", {"nl": "Fries"}]], "meta": null, "name": "language", "auto_created": false},
            {"max_length": 10, "blank": true, "verbose_name": {"en": "Translated", "nl": "Vertaald"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "translated", "auto_created": false},
            {"max_length": 80, "blank": false, "verbose_name": {"en": "(Organisation) Name", "nl": "Organisatie Naam"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "value", "auto_created": false},
        ],

    }
);

export class Name extends LocalizedContent{

    $classDescription = nameClassDescription;
    
    value = null; 

    constructor(parent, { language,translated,value }){
        super(parent,{  language ,  translated  });
        
        this.value = value ? value : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            value: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Name(null,data);
    }
}

export const objectHierarchyLinkClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.ObjectHierarchyLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "rel": Yup.string(),
            "href": Yup.string(),
            "_path": Yup.string(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 40, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "rel", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "rel", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "href", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "href", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": " path", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "_path", "auto_created": true},
        ],

    }
);

export class ObjectHierarchyLink extends ObjectLink{

    $classDescription = objectHierarchyLinkClassDescription;
    
    _path = null; 

    constructor(parent, { id,rel,href,_path }){
        super(parent,{  id ,  rel ,  href  });
        
        this._path = _path ? _path : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            _path: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ObjectHierarchyLink(null,data);
    }
}

export const roleClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.Role",
        verboseName: "None",
        validationSchemas: {
        
            "_id": Yup.string().max(125),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 125, "blank": false, "verbose_name": " id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "_id", "auto_created": true},
        ],

    }
);

export class Role extends BaseModel{

    $classDescription = roleClassDescription;
    
    _id = null; 

    constructor(parent, { _id }){
        super(parent,{  });
        
        this._id = _id ? _id : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            _id: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Role(null,data);
    }
}

export const locationClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.Location",
        verboseName: "None",
        validationSchemas: {
        
            "lat": Yup.number().required(),
            "lng": Yup.number().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": null, "blank": false, "verbose_name": {"en": "Latitude", "nl": "Latitude"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.FloatField", "choices": [], "meta": null, "name": "lat", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": {"en": "Longitude", "nl": "Longitude"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.FloatField", "choices": [], "meta": null, "name": "lng", "auto_created": false},
        ],

    }
);

export class Location extends BaseModel{

    $classDescription = locationClassDescription;
    
    lat = null; 
    lng = null; 

    constructor(parent, { lat,lng }){
        super(parent,{  });
        
        this.lat = lat ? lat : null;
        this.lng = lng ? lng : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            lat: observable,  
            lng: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Location(null,data);
    }
}

export const descriptionClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.Description",
        verboseName: "None",
        validationSchemas: {
        
            "language": Yup.string().max(2).required(),
            "translated": Yup.string().max(10).nullable(),
            "value": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 2, "blank": false, "verbose_name": {"en": "Language", "nl": "Taal"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [["nl", {"nl": "Nederlands"}], ["de", {"nl": "Duits"}], ["en", {"nl": "Engels"}], ["fr", {"nl": "Frans"}], ["fy", {"nl": "Fries"}]], "meta": null, "name": "language", "auto_created": false},
            {"max_length": 10, "blank": true, "verbose_name": {"en": "Translated", "nl": "Vertaald"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "translated", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": {"en": "Description", "nl": "Omschrijving"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "value", "auto_created": false},
        ],

    }
);

export class Description extends LocalizedContent{

    $classDescription = descriptionClassDescription;
    
    value = null; 

    constructor(parent, { language,translated,value }){
        super(parent,{  language ,  translated  });
        
        this.value = value ? value : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            value: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Description(null,data);
    }
}

export const imageClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.Image",
        verboseName: "None",
        validationSchemas: {
        
            "thumbnailUrl": Yup.string().max(300).required(),
            "imageUrl": Yup.string().max(300).required(),
            "description": Yup.string().max(400).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 300, "blank": false, "verbose_name": "thumbnailUrl", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "thumbnailUrl", "auto_created": false},
            {"max_length": 300, "blank": false, "verbose_name": "imageUrl", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "imageUrl", "auto_created": false},
            {"max_length": 400, "blank": true, "verbose_name": "description", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "description", "auto_created": false},
        ],

    }
);

export class Image extends BaseModel{

    $classDescription = imageClassDescription;
    
    thumbnailUrl = null; 
    imageUrl = null; 
    description = null; 

    constructor(parent, { thumbnailUrl,imageUrl,description }){
        super(parent,{  });
        
        this.thumbnailUrl = thumbnailUrl ? thumbnailUrl : null;
        this.imageUrl = imageUrl ? imageUrl : null;
        this.description = description ? description : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            thumbnailUrl: observable,  
            imageUrl: observable,  
            description: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Image(null,data);
    }
}

export const anyObjectClassDescription = new ClassDescription(
    {
        clazzName : "kiini.core.persistent.AnyObject",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": null, "blank": true, "verbose_name": "id", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
        ],

    }
);

export class AnyObject extends BaseModel{

    $classDescription = anyObjectClassDescription;
    
    id = null; 

    constructor(parent, { id }){
        super(parent,{  });
        
        this.id = id ? id : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            id: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new AnyObject(null,data);
    }
}

