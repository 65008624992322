import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';



import { observer } from 'mobx-react'

import ActionButtons from 'kiini/ActionButtons';

const HEADER_HEIGHT = 64;

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    headerBox: {
        height: HEADER_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        alignItems: 'center',
    },
    ...theme.storytrails.paperStyle
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const SelectedObjectEditDialog = ({ store, open, handleClose, children, enableSaveButton, onSave }) => {

    const classes = useStyles();

    const selectedObject = store.selectedObject;

    return <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>

        <Box className={classes.headerBox} bgcolor="primary.main">
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
            </IconButton>
            <Box margin={3}>  
                <ActionButtons instance={selectedObject} store={store} enableSaveButton={enableSaveButton} onSave={onSave}/>
            </Box>
            <Typography variant="h6" className={classes.title}>
                {selectedObject.name}
            </Typography>
        </Box>
        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, margin: 10, overflow: 'scroll'}}>
            {children ? children: null}
        </div>
    </Dialog>
}


export default observer(SelectedObjectEditDialog);