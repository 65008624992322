import { ModelFactory, DEFAULT_FIELD_VALUES } from "jimbo/model"; 



const validatePasswordSame = (instance) => {
    const password = instance.getFieldValue("password");
    const passwordRepeat = instance.getFieldValue("passwordRepeat");

    if (password !== passwordRepeat){
        return [{"message": "Password and password repeat are not the same"}];
    }
    return null;
}


export const accountsPackageClassFactory = new ModelFactory();

const USERNAME_MIN_LENGTH = 4
const PASSWORD_MIN_LENGTH = 7

accountsPackageClassFactory.addClassDescriptionsData(
    [
        {
        clazzName: "jimbo_accounts.model.SignIn",
        verboseName: "Signin",
        fields: [
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "userName",
                "label": "UserName",
                "min_length": USERNAME_MIN_LENGTH,
                "max_length": 64,
                "type": "persistent.fields.CharField",
            }, {
                ...DEFAULT_FIELD_VALUES,
                "name": "password",
                "label": "Password",
                "min_length": PASSWORD_MIN_LENGTH,
                "type": "persistent.fields.CharField",
                "init_value": false,
            },
        ]
    },
        {
            clazzName: "jimbo_accounts.model.ForgotPassword",
            verboseName: "ForgotPassword",
            fields: [
                {
                    ...DEFAULT_FIELD_VALUES,
                    "name": "userName",
                    "label": "UserName",
                    "min_length": USERNAME_MIN_LENGTH,
                    "max_length": 64,
                    "auto_created": false,
                    "type": "persistent.fields.CharField",
                    "init_value": "",
                }, {
                    ...DEFAULT_FIELD_VALUES,
                    "name": "requested",
                    "auto_created": true,
                    "type": "persistent.fields.BooleanField",
                    "init_value": false,
                },
            ]
        },
        {
            clazzName: "jimbo_accounts.model.PasswordReset",
            verboseName: "PasswordReset",
            additionalValidationFuction: validatePasswordSame,
            fields: [
                {
                    ...DEFAULT_FIELD_VALUES,
                    "name": "id",
                    "auto_created": true,
                    "type": "persistent.fields.CharField",
                    "init_value": "",
                }, {
                    ...DEFAULT_FIELD_VALUES,
                    "name": "password",
                    "auto_created": false,
                    "type": "persistent.fields.CharField",
                    "min_length": 7,
                    "max_length": 64,
                    "init_value": "",

                },
                {
                    ...DEFAULT_FIELD_VALUES,
                    "name": "passwordRepeat",
                    "auto_created": false,
                    "type": "persistent.fields.CharField",
                    "min_length": 7,
                    "max_length": 64,
                    "init_value": "",
                },
                {
                    ...DEFAULT_FIELD_VALUES,
                    "name": "access",
                    "auto_created": true,
                    "type": "persistent.fields.CharField",
                    "init_value": "null",
                },
                {
                    ...DEFAULT_FIELD_VALUES,
                    "name": "refresh",
                    "auto_created": true,
                    "type": "persistent.fields.CharField",
                    "init_value": "null",
                },
            ]
        },
    ]
);
