import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import SelectFileContentDialog from 'components/dialogs/SelectFileContentDialog';
import {inputLabelStyle, formControlStyle } from 'kiini/Style';
import { ImageElement } from 'model/contentObjects';

import ImagesSlider from 'components/ImagesSlider';

const useStyles = makeStyles({
    card: {
        maxWidth: 345,
    },
});


const ImagesElementWidget = observer(({id, label, instance, store }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();


    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    }

    const handleClose = (selectedImage) => {
        console.log("Dialog selected ", selectedImage);
        setOpen(false);

        const imageElement = new ImageElement(
            null,{
                id: uuidv4(),
                contentObjectId: selectedImage.id,
                rendition:  selectedImage.renditions[0]
            }
        );
        instance.addToArray("values", imageElement);
    };

    const removeLatest = () => {
        const indxToRemove = instance.values.length - 1;
        instance.removeFromArray("values",indxToRemove)
    }

    const images = instance ? instance.values.map((imageElement)=>{
        const url =  imageElement.rendition.defaultImage ? imageElement.rendition.defaultImage.url : null;
        return {url}
    }): [];


    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        {open ?
            <SelectFileContentDialog assetType="image" typeLabel="Image" open={open} handleCancel={handleCancel} handleClose={handleClose}></SelectFileContentDialog>
            :null}
        <Card className={classes.card}>
            <CardActionArea>
               <ImagesSlider images={images} />
            </CardActionArea>
            <CardActions>
                <Button variant="contained" onClick={() => handleClickOpen()}>Add Image</Button>
                <Button variant="contained" onClick={() => removeLatest()} color="primary">
                    Remove latest
                </Button>
            </CardActions>
        </Card>
    </FormControl>
});

export default ImagesElementWidget;