
import { makeObservable, observable, action} from "mobx";
import {ExperienceEditorStore} from './ExperienceEditorStore';


export default class ExperienceStore {

    experienceEditorStore = undefined;

    constructor({rootStore}) {
        //this.experience = experience;
        this.rootStore = rootStore;
        makeObservable(this, {
            experienceEditorStore: observable.ref,
            loadExperience: action,
            newObject: action
        });
        
    }

    loadExperience = (experienceId) => {
        this.experienceEditorStore = new ExperienceEditorStore({rootStore: this.rootStore});
        this.experienceEditorStore.loadExperience(experienceId);
    }

    newObject = () => {
        this.experienceEditorStore = new ExperienceEditorStore({rootStore: this.rootStore});
        this.experienceEditorStore.newObject();
    }

    hasUnsavedChanges = () => {
        if (this.experienceEditorStore){
            return this.experienceEditorStore.experience.$instanceMeta.isTouched;
        };
        return false;
    }

}

