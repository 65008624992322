
import { action, computed, makeObservable, observable } from 'mobx';
import { LsKeys } from './LsKeys';

import {createTheme} from '../theme';
import {Storage} from '../utils/Storage';


export class PrefStore {
    rootStore;
    paletteType = 'dark';

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            paletteType: observable,
            theme: computed,
            init: action,
            toggleTheme: action,
            //newObject: action,
        });
    }

    // ----- Load from storage -----
    init() {
        this.paletteType = Storage.get(LsKeys.paletteType, 'dark');
    }

    // ----- Actions -----
    toggleTheme = () => {
        this.paletteType = this.paletteType === 'light' ? 'dark' : 'light';
        Storage.set(LsKeys.paletteType, this.paletteType);
    };

    // ----- Computed -----
    get theme() {
        // const palette = {
        //     primary: {
        //         main: '#556CD6',
        //     },
        //     secondary: {
        //         main: pink.A400,
        //     },
        //     error: {
        //         main: red.A400,
        //     },
        //     type: this.paletteType,
        //     // Initialize background to white (default is #fafafa)
        //     // This allows pictures with white background to blend in.
        //     background: {
        //         default: this.paletteType === 'light' ? '#ffffff' : '#303030',
        //     },
        // };

        return createTheme({paletteType: this.paletteType });
    }
}


