
import { analyticsApi } from 'Api';
import { makeObservable, observable, action } from "mobx";

import {
    // PERIOD_LAST_7_DAYS,
    // PERIOD_LAST_30_DAYS,
    // PERIOD_12_MONTH,
    PERIOD_6_MONTH,
    // PERIOD_THIS_MONTH,
     PERIOD_CUSTOM,
    // PERIOD_CHOICES
} from 'analytics/model';

const DATE_PARAMS_FORMAT = "YYYY-MM-DD"

const fromPeriodToParams = (periodSelection) => {
    if (periodSelection.value === PERIOD_CUSTOM){
        const dateStartStr = periodSelection.startDate.format(DATE_PARAMS_FORMAT)
        const dateEndStr  = periodSelection.endDate.format(DATE_PARAMS_FORMAT)
        return { date: `${dateStartStr},${dateEndStr}`, period: periodSelection.value}
    }else {
        return { period: periodSelection.value}
    }
}


export default class AnalyticsStore {

    downloadTimeseries = [];
    platformDownloadTimeseries = [];

    downloadAggregates = [];

    appSessionTimeseries = [];

    runStartTimeseries = [];
    platformRunStartTimeseries = [];
    
    runStartAggregates = [];
    

    selectedPeriod = { 
        value: PERIOD_6_MONTH, 
        startDate: null, 
        endDate: null 
    };


    defaultAnalyticsParams = {
        'metrics': 'count',
    }
    defaultDownloadsFilter = {
        'event:name': 'download',
        'event:domain': 'storytrails',
    }

    runStartsFilter = {
        'event:name': 'download',
        'event:domain': 'storytrails',
    }

    defaultAnalyticsAggregateParams = {
        'metric': 'count',
        'limit': 400,
        'page': 1,
        'timezone': 'Europe/Amsterdam'
    }



    constructor(rootStore) {
        //this.experience = experience;
        this.rootStore = rootStore;
        makeObservable(this, {
            downloadTimeseries: observable,
            platformDownloadTimeseries: observable,
            downloadAggregates: observable,
            appSessionTimeseries: observable,
            runStartTimeseries: observable,
            platformRunStartTimeseries: observable,
            selectedPeriod: observable,
            runStartAggregates: observable,
            loadObjects: action,
        });

    }

    loadObjects = () => {

        const publisherId = this.rootStore.authStore.selectedOrganisation ?
            this.rootStore.authStore.selectedOrganisation.id : null;

        if (publisherId) {
            const periodParams = fromPeriodToParams(this.selectedPeriod);
            const analyticsTimeSeriesParams = {...this.defaultAnalyticsParams, ...periodParams};

            const filter = { 'event:publisher_id': publisherId, ...this.defaultDownloadsFilter }
            analyticsApi.getTimeSeries(analyticsTimeSeriesParams, filter).then(
                action("loadAnalyticsDownloadResultsTimeseries", results => {
                    this.downloadTimeseries = results;
                })
            );

            const platformDownloadsFilter = { ...this.defaultDownloadsFilter }
            analyticsApi.getTimeSeries(analyticsTimeSeriesParams, platformDownloadsFilter).then(
                action("loadAnalyticsDownloadResultsTimeseries", results => {
                    this.platformDownloadTimeseries = results;
                })
            );

            const analyticsAggregateParams = {...this.defaultAnalyticsAggregateParams, ...periodParams};

            const aggregatesFilter = { 'event:publisher_id': publisherId, ...this.defaultDownloadsFilter }
            analyticsApi.getContentObjectAggregates(analyticsAggregateParams, aggregatesFilter).then(
                action("loadAnalyticsDownloadAggregates", response => {
                    this.downloadAggregates = response;
                })
            );

            const runStartFilter = { 
                'event:publisher_id': publisherId, 
                'event:name': 'run-start',
                'event:domain': 'storytrails'
            }
            analyticsApi.getTimeSeries(analyticsTimeSeriesParams, runStartFilter).then(
                action("runStartTimeseriesResults", results => {
                    this.runStartTimeseries = results;
                })
            );

            const platformRunStartFilter = {
                'event:name': 'run-start',
                'event:domain': 'storytrails'
            }
            
            analyticsApi.getTimeSeries(analyticsTimeSeriesParams, platformRunStartFilter).then(
                action("runStartTimeseriesResults", results => {
                    this.platformRunStartTimeseries = results;
                })
            ); 

            

            analyticsApi.getContentObjectAggregates(analyticsAggregateParams, runStartFilter).then(
                action("loadAnalyticsRunstartsAggregates", response => {
                    this.runStartAggregates = response;
                })
            );

            const appSessionsFilter = {'event:name': 'session-start'};
            analyticsApi.getTimeSeries(analyticsTimeSeriesParams, appSessionsFilter).then(
                action("loadAppsessionTimeseries", results => {
                    this.appSessionTimeseries = results;
                })
            );

        }
    }

    setPeriod = (selectedPeriod) => {
        this.selectedPeriod = {...selectedPeriod};
        this.loadObjects();
    }

}

