import * as React from "react";
import Box from '@material-ui/core/Box';
import IconButton  from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';

import {IoIosAddCircleOutline } from 'react-icons/io';

import { v4 as uuidv4 } from 'uuid';

import SelectFileContentDialog from 'components/dialogs/SelectFileContentDialog';

import ListPanel from 'kiini/ListPanel';
import ObjectList from "kiini/ObjectList";
import { Rendition } from "model/contentObjects";



const useStyles = makeStyles(theme => ({
    addPanel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }
}));

        

const RenditionsInputList = ({instance, store, fieldName, label, path, formConfigurations }) => {
    const classes = useStyles()

    const [open, setOpen] = React.useState(false);

    const handleClickOpenImageSelect = () => {
        setOpen(true);
    };

    const handleCancelImageSelect = () => {
        setOpen(false);
    }

    const handleCloseImageSelect = (selectedImage) => {
        console.log("Dialog selected ", selectedImage);
        setOpen(false);
        const newItem  = new Rendition(null, {
            ...selectedImage.renditions[0]
        });
        newItem.id = uuidv4();
        instance.addToArray(fieldName, newItem);      
    };

    return <Box border={1} padding={1} margin={1} borderColor="divider">
          {open ?
            <SelectFileContentDialog assetType="image" typeLabel="Image" open={open} handleCancel={handleCancelImageSelect} handleClose={handleCloseImageSelect}></SelectFileContentDialog>
           : null }
          <ListPanel label={label} />
          <Box >
            <ObjectList instance={instance} store={store} fieldName={fieldName} path={path} formConfigurations={formConfigurations} />
            <Box className={classes.addPanel}>
                <IconButton edge="end" aria-label="add" onClick={(event) => handleClickOpenImageSelect(event)} >
                    <IoIosAddCircleOutline size={16}/>
                </IconButton>
            </Box>
          </Box>
    </Box>
}




export default RenditionsInputList;