import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { observer } from "mobx-react";
import BaseInput from '../BaseInput';

import { inputLabelStyle, formControlStyle } from '../Style';

import { DateTimePicker } from "@material-ui/pickers";

import dayjs from 'dayjs';

const DateTimeField = ({ id, label, instance, fieldName, createItem, creationOptions, path, formConfigurations, ...rest }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const handleChange = (selectedDateTime) => {
        console.log("selected date ", selectedDateTime);
        if (selectedDateTime) {
            const dateTimeStrJson = selectedDateTime.toISOString()
            instance.setValue(fieldName, dateTimeStrJson);
        } else {
            instance.setValue(fieldName, null);
        }
    }

    const field = instance.$fields.get(fieldName);
    if (field) {
        const touched = field.touched;
        const hasErrors = field.errors;
        const value = field.value ? dayjs(field.value) : null;

        const showError = touched && hasErrors;

        const renderTextField = (props) => {
            return <BaseInput {...props} />
        }

        const isClearable = field.fieldDescriptor.isNull;

        return <FormControl error={showError} fullWidth classes={formControlClasses}>
            <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
            </InputLabel>
            <DateTimePicker
                TextFieldComponent={renderTextField}
                clearable={isClearable}
                format="dd/MM/YYYY HH:mm"
                label={label}
                value={value}
                //views={["year", "month", "date"]}
                onChange={handleChange}
            />
        </FormControl>
    } else {
        return <div>Error: Field {fieldName} to found</div>
    }
}


export default observer(DateTimeField);