import moment from 'moment';

export const PERIOD_LAST_7_DAYS = '7d'
export const PERIOD_LAST_30_DAYS = '30d'
export const PERIOD_12_MONTH = '12mo'
export const PERIOD_6_MONTH = '6mo'
export const PERIOD_THIS_MONTH = 'month'
export const PERIOD_CUSTOM = 'custom'

export const PERIOD_CHOICES = [
    {label: "Last 7 days",value: PERIOD_LAST_7_DAYS },
    {label: "Last 30 days",value: PERIOD_LAST_30_DAYS },
    {label: "Last month",value: PERIOD_THIS_MONTH },
    {label: "Last 6 months",value: PERIOD_6_MONTH },
    {label: "Last 12 months",value: PERIOD_12_MONTH },
]

export const periodToLabel = (periodValue) =>{
    const foundPeriod = PERIOD_CHOICES.find( period => period.value === periodValue );
    return foundPeriod ? foundPeriod.label : "Custom"; 
}




const DATE_INPUT_FORMAT = "YYYY-MM-DD";
const DATE_DISPLAY_FORMAT = "DD-MM-YYYY";

export const getTimeSeriesExportData = (timeseries, countName) => {
    const fields = ['date', countName];
    const data =  timeseries.results.map(result => [moment(result.date, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT), result.count])
    return {fields, data};
}

export const getAggregationExportData = (aggregates) => {

    const fields = ['name', 'id', 'count'];
    const data = aggregates.results.map((result) => [result.content_name, result.content_object_id, result.count]);
    return {fields, data};
}