import React from 'react';
import Dialog from '@material-ui/core/Dialog';
//import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
//import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

//import { AutoSizer } from 'react-virtualized';

import { Player } from 'video-react';

import { getDefaultImage } from 'model/content-object-utils';

import { observer } from 'mobx-react'

import FileAssetEditPanel from './FileAssetEditPanel';
import ActionButtons from 'kiini/ActionButtons';

import 'video-react/dist/video-react.css';

const HEADER_HEIGHT = 64;

const marginFileAssetRenderer = 16;
const totalMarginFileAssetRenderer = 2 * marginFileAssetRenderer;


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    headerBox: {
        height: HEADER_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        alignItems: 'center',
    },
    container: {
        display: 'flex', 
        flexDirection: 'row', 
        width: '100%', 
        height: `calc(100% - ${HEADER_HEIGHT}px)`
    },
    imageContainer: {
        flexGrow: 1, 
        flexShrink: 1, 
        margin: marginFileAssetRenderer, 
        width: `calc(100% - ${totalMarginFileAssetRenderer}px)`, 
        height: `calc(100% - ${totalMarginFileAssetRenderer}px)`,
    },
    propertyContainer: {
        flexGrow: 0, 
        flexShrink: 0, 
        width: 512, 
        margin: marginFileAssetRenderer, 
        height: `calc(100% - ${totalMarginFileAssetRenderer}px)`, 
        overflowY: 'auto'
    },
    propertyPanel: {
        marginRight: 24, // make room for scrollbar
    },
    ...theme.storytrails.paperStyle
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useImageRenderStyles = makeStyles(theme => ({
    root: {
        //    display: 'flex',
        //    flexDirection: "column",
    },
    imageContainer: {
        //    flexGrow: 1,
        //    flexShrink: 1
    }
}));


const ImageRenderer = ({ fileAsset }) => {

    const classes = useImageRenderStyles();

    const image = fileAsset;
    // const size = fileAsset.size;
    // const isPortrait = size.height > size.width;

    // const renderWithKnownSize = (width, height) => {
    //     const style = isPortrait ? { height: `${height}px`, width:`${width}px`, margin: "8px" } : { width: `${width}px`, height: `${height}px`, margin: "8px" };
    //     return 
    // }

    const imageBgStyle = {
        /* The image used */
        backgroundImage: `url("${image.url}")`,
        /* Full height */
        height: '100%',

        /* Center and scale the image nicely */
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
    }

    return  <div style={imageBgStyle} />
}

const VideoRenderer = (props) => {

    const image = getDefaultImage(props.fileAsset);

    return <Player playsInline
        poster={image.url}
        src={props.fileAsset.url} />
}


const AssetRenderMapping = {
    "content_objects.model.Image": ImageRenderer,
    "content_objects.model.Video": VideoRenderer,
    "content_objects.model.Audio": VideoRenderer
}



const FileAssetRenderer = (props) => {
    const fileAsset = props.fileAsset;
    const clazzName = fileAsset.$classDescription.clazzName;
    const Renderer = AssetRenderMapping[clazzName];
    if (Renderer) {
        return <Renderer fileAsset={fileAsset} />
    }
    return <div> No renderer found for file asset type {clazzName} </div>
}

const FileAssetDialog = ({ store, open, handleClose }) => {

    const classes = useStyles();

    const fileAsset = store.selectedObject;

    
    return <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>

        <Box className={classes.headerBox} bgcolor="primary.main">
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
            </IconButton>
            <Box margin={3}>  
                <ActionButtons instance={fileAsset} store={store} useStoreSelected={true}/>
            </Box>
            <Typography variant="h6" className={classes.title}>
                {fileAsset.name}
            </Typography>
        </Box>

        <div className={classes.container}>
            <div className={classes.imageContainer}>
                <FileAssetRenderer fileAsset={fileAsset} />
            </div>
            <div className={classes.propertyContainer}>
                <div className={classes.propertyPanel}>
                    <FileAssetEditPanel store={store} />
                </div>
            </div>
        </div>
    </Dialog>
}

export default observer(FileAssetDialog);