import {
  makeStyles,
} from '@material-ui/core/styles';



export const inputLabelStyle = makeStyles(theme => ({
    root: {
        paddingTop: 13,
        top: 'unset',
        left: 'unset',
        position: 'unset',
        fontSize: 12,
        color: theme.palette.label,
        width: 120,
        textAlign: 'right',
        marginRight: 8
    },
    shrink: {  // no shrinking of label
        transform: 'unset',
        transformOrigin: 'unset'
    },
    animated: {
      transition: 'unset'
    },
    formControl: {
      transform: 'unset',
      flexShrink: 0    
    }
}));

export const formControlStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        margin:  1,
        //height: '100%'
    }
}));