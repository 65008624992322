import * as React from "react";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
        paddingLeft: 4,
        minHeight: 24
    }
}));

const GroupPanel = ({label}) => {
    const classes = useStyles();
    return <Box className={classes.root} color="text.primary" bgcolor="divider">
        <Typography variant="h6" component="div">{label}</Typography>
    </Box>
}

export default GroupPanel;