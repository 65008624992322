import * as React from 'react';

import TableCell from '@material-ui/core/TableCell';


import moment from 'moment';

import ObjectSelectWidget from 'jimbo/ui/ObjectSelectWidget';
import { useRootStore } from 'contexts';

import { notificationPackageClassFactory } from "schema/notifications";

const experienceColumns = ({ sort }) => {
    return [
        {
            width: 400,
            label: 'Name',
            dataKey: 'name',
            sort: () => sort('name')
        },
        {
            width: 200,
            label: 'Last updated',
            dataKey: 'modificationTimeStr'
        },

    ]
};

const getRowDataFn = ({store}) => {

    return ({ index }) => {
        const resultObject = store.selectObjectsState.objects[index];

        const instance = resultObject.instance;
        const id = instance.id;
        const name = instance.name;
        const modificationTimeStr = moment(instance.meta.modificationTime).format('DD/MM/YYYY');
        return { id, name, modificationTimeStr, resultObject }
    }

}

const selectedRowToValue = (rowData) => {
    const initialData = {id: rowData.id, name: rowData.name};
    const publishedContentObjectProxy = notificationPackageClassFactory.createInstance("PublishedContentObjectProxy", {initialData});
    return publishedContentObjectProxy;
}


const experienceTableCellRenderer = ({ cellData, columnIndex, rowData }) => {

    const rowHeight = 48;
    const align = 'right'; //(columnIndex != null && experienceColumns[columnIndex].numeric) || false ? 'right' : 'left'
    return (
        <TableCell
            style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box', flex: 1, height: rowHeight }}
            component="div"
            variant="body"
            //          style={{  }}
            align={align}
        >
            {cellData}
        </TableCell>
    );

};



const SelectExperienceWidget = ({ id, label, instance, fieldName }) => {

    const rootStore = useRootStore();

    const valueToLabel = (value) => {
        const nameField = value ? value.$fields.get("name"): null;
        const name = nameField ? nameField.value : "";
        return name
    }
    const objectSelectDialogProps = {
        store: rootStore.publishedContentObjectsStore,
        columnsCreator: experienceColumns, // function
        cellRenderer: experienceTableCellRenderer, 
        getRowDataFn: getRowDataFn,
        selectedRowToValueFn: selectedRowToValue,
        label: "Select a Storytrail"
    }

    return <ObjectSelectWidget 
                id={id} 
                label={label}
                instance={instance} 
                fieldName={fieldName}
                objectSelectDialogProps={objectSelectDialogProps}
                valueToLabel={valueToLabel}
            />
};

export default SelectExperienceWidget;
