import * as React from 'react';

import { FormWidget, FormConfigurations } from 'jimbo/ui/Forms';
import TextField from 'jimbo/ui/TextField';
import DateTimeField from 'jimbo/ui/DateTimeField';
import SelectionChoicesField from 'jimbo/ui/SelectionChoicesField';


import SelectExperienceWidget from './SelectExperienceWidget';

const notificationFormConfigurationsData = {
    "Notification": {
        fields: [
            { 
                fieldName: "title", 
                label: "Title", 
                widget: TextField 
            },
            {
                fieldName: "text",
                label: "Text",
                widget: TextField,
                widgetProps: { 
                    multiline: true, 
                    rows: 5 
                }                
            },
            {
                fieldName: "notificationType",
                label: "Type",
                widget: SelectionChoicesField
            },
            {
                fieldName: "publishStartDateTime",
                label: "Start date",
                widget: DateTimeField
            },
            {
                fieldName: "publishEndDateTime",
                label: "End date",
                widget: DateTimeField
            },
            {
                fieldName: "publishedContentObject",
                label: "Storytrail",
                widget: SelectExperienceWidget,
            },
        ]
    }
}


const notificationFormConfigurations = new FormConfigurations(notificationFormConfigurationsData);


const NotificationForm = ({ instance, store }) => {
    return <React.Fragment>
        <FormWidget label="Notification" instance={instance} formConfigurations={notificationFormConfigurations} store={store}/>
    </React.Fragment>
}


export default NotificationForm;