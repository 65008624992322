import * as React from 'react';


import {FormWidget, FormConfigurations} from 'kiini/Forms';
import TextField from 'kiini/TextField';
import SelectionField from 'kiini/SelectionField';
import LineStyleWidget from 'components/contentObjects/LineStyleWidget';

const routeTypeOptions = [
    {label: "main route", value: "mainRoute"},
    {label: "approach route", value: "approachRoute"},
    {label: "shortening route", value: "shorteningRoute"},
    {label: "connection route", value: "connectionRoute"},
    {label: "extension route", value: "extensionRoute"},
    {label: "detour route", value: "detourRoute"},
    {label: "alternative route", value: "alternativeRoute"},
]

const formConfigurationData = {
       "content_objects.model.RoutePart": {
        groups:  [
            {
                label: "Track",
                fields: [
                    {fieldName: "name", label: "Name", widget: TextField},
                    {fieldName: "usage", label: "Usage", widget: SelectionField, widgetProps:{selectOptions: routeTypeOptions}},
                    {fieldName: "length", label: "Length (meters)", widget: TextField},
                    {fieldName: "lineStyle", label: "lineStyle", widget: LineStyleWidget},
                ]   
            }
        ]
    }
}

const formConfiguration = new FormConfigurations(formConfigurationData);

const ExperienceTrackForm = ({instance, store}) => {
    return <FormWidget instance={instance} formConfigurations={formConfiguration}  store={store}/>
}


export default ExperienceTrackForm;