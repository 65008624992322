import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';

import {FormWidget, FormConfigurations} from 'kiini/Forms';
import TextField from 'kiini/TextField';
import BooleanField from 'kiini/BooleanField';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';

import ImageElementWidget from './ImageElementWidget';
import ImagesElementWidget from './ImagesElementWidget';
import FileAssetElementWidget from './FileAssetElementWidget';
import HtmlContentElementWidget from './HtmlContentElementWidget';


import { 
    Heading1, 
    Heading2, 
    Paragraph, 
    MultipleChoiceQuestion, 
    MultipleChoiceAnswer, 
    ImageElement, 
    ImagesElement,
    AudioElement,
    VideoElement,
    HtmlContentElement
} from 'model/contentObjects';


import { AUDIO_ASSET_TYPE, VIDEO_ASSET_TYPE, IMAGE_ASSET_TYPE } from 'model/const';

const defaultImage = {
    url: null,
    size: { width: null, height: null },
    type: IMAGE_ASSET_TYPE
}


const imageElementInitialData = {
    rendition: {
        base: {
            url: null,
            size: { width: null, height: null },
            type: IMAGE_ASSET_TYPE
        },
        defaultImage,
        variants: {}
    }
}


const videoElementInitialData = {
    rendition: {
        base: {
            url: null,
            size: { width: null, height: null },
            type: VIDEO_ASSET_TYPE
        },
        defaultImage,
        variants: {}
    }
}

const audioElementInitialData = {
    rendition: {
        base: {
            url: null,
            type: AUDIO_ASSET_TYPE
        },
        defaultImage,
        variants: {}
    }
}



const compoundObjectChildCreationOptions = {
    "RichContent": {
        label: "Rich content", 
        creator: () => (new HtmlContentElement(null, {id: uuidv4()}))
    },
    "Heading1": {
        label: "Heading 1", 
        creator: () => (new Heading1(null, {id: uuidv4()}))
    },
    "Heading2": {
        label: "Heading 2", 
        creator: () => (new Heading2(null, {id: uuidv4()}))
    },
    "Paragraph": {
        label: "Paragraph", 
        creator: () => (new Paragraph(null, {id: uuidv4()}))
    },
    "MultiplechoiceQuestion": {
        label: "Multiplechoice question", 
        creator: () => (new MultipleChoiceQuestion(null, {id: uuidv4(),answers:[{id: uuidv4()},{id: uuidv4()}] }))
    },
    "Image": {
        label: "Image", 
        creator: () => (new ImageElement(null, {id: uuidv4(), ...imageElementInitialData}))
    },
    "Images": {
        label: "Images gallery (beta)", 
        creator: () => (new ImagesElement(null, {id: uuidv4(), values:[]}))
    },
    "Audio": {
        label: "Audio", 
        creator: () => (new AudioElement(null, {id: uuidv4(), ...audioElementInitialData}))
    },
    "Video": {
        label: "Video", 
        creator: () => (new VideoElement(null,{id: uuidv4(), ...videoElementInitialData}))
    }
}


const formConfigurationsData = {
    "content_objects.model.Heading1": {
        label: "Heading 1",
        fields: [
            {fieldName: "value",  label: "Name", widget: TextField},
        ]
    },
    "content_objects.model.Heading2": {
        label: "Heading 2",
        fields: [
            {fieldName: "value",  label: "Name", widget: TextField},
        ]
    },
    "content_objects.model.ImageElement": {
        widget: ImageElementWidget,
        label: "Image",
        // fields: [
        //     {fieldName: "value",  label: "Value", widget: ImageElementField},
        // ]
    },
    "content_objects.model.ImagesElement": {
        widget: ImagesElementWidget,
        label: "Images gallery (beta)",
    },
    "content_objects.model.VideoElement": {
        widget: FileAssetElementWidget,
        label: "Video",
        widgetProps: {assetType:VIDEO_ASSET_TYPE, selectLabel: "Select Video"}
        // fields: [
        //     {fieldName: "value",  label: "Value", widget: ImageElementField},
        // ]
    },
    "content_objects.model.AudioElement": {
        widget: FileAssetElementWidget,
        label: "Audio",
        widgetProps: {assetType:AUDIO_ASSET_TYPE, selectLabel: "Select Audio"}
        // fields: [
        //     {fieldName: "value",  label: "Value", widget: ImageElementField},
        // ]
    },
    "content_objects.model.HtmlContentElement": {
        widget: HtmlContentElementWidget,
        label: "Rich content",
        // fields: [
        //     {fieldName: "value",  label: "Value", widget: ImageElementField},
        // ]
    },
    "content_objects.model.Paragraph": {
        label: "Paragraph",
        fields: [
            {
                fieldName: "value",  
                label: "Value", 
                widget: TextField, 
                widgetProps: { 
                    multiline: true, 
                    rows: 5 }
                },
        ]
    },
    "content_objects.model.MultipleChoiceQuestion": {
        label: "Answers",
        //widget: MultipleChoiceQuestionWidget
        fields: [
            {
                fieldName: "question",  
                label: "Question", 
                widget: TextField, 
                widgetProps: { 
                    multiline: true, 
                    rows: 3 
                }
            },
            {
                fieldName: "answers", 
                label: "Answers", 
                widget: ObjectListWithPanel, 
                widgetProps: {
                   createItem: () => (new MultipleChoiceAnswer(null, {id: uuidv4()}))
                }
            },
        ]
    },
    "content_objects.model.MultipleChoiceAnswer": {
        label: "Answer",
        //widget: MultipleChoiceAnswerWidget
        fields: [
            {
                fieldName: "answer",  
                label: "Answer", 
                widget: TextField, 
                widgetProps: { 
                    multiline: true, 
                    rows: 3 
                }
            },
            {fieldName: "isCorrect",label: "Is corect", widget: BooleanField },
        ]
    },
    "content_objects.model.CompoundContentObject": {
        groups:  [
            {
                label: "Content",
                fields: [
                    {
                        fieldName: "children", 
                        label: "Elements", 
                        widget: ObjectListWithPanel, 
                        widgetProps: {
                            creationOptions: compoundObjectChildCreationOptions,
                            showInsertButtons: true
                        },
                    }
                ]   
            }
        ]
    }
}

const contentStoryBlockFormConfigurations = new FormConfigurations(formConfigurationsData);

const StoryBlockContentForm = ({instance, store}) => {
    return <FormWidget instance={instance} store={store} formConfigurations={contentStoryBlockFormConfigurations}/>
}


export default StoryBlockContentForm;