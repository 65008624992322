import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { observer } from "mobx-react";
  
import BaseInput from '../BaseInput';


const KiiniSelect = observer(({id, label, instance, fieldName, selectOptions, ...rest }) =>{


    const onChange = (evt) => {
        instance.setValue(fieldName, evt.target.value);
    } 

    
    const menuItems = selectOptions ? selectOptions.map( (entry,index) =>  <MenuItem key={index} value={entry.value}>{entry.label}</MenuItem>): null;
    const value = instance[fieldName] ? instance[fieldName]: "";

    return <Select
          id={id}
          value={value}
          onChange={onChange}
          label={label}
          input={<BaseInput />}
        >
          {menuItems}
        </Select>
});

export default KiiniSelect;