import React from 'react';
//import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react'
import InputBase from '@material-ui/core/InputBase';
import {fade as alpha, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
})
);


const TextSearch = observer(({store, searchTerm = "search.all", placeholder="Search…", showSearchIcon=true})=> {

  //const searchTerm = store.selectObjectsState.filter["search.all"] ?  store.selectObjectsState.filter["search.all"]: "";

  const classes = useStyles();

  const onSearchInputChange = (event) => {
    const value = event.target.value;
    if (value === ""){
      store.setFilterPart({ [searchTerm]: null });
    }else{
      store.setFilterPart({ [searchTerm]: value });
    }
  }

 

  return <div className={classes.search}>
      {showSearchIcon ? <div className={classes.searchIcon}>
        <SearchIcon />
      </div>: null}
      <InputBase
        onChange={(value => onSearchInputChange(value))}
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
</div>
});

export default TextSearch;