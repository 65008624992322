import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { observer } from 'mobx-react'

import { enUS } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

import moment from 'moment';

import {
  PERIOD_CUSTOM,
  PERIOD_CHOICES,
  periodToLabel

} from 'analytics/model';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));


const SelectPeriodMenu = observer(({ selectedPeriod, onSelectPeriod}) =>{
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  
  const [customDatesOpen, setCustomDatesOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const label = periodToLabel(selectedPeriod.value);

  const handleClose = (event, value) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    console.log("choosen value ", value);
    onSelectPeriod({value: value})

    setOpen(false);
  };

  const handleCloseAndShowDateDialog = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setCustomDatesOpen(true);
  }

  const handleCloseShowDate = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setCustomDatesOpen(false);
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const PeriodChoicesMenuItems = PERIOD_CHOICES.map( 
      periodChoice => <MenuItem key={periodChoice.value} onClick={(event) => handleClose(event, periodChoice.value)}>{periodChoice.label}</MenuItem>   
);

  

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [startDate, setStartDate] = React.useState()
  const endDate = null;
  const [focus, setFocus] = React.useState(START_DATE)
  const handleFocusChange = newFocus => {
    setFocus(newFocus || START_DATE)
  }

  const setEndDate = (value) => {
    onSelectPeriod({value: PERIOD_CUSTOM, startDate: moment(startDate), endDate: moment(value)})
    setCustomDatesOpen(false);
  }

  return (
    <div className={classes.root}>
      <div>


        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          endIcon={<ExpandMoreIcon />}
        >
          {label}
        </Button>
        <Popper open={customDatesOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper style={{padding: 24}}>
                <ClickAwayListener onClickAway={handleCloseShowDate}>
                  <div>
                <DateRangePickerCalendar
        startDate={startDate}
        endDate={endDate}
        focus={focus}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onFocusChange={handleFocusChange}
        locale={enUS} />
         </div>
                </ClickAwayListener>
               
              </Paper>
            </Grow>
          )}
        </Popper>
        
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    { PeriodChoicesMenuItems }
                    <MenuItem key="custom" onClick={handleCloseAndShowDateDialog}>Custom range</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
});


export default SelectPeriodMenu;