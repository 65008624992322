import React, { useState } from 'react'

import IconButton from '@material-ui/core/IconButton';

import ButtonGroup from '@material-ui/core/ButtonGroup';


import { RiH1, RiH2, RiH3, RiListOrdered, RiListUnordered, RiStrikethrough, RiBold, RiItalic, RiLink, RiUnderline, RiChatQuoteLine } from 'react-icons/ri'

import LinkDialog from './LinkDialog';

export const EditMenuButton = ({ onClick, isActive, icon, children }) => {
    return <IconButton
        onClick={onClick}
        color={isActive ? 'primary' : 'secondary'}
    >
        {icon}{children}
    </IconButton>
}



export const HeadingMenuGroup = ({ editor }) => {

    return <ButtonGroup variant="contained" color="primary">
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            isActive={editor.isActive('heading', { level: 1 })}
        >
            <RiH1 size={16} />
        </EditMenuButton>
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            isActive={editor.isActive('heading', { level: 2 })}
        >
            <RiH2 size={16} />
        </EditMenuButton>
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            isActive={editor.isActive('heading', { level: 3 })}
        >
            <RiH3 size={16} />
        </EditMenuButton>
    </ButtonGroup>
}

export const BoldItalicStrikeThroughMenuGroup = ({ editor }) => {


    return <ButtonGroup variant="contained" color="primary">
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
        >
            <RiBold size={16} />
        </EditMenuButton>
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}>
            <RiItalic size={16} />
        </EditMenuButton>

        <EditMenuButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            isActive={editor.isActive('strike')}>
            <RiStrikethrough size={16} />
        </EditMenuButton>
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            isActive={editor.isActive('underline')}>
            <RiUnderline size={16} />
        </EditMenuButton>
    </ButtonGroup>
}

export const ListMenuGroup = ({ editor }) => {

    return <ButtonGroup variant="contained" color="primary">
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
        >
            <RiListUnordered size={16} />
        </EditMenuButton>
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}>
            <RiListOrdered size={16} />
        </EditMenuButton>
    </ButtonGroup>
}

export const BlockQuotesMenuGroup = ({editor}) => {
    return <ButtonGroup variant='contained' color="primary">
        <EditMenuButton
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            isActive={editor.isActive('blockquote')}
        >
            <RiChatQuoteLine size={16} />
        </EditMenuButton>
    </ButtonGroup>
}

export const LinkMenuButton = ({ editor }) => {

    const [editLinkDialogState, setEditLinkDialogState] = useState({ open: false, value: { href: null } })

    const onLinkDialogClose = (selectedValue) => {

        setEditLinkDialogState({ open: false, value: { href: null } });

        const href = selectedValue.href;

        if (href === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run()
            return
        }
        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: href }).run()
    }

    const onLinkDialogCancel = () => {
        setEditLinkDialogState({ open: false, value: { href: null } });
    }

    const handleLink = () => {

        const previousUrl = editor.getAttributes('link').href;
        setEditLinkDialogState({ open: true, value: { href: previousUrl } });
    }

    return <React.Fragment>
        <LinkDialog
            value={editLinkDialogState.value}
            open={editLinkDialogState.open}
            onClose={onLinkDialogClose}
            onCancel={onLinkDialogCancel} />
        <EditMenuButton
            onClick={() => handleLink()}
            isActive={editor.isActive('link')}
        >
            <RiLink size={16} />
        </EditMenuButton>
    </React.Fragment>
}


export const LinkMenuGroup = ({ editor }) => {

    return <ButtonGroup variant="contained" color="primary">
        <LinkMenuButton editor={editor} />
    </ButtonGroup>
}


export const MenuBar = ({ editor }) => {

    if (!editor) {
        return null
    }

    return (
        <React.Fragment>
            <HeadingMenuGroup editor={editor} />
            <BoldItalicStrikeThroughMenuGroup editor={editor} />
            <ListMenuGroup editor={editor} />
            <LinkMenuGroup editor={editor} />
        </React.Fragment>
    )
}