import React from 'react';

import { observer } from "mobx-react";

const ErrorsInstance = observer(({instance})=> {

    const instanceErrors = instance.errors;
    if (instanceErrors){
        const errors = instanceErrors.map( instanceError => <div>{instanceError.message}</div>)
        return <React.Fragment>
            {errors}
        </React.Fragment>
    }else{
        return null;
    }

} );

export default ErrorsInstance;