// @flow

import * as React from 'react';
import { Map, TileLayer, Marker } from "react-leaflet";


require('./cssstyles.css');

export type LocationType = {
  lat: number,
  lng: number
}

type LeafletMapProps = {
  location?: LocationType,
  defaultCenter: LocationType,
  noLocationZoom: number,
  onLocationChange: any,
  height?: number
}

/*
type LeafletMapState = {
  center: LocationType,
  location?: LocationType,
  zoom: number,
};
*/




const LeafletMap = (props: LeafletMapProps) => {

  const used_center = props.location ? { lat: props.location.lat, lng: props.location.lng } : props.defaultCenter;
  const used_zoom = props.location ? 14 : props.noLocationZoom;

  /*
  Don't remove setCenter, setZoom state, otherwise the map is centered when clicking for a new location.
  ??? 
  */
  //const [center, setCenter] = React.useState(used_center);  // eslint-disable-line no-unused-vars
  //const [zoom, setZoom] = React.useState(used_zoom);  // eslint-disable-line no-unused-vars

  const zoom = used_zoom;
  const center = used_center;

  const updatePosition = (dragEndEvent: any, object?: any, index?: number) => {
    const location = dragEndEvent.target.getLatLng();
    if (props.onLocationChange) {
      props.onLocationChange(location);
    }
  }

  const handleClick = (mapClickEvent: any) => {
    props.onLocationChange({ lat: mapClickEvent.latlng.lat, lng: mapClickEvent.latlng.lng });
  }

  const marker = props.location ? <Marker
    draggable={true}
    onDragend={(dragEndEvent) => updatePosition(dragEndEvent)}
    position={props.location}>
  </Marker> : null;

  console.log("Center map, ", center);

  return (
    <div>
      <Map
        center={center}
        zoom={zoom}
        onClick={handleClick}
      >
        <TileLayer
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://tiles.jimbogo.com/netherlands/{z}/{x}/{y}.png"
        />
        {marker}
      </Map>
    </div>
  )
}

LeafletMap.defaultProps = {
  height: 300
};


export default LeafletMap;

/*
export default class LeafletMap extends Component<LeafletMapProps, LeafletMapState> {

  constructor(props: LeafletMapProps) {
    super(props);
    const center = props.location ? { lat: props.location.lat, lng: props.location.lng } : props.defaultCenter;
    const zoom = props.location ? 14 : props.noLocationZoom;
    this.state = {
      center,
      location: props.location,
      zoom
    };
  }


  updatePosition = (dragEndEvent: any, object?: any, index?: number) => {
    const location = dragEndEvent.target.getLatLng();
    this.setState({ location: { lat: location.lat, lng: location.lng } });
    if (this.props.onLocationChange) {
      this.props.onLocationChange(location);
    }
  }

  handleClick = (mapClickEvent: any) => {
    this.props.onLocationChange({ lat: mapClickEvent.latlng.lat, lng: mapClickEvent.latlng.lng });
  }

  render() {

    const marker = this.props.location ? <Marker
      draggable={true}
      onDragend={(dragEndEvent) => this.updatePosition(dragEndEvent)}
      position={this.props.location}>
    </Marker> : null;
    return (
      <div style={{ height: "300px", position: "relative" }}>
        <Map
          center={this.state.center}
          zoom={this.state.zoom}
          onClick={this.handleClick}
        >
          <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {marker}
        </Map>
      </div>
    )
  }
}
*/
