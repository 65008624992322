import React from 'react'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UploadFileAssetPanel from 'components/fileAssets/UploadFileAssetPanel';
import FileAssetsGrid from 'components/fileAssets/FileAssetsGrid';
import FileAssetEditPanel from 'components/fileAssets/FileAssetEditPanel';
import FixedToolbar from 'components/FixedToolbar'
import MainContentLayout from 'layouts/MainContentLayout';
import ActionButtons from 'kiini/ActionButtons';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts'

const UploadFileAssetScreen = observer((props) => {

    const rootStore = useRootStore();
    const routerStore = useRouterStore();
    const { fileAssetsStore, authStore } = rootStore;
    const uploadedFileAssets = fileAssetsStore.uploadedFileAssets;
    const selectedUploadedFileAsset = fileAssetsStore.selectedObject;

    const organisationId = authStore.selectedOrganisation.id;

    const onSelectUploadedFileAsset = (selectedUploadedFileAsset) => {
        fileAssetsStore.selectObject(selectedUploadedFileAsset.id);
    };

    const onClickBack = () => {
        fileAssetsStore.resetUploaded();
        fileAssetsStore.loadObjects();
        routerStore.goTo('fileAssets')
    }

    const back = <Button
        onClick={() => onClickBack()}
        color="primary"
        size="small"
        variant="text">
        <ArrowBackIcon />
        Back to overview
    </Button>

    const toolbar = <FixedToolbar back={back}></FixedToolbar>

    return <MainContentLayout toolbar={toolbar}>
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <UploadFileAssetPanel fileAssetsStore={fileAssetsStore} authStore={authStore} organisationId={organisationId} />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={4}>
                    <FileAssetsGrid
                        fileAssets={uploadedFileAssets}
                        setSelectedFileAsset={onSelectUploadedFileAsset}
                        selectedFileAsset={selectedUploadedFileAsset}
                        col={1}
                    />
                </Grid>
                <Grid item xs={8}>
                    {fileAssetsStore.selectedObject ?
                        <React.Fragment>
                            <Box margin={3}>
                                <ActionButtons instance={fileAssetsStore.selectedObject} store={fileAssetsStore} useStoreSelected={true}/>
                            </Box>
                            <FileAssetEditPanel store={fileAssetsStore} organisationId={organisationId} />
                        </React.Fragment> : null}
                </Grid>
            </Grid>
        </Grid>
    </MainContentLayout>
});


export default UploadFileAssetScreen;

