import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

//import { createStyles, makeStyles } from '@material-ui/core/styles';


import { observer } from 'mobx-react'


import UploadPanel from './UploadPanel';
import RunnableObjectForm from './RunnableObjectForm';
import PreviewUploadFiles from './PreviewUploadFiles';
import RunnableObjectPackagesForm from './RunnableObjectPackagesForm';


const RunnableObjectEditPanel = observer(({ store }) => {

    const selectedObject = store.selectedObject;
    const files = store.files;

    const accept = {
        'application/*': ['.zip', '.pck']
    }

    const onAddFile = (file) => {
        store.addFile(file);
    }

    return <Grid container>
        <Grid item xs={6}>
            <RunnableObjectForm instance={selectedObject} />
        </Grid>
        <Grid item xs={6}>
            <Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
                <UploadPanel files={files} accept={accept} onAddFile={onAddFile} />
                <PreviewUploadFiles files={files}/>
                <RunnableObjectPackagesForm instance={selectedObject}/>
            </Box>
        </Grid>
    </Grid>
});

export default RunnableObjectEditPanel;

