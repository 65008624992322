import * as React from "react";
import Box from '@material-ui/core/Box';
import IconButton  from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {IoIosAddCircleOutline } from 'react-icons/io';

import ListPanel from 'kiini/ListPanel';
import ObjectList from "kiini/ObjectList";

const useStyles = makeStyles(theme => ({
    addPanel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }
}));    

const ObjectListWithPanel = (
    {
        instance, 
        store,
        fieldName, 
        label, 
        path, 
        formConfigurations, 
        creationOptions, 
        itemFilterFuction, 
        createItem, 
        oneInstancePerClassInList,
        showInsertButtons
    }) => {

    const classes = useStyles()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [insertIndex, setInsertIndex] = React.useState(null);

    const handleClick = (event) => {
        if (creationOptions){
            // show menu for choice
            setAnchorEl(event.currentTarget);
        }else if(createItem){
            const newItem = createItem();
            instance.addToArray(fieldName, newItem);      
        }
    };

    const onHandleInsert = (event, insertAtIndex) => {
        if (creationOptions){
            // show menu for choice
            setInsertIndex(insertAtIndex);
            setAnchorEl(event.currentTarget);
        }else if(createItem){
            const newItem = createItem();
            instance.insertIntoArray(fieldName, insertAtIndex, newItem);      
        }
    }
  
    const handleClose = () => {
        setAnchorEl(null);
    }  

    const checkForOneInstance = typeof oneInstancePerClassInList == 'undefined' ? false: oneInstancePerClassInList;

    const select = (selection) => {
      setAnchorEl(null);
      const newItem = creationOptions[selection].creator();
      if(insertIndex === null){
        instance.addToArray(fieldName, newItem);
      }else{
        instance.insertIntoArray(fieldName, insertIndex, newItem);
      }
      setInsertIndex(null);
    };
    
    const someInstanceClassInList = (clazzName) => {
        const values = instance[fieldName];
        return values ? values.some( item => item.$classDescription.clazzName === clazzName ): false;
    }

    const menuItems = creationOptions ? Object.keys(creationOptions).map( 
        (creationOptionClazzName,index) => {
            if(checkForOneInstance && someInstanceClassInList(creationOptionClazzName)){
                return null;
            }
            return (<MenuItem key={index} onClick={() => select(creationOptionClazzName)}>{creationOptions[creationOptionClazzName].label}</MenuItem>)} 
    ) : null;

    
    return <Box border={1} padding={1} marginTop={1} marginBottom={1} borderColor="divider">
          <ListPanel label={label} />
          <Box >
            <ObjectList 
                instance={instance} 
                store={store}
                fieldName={fieldName} 
                path={path} 
                formConfigurations={formConfigurations}  
                itemFilterFuction={itemFilterFuction}
                showInsertButtons={showInsertButtons}
                onHandleInsert={onHandleInsert}
                />
            <Box className={classes.addPanel}>
                <IconButton edge="end" aria-label="add" onClick={(event) => handleClick(event)} >
                    <IoIosAddCircleOutline size={16}/>
                </IconButton>
            </Box>
          </Box>
          <Menu id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
        {menuItems}
      </Menu>
    </Box>
}


export default ObjectListWithPanel;