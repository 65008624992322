import * as React from 'react';


import {FormWidget, FormConfigurations} from 'kiini/Forms';


import SelectMapConfigWidget from 'components/contentObjects/SelectMapConfigWidget';


const formConfigurationData = {
       "content_objects.model.EditableExperienceMap": {
        groups:  [
            {
                label: "Map configuration",
                fields: [
                    {fieldName: "mapConfigLink", label: "map", widget: SelectMapConfigWidget},
                ]   
            }
        ]
    }
}

const formConfiguration = new FormConfigurations(formConfigurationData);

const EditableExperienceMapForm = ({instance, store}) => {
    return <FormWidget instance={instance} formConfigurations={formConfiguration} store={store}/>
}


export default EditableExperienceMapForm;