
import { makeObservable, observable, action, runInAction} from "mobx";


export default class AbstractBaseInstanceStore {

    selectedObject = undefined;
    
    constructor({rootStore, api}) {
        this.rootStore = rootStore;
        this.api = api;
        makeObservable(this, {
            
            selectedObject: observable.ref,  
            selectObject: action,
            setSelectedObject: action,
            newObject: action,
            clear: action,
        });
    }

    _createNewObject = (initValues) => {
        return null;
    }

    newObject = (initValues) => {
        this.selectObject = this._createNewObject(initValues)
    }

    _transformJsonToObject = (objectAsJson) => {
        return objectAsJson;
    }
 
    _selectObjectAction = (objectData) => {
        this.selectedObject = this._transformJsonToObject(objectData);
    }

    _afterSelectObject = () => {
        // template method
    }

    selectObject = (id) => {
        this.api.getObject(id).then(
            action("selected Object", objectData => {
                this._selectObjectAction(objectData);
                this._afterSelectObject();
            })
        );
    }


    setSelectedObject = (instance) => {
        this.selectedObject = instance;
    }
 
    clear = () => {
        console.log("cleared object")
        this.selectedObject = null;
    }

    _createNewObject = (initValues) => {
        return null;
    }

    newObject = (initValues) => {
        this.selectedObject = this._createNewObject(initValues)
    }

    save = (instance) => {
    
        const isValid = instance.validate({touched:true});
        if (isValid){
            const instanceJson = instance.toJSON();
            this.api.save(instanceJson).then(
                action("selected Object", savedInstance => {
                    this.selectedObject = this._transformJsonToObject(savedInstance);
                })
            );
        }
    }

    saveAsCopyAsync = async() => {
        this.selectedObject.renewIds();
        this.selectedObject.id = null;
        return await this.saveAsync(); 
    }   

    saveAsync = async() => {
        const isValid = this.selectedObject.validate({touched:true});
        if (isValid){
            try{
                const instanceJson = this.selectedObject.toJSON();
                const savedInstanceJson = await this.api.save(instanceJson);
                runInAction(() => {
                    this.selectedObject = this._transformJsonToObject(savedInstanceJson);
                    console.log("saved instance id ", this.selectedObject.id);
                });
                return true;
            }catch(invalidObject){
                // merge errors
                return false;
            };
        }else{
            const errors = this.selectedObject.getErrors();
            console.log("invalid ", errors);
            return new Promise((resolve, reject) => {
                resolve(false);
            })
        }
    }

    delete = (id) => {
        this.api.delete(id).then(
            action("delete Object", id => {
                this.selectedObject = null;
            })    
        );
    }

    hasUnsavedChanges = () => {
        return this.selectedObject ? this.selectedObject.$instanceMeta.isTouched : false;
    }

}

