import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import AccountMenu from './AccountMenu';

const useStyles = makeStyles((theme) =>
	createStyles({

		toolbar: {
			justifyContent: 'space-between',
			flexGrow: 1,
			backgroundColor: theme.palette.primary.main,
		},
		toolbarElements: {
			display: 'flex',
			flexDirection: 'row',
			'& > *': {
			  margin: theme.spacing(1),
			},
		},
		back: {
			width: 240,
			//background: theme.creator.sideBarMenuBackGround,
			//borderRadius: 4,
			padding: theme.spacing(1),
        	flexGrow: 0,
			display: 'flex'
		},
		appBar: {
			backgroundColor: theme.palette.background.default,
			flexDirection: 'row',
			color: '#fff'
		}
	})
);

const FixedToolbar = (props) => {

	const classes = useStyles();

	return	<AppBar position="sticky" className={classes.appBar} >
		{props.back ? <Box className={classes.back}>
			{props.back}
		</Box>: null}
		<Toolbar className={classes.toolbar}>
			<div className={classes.toolbarElements}>
				{props.children}
			</div>
			<AccountMenu />
		</Toolbar>		
	</AppBar>		
}

export default FixedToolbar;
