import React from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import StoreIcon from '@material-ui/icons/Store';
import DeleteIcon from '@material-ui/icons/Delete';

import { observer } from "mobx-react";


const usesStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1)
    },
    buttons: {
        marginRight: theme.spacing(1),
    },
  }));


const ActionButtons = ({store, instance, onSave, enableSaveButton, useStoreSelected}) => {

    const classes = usesStyles();

    const useSelected = useStoreSelected != null ? useStoreSelected : false;

    console.log("instance ", instance)

    const saveInstance = () => {
        if (onSave){
            onSave();
        }else{
            if (useSelected){
                store.saveSelectedObject()
            }else{
                store.save(instance)
            }
        }
    }

    const deleteInstance = () => {
        if (useSelected){
            store.deleteSelectedObject();
        }else{    
            store.delete(instance.id)
        }
    }

    const enableSave = enableSaveButton ? enableSaveButton : instance.$instanceMeta.isTouched;
    const enableDelete = instance.id;

    return <div className={classes.root} >
        <Button variant="contained"
            color="primary"
            className={classes.buttons}
            onClick={() => saveInstance()}
            disabled={!enableSave}
        >
            Save changes
            <StoreIcon />
        </Button>
        {enableDelete ?
        <Button
            className={classes.buttons}
            variant="contained"
            color="secondary"
            disabled={false}
            onClick={() => deleteInstance()}
        >
            Delete
            <DeleteIcon />
        </Button>: null}
    </div>
}

export default observer(ActionButtons);