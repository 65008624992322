import * as React from 'react';
import { useRootStore } from 'contexts';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';

import { SELECTED_OBJECT_STATE_SAVING } from 'jimbo/store';

import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Grid,
  Button,  
  Typography,
  InputLabel
} from '@material-ui/core';


import MinimalLayout from 'layouts/Minimal'

import FieldInput from 'jimbo/ui2/FieldInput';


const useStyles = makeStyles(theme => ({
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  content: {
    height: '100%',
    justifyContent: 'center'
  }, 
  textField: {
    marginTop: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(1)
  }
}));



const ForgotPasswordFormAction = observer(({ forgotPassword, forgotPasswordStore }) => {

  const classes = useStyles();

  const saveInstance = () => {
    forgotPasswordStore.save(forgotPassword)
  }

  const routerStore = useRouterStore();

  const onBackToSignin = () => {
    routerStore.goTo("signin")
  }

  if (forgotPassword) {

   
    const isSaving = forgotPasswordStore.selectedObjectState === SELECTED_OBJECT_STATE_SAVING;
    const canSave = forgotPassword.touched && forgotPassword.valid && !isSaving;

    const id = "userNameInput";

    const userNameField = forgotPassword.getField("userName");

    return <Grid style={{marginTop: 16}} container direction='column'>
      
      <Grid item>
        <InputLabel shrink={false} htmlFor={id}>{userNameField.label}</InputLabel>
      </Grid>
      <Grid item style={{marginTop: 8}}> 
        <FieldInput id={id} field={userNameField} />
      </Grid>
      <Grid item>
        <Button variant="contained"
          color="primary"
          className={classes.buttons}
          onClick={() => saveInstance()}
          disabled={!canSave}
        >
          Send reset instructions
        </Button>
        <Button color="secondary" onClick={onBackToSignin}>
          Back to signin
        </Button>
      </Grid>
    </Grid>
  } else {
    return null;
  }
});

const PasswordIsRequestedMessage = () => {
  return <Grid item>
    <Box>Your password reset is requested, check your mail.</Box>
  </Grid>
}

const ForgotPasswordScreen = observer((props) => {

  const classes = useStyles();

  //const routerStore = useRouterStore();
  const rootStore = useRootStore();
  const { forgotPasswordStore } = rootStore;
  const forgotPassword = forgotPasswordStore.selectedObject;

  const isRequested = forgotPassword.getFieldValue('requested');

  return (
    <MinimalLayout>
      <Grid
        className={classes.grid}
        container>
        <Grid
          className={classes.quoteContainer}
          item
          quoteContainer
          lg={5}>
        </Grid>
        <Grid
          className={classes.content}
          container
          item
          direction="column"
          lg={7}
          xs={12}
        >
          <Grid item>
            <Grid>
              <Typography variant="h3">Forgot Password?</Typography>
              <Typography variant='body1'>
                Enter the username and we’ll send you instructions to reset your password.
              </Typography>
              <Typography variant='body1'>
                For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
              </Typography>
            </Grid>

            <Grid container
              direction="row"
              alignItems="center">
              {isRequested ? <PasswordIsRequestedMessage /> : <ForgotPasswordFormAction forgotPassword={forgotPassword} forgotPasswordStore={forgotPasswordStore} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MinimalLayout>
  );
});

export default ForgotPasswordScreen;
