
import React from 'react';
import {
    createStyles,
    makeStyles,
  } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';

import LeafletMap from 'components/widgets/LeafletMap';
/*
Note: css for leaflet is added in index.html
*/


import { observer } from "mobx-react";

import {Location} from "model/contentObjects";

import {inputLabelStyle, formControlStyle } from '../Style';


const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			marginTop: theme.spacing(1),
		},
        mapContainer: {
            //flex: '1 1 auto',
            height: 500,
            width: "100%",
            position: 'relative',
            backgroundColor: 'red'
        }
    }),
    
);



const LocationField = observer(({id, label, instance, fieldName, ...rest }) =>{

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();
    const classes = useStyles()


    const value = instance[fieldName] ? instance[fieldName]: "";
    const toLocation = (value => ({lat: value.lat, lng: value.lng}))

    const location = value ? toLocation(value): undefined

    const defaultCenter = location ? location : {lat : 52.34507193751071, lng : 5.625};
    const noLocationZoom = 7;

    const onLocationChange = (location) => {
        const newLocation = new Location(null, {...location});
        instance.setValue(fieldName, newLocation);
    }

    const deleteLocation = () => {
        instance.setValue(fieldName, undefined);
    }

    // return <Select label={props.label} name={props.name} required={props.required} formControlProps={{variant:"outlined"}} data={LocaleOptionsData} />
    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
        </InputLabel>
        <Grid container >
            <Grid item xs={12} className={classes.mapContainer}>
            <LeafletMap
                height={600}
                defaultCenter={defaultCenter} 
                location={location} 
                noLocationZoom={noLocationZoom}
                onLocationChange={(location) => onLocationChange(location)} />
            </Grid>
            <Grid item xs={12} >
            
            <Button
                className={classes.button}
                disabled={!location}
                disableElevation 
                variant="contained" onClick={() => deleteLocation()} color="primary">
                Remove location
            </Button>
            </Grid>
        </Grid>
  </FormControl>
});

export default LocationField;