import React from 'react';
import MovieIcon from '@material-ui/icons/Movie'
import AudioTrackIcon from '@material-ui/icons/Audiotrack'
import ImageIcon from '@material-ui/icons/Image'
import AddTrailIcon from '@material-ui/icons/Timeline'
import StoryBlockIcon from '@material-ui/icons/Message'
import CollectionsIcon from '@material-ui/icons/Collections';
//import GodotSceneIcon from '@material-ui/icons/Games'

import { UploadTracksDialogAdapter } from './DialogWrappers';
import SelectFileContentDialog from 'components/dialogs/SelectFileContentDialog'
import SelectStoryBlockDialog from 'components/dialogs/SelectStoryBlockDialog'
import SelectRunnableObjectDialog from 'components/dialogs/SelectRunnableObjectDialog'
//import SpotAmentiesDialog from 'components/dialogs/SpotAmentiesDialog'

import {
    OBJECT_TYPE_TRACK, 
    OBJECT_TYPE_IMAGE, 
    OBJECT_TYPE_VIDEO, 
    OBJECT_TYPE_AUDIO, 
    OBJECT_TYPE_RUNNABLE_OBJECT,
//    OBJECT_TYPE_SCENE,
    OBJECT_TYPE_STORYBLOCK,
//    OBJECT_TYPE_SPOT,
//    OBJECT_TYPE_INSTRUCTION,
//    OBJECT_TYPE_START_SPOT,
//    CONTENT_OBJECTS_LIST,
//    ROUTE_PART_lIST,
//    INSTRUCTION_LIST,
//    START_SPOTS_LIST,
//    ROUTE_GENERAL_TYPE
    IMAGE_ASSET_TYPE,
    AUDIO_ASSET_TYPE,
    VIDEO_ASSET_TYPE

} from 'model/const';


class ObjectTypeRegistry {

    constructor() {
        this.locationObjectTypeRegistry = new Map();  // types for which 
        this.experienceObjectTypeRegistry = new Map();
        
    }

    registerLocationObjectType = (typeName, typeLabel, Icon, DialogClass, order, tooltipTitle) => {
        this.locationObjectTypeRegistry.set(typeName, { typeName, typeLabel, Icon, DialogClass, order, tooltipTitle })
    }

    


    registerExperienceObjectType = (typeName, typeLabel, Icon, DialogClass, order, tooltipTitle) => {
        this.experienceObjectTypeRegistry.set(typeName, { typeName, typeLabel, Icon, DialogClass, order, tooltipTitle })
    }



    // getExperienceObjectDialogClazz = (typeName) => {
    //     const hasType = this.experienceObjectTypeRegistry.has(typeName)
    //     if (hasType) {
    //         const registration = this.experienceObjectTypeRegistry.get(typeName);
    //         return registration.DialogClass;

    //     }
    // }

    // getLocationObjectDialogClazz = (typeName) => {
    //     const hasType = this.locationObjectTypeRegistry.has(typeName)
    //     if (hasType) {
    //         const registration = this.locationObjectTypeRegistry.get(typeName);
    //         return registration.DialogClass;
    //     }
    // }

    getDialogClazz = (typeName) => {
        const hasType = this.experienceObjectTypeRegistry.has(typeName)
        if (hasType) {
            const registration = this.experienceObjectTypeRegistry.get(typeName);
            return registration.DialogClass;
        }
        const hasLocationType = this.locationObjectTypeRegistry.has(typeName)
        if (hasLocationType) {
            const aregistration = this.locationObjectTypeRegistry.get(typeName);
            return aregistration.DialogClass;
        }
        
    }

    getExperienceObjectTypes = (locale) => {
        // return code/ name/icon
        let result = []

        this.experienceObjectTypeRegistry.forEach((value, key) => {
            result.push(value)
        })
        return result;
    }

    getLocationObjectTypes = (locale) => {
        let result = []
        this.locationObjectTypeRegistry.forEach((value, key) => {
            result.push(value)
        })
        return result;
    }

}


const SelectImageDialogAdapter = (props) => {
    return <SelectFileContentDialog assetType={IMAGE_ASSET_TYPE} typeLabel="Image" {...props} />
}

const SelectAudioDialogAdapter = (props) => {
    return <SelectFileContentDialog assetType={AUDIO_ASSET_TYPE} typeLabel="Audio" {...props} />
}

const SelectVideoDialogAdapter = (props) => {
    return <SelectFileContentDialog assetType={VIDEO_ASSET_TYPE} typeLabel="Video" {...props} />
}


const objectTypeRegistry = new ObjectTypeRegistry();

objectTypeRegistry.registerExperienceObjectType(OBJECT_TYPE_TRACK, "Track", AddTrailIcon, UploadTracksDialogAdapter, 1, "Add a track")

objectTypeRegistry.registerLocationObjectType(OBJECT_TYPE_IMAGE, "Image", ImageIcon, SelectImageDialogAdapter, 0, "Add a image")
objectTypeRegistry.registerLocationObjectType(OBJECT_TYPE_VIDEO,"Video", MovieIcon, SelectVideoDialogAdapter, 1, "Add a video")
objectTypeRegistry.registerLocationObjectType(OBJECT_TYPE_AUDIO,"Audio", AudioTrackIcon,SelectAudioDialogAdapter, 2, "Add an audio")
objectTypeRegistry.registerLocationObjectType(OBJECT_TYPE_RUNNABLE_OBJECT,"App", CollectionsIcon,SelectRunnableObjectDialog, 3, "Add an app object")

//objectTypeRegistry.registerLocationObjectType(OBJECT_TYPE_SCENE,"Godot Scene",GodotSceneIcon, SelectFileContentDialog, 3) 
objectTypeRegistry.registerLocationObjectType(OBJECT_TYPE_STORYBLOCK, "Story Block", StoryBlockIcon, SelectStoryBlockDialog, 4, "Add a storyblock")



export default objectTypeRegistry