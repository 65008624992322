import React from 'react';
import { makeStyles } from '@material-ui/styles';

//import Box from '@material-ui/core/Box';
//import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
  heading: {
      color: theme.palette.text.accentuation
  }
}));

const BoxHeader = ({ header, subHeader, rightMenu }) => {

  const classes = useStyles();

  return <Grid
    container
    direction="row"
    justify="space-between"
    alignItems="flex-start">
    <Grid item direction="column" >
      <Grid item >
        <Typography variant="h5" gutterBottom component="div">
          {subHeader}
        </Typography>
      </Grid>
      <Grid item >
        <Typography className={classes.heading} variant="h3" gutterBottom component="div">
          {header}
        </Typography>
      </Grid>
    </Grid>
    <Grid item>
      {rightMenu}
    </Grid>
  </Grid>
}

export default BoxHeader;