import React, { useState } from 'react'

import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import { RiMindMap } from 'react-icons/ri'

import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import Link from 'tiptap-extensions/link'
import ContentObjectlink from 'tiptap-extensions/content-object-link';
import ExtensionUnderline from '@tiptap/extension-underline';


import { EditMenuButton, HeadingMenuGroup, BoldItalicStrikeThroughMenuGroup, ListMenuGroup, BlockQuotesMenuGroup, LinkMenuButton } from 'tiptap/menu';
import SelectStoryblockForLinkDialog from './SelectStoryblockForLinkDialog';

import { getStoryPointUrl, editableToPublishedId } from 'model/content-object-utils'

import './tiptap.css';


const useStyles = makeStyles(theme => ({
    editorMenubar: {
       marginBottom:  theme.spacing(1),
    }
}));


const EditObjectLinkMenuButton = ({ editor }) => {

    const [selectContentDialogState, setSelectContentDialogState] = useState({ open: false, value: null })


    const onSelectContentDialogClose = (selectedValue) => {
        setSelectContentDialogState({ open: false, value: null });
        if (selectedValue) {
            const editableId = selectedValue.id;
            const publishedId = editableToPublishedId(editableId);
            const sharingHost = 'https://storytrails.eu'; 
            const url = getStoryPointUrl(selectedValue, sharingHost);

            const contentObjectLinkAttrs = {
                href: url,
                'data-content-object-type': "storyblock",
                'data-content-object-id': publishedId,
            }
            editor.chain().focus().extendMarkRange('content-object-link').setContentObjectLink(contentObjectLinkAttrs).run()
        } else {
            editor.chain().focus().extendMarkRange('link').unsetContentObjectLink().run()
        }
    }

    const onSelectContentDialogCancel = () => {
        setSelectContentDialogState({ open: false, value: null });
    }

    const handleContentObjectLink = () => {
        const previousUrl = editor.getAttributes('content-object-link').href;
        setSelectContentDialogState({ open: true, value: { 'content-object-link': previousUrl } });
    }

    return <React.Fragment>
        {selectContentDialogState.open ?
         <SelectStoryblockForLinkDialog
            open={selectContentDialogState.open}
            handleClose={onSelectContentDialogClose}
            handleCancel={onSelectContentDialogCancel}
        />: null }
        <EditMenuButton
            onClick={() => handleContentObjectLink()}
            isActive={editor.isActive('content-object-link')}>
            <RiMindMap size={16} />
        </EditMenuButton>
    </React.Fragment>
}


const MenuBar = ({ editor, className }) => {
    if (!editor) {
        return null
    }
    return <Box className={className}>
        <HeadingMenuGroup editor={editor} />
        <BoldItalicStrikeThroughMenuGroup editor={editor} />
        <ListMenuGroup editor={editor} />
        <BlockQuotesMenuGroup editor={editor} />
        <ButtonGroup variant="contained" color="primary">
            <LinkMenuButton editor={editor} />
            <EditObjectLinkMenuButton editor={editor} />
        </ButtonGroup>
    </Box>
}


const ExtendedTiptapEditor = ({ content, onContentChange }) => {

    const classes = useStyles();

    Link.configure({
        openOnClick: false,
    });

    ContentObjectlink.configure({
        openOnClick: false,
    });


    const editor = useEditor({
        extensions: [
            StarterKit,
            ExtensionUnderline,
            Link,
            ContentObjectlink,
        ],
        content,
        onUpdate({ editor }) {
            onContentChange(editor.getJSON(), editor.getHTML());
        },
        editable: true
    })


    return <React.Fragment>
        <MenuBar className={classes.editorMenubar} editor={editor} />
        <EditorContent editor={editor} />
    </React.Fragment>
}

export default ExtendedTiptapEditor;