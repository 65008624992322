import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';


import VirtualizedTable from 'components/VirtualizedTable';

import moment from 'moment';


import { observer } from 'mobx-react'

import { ExperienceTableCard, InlineExperienceMenu, DraftStateIcon, ProcessingStateIcon, PublishedStateIcon } from './ExperienceTableWidgets'


const getPage = (meta) => {
  return Math.floor(meta.start / meta.count);
}

const ExperiencesTableVirtualized = observer((
  { 
    store, 
    onSelectObject, 
    onSort, 
    onCreateDraft, 
    onRepackage, 
    onPublish, 
    onRemovePublished, 
    onCopy, 
    onDelete, 
    onShowQR, 
    className, 
    ...rest 
  }) => {

  const rowsPerPage = store.selectObjectsState.count;
  const results = store.selectObjectsState.objects;
  const meta = store.selectObjectsState.meta;
  const count = meta ? meta.numFound : 0;
  const page = meta ? getPage(meta) : 0;

  const handlePageChange = (event, page) => {
    store.fetchPage(page)
  };

  const selectRow = (rowData) => {
    onSelectObject(rowData.id);
  }

  const sort = (name) => {
    if (onSort){
      onSort(name)
    }
  }

  

  const columns = [
    {
      width: 400,
      label: 'Name',
      dataKey: 'contentObjectResult',
      sort: () => sort('name'),
      renderCell: ({ rowData, cellData }) => (<ExperienceTableCard resultObject={cellData} onClick={() => selectRow(rowData)}/>),
      rowClick: true,
    },
    {
      width: 200,
      label: 'Name',
      sort: () => sort('name'),
      dataKey: 'name',
      rowClick: true,
    },
    {
      width: 200,
      label: 'Locale',
      dataKey: 'locale',
      rowClick: true,
    },
    {
      width: 200,
      label: 'Last updated',
      dataKey: 'modificationTimeStr',
      sort: () => sort('modified'),
      rowClick: true,
    },
    {
      width: 100,
      label: 'Packaging',
      dataKey: 'publishingData',
      rowClick: true,
      renderCell: ({ rowData, cellData }) => (<ProcessingStateIcon publishingData={cellData} />),
    },
    {
      width: 100,
      label: 'Draft',
      dataKey: 'publishingData',
      rowClick: true,
      renderCell: ({ rowData, cellData }) => (<DraftStateIcon publishingData={cellData} />),
    },
    {
      width: 200,
      label: 'Pubished live',
      dataKey: 'publishingData',
      rowClick: true,
      renderCell: ({ rowData, cellData }) => (<PublishedStateIcon publishingData={cellData} />),
    },
    {
      width: 200,
      label: 'Actions',
      dataKey: 'publishingData',
      renderCell: ({ rowData, cellData }) => (<InlineExperienceMenu
        publishingData={cellData}
        contentObjectId={rowData.id}
        name={rowData.name}
        organisationId={rowData.organisationId}
        onCreateDraft={onCreateDraft}
        onRepackage={onRepackage}
        onPublish={onPublish}
        onRemovePublished={onRemovePublished}
        onCopy={onCopy}
        onDelete={onDelete} 
        onShowQR={onShowQR}/>),
      rowClick: false,
    },
  ]

  const getRowData = ({ index }) => {
    const contentObjectResult = results[index];
    const instance = contentObjectResult.object;
    const name = instance.name;
    const id = instance.id;
    const locale = instance.locale;
    const organisationId = instance.ownerLink.id;
    const modificationTimeStr = moment(instance.meta.modificationTime).format('DD/MM/YYYY');
    const publishingData = contentObjectResult.publishingData
    return { id, name, organisationId, modificationTimeStr, publishingData, contentObjectResult, locale }
  }

  const rowHeight = 96;
  

  const cellRenderer = ({ cellData, columnIndex, rowData, ...rest }) => {

    const columnData = columns[columnIndex]
    const widget = columnData.renderCell ? columnData.renderCell({ rowData, cellData }) : null;
    const rowClick = columnData.rowClick;

    return <TableCell
      onClick={rowClick ? () => selectRow(rowData): null}
      style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box', flex: 1, height: rowHeight }}
      component="div"
      variant="body"
      //          style={{  }}
      align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
    >
      {widget ? widget : cellData}
    </TableCell>;
  }



  return (
    <React.Fragment>
      <div>
        <TablePagination
          component="div"
          count={count}
          onChangePage={handlePageChange}
          //onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </div>
      <Paper style={{ height: '100%', width: '100%' }}>
        {results ? <VirtualizedTable
          rowHeight={rowHeight}
          rowCount={results.length}
          rowGetter={getRowData}
          //onRowClick={selectRow}
          cellRenderer={cellRenderer}
          columns={columns}
          scrollIndex={0}
        /> : null}
      </Paper>
      <div>
        <TablePagination
          component="div"
          count={count}
          onChangePage={handlePageChange}
          //onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </div>
    </React.Fragment>
  );
});

export default ExperiencesTableVirtualized;