import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', //  '100%',
        overflow: 'hidden',
    }
}));


const MaxAppLayout = (props) => {
    const { children } = props;
    const classes = useStyles();

    // const dispatch = useDispatch();
	// const editorMessage = useSelector(state => state.editorMessage);
    
    // const snackBarOpen = editorMessage ? true: false;
    // const snackBarMessage = editorMessage ? editorMessage.message: "";
    // const snackBarSeverity = editorMessage ? editorMessage.severity: "warning";

    // const handleSnackBarClose = () => {
    //     dispatch(removeEditorMessage())
    // }

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
}

export default MaxAppLayout;

    // return (
    //     <div className={classes.root}>
    //         <div className="top"></div>
	// 	    <div className="route">
	// 		    <div style={{overflowY: 'auto',  minWidth: '300px'}}></div>
	// 		    <div className="main-container"></div>
	// 		    <div style={{overflowY: 'auto', minWidth: '400px'}}>
	// 			<div style={{width: '100%', padding: '10px'}}></div>
	// 		</div>
	// 	</div>
            
    //     </div>
    // );
