
import * as React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import {inputLabelStyle, formControlStyle } from 'kiini/Style';



const useStyles = makeStyles(theme => ({
    imageContainer: {
       marginBottom: 4,
       color: theme.palette.text.primary,
       flexGrow: 1
    },
    card: {
        maxWidth: 345,
    },
}));

const RenditionWidget = observer(({id, label, instance }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();
    const classes = useStyles();
    
    // const setValue = (value) => {
    //     instance.setValue("value", value);
    // }
    
    //const imageUrl = instance ?  instance.rendition.defaultImage.url: null;
    const imageUrl = instance ?  instance.defaultImage.url: null;

    //const value = instance ?  instance.value: "";

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
        </InputLabel>
        <div className={classes.imageContainer}>
        <Card className={classes.card}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="100"
                    image={imageUrl}
                />
            </CardActionArea>
        </Card>
        </div>
    </FormControl>
});

export default RenditionWidget;