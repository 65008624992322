import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { observer } from "mobx-react";
import BaseInput from '../BaseInput';

import {inputLabelStyle, formControlStyle } from '../Style';


const TextField = ({id, label, instance, fieldName, createItem, creationOptions, path, formConfigurations, ...rest }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const onChange = (evt) => {
        instance.setValue(fieldName, evt.target.value);
    } 

    const onBlur = (evt) => {
        console.log("blurred ", fieldName )
    }

    const value = instance[fieldName] ? instance[fieldName]: "";

    const fieldMeta = instance.$fieldsMeta.get(fieldName);
    const isTouched = fieldMeta.isTouched;
    const hasErrors = fieldMeta.errors;
    
    const showError = isTouched && hasErrors;

    return <FormControl error={showError} fullWidth classes={formControlClasses}>
            <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
            </InputLabel>
            <BaseInput id={id} value={value} onChange={(evt) => onChange(evt)} onBlur={(evt) => onBlur(evt)} {...rest}/>
    </FormControl>
}


export default observer(TextField);