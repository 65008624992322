import { observable, makeObservable } from 'mobx';
import * as Yup from 'yup';

import  { BaseModel, ClassDescription, FieldMeta, parseMapValues, isNotUndefined } from "./core"; // eslint-disable-line no-unused-vars


import {ObjectLink as KcpObjectLink} from "./kiini";
import { Organisation } from "./uso";
import { organisationFromJson } from "./uso";



export const identifiableClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Identifiable",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Identifiable extends BaseModel{

    $classDescription = identifiableClassDescription;
    
    id = null; 

    constructor(parent, { id }){
        super(parent,{  });
        
        this.id = id ? id : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            id: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Identifiable(null,data);
    }
}

export const baseAuthorClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.BaseAuthor",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class BaseAuthor extends Identifiable{

    $classDescription = baseAuthorClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BaseAuthor(null,data);
    }
}

export const authorLinkClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.AuthorLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "rel": Yup.string(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "rel", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rel", "blank": true, "null": false, "type": "persistent.fields.TextField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class AuthorLink extends BaseAuthor{

    $classDescription = authorLinkClassDescription;
    
    rel = null; 

    constructor(parent, { id,rel }){
        super(parent,{  id  });
        
        this.rel = rel ? rel : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            rel: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new AuthorLink(null,data);
    }
}

export const mediaSizeClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MediaSize",
        verboseName: "None",
        validationSchemas: {
        
            "width": Yup.number().integer().required(),
            "height": Yup.number().integer().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "width", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "width", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "height", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "height", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class MediaSize extends BaseModel{

    $classDescription = mediaSizeClassDescription;
    
    width = null; 
    height = null; 

    constructor(parent, { width,height }){
        super(parent,{  });
        
        this.width = width ? width : null;
        this.height = height ? height : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            width: observable,  
            height: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MediaSize(null,data);
    }
}

export const focalPointClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.FocalPoint",
        verboseName: "None",
        validationSchemas: {
        
            "x": Yup.number().integer().required(),
            "y": Yup.number().integer().required(),
            "width": Yup.number().integer().nullable(),
            "height": Yup.number().integer().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "x", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "x", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "y", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "y", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "width", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "width", "blank": true, "null": true, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "height", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "height", "blank": true, "null": true, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class FocalPoint extends BaseModel{

    $classDescription = focalPointClassDescription;
    
    x = null; 
    y = null; 
    width = null; 
    height = null; 

    constructor(parent, { x,y,width,height }){
        super(parent,{  });
        
        this.x = x ? x : null;
        this.y = y ? y : null;
        this.width = width ? width : null;
        this.height = height ? height : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            x: observable,  
            y: observable,  
            width: observable,  
            height: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new FocalPoint(null,data);
    }
}

export const copyrightsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Copyrights",
        verboseName: "None",
        validationSchemas: {
        
            "licenceType": Yup.string().nullable(),
            "copyrightAddition": Yup.string().nullable(),
            "attribution": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "licenceType", "meta": null, "min_length": null, "max_length": null, "choices": [["no-rights-reserved", "No rights reserved"], ["CC-BY", "CC BY"], ["CC-BY-SA", "CC BY-SA"], ["CC-BY-ND", "CC BY-ND"], ["CC-BY-NC", "CC BY-NC"], ["CC-BY-NC-SA", "CC BY-NC-SA"], ["CC-BY-NC-ND", "CC BY-NC-ND"], ["internal-rights", "Voor intern gebruik"]], "verbose_name": "Licence type", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "internal-rights", "is_super_class_field": false, "init_value": "\"internal-rights\""},
            {"name": "copyrightAddition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Addition to copyright", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "attribution", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Attribution", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "additional_meta": {"inline_help": "Attribution, for example the name of the creator of the media."}, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Copyrights extends BaseModel{

    $classDescription = copyrightsClassDescription;
    
    licenceType = "internal-rights"; 
    copyrightAddition = null; 
    attribution = null; 

    constructor(parent, { licenceType,copyrightAddition,attribution }){
        super(parent,{  });
        
        this.licenceType = licenceType ? licenceType : "internal-rights";
        this.copyrightAddition = copyrightAddition ? copyrightAddition : null;
        this.attribution = attribution ? attribution : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            licenceType: observable,  
            copyrightAddition: observable,  
            attribution: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Copyrights(null,data);
    }
}

export const renditionVariantClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.RenditionVariant",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(64).required(),
            "contentObjectId": Yup.string().max(40).nullable(),
            "fileId": Yup.string().max(40).nullable(),
            "url": Yup.string().max(1024).required(),
            "size": Yup.mixed().nullable(),
            "duration": Yup.number().integer().nullable(),
            "type": Yup.string().max(64).required(),
            "focalPoint": Yup.mixed().nullable(),
            "copyrights": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "name", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "original", "is_super_class_field": false, "init_value": "\"original\""},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "fileId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "fileId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "size", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "size", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MediaSize", "is_super_class_field": false, "init_value": "null"},
            {"name": "duration", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "duration", "blank": true, "null": true, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "type", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "type", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "focalPoint", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "focalPoint", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.FocalPoint", "is_super_class_field": false, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class RenditionVariant extends Identifiable{

    $classDescription = renditionVariantClassDescription;
    
    name = "original"; 
    contentObjectId = null; 
    fileId = null; 
    url = null; 
    size = null; 
    duration = null; 
    type = null; 
    focalPoint = null; 
    copyrights = null; 

    constructor(parent, { id,name,contentObjectId,fileId,url,size,duration,type,focalPoint,copyrights }){
        super(parent,{  id  });
        
        this.name = name ? name : "original";
        this.contentObjectId = contentObjectId ? contentObjectId : null;
        this.fileId = fileId ? fileId : null;
        this.url = url ? url : null;
        this.size = size ? mediaSizeFromJson(this, size): null;
        this.duration = duration ? duration : null;
        this.type = type ? type : null;
        this.focalPoint = focalPoint ? focalPointFromJson(this, focalPoint): null;
        this.copyrights = copyrights ? copyrightsFromJson(this, copyrights): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
            contentObjectId: observable,  
            fileId: observable,  
            url: observable,  
            size: observable,  
            duration: observable,  
            type: observable,  
            focalPoint: observable,  
            copyrights: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RenditionVariant(null,data);
    }
}

export const renditionClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Rendition",
        verboseName: "None",
        validationSchemas: {
        
            "base": Yup.mixed().required(),
            "defaultImage": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "base", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "base", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RenditionVariant", "is_super_class_field": false, "init_value": "null"},
            {"name": "defaultImage", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "defaultImage", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RenditionVariant", "is_super_class_field": false, "init_value": "null"},
            {"name": "variants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "variants", "blank": true, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RenditionVariant", "is_super_class_field": false, "init_value": "{}"},
        ],

    }
);

export class Rendition extends BaseModel{

    $classDescription = renditionClassDescription;
    
    base = null; 
    defaultImage = null; 
    variants = {}; 

    constructor(parent, { base,defaultImage,variants }){
        super(parent,{  });
        
        this.base = base ? renditionVariantFromJson(this, base): null;
        this.defaultImage = defaultImage ? renditionVariantFromJson(this, defaultImage): null;
        this.variants = variants ? parseMapValues(this, variants, renditionVariantFromJson): {};
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            base: observable,  
            defaultImage: observable,  
            variants: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Rendition(null,data);
    }
}

export const materializedAuthorClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MaterializedAuthor",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(128).required(),
            "avatar": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 128, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "avatar", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "avatar", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class MaterializedAuthor extends BaseAuthor{

    $classDescription = materializedAuthorClassDescription;
    
    name = null; 
    avatar = null; 

    constructor(parent, { id,name,avatar }){
        super(parent,{  id  });
        
        this.name = name ? name : null;
        this.avatar = avatar ? renditionFromJson(this, avatar): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
            avatar: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MaterializedAuthor(null,data);
    }
}

export const contentRoleClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ContentRole",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class ContentRole extends BaseModel{

    $classDescription = contentRoleClassDescription;
    
    id = null; 

    constructor(parent, { id }){
        super(parent,{  });
        
        this.id = id ? id : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            id: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContentRole(null,data);
    }
}

export const locatableClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Locatable",
        verboseName: "None",
        validationSchemas: {
        
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
        ],

    }
);

export class Locatable extends BaseModel{

    $classDescription = locatableClassDescription;
    

    constructor(parent, {  }){
        super(parent,{  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Locatable(null,data);
    }
}

export const locationClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Location",
        verboseName: "None",
        validationSchemas: {
        
            "lat": Yup.number().required(),
            "lng": Yup.number().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "lat", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": {"en": "Latitude", "nl": "Latitude"}, "blank": false, "null": false, "type": "persistent.fields.FloatField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "lng", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": {"en": "Longitude", "nl": "Longitude"}, "blank": false, "null": false, "type": "persistent.fields.FloatField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Location extends Locatable{

    $classDescription = locationClassDescription;
    
    lat = null; 
    lng = null; 

    constructor(parent, { lat,lng }){
        super(parent,{  });
        
        this.lat = lat ? lat : null;
        this.lng = lng ? lng : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            lat: observable,  
            lng: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Location(null,data);
    }
}

export const locatableMultiLocationsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.LocatableMultiLocations",
        verboseName: "None",
        validationSchemas: {
        
            "locations": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "locations", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": {"en": "Locations", "nl": "Locaties"}, "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class LocatableMultiLocations extends Locatable{

    $classDescription = locatableMultiLocationsClassDescription;
    
    locations = []; 

    constructor(parent, { locations }){
        super(parent,{  });
        
        this.locations = locations ? locations.map( item => locationFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            locations: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new LocatableMultiLocations(null,data);
    }
}

export const locatableAreaClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.LocatableArea",
        verboseName: "None",
        validationSchemas: {
        
            "areaCode": Yup.string().max(128).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "areaCode", "meta": null, "min_length": null, "max_length": 128, "choices": [], "verbose_name": "areaCode", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class LocatableArea extends Locatable{

    $classDescription = locatableAreaClassDescription;
    
    areaCode = null; 

    constructor(parent, { areaCode }){
        super(parent,{  });
        
        this.areaCode = areaCode ? areaCode : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            areaCode: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new LocatableArea(null,data);
    }
}

export const baseStartSpotClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.BaseStartSpot",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class BaseStartSpot extends Identifiable{

    $classDescription = baseStartSpotClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BaseStartSpot(null,data);
    }
}

export const startSpotClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.StartSpot",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "description": Yup.string().max(80).nullable(),
            "location": Yup.mixed().required(),
            "rendition": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": {"en": "Location", "nl": "Locatie"}, "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
            {"name": "rendition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rendition", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class StartSpot extends BaseStartSpot{

    $classDescription = startSpotClassDescription;
    
    description = null; 
    location = null; 
    rendition = null; 

    constructor(parent, { id,description,location,rendition }){
        super(parent,{  id  });
        
        this.description = description ? description : null;
        this.location = location ? locationFromJson(this, location): null;
        this.rendition = rendition ? renditionFromJson(this, rendition): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            description: observable,  
            location: observable,  
            rendition: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new StartSpot(null,data);
    }
}

export const contentElementClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ContentElement",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class ContentElement extends Identifiable{

    $classDescription = contentElementClassDescription;
    
    copyrights = null; 

    constructor(parent, { id,copyrights }){
        super(parent,{  id  });
        
        this.copyrights = copyrights ? copyrightsFromJson(this, copyrights): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            copyrights: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContentElement(null,data);
    }
}

export const heading1ClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Heading1",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "value": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "value", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "value", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Heading1 extends ContentElement{

    $classDescription = heading1ClassDescription;
    
    value = null; 

    constructor(parent, { id,copyrights,value }){
        super(parent,{  id ,  copyrights  });
        
        this.value = value ? value : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            value: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Heading1(null,data);
    }
}

export const heading2ClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Heading2",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "value": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "value", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "value", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Heading2 extends ContentElement{

    $classDescription = heading2ClassDescription;
    
    value = null; 

    constructor(parent, { id,copyrights,value }){
        super(parent,{  id ,  copyrights  });
        
        this.value = value ? value : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            value: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Heading2(null,data);
    }
}

export const paragraphClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Paragraph",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "value": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "value", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "value", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Paragraph extends ContentElement{

    $classDescription = paragraphClassDescription;
    
    value = null; 

    constructor(parent, { id,copyrights,value }){
        super(parent,{  id ,  copyrights  });
        
        this.value = value ? value : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            value: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Paragraph(null,data);
    }
}

export const fileAssetElementClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.FileAssetElement",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "contentObjectId": Yup.string().max(40).required(),
            "rendition": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "rendition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rendition", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class FileAssetElement extends ContentElement{

    $classDescription = fileAssetElementClassDescription;
    
    contentObjectId = null; 
    rendition = null; 

    constructor(parent, { id,copyrights,contentObjectId,rendition }){
        super(parent,{  id ,  copyrights  });
        
        this.contentObjectId = contentObjectId ? contentObjectId : null;
        this.rendition = rendition ? renditionFromJson(this, rendition): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            contentObjectId: observable,  
            rendition: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new FileAssetElement(null,data);
    }
}

export const videoElementClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.VideoElement",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "contentObjectId": Yup.string().max(40).required(),
            "rendition": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "rendition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rendition", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class VideoElement extends FileAssetElement{

    $classDescription = videoElementClassDescription;
    

    constructor(parent, { id,copyrights,contentObjectId,rendition }){
        super(parent,{  id ,  copyrights ,  contentObjectId ,  rendition  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new VideoElement(null,data);
    }
}

export const imageElementClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ImageElement",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "contentObjectId": Yup.string().max(40).required(),
            "rendition": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "rendition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rendition", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class ImageElement extends FileAssetElement{

    $classDescription = imageElementClassDescription;
    

    constructor(parent, { id,copyrights,contentObjectId,rendition }){
        super(parent,{  id ,  copyrights ,  contentObjectId ,  rendition  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ImageElement(null,data);
    }
}

export const imagesElementClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ImagesElement",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "values": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "values", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "values", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ImageElement", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class ImagesElement extends ContentElement{

    $classDescription = imagesElementClassDescription;
    
    values = []; 

    constructor(parent, { id,copyrights,values }){
        super(parent,{  id ,  copyrights  });
        
        this.values = values ? values.map( item => imageElementFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            values: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ImagesElement(null,data);
    }
}

export const audioElementClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.AudioElement",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "contentObjectId": Yup.string().max(40).required(),
            "rendition": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "rendition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rendition", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class AudioElement extends FileAssetElement{

    $classDescription = audioElementClassDescription;
    

    constructor(parent, { id,copyrights,contentObjectId,rendition }){
        super(parent,{  id ,  copyrights ,  contentObjectId ,  rendition  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new AudioElement(null,data);
    }
}

export const baseContentObjectLinkClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.BaseContentObjectLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "contentObjectId": Yup.string().max(40).required(),
            "contentRoles": Yup.array().nullable(),
            "name": Yup.string().max(64).nullable(),
            "state": Yup.string().max(64).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": false, "init_value": "[]"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "name", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "state", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "state", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class BaseContentObjectLink extends Identifiable{

    $classDescription = baseContentObjectLinkClassDescription;
    
    contentObjectId = null; 
    contentRoles = []; 
    name = null; 
    state = null; 

    constructor(parent, { id,contentObjectId,contentRoles,name,state }){
        super(parent,{  id  });
        
        this.contentObjectId = contentObjectId ? contentObjectId : null;
        this.contentRoles = contentRoles ? contentRoles.map( item => contentRoleFromJson(this, item)): [];
        this.name = name ? name : null;
        this.state = state ? state : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            contentObjectId: observable,  
            contentRoles: observable,  
            name: observable,  
            state: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BaseContentObjectLink(null,data);
    }
}

export const objectMetaClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ObjectMeta",
        verboseName: "None",
        validationSchemas: {
        
            "owner": Yup.string().max(120),
            "creator": Yup.string().max(120),
            "creationTime": Yup.date(),
            "modifier": Yup.string().max(120),
            "modificationTime": Yup.date(),
            "version": Yup.number().integer().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "owner", "meta": null, "min_length": null, "max_length": 120, "choices": [], "verbose_name": "owner", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "creator", "meta": null, "min_length": null, "max_length": 120, "choices": [], "verbose_name": "creator", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "creationTime", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "creationTime", "blank": false, "null": false, "type": "persistent.fields.DateTimeField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "modifier", "meta": null, "min_length": null, "max_length": 120, "choices": [], "verbose_name": "modifier", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "modificationTime", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "modificationTime", "blank": false, "null": false, "type": "persistent.fields.DateTimeField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "version", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "version", "blank": false, "null": true, "type": "persistent.fields.IntegerField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class ObjectMeta extends BaseModel{

    $classDescription = objectMetaClassDescription;
    
    owner = null; 
    creator = null; 
    creationTime = null; 
    modifier = null; 
    modificationTime = null; 
    version = null; 

    constructor(parent, { owner,creator,creationTime,modifier,modificationTime,version }){
        super(parent,{  });
        
        this.owner = owner ? owner : null;
        this.creator = creator ? creator : null;
        this.creationTime = creationTime ? creationTime : null;
        this.modifier = modifier ? modifier : null;
        this.modificationTime = modificationTime ? modificationTime : null;
        this.version = version ? version : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            owner: observable,  
            creator: observable,  
            creationTime: observable,  
            modifier: observable,  
            modificationTime: observable,  
            version: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ObjectMeta(null,data);
    }
}

export const sourceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Source",
        verboseName: "None",
        validationSchemas: {
        
            "name": Yup.string().max(200),
            "id": Yup.string().max(125),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "name", "meta": null, "min_length": null, "max_length": 200, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "id", "meta": null, "min_length": null, "max_length": 125, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Source extends BaseModel{

    $classDescription = sourceClassDescription;
    
    name = null; 
    id = null; 

    constructor(parent, { name,id }){
        super(parent,{  });
        
        this.name = name ? name : null;
        this.id = id ? id : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
            id: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Source(null,data);
    }
}

export const organisationLinkClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.OrganisationLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "rel": Yup.string(),
            "href": Yup.string(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "rel", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rel", "blank": true, "null": false, "type": "persistent.fields.TextField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "href", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "href", "blank": true, "null": false, "type": "persistent.fields.TextField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class OrganisationLink extends KcpObjectLink{

    $classDescription = organisationLinkClassDescription;
    

    constructor(parent, { id,rel,href }){
        super(parent,{  id ,  rel ,  href  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new OrganisationLink(null,data);
    }
}

export const contentBaseClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ContentBase",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": false, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": false, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": false, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": false, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": false, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class ContentBase extends ContentElement{

    $classDescription = contentBaseClassDescription;
    
    name = null; 
    description = null; 
    renditions = []; 
    meta = null; 
    source = null; 
    ownerLink = null; 
    locale = "*"; 
    location = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location }){
        super(parent,{  id ,  copyrights  });
        
        this.name = name ? name : null;
        this.description = description ? description : null;
        this.renditions = renditions ? renditions.map( item => renditionFromJson(this, item)): [];
        this.meta = meta ? objectMetaFromJson(this, meta): null;
        this.source = source ? sourceFromJson(this, source): null;
        this.ownerLink = ownerLink ? organisationLinkFromJson(this, ownerLink): null;
        this.locale = locale ? locale : "*";
        this.location = location ? locatableFromJson(this, location): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
            description: observable,  
            renditions: observable,  
            meta: observable,  
            source: observable,  
            ownerLink: observable,  
            locale: observable,  
            location: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContentBase(null,data);
    }
}

export const contentRepresentationClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ContentRepresentation",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "representedClazz": Yup.string().max(164).required(),
            "baseType": Yup.string().max(64).required(),
            "contentAttributes": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "representedClazz", "meta": null, "min_length": null, "max_length": 164, "choices": [], "verbose_name": "representedClazz", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "baseType", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "baseType", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "contentAttributes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentAttributes", "blank": true, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "{}", "is_super_class_field": false, "init_value": "{}"},
        ],

    }
);

export class ContentRepresentation extends ContentBase{

    $classDescription = contentRepresentationClassDescription;
    
    representedClazz = null; 
    baseType = null; 
    contentAttributes = {}; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,representedClazz,baseType,contentAttributes }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location  });
        
        this.representedClazz = representedClazz ? representedClazz : null;
        this.baseType = baseType ? baseType : null;
        this.contentAttributes = contentAttributes ? contentAttributes : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            representedClazz: observable,  
            baseType: observable,  
            contentAttributes: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContentRepresentation(null,data);
    }
}

export const representedContentObjectLinkClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.RepresentedContentObjectLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "contentObjectId": Yup.string().max(40).required(),
            "contentRoles": Yup.array().nullable(),
            "name": Yup.string().max(64).nullable(),
            "state": Yup.string().max(64).nullable(),
            "representation": Yup.mixed().required(),
            "location": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "name", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "state", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "state", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "representation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "representation", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRepresentation", "is_super_class_field": false, "init_value": "null"},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class RepresentedContentObjectLink extends BaseContentObjectLink{

    $classDescription = representedContentObjectLinkClassDescription;
    
    representation = null; 
    location = null; 

    constructor(parent, { id,contentObjectId,contentRoles,name,state,representation,location }){
        super(parent,{  id ,  contentObjectId ,  contentRoles ,  name ,  state  });
        
        this.representation = representation ? contentRepresentationFromJson(this, representation): null;
        this.location = location ? locationFromJson(this, location): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            representation: observable,  
            location: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RepresentedContentObjectLink(null,data);
    }
}

export const htmlContentElementClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.HtmlContentElement",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "value": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "value", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "value", "blank": true, "null": false, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class HtmlContentElement extends ContentElement{

    $classDescription = htmlContentElementClassDescription;
    
    value = null; 

    constructor(parent, { id,copyrights,value }){
        super(parent,{  id ,  copyrights  });
        
        this.value = value ? value : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            value: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new HtmlContentElement(null,data);
    }
}

export const multipleChoiceAnswerClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MultipleChoiceAnswer",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "answer": Yup.string().required(),
            "isCorrect": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "answer", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "answer", "blank": false, "null": false, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "isCorrect", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isCorrect", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
        ],

    }
);

export class MultipleChoiceAnswer extends Identifiable{

    $classDescription = multipleChoiceAnswerClassDescription;
    
    answer = null; 
    isCorrect = false; 

    constructor(parent, { id,answer,isCorrect }){
        super(parent,{  id  });
        
        this.answer = answer ? answer : null;
        this.isCorrect = isNotUndefined( isCorrect) ? isCorrect : false;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            answer: observable,  
            isCorrect: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MultipleChoiceAnswer(null,data);
    }
}

export const multipleChoiceQuestionClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MultipleChoiceQuestion",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "question": Yup.string().required(),
            "answers": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "question", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "question", "blank": false, "null": false, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "answers", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "answers", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MultipleChoiceAnswer", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class MultipleChoiceQuestion extends ContentElement{

    $classDescription = multipleChoiceQuestionClassDescription;
    
    question = null; 
    answers = []; 

    constructor(parent, { id,copyrights,question,answers }){
        super(parent,{  id ,  copyrights  });
        
        this.question = question ? question : null;
        this.answers = answers ? answers.map( item => multipleChoiceAnswerFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            question: observable,  
            answers: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MultipleChoiceQuestion(null,data);
    }
}

export const contentObjectLinkClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ContentObjectLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "contentObjectId": Yup.string().max(40).required(),
            "contentRoles": Yup.array().nullable(),
            "name": Yup.string().max(64).nullable(),
            "state": Yup.string().max(64).nullable(),
            "overriddenLocation": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "name", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "state", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "state", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "overriddenLocation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "overriddenLocation", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class ContentObjectLink extends BaseContentObjectLink{

    $classDescription = contentObjectLinkClassDescription;
    
    overriddenLocation = null; 

    constructor(parent, { id,contentObjectId,contentRoles,name,state,overriddenLocation }){
        super(parent,{  id ,  contentObjectId ,  contentRoles ,  name ,  state  });
        
        this.overriddenLocation = overriddenLocation ? locationFromJson(this, overriddenLocation): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            overriddenLocation: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContentObjectLink(null,data);
    }
}

export const notesClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Notes",
        verboseName: "None",
        validationSchemas: {
        
            "text": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "text", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "text", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Notes extends BaseModel{

    $classDescription = notesClassDescription;
    
    text = null; 

    constructor(parent, { text }){
        super(parent,{  });
        
        this.text = text ? text : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            text: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Notes(null,data);
    }
}

export const contentObjectClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ContentObject",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": false, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": false, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": false, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": false, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": false, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": false, "init_value": "true"},
        ],

    }
);

export class ContentObject extends ContentBase{

    $classDescription = contentObjectClassDescription;
    
    contentRoles = []; 
    tags = []; 
    isLocalisationBase = true; 
    translatedVariants = {}; 
    authors = []; 
    notes = null; 
    isInLibrary = true; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location  });
        
        this.contentRoles = contentRoles ? contentRoles.map( item => contentRoleFromJson(this, item)): [];
        this.tags = tags ? tags : null;
        this.isLocalisationBase = isNotUndefined( isLocalisationBase) ? isLocalisationBase : true;
        this.translatedVariants = translatedVariants ? parseMapValues(this, translatedVariants, contentObjectLinkFromJson): {};
        this.authors = authors ? authors.map( item => baseAuthorFromJson(this, item)): [];
        this.notes = notes ? notesFromJson(this, notes): null;
        this.isInLibrary = isNotUndefined( isInLibrary) ? isInLibrary : true;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            contentRoles: observable,  
            tags: observable,  
            isLocalisationBase: observable,  
            translatedVariants: observable,  
            authors: observable,  
            notes: observable,  
            isInLibrary: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContentObject(null,data);
    }
}

export const contentObjectBaseClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ContentObjectBase",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
        ],

    }
);

export class ContentObjectBase extends ContentObject{

    $classDescription = contentObjectBaseClassDescription;
    

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContentObjectBase(null,data);
    }
}

export const experienceFileVariantClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ExperienceFileVariant",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "size": Yup.number().integer().required(),
            "url": Yup.string().max(1024).required(),
            "path": Yup.string().max(1024).required(),
            "action": Yup.string().max(64).required(),
            "shared": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "size", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "size", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "path", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "path", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "action", "meta": null, "min_length": null, "max_length": 64, "choices": [["extract", "Extract the file"], ["move", "Move the file to path"]], "verbose_name": "action", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "shared", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "shared", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
        ],

    }
);

export class ExperienceFileVariant extends Identifiable{

    $classDescription = experienceFileVariantClassDescription;
    
    size = null; 
    url = null; 
    path = null; 
    action = null; 
    shared = false; 

    constructor(parent, { id,size,url,path,action,shared }){
        super(parent,{  id  });
        
        this.size = size ? size : null;
        this.url = url ? url : null;
        this.path = path ? path : null;
        this.action = action ? action : null;
        this.shared = isNotUndefined( shared) ? shared : false;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            size: observable,  
            url: observable,  
            path: observable,  
            action: observable,  
            shared: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ExperienceFileVariant(null,data);
    }
}

export const runnablePackageFileClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.RunnablePackageFile",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(128).required(),
            "size": Yup.number().integer(),
            "url": Yup.string().max(1024),
            "action": Yup.string().max(64).required(),
            "variant": Yup.string().max(64).required(),
            "platforms": Yup.array().required(),
            "neededFeatures": Yup.array().required(),
            "type": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
            "platforms": Yup.string(),
            "neededFeatures": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 128, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "size", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "size", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "action", "meta": null, "min_length": null, "max_length": 64, "choices": [["extract", "Extract the file"], ["move", "Move the file to path"]], "verbose_name": "action", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "variant", "meta": null, "min_length": null, "max_length": 64, "choices": [["base", "base"]], "verbose_name": "variant", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "base", "is_super_class_field": false, "init_value": "\"base\""},
            {"name": "platforms", "meta": null, "min_length": null, "max_length": null, "choices": [["ios", "ios"], ["android", "android"]], "verbose_name": "platforms", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
            {"name": "neededFeatures", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "neededFeatures", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
            {"name": "type", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "type", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "variants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "variants", "blank": false, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceFileVariant", "is_super_class_field": false, "init_value": "{}"},
        ],

    }
);

export class RunnablePackageFile extends Identifiable{

    $classDescription = runnablePackageFileClassDescription;
    
    name = null; 
    size = null; 
    url = null; 
    action = null; 
    variant = "base"; 
    platforms = []; 
    neededFeatures = []; 
    type = null; 
    variants = {}; 

    constructor(parent, { id,name,size,url,action,variant,platforms,neededFeatures,type,variants }){
        super(parent,{  id  });
        
        this.name = name ? name : null;
        this.size = size ? size : null;
        this.url = url ? url : null;
        this.action = action ? action : null;
        this.variant = variant ? variant : "base";
        this.platforms = platforms ? platforms : null;
        this.neededFeatures = neededFeatures ? neededFeatures : null;
        this.type = type ? type : null;
        this.variants = variants ? parseMapValues(this, variants, experienceFileVariantFromJson): {};
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
            size: observable,  
            url: observable,  
            action: observable,  
            variant: observable,  
            platforms: observable,  
            neededFeatures: observable,  
            type: observable,  
            variants: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RunnablePackageFile(null,data);
    }
}

export const runnableObjectClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.RunnableObject",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "packages": Yup.array().required(),
            "type": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "packages", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "packages", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RunnablePackageFile", "is_super_class_field": false, "init_value": "[]"},
            {"name": "type", "meta": null, "min_length": null, "max_length": null, "choices": [["godot", "godot"], ["godot-ar", "godot-ar"], ["js-page", "js-page"]], "verbose_name": "type", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class RunnableObject extends ContentObjectBase{

    $classDescription = runnableObjectClassDescription;
    
    packages = []; 
    type = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,packages,type }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary  });
        
        this.packages = packages ? packages.map( item => runnablePackageFileFromJson(this, item)): [];
        this.type = type ? type : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            packages: observable,  
            type: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RunnableObject(null,data);
    }
}

export const baseFileAssetClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.BaseFileAsset",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "url": Yup.string().max(1024).required(),
            "fileId": Yup.string().max(40).nullable(),
            "mimeType": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
            "metaData": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "fileId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "fileId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "metaData", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "metaData", "blank": true, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "{}"},
            {"name": "mimeType", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "mimeType", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class BaseFileAsset extends ContentObject{

    $classDescription = baseFileAssetClassDescription;
    
    url = null; 
    fileId = null; 
    metaData = {}; 
    mimeType = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,url,fileId,metaData,mimeType }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary  });
        
        this.url = url ? url : null;
        this.fileId = fileId ? fileId : null;
        this.metaData = metaData ? metaData : null;
        this.mimeType = mimeType ? mimeType : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            url: observable,  
            fileId: observable,  
            metaData: observable,  
            mimeType: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BaseFileAsset(null,data);
    }
}

export const imageClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Image",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "url": Yup.string().max(1024).required(),
            "fileId": Yup.string().max(40).nullable(),
            "mimeType": Yup.string().required(),
            "size": Yup.mixed().nullable(),
            "focalPoint": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
            "metaData": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "fileId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "fileId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "metaData", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "metaData", "blank": true, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "{}"},
            {"name": "mimeType", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "mimeType", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "size", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "size", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MediaSize", "is_super_class_field": false, "init_value": "null"},
            {"name": "focalPoint", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "focalPoint", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.FocalPoint", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Image extends BaseFileAsset{

    $classDescription = imageClassDescription;
    
    size = null; 
    focalPoint = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,url,fileId,metaData,mimeType,size,focalPoint }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary ,  url ,  fileId ,  metaData ,  mimeType  });
        
        this.size = size ? mediaSizeFromJson(this, size): null;
        this.focalPoint = focalPoint ? focalPointFromJson(this, focalPoint): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            size: observable,  
            focalPoint: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Image(null,data);
    }
}

export const videoClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Video",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "url": Yup.string().max(1024).required(),
            "fileId": Yup.string().max(40).nullable(),
            "mimeType": Yup.string().required(),
            "size": Yup.mixed().nullable(),
            "duration": Yup.number().nullable(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
            "metaData": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "fileId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "fileId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "metaData", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "metaData", "blank": true, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "{}"},
            {"name": "mimeType", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "mimeType", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "size", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "size", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MediaSize", "is_super_class_field": false, "init_value": "null"},
            {"name": "duration", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "duration", "blank": false, "null": true, "type": "persistent.fields.FloatField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Video extends BaseFileAsset{

    $classDescription = videoClassDescription;
    
    size = null; 
    duration = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,url,fileId,metaData,mimeType,size,duration }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary ,  url ,  fileId ,  metaData ,  mimeType  });
        
        this.size = size ? mediaSizeFromJson(this, size): null;
        this.duration = duration ? duration : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            size: observable,  
            duration: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Video(null,data);
    }
}

export const audioClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Audio",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "url": Yup.string().max(1024).required(),
            "fileId": Yup.string().max(40).nullable(),
            "mimeType": Yup.string().required(),
            "duration": Yup.number().nullable(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
            "metaData": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 1024, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "fileId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "fileId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "metaData", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "metaData", "blank": true, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "{}"},
            {"name": "mimeType", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "mimeType", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "duration", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "duration", "blank": false, "null": true, "type": "persistent.fields.FloatField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Audio extends BaseFileAsset{

    $classDescription = audioClassDescription;
    
    duration = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,url,fileId,metaData,mimeType,duration }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary ,  url ,  fileId ,  metaData ,  mimeType  });
        
        this.duration = duration ? duration : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            duration: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Audio(null,data);
    }
}

export const geoFenceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.GeoFence",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class GeoFence extends Identifiable{

    $classDescription = geoFenceClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new GeoFence(null,data);
    }
}

export const geoFenceCircleClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.GeoFenceCircle",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "location": Yup.mixed().required(),
            "radius": Yup.number().integer().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
            {"name": "radius", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "radius", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class GeoFenceCircle extends GeoFence{

    $classDescription = geoFenceCircleClassDescription;
    
    location = null; 
    radius = null; 

    constructor(parent, { id,location,radius }){
        super(parent,{  id  });
        
        this.location = location ? locationFromJson(this, location): null;
        this.radius = radius ? radius : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            location: observable,  
            radius: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new GeoFenceCircle(null,data);
    }
}

export const geoTriggerClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.GeoTrigger",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "geoFence": Yup.mixed().required(),
            "contentObjectId": Yup.string().nullable(),
            "contentObjectLinkId": Yup.string().required(),
            "image": Yup.mixed().nullable(),
            "name": Yup.string().max(64).nullable(),
            "enabled": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "geoFence", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "geoFence", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.GeoFence", "is_super_class_field": false, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentObjectId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "contentObjectLinkId", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentObjectLinkId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "image", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "image", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": false, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "name", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "enabled", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "enabled", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": false, "init_value": "true"},
        ],

    }
);

export class GeoTrigger extends Identifiable{

    $classDescription = geoTriggerClassDescription;
    
    geoFence = null; 
    contentObjectId = null; 
    contentObjectLinkId = null; 
    image = null; 
    name = null; 
    enabled = true; 

    constructor(parent, { id,geoFence,contentObjectId,contentObjectLinkId,image,name,enabled }){
        super(parent,{  id  });
        
        this.geoFence = geoFence ? geoFenceFromJson(this, geoFence): null;
        this.contentObjectId = contentObjectId ? contentObjectId : null;
        this.contentObjectLinkId = contentObjectLinkId ? contentObjectLinkId : null;
        this.image = image ? renditionFromJson(this, image): null;
        this.name = name ? name : null;
        this.enabled = isNotUndefined( enabled) ? enabled : true;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            geoFence: observable,  
            contentObjectId: observable,  
            contentObjectLinkId: observable,  
            image: observable,  
            name: observable,  
            enabled: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new GeoTrigger(null,data);
    }
}

export const storyLineClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.StoryLine",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "storyBlocks": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "storyBlocks", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "storyBlocks", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObject", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class StoryLine extends ContentObject{

    $classDescription = storyLineClassDescription;
    
    storyBlocks = []; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,storyBlocks }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary  });
        
        this.storyBlocks = storyBlocks ? storyBlocks.map( item => contentObjectFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            storyBlocks: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new StoryLine(null,data);
    }
}

export const spotContentObjectLinkClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.SpotContentObjectLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "contentObjectId": Yup.string().max(40).required(),
            "contentRoles": Yup.array().nullable(),
            "name": Yup.string().max(64).nullable(),
            "state": Yup.string().max(64).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "contentObjectId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "name", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "state", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "state", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class SpotContentObjectLink extends BaseContentObjectLink{

    $classDescription = spotContentObjectLinkClassDescription;
    

    constructor(parent, { id,contentObjectId,contentRoles,name,state }){
        super(parent,{  id ,  contentObjectId ,  contentRoles ,  name ,  state  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new SpotContentObjectLink(null,data);
    }
}

export const lineStyleClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.LineStyle",
        verboseName: "None",
        validationSchemas: {
        
            "color": Yup.string().max(9).nullable(),
            "visible": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "color", "meta": null, "min_length": null, "max_length": 9, "choices": [], "verbose_name": "color", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "#00aeff", "is_super_class_field": false, "init_value": "\"#00aeff\""},
            {"name": "visible", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "visible", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": false, "init_value": "true"},
        ],

    }
);

export class LineStyle extends BaseModel{

    $classDescription = lineStyleClassDescription;
    
    color = "#00aeff"; 
    visible = true; 

    constructor(parent, { color,visible }){
        super(parent,{  });
        
        this.color = color ? color : "#00aeff";
        this.visible = isNotUndefined( visible) ? visible : true;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            color: observable,  
            visible: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new LineStyle(null,data);
    }
}

export const routePartClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.RoutePart",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(64).nullable(),
            "points": Yup.array().required(),
            "directionPoints": Yup.array().nullable(),
            "length": Yup.number().integer().required(),
            "usage": Yup.string().max(64).required(),
            "lineStyle": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
            "points": Yup.number(),
            "directionPoints": Yup.number(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 64, "choices": [], "verbose_name": "name", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "points", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "points", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "additional_meta": {"skipClientValidation": true}, "relation_clazz": "float", "is_super_class_field": false, "init_value": "[]"},
            {"name": "directionPoints", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "directionPoints", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "additional_meta": {"skipClientValidation": true}, "relation_clazz": "float", "is_super_class_field": false, "init_value": "[]"},
            {"name": "length", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "length", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "usage", "meta": null, "min_length": null, "max_length": 64, "choices": [["mainRoute", "main route"], ["approachRoute", "approach route"], ["shorteningRoute", "shortening route"], ["extensionRoute", "extension route"], ["connectionRoute", "connection route"], ["detourRoute", "detour route"], ["alternativeRoute", "alternative route"]], "verbose_name": "usage", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "mainRoute", "is_super_class_field": false, "init_value": "\"mainRoute\""},
            {"name": "lineStyle", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "lineStyle", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.LineStyle", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class RoutePart extends Identifiable{

    $classDescription = routePartClassDescription;
    
    name = null; 
    points = []; 
    directionPoints = []; 
    length = null; 
    usage = "mainRoute"; 
    lineStyle = null; 

    constructor(parent, { id,name,points,directionPoints,length,usage,lineStyle }){
        super(parent,{  id  });
        
        this.name = name ? name : null;
        this.points = points ? points : null;
        this.directionPoints = directionPoints ? directionPoints : null;
        this.length = length ? length : null;
        this.usage = usage ? usage : "mainRoute";
        this.lineStyle = lineStyle ? lineStyleFromJson(this, lineStyle): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
            points: observable,  
            directionPoints: observable,  
            length: observable,  
            usage: observable,  
            lineStyle: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RoutePart(null,data);
    }
}

export const routeClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Route",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "routeParts": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "routeParts", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "routeParts", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RoutePart", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class Route extends Identifiable{

    $classDescription = routeClassDescription;
    
    routeParts = []; 

    constructor(parent, { id,routeParts }){
        super(parent,{  id  });
        
        this.routeParts = routeParts ? routeParts.map( item => routePartFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            routeParts: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Route(null,data);
    }
}

export const instructionBaseClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.InstructionBase",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class InstructionBase extends BaseModel{

    $classDescription = instructionBaseClassDescription;
    
    id = null; 

    constructor(parent, { id }){
        super(parent,{  });
        
        this.id = id ? id : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            id: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new InstructionBase(null,data);
    }
}

export const datePeriodClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.DatePeriod",
        verboseName: "None",
        validationSchemas: {
        
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "start", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Start date", "blank": false, "null": false, "type": "persistent.fields.DateField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "end", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "End date", "blank": false, "null": false, "type": "persistent.fields.DateField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class DatePeriod extends BaseModel{

    $classDescription = datePeriodClassDescription;
    
    start = null; 
    end = null; 

    constructor(parent, { start,end }){
        super(parent,{  });
        
        this.start = start ? start : null;
        this.end = end ? end : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            start: observable,  
            end: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new DatePeriod(null,data);
    }
}

export const instructionClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Instruction",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40).nullable(),
            "title": Yup.string().required(),
            "text": Yup.string().nullable(),
            "applicablePeriod": Yup.mixed().nullable(),
            "location": Yup.mixed().required(),
            "geoFence": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "title", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "title", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "text", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "text", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "applicablePeriod", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "applicablePeriod", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.DatePeriod", "is_super_class_field": false, "init_value": "null"},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
            {"name": "geoFence", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "geoFence", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.GeoFence", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Instruction extends InstructionBase{

    $classDescription = instructionClassDescription;
    
    title = null; 
    text = null; 
    applicablePeriod = null; 
    location = null; 
    geoFence = null; 

    constructor(parent, { id,title,text,applicablePeriod,location,geoFence }){
        super(parent,{  id  });
        
        this.title = title ? title : null;
        this.text = text ? text : null;
        this.applicablePeriod = applicablePeriod ? datePeriodFromJson(this, applicablePeriod): null;
        this.location = location ? locationFromJson(this, location): null;
        this.geoFence = geoFence ? geoFenceFromJson(this, geoFence): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            title: observable,  
            text: observable,  
            applicablePeriod: observable,  
            location: observable,  
            geoFence: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Instruction(null,data);
    }
}

export const pathInstructionClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.PathInstruction",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40).nullable(),
            "pathId": Yup.string().max(300).required(),
            "pathInterval": Yup.array().max(2).min(2).required(),
        },
        arrayItemValidationSchema: {
        
            "pathInterval": Yup.number().integer(),
            "text": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "pathId", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "pathId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "pathInterval", "meta": null, "min_length": 2, "max_length": 2, "choices": [], "verbose_name": "pathInterval", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "int", "is_super_class_field": false, "init_value": "[]"},
            {"name": "text", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "text", "blank": false, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "{}"},
        ],

    }
);

export class PathInstruction extends InstructionBase{

    $classDescription = pathInstructionClassDescription;
    
    pathId = null; 
    pathInterval = []; 
    text = {}; 

    constructor(parent, { id,pathId,pathInterval,text }){
        super(parent,{  id  });
        
        this.pathId = pathId ? pathId : null;
        this.pathInterval = pathInterval ? pathInterval : null;
        this.text = text ? text : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            pathId: observable,  
            pathInterval: observable,  
            text: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new PathInstruction(null,data);
    }
}

export const routeNodeInstructionClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.RouteNodeInstruction",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40).nullable(),
            "nodeId": Yup.string().max(300).nullable(),
        },
        arrayItemValidationSchema: {
        
            "nodeText": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "nodeId", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "nodeId", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "nodeText", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "nodeText", "blank": false, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "{}"},
        ],

    }
);

export class RouteNodeInstruction extends InstructionBase{

    $classDescription = routeNodeInstructionClassDescription;
    
    nodeId = null; 
    nodeText = {}; 

    constructor(parent, { id,nodeId,nodeText }){
        super(parent,{  id  });
        
        this.nodeId = nodeId ? nodeId : null;
        this.nodeText = nodeText ? nodeText : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            nodeId: observable,  
            nodeText: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RouteNodeInstruction(null,data);
    }
}

export const mapLayerClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MapLayer",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(300).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class MapLayer extends Identifiable{

    $classDescription = mapLayerClassDescription;
    
    name = null; 

    constructor(parent, { id,name }){
        super(parent,{  id  });
        
        this.name = name ? name : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            name: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MapLayer(null,data);
    }
}

export const areaMapClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.AreaMap",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(300).required(),
            "url": Yup.string().max(300).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class AreaMap extends MapLayer{

    $classDescription = areaMapClassDescription;
    
    url = null; 

    constructor(parent, { id,name,url }){
        super(parent,{  id ,  name  });
        
        this.url = url ? url : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            url: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new AreaMap(null,data);
    }
}

export const mapLayerAliasClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MapLayerAlias",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(300).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class MapLayerAlias extends MapLayer{

    $classDescription = mapLayerAliasClassDescription;
    

    constructor(parent, { id,name }){
        super(parent,{  id ,  name  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MapLayerAlias(null,data);
    }
}

export const clientAreaMapsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ClientAreaMaps",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(300).required(),
            "platforms": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "platforms": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "platforms", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "platforms", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class ClientAreaMaps extends MapLayer{

    $classDescription = clientAreaMapsClassDescription;
    
    platforms = []; 

    constructor(parent, { id,name,platforms }){
        super(parent,{  id ,  name  });
        
        this.platforms = platforms ? platforms : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            platforms: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ClientAreaMaps(null,data);
    }
}

export const rasterMapClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.RasterMap",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(300).required(),
            "platforms": Yup.array().required(),
            "url": Yup.string().max(300).required(),
        },
        arrayItemValidationSchema: {
        
            "platforms": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "platforms", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "platforms", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class RasterMap extends ClientAreaMaps{

    $classDescription = rasterMapClassDescription;
    
    url = null; 

    constructor(parent, { id,name,platforms,url }){
        super(parent,{  id ,  name ,  platforms  });
        
        this.url = url ? url : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            url: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RasterMap(null,data);
    }
}

export const vectorMapClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.VectorMap",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "name": Yup.string().max(300).required(),
            "platforms": Yup.array().required(),
            "url": Yup.string().max(300).required(),
        },
        arrayItemValidationSchema: {
        
            "platforms": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "platforms", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "platforms", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "url", "meta": null, "min_length": null, "max_length": 300, "choices": [], "verbose_name": "url", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class VectorMap extends ClientAreaMaps{

    $classDescription = vectorMapClassDescription;
    
    url = null; 

    constructor(parent, { id,name,platforms,url }){
        super(parent,{  id ,  name ,  platforms  });
        
        this.url = url ? url : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            url: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new VectorMap(null,data);
    }
}

export const mapBoundsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MapBounds",
        verboseName: "None",
        validationSchemas: {
        
            "ne": Yup.mixed().required(),
            "sw": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "ne", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ne", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
            {"name": "sw", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "sw", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class MapBounds extends BaseModel{

    $classDescription = mapBoundsClassDescription;
    
    ne = null; 
    sw = null; 

    constructor(parent, { ne,sw }){
        super(parent,{  });
        
        this.ne = ne ? locationFromJson(this, ne): null;
        this.sw = sw ? locationFromJson(this, sw): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            ne: observable,  
            sw: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MapBounds(null,data);
    }
}

export const experienceMapClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ExperienceMap",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "mapLayers": Yup.array().required(),
            "bounds": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "mapLayers", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "mapLayers", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MapLayer", "is_super_class_field": false, "init_value": "[]"},
            {"name": "bounds", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "bounds", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MapBounds", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class ExperienceMap extends Identifiable{

    $classDescription = experienceMapClassDescription;
    
    mapLayers = []; 
    bounds = null; 

    constructor(parent, { id,mapLayers,bounds }){
        super(parent,{  id  });
        
        this.mapLayers = mapLayers ? mapLayers.map( item => mapLayerFromJson(this, item)): [];
        this.bounds = bounds ? mapBoundsFromJson(this, bounds): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            mapLayers: observable,  
            bounds: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ExperienceMap(null,data);
    }
}

export const experienceInstructionsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ExperienceInstructions",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "instructions": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "instructions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "instructions", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Instruction", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class ExperienceInstructions extends Identifiable{

    $classDescription = experienceInstructionsClassDescription;
    
    instructions = []; 

    constructor(parent, { id,instructions }){
        super(parent,{  id  });
        
        this.instructions = instructions ? instructions.map( item => instructionFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            instructions: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ExperienceInstructions(null,data);
    }
}

export const basePointClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.BasePoint",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class BasePoint extends Identifiable{

    $classDescription = basePointClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BasePoint(null,data);
    }
}

export const pointClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Point",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "description": Yup.string().max(80).nullable(),
            "location": Yup.mixed().required(),
            "rendition": Yup.mixed().required(),
            "type": Yup.string().max(64).required(),
            "direction": Yup.string().max(2).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Location", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
            {"name": "rendition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "rendition", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": false, "init_value": "null"},
            {"name": "type", "meta": null, "min_length": null, "max_length": 64, "choices": [["mainStartPoint", "main start point"], ["secondaryStartPoint", "secondary start point"], ["transferPoint", "transfer point"], ["endPoint", "end point"], ["meetingPoint", "meeting point"]], "verbose_name": "type", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "mainStartPoint", "is_super_class_field": false, "init_value": "\"mainStartPoint\""},
            {"name": "direction", "meta": null, "min_length": null, "max_length": 2, "choices": [["n", "north"], ["e", "east"], ["w", "west"], ["s", "south"], ["ne", "northeast"], ["se", "southeast"], ["sw", "southwest"], ["nw", "northwest"]], "verbose_name": "direction", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Point extends BasePoint{

    $classDescription = pointClassDescription;
    
    description = null; 
    location = null; 
    rendition = null; 
    type = "mainStartPoint"; 
    direction = null; 

    constructor(parent, { id,description,location,rendition,type,direction }){
        super(parent,{  id  });
        
        this.description = description ? description : null;
        this.location = location ? locationFromJson(this, location): null;
        this.rendition = rendition ? renditionFromJson(this, rendition): null;
        this.type = type ? type : "mainStartPoint";
        this.direction = direction ? direction : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            description: observable,  
            location: observable,  
            rendition: observable,  
            type: observable,  
            direction: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Point(null,data);
    }
}

export const baseExperienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.BaseExperience",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "points": Yup.array().required(),
            "experienceMap": Yup.mixed().nullable(),
            "route": Yup.mixed().nullable(),
            "extendedDescription": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "points", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "points", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Point", "is_super_class_field": false, "init_value": "[]"},
            {"name": "experienceMap", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "experienceMap", "blank": false, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceMap", "is_super_class_field": false, "init_value": "null"},
            {"name": "route", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "route", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Route", "is_super_class_field": false, "init_value": "null"},
            {"name": "extendedDescription", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "extendedDescription", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObject", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class BaseExperience extends ContentObject{

    $classDescription = baseExperienceClassDescription;
    
    points = []; 
    experienceMap = null; 
    route = null; 
    extendedDescription = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,points,experienceMap,route,extendedDescription }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary  });
        
        this.points = points ? points.map( item => pointFromJson(this, item)): [];
        this.experienceMap = experienceMap ? experienceMapFromJson(this, experienceMap): null;
        this.route = route ? routeFromJson(this, route): null;
        this.extendedDescription = extendedDescription ? contentObjectFromJson(this, extendedDescription): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            points: observable,  
            experienceMap: observable,  
            route: observable,  
            extendedDescription: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BaseExperience(null,data);
    }
}

export const packageInstallDataClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.PackageInstallData",
        verboseName: "None",
        validationSchemas: {
        
            "rootDataPath": Yup.string().max(256).required(),
            "objectDbPath": Yup.string().max(256).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "rootDataPath", "meta": null, "min_length": null, "max_length": 256, "choices": [], "verbose_name": "rootDataPath", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "objectDbPath", "meta": null, "min_length": null, "max_length": 256, "choices": [], "verbose_name": "objectDbPath", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class PackageInstallData extends BaseModel{

    $classDescription = packageInstallDataClassDescription;
    
    rootDataPath = null; 
    objectDbPath = null; 

    constructor(parent, { rootDataPath,objectDbPath }){
        super(parent,{  });
        
        this.rootDataPath = rootDataPath ? rootDataPath : null;
        this.objectDbPath = objectDbPath ? objectDbPath : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            rootDataPath: observable,  
            objectDbPath: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new PackageInstallData(null,data);
    }
}

export const experienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.Experience",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "points": Yup.array().required(),
            "experienceMap": Yup.mixed().nullable(),
            "route": Yup.mixed().nullable(),
            "extendedDescription": Yup.mixed().nullable(),
            "organisation": Yup.mixed().required(),
            "contentAttributes": Yup.mixed().required(),
            "contentObjectLinks": Yup.array().required(),
            "packageInstallData": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "points", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "points", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Point", "is_super_class_field": true, "init_value": "[]"},
            {"name": "experienceMap", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "experienceMap", "blank": false, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceMap", "is_super_class_field": true, "init_value": "null"},
            {"name": "route", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "route", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Route", "is_super_class_field": true, "init_value": "null"},
            {"name": "extendedDescription", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "extendedDescription", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObject", "is_super_class_field": true, "init_value": "null"},
            {"name": "organisation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "organisation", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "uso.db.model.Organisation", "is_super_class_field": false, "init_value": "null"},
            {"name": "contentAttributes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentAttributes", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "{}", "is_super_class_field": false, "init_value": "{}"},
            {"name": "contentObjectLinks", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentObjectLinks", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RepresentedContentObjectLink", "is_super_class_field": false, "init_value": "[]"},
            {"name": "packageInstallData", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "packageInstallData", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.PackageInstallData", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class Experience extends BaseExperience{

    $classDescription = experienceClassDescription;
    
    organisation = null; 
    contentAttributes = {}; 
    contentObjectLinks = []; 
    packageInstallData = null; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,points,experienceMap,route,extendedDescription,organisation,contentAttributes,contentObjectLinks,packageInstallData }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary ,  points ,  experienceMap ,  route ,  extendedDescription  });
        
        this.organisation = organisation ? organisationFromJson(this, organisation): null;
        this.contentAttributes = contentAttributes ? contentAttributes : null;
        this.contentObjectLinks = contentObjectLinks ? contentObjectLinks.map( item => representedContentObjectLinkFromJson(this, item)): [];
        this.packageInstallData = packageInstallData ? packageInstallDataFromJson(this, packageInstallData): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            organisation: observable,  
            contentAttributes: observable,  
            contentObjectLinks: observable,  
            packageInstallData: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Experience(null,data);
    }
}

export const packedExperienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.PackedExperience",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "points": Yup.array().required(),
            "experienceMap": Yup.mixed().nullable(),
            "route": Yup.mixed().nullable(),
            "extendedDescription": Yup.mixed().nullable(),
            "organisation": Yup.mixed().required(),
            "contentAttributes": Yup.mixed().required(),
            "contentObjectLinks": Yup.array().required(),
            "packageInstallData": Yup.mixed().nullable(),
            "experienceInstructions": Yup.mixed().required(),
            "geoTriggers": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "points", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "points", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Point", "is_super_class_field": true, "init_value": "[]"},
            {"name": "experienceMap", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "experienceMap", "blank": false, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceMap", "is_super_class_field": true, "init_value": "null"},
            {"name": "route", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "route", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Route", "is_super_class_field": true, "init_value": "null"},
            {"name": "extendedDescription", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "extendedDescription", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObject", "is_super_class_field": true, "init_value": "null"},
            {"name": "organisation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "organisation", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "uso.db.model.Organisation", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentAttributes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentAttributes", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "{}", "is_super_class_field": true, "init_value": "{}"},
            {"name": "contentObjectLinks", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentObjectLinks", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RepresentedContentObjectLink", "is_super_class_field": true, "init_value": "[]"},
            {"name": "packageInstallData", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "packageInstallData", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.PackageInstallData", "is_super_class_field": true, "init_value": "null"},
            {"name": "experienceInstructions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "experienceInstructions", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceInstructions", "is_super_class_field": false, "init_value": "null"},
            {"name": "geoTriggers", "meta": null, "min_length": 0, "max_length": null, "choices": [], "verbose_name": "geoTriggers", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.GeoTrigger", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class PackedExperience extends Experience{

    $classDescription = packedExperienceClassDescription;
    
    experienceInstructions = null; 
    geoTriggers = []; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,points,experienceMap,route,extendedDescription,organisation,contentAttributes,contentObjectLinks,packageInstallData,experienceInstructions,geoTriggers }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary ,  points ,  experienceMap ,  route ,  extendedDescription ,  organisation ,  contentAttributes ,  contentObjectLinks ,  packageInstallData  });
        
        this.experienceInstructions = experienceInstructions ? experienceInstructionsFromJson(this, experienceInstructions): null;
        this.geoTriggers = geoTriggers ? geoTriggers.map( item => geoTriggerFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            experienceInstructions: observable,  
            geoTriggers: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new PackedExperience(null,data);
    }
}

export const experienceFileClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ExperienceFile",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "platforms": Yup.array().required(),
            "neededFeatures": Yup.array().required(),
            "type": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
            "platforms": Yup.string(),
            "neededFeatures": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "platforms", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "platforms", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
            {"name": "neededFeatures", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "neededFeatures", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
            {"name": "type", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "type", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "variants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "variants", "blank": false, "null": false, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceFileVariant", "is_super_class_field": false, "init_value": "{}"},
        ],

    }
);

export class ExperienceFile extends Identifiable{

    $classDescription = experienceFileClassDescription;
    
    platforms = []; 
    neededFeatures = []; 
    type = null; 
    variants = {}; 

    constructor(parent, { id,platforms,neededFeatures,type,variants }){
        super(parent,{  id  });
        
        this.platforms = platforms ? platforms : null;
        this.neededFeatures = neededFeatures ? neededFeatures : null;
        this.type = type ? type : null;
        this.variants = variants ? parseMapValues(this, variants, experienceFileVariantFromJson): {};
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            platforms: observable,  
            neededFeatures: observable,  
            type: observable,  
            variants: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ExperienceFile(null,data);
    }
}

export const mapConfigLinkClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.MapConfigLink",
        verboseName: "None",
        validationSchemas: {
        
            "mapConfigId": Yup.string().max(40).required(),
            "name": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "mapConfigId", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "mapConfigId", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class MapConfigLink extends BaseModel{

    $classDescription = mapConfigLinkClassDescription;
    
    mapConfigId = null; 
    name = null; 

    constructor(parent, { mapConfigId,name }){
        super(parent,{  });
        
        this.mapConfigId = mapConfigId ? mapConfigId : null;
        this.name = name ? name : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            mapConfigId: observable,  
            name: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new MapConfigLink(null,data);
    }
}

export const editableExperienceMapClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.EditableExperienceMap",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "mapLayers": Yup.array().required(),
            "bounds": Yup.mixed().required(),
            "mapConfigLink": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "mapLayers", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "mapLayers", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MapLayer", "is_super_class_field": true, "init_value": "[]"},
            {"name": "bounds", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "bounds", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MapBounds", "is_super_class_field": true, "init_value": "null"},
            {"name": "mapConfigLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "mapConfigLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.MapConfigLink", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class EditableExperienceMap extends ExperienceMap{

    $classDescription = editableExperienceMapClassDescription;
    
    mapConfigLink = null; 

    constructor(parent, { id,mapLayers,bounds,mapConfigLink }){
        super(parent,{  id ,  mapLayers ,  bounds  });
        
        this.mapConfigLink = mapConfigLink ? mapConfigLinkFromJson(this, mapConfigLink): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            mapConfigLink: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new EditableExperienceMap(null,data);
    }
}

export const publishedExperienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.PublishedExperience",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "points": Yup.array().required(),
            "experienceMap": Yup.mixed().nullable(),
            "route": Yup.mixed().nullable(),
            "extendedDescription": Yup.mixed().nullable(),
            "organisation": Yup.mixed().required(),
            "contentAttributes": Yup.mixed().required(),
            "contentObjectLinks": Yup.array().required(),
            "packageInstallData": Yup.mixed().nullable(),
            "files": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "points", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "points", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Point", "is_super_class_field": true, "init_value": "[]"},
            {"name": "experienceMap", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "experienceMap", "blank": false, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceMap", "is_super_class_field": true, "init_value": "null"},
            {"name": "route", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "route", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Route", "is_super_class_field": true, "init_value": "null"},
            {"name": "extendedDescription", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "extendedDescription", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObject", "is_super_class_field": true, "init_value": "null"},
            {"name": "organisation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "organisation", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "uso.db.model.Organisation", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentAttributes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentAttributes", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "{}", "is_super_class_field": true, "init_value": "{}"},
            {"name": "contentObjectLinks", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentObjectLinks", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.RepresentedContentObjectLink", "is_super_class_field": true, "init_value": "[]"},
            {"name": "packageInstallData", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "packageInstallData", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.PackageInstallData", "is_super_class_field": true, "init_value": "null"},
            {"name": "files", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "files", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceFile", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class PublishedExperience extends Experience{

    $classDescription = publishedExperienceClassDescription;
    
    files = []; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,points,experienceMap,route,extendedDescription,organisation,contentAttributes,contentObjectLinks,packageInstallData,files }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary ,  points ,  experienceMap ,  route ,  extendedDescription ,  organisation ,  contentAttributes ,  contentObjectLinks ,  packageInstallData  });
        
        this.files = files ? files.map( item => experienceFileFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            files: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new PublishedExperience(null,data);
    }
}

export const editableExperienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.EditableExperience",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "points": Yup.array().required(),
            "experienceMap": Yup.mixed().nullable(),
            "route": Yup.mixed().nullable(),
            "extendedDescription": Yup.mixed().nullable(),
            "contentObjectLinks": Yup.array().required(),
            "experienceInstructions": Yup.mixed().required(),
            "geoTriggers": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "points", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "points", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Point", "is_super_class_field": true, "init_value": "[]"},
            {"name": "experienceMap", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "experienceMap", "blank": false, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceMap", "is_super_class_field": true, "init_value": "null"},
            {"name": "route", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "route", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Route", "is_super_class_field": true, "init_value": "null"},
            {"name": "extendedDescription", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "extendedDescription", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObject", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentObjectLinks", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentObjectLinks", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseContentObjectLink", "is_super_class_field": false, "init_value": "[]"},
            {"name": "experienceInstructions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "experienceInstructions", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ExperienceInstructions", "is_super_class_field": false, "init_value": "null"},
            {"name": "geoTriggers", "meta": null, "min_length": 0, "max_length": null, "choices": [], "verbose_name": "geoTriggers", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.GeoTrigger", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class EditableExperience extends BaseExperience{

    $classDescription = editableExperienceClassDescription;
    
    contentObjectLinks = []; 
    experienceInstructions = null; 
    geoTriggers = []; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,points,experienceMap,route,extendedDescription,contentObjectLinks,experienceInstructions,geoTriggers }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary ,  points ,  experienceMap ,  route ,  extendedDescription  });
        
        this.contentObjectLinks = contentObjectLinks ? contentObjectLinks.map( item => baseContentObjectLinkFromJson(this, item)): [];
        this.experienceInstructions = experienceInstructions ? experienceInstructionsFromJson(this, experienceInstructions): null;
        this.geoTriggers = geoTriggers ? geoTriggers.map( item => geoTriggerFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            contentObjectLinks: observable,  
            experienceInstructions: observable,  
            geoTriggers: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new EditableExperience(null,data);
    }
}

export const compoundContentObjectClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.CompoundContentObject",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "name": Yup.string().max(80).required(),
            "description": Yup.string().nullable(),
            "renditions": Yup.array().nullable(),
            "meta": Yup.mixed(),
            "source": Yup.mixed(),
            "ownerLink": Yup.mixed().nullable(),
            "locale": Yup.string().max(2).min(1).required(),
            "location": Yup.mixed().nullable(),
            "contentRoles": Yup.array().nullable(),
            "tags": Yup.array().max(250).nullable(),
            "isLocalisationBase": Yup.boolean().required(),
            "authors": Yup.array().nullable(),
            "notes": Yup.mixed().nullable(),
            "isInLibrary": Yup.boolean().nullable(),
            "children": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "tags": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "name", "meta": null, "min_length": null, "max_length": 80, "choices": [], "verbose_name": "name", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "renditions", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "renditions", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Rendition", "is_super_class_field": true, "init_value": "[]"},
            {"name": "meta", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "meta", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.ObjectMeta", "is_super_class_field": true, "init_value": "null"},
            {"name": "source", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "source", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": true, "is_group": false, "relation_clazz": "content_objects.model.Source", "is_super_class_field": true, "init_value": "null"},
            {"name": "ownerLink", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "ownerLink", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.OrganisationLink", "is_super_class_field": true, "init_value": "null"},
            {"name": "locale", "meta": null, "min_length": 1, "max_length": 2, "choices": [], "verbose_name": "locale", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "*", "is_super_class_field": true, "init_value": "\"*\""},
            {"name": "location", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "location", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Locatable", "is_super_class_field": true, "init_value": "null"},
            {"name": "contentRoles", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "contentRoles", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentRole", "is_super_class_field": true, "init_value": "[]"},
            {"name": "tags", "meta": null, "min_length": null, "max_length": 250, "choices": [], "verbose_name": "tags", "blank": true, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": true, "init_value": "[]"},
            {"name": "isLocalisationBase", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isLocalisationBase", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "translatedVariants", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "translatedVariants", "blank": true, "null": true, "type": "persistent.related.MapOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentObjectLink", "is_super_class_field": true, "init_value": "{}"},
            {"name": "authors", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "authors", "blank": false, "null": true, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.BaseAuthor", "is_super_class_field": true, "init_value": "[]"},
            {"name": "notes", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "notes", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Notes", "is_super_class_field": true, "init_value": "null"},
            {"name": "isInLibrary", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "isInLibrary", "blank": true, "null": true, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": true, "init_value": "true"},
            {"name": "children", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "children", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentElement", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class CompoundContentObject extends ContentObjectBase{

    $classDescription = compoundContentObjectClassDescription;
    
    children = []; 

    constructor(parent, { id,copyrights,name,description,renditions,meta,source,ownerLink,locale,location,contentRoles,tags,isLocalisationBase,translatedVariants,authors,notes,isInLibrary,children }){
        super(parent,{  id ,  copyrights ,  name ,  description ,  renditions ,  meta ,  source ,  ownerLink ,  locale ,  location ,  contentRoles ,  tags ,  isLocalisationBase ,  translatedVariants ,  authors ,  notes ,  isInLibrary  });
        
        this.children = children ? children.map( item => contentElementFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            children: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new CompoundContentObject(null,data);
    }
}

export const compoundContentElementsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.CompoundContentElements",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "copyrights": Yup.mixed().nullable(),
            "elements": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": false, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "copyrights", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "copyrights", "blank": true, "null": true, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Copyrights", "is_super_class_field": true, "init_value": "null"},
            {"name": "elements", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "elements", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ContentElement", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class CompoundContentElements extends ContentElement{

    $classDescription = compoundContentElementsClassDescription;
    
    elements = []; 

    constructor(parent, { id,copyrights,elements }){
        super(parent,{  id ,  copyrights  });
        
        this.elements = elements ? elements.map( item => contentElementFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            elements: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new CompoundContentElements(null,data);
    }
}

export const augmentedRealityImageClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.AugmentedRealityImage",
        verboseName: "Augmented reality image",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "live": Yup.boolean().required(),
            "photoLocation": Yup.mixed().required(),
            "subjectLocation": Yup.mixed().required(),
            "rotation": Yup.number().required(),
            "scale": Yup.number().required(),
            "altitude": Yup.number().required(),
            "sourceURL": Yup.string().max(256).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "live", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Live", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
            {"name": "photoLocation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Location where image should be viewed", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
            {"name": "subjectLocation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Location of the subject of the image", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.Location", "is_super_class_field": false, "init_value": "null"},
            {"name": "rotation", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Rotation relative to the north", "blank": false, "null": false, "type": "persistent.fields.FloatField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "scale", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Scale", "blank": false, "null": false, "type": "persistent.fields.FloatField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "altitude", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Altitude adjustment", "blank": false, "null": false, "type": "persistent.fields.FloatField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "sourceURL", "meta": null, "min_length": null, "max_length": 256, "choices": [], "verbose_name": "Source URL", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "js_validate_regex": "^(((ftp|http|https)(:[/][/]){1})|((www.){1}))[-a-zA-Z0-9@:%_\\+.~#?&//=]+$", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class AugmentedRealityImage extends ContentRole{

    $classDescription = augmentedRealityImageClassDescription;
    
    live = false; 
    photoLocation = null; 
    subjectLocation = null; 
    rotation = null; 
    scale = null; 
    altitude = null; 
    sourceURL = null; 

    constructor(parent, { id,live,photoLocation,subjectLocation,rotation,scale,altitude,sourceURL }){
        super(parent,{  id  });
        
        this.live = isNotUndefined( live) ? live : false;
        this.photoLocation = photoLocation ? locationFromJson(this, photoLocation): null;
        this.subjectLocation = subjectLocation ? locationFromJson(this, subjectLocation): null;
        this.rotation = rotation ? rotation : null;
        this.scale = scale ? scale : null;
        this.altitude = altitude ? altitude : null;
        this.sourceURL = sourceURL ? sourceURL : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            live: observable,  
            photoLocation: observable,  
            subjectLocation: observable,  
            rotation: observable,  
            scale: observable,  
            altitude: observable,  
            sourceURL: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new AugmentedRealityImage(null,data);
    }
}

export const trailLengthsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.TrailLengths",
        verboseName: "Trail Lengths",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "lengths": Yup.array().required(),
            "description": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
            "lengths": Yup.number().integer(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "lengths", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Length in meters", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "int", "is_super_class_field": false, "init_value": "[]"},
            {"name": "description", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Description", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class TrailLengths extends ContentRole{

    $classDescription = trailLengthsClassDescription;
    
    lengths = []; 
    description = null; 

    constructor(parent, { id,lengths,description }){
        super(parent,{  id  });
        
        this.lengths = lengths ? lengths : null;
        this.description = description ? description : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            lengths: observable,  
            description: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new TrailLengths(null,data);
    }
}

export const experienceDurationsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.ExperienceDurations",
        verboseName: "Durations",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "durations": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "durations": Yup.number().integer(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "durations", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Duration in minutes", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "int", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class ExperienceDurations extends ContentRole{

    $classDescription = experienceDurationsClassDescription;
    
    durations = []; 

    constructor(parent, { id,durations }){
        super(parent,{  id  });
        
        this.durations = durations ? durations : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            durations: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ExperienceDurations(null,data);
    }
}

export const audienceRoleClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.AudienceRole",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class AudienceRole extends ContentRole{

    $classDescription = audienceRoleClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new AudienceRole(null,data);
    }
}

export const childAudienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.ChildAudience",
        verboseName: "Child Audience",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "childAgeGroups": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "childAgeGroups": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "childAgeGroups", "meta": null, "min_length": null, "max_length": null, "choices": [["0-3", "0-3"], ["4-5", "4-5"], ["6-11", "6-11"], ["12-18", "12-19"]], "verbose_name": "childAgeGroups", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class ChildAudience extends AudienceRole{

    $classDescription = childAudienceClassDescription;
    
    childAgeGroups = []; 

    constructor(parent, { id,childAgeGroups }){
        super(parent,{  id  });
        
        this.childAgeGroups = childAgeGroups ? childAgeGroups : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            childAgeGroups: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ChildAudience(null,data);
    }
}

export const familyAudienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.FamilyAudience",
        verboseName: "Family Audience",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class FamilyAudience extends AudienceRole{

    $classDescription = familyAudienceClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new FamilyAudience(null,data);
    }
}

export const adultAudienceClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.AdultAudience",
        verboseName: "Adult Audience",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "adultAges": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "adultAges": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "adultAges", "meta": null, "min_length": null, "max_length": null, "choices": [["20-39", "20-39"], ["40-59", "40-59"], ["60-*", "60-*"]], "verbose_name": "adultAges", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class AdultAudience extends AudienceRole{

    $classDescription = adultAudienceClassDescription;
    
    adultAges = []; 

    constructor(parent, { id,adultAges }){
        super(parent,{  id  });
        
        this.adultAges = adultAges ? adultAges : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            adultAges: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new AdultAudience(null,data);
    }
}

export const subjectClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.Subject",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class Subject extends ContentRole{

    $classDescription = subjectClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Subject(null,data);
    }
}

export const natureClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.Nature",
        verboseName: "Nature",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class Nature extends Subject{

    $classDescription = natureClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Nature(null,data);
    }
}

export const historyClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.History",
        verboseName: "History",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class History extends Subject{

    $classDescription = historyClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new History(null,data);
    }
}

export const culturalHistoryClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.CulturalHistory",
        verboseName: "Cultural History",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class CulturalHistory extends Subject{

    $classDescription = culturalHistoryClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new CulturalHistory(null,data);
    }
}

export const foodClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.Food",
        verboseName: "Food",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class Food extends Subject{

    $classDescription = foodClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Food(null,data);
    }
}

export const artClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.Art",
        verboseName: "Art",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class Art extends Subject{

    $classDescription = artClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Art(null,data);
    }
}

export const trailPropertiesClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.TrailProperties",
        verboseName: "Trail Properties",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "pavedPercentage": Yup.number().integer().required(),
            "markedTrail": Yup.boolean().required(),
            "shouldStartAtAStartPoint": Yup.boolean().required(),
            "directionImportant": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "pavedPercentage", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Percentage Paved", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "default": 100, "is_super_class_field": false, "init_value": 100},
            {"name": "markedTrail", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Marked Trail", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
            {"name": "shouldStartAtAStartPoint", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Should start at start point", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
            {"name": "directionImportant", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Direction important", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
        ],

    }
);

export class TrailProperties extends ContentRole{

    $classDescription = trailPropertiesClassDescription;
    
    pavedPercentage = 100; 
    markedTrail = false; 
    shouldStartAtAStartPoint = false; 
    directionImportant = false; 

    constructor(parent, { id,pavedPercentage,markedTrail,shouldStartAtAStartPoint,directionImportant }){
        super(parent,{  id  });
        
        this.pavedPercentage = pavedPercentage ? pavedPercentage : 100;
        this.markedTrail = isNotUndefined( markedTrail) ? markedTrail : false;
        this.shouldStartAtAStartPoint = isNotUndefined( shouldStartAtAStartPoint) ? shouldStartAtAStartPoint : false;
        this.directionImportant = isNotUndefined( directionImportant) ? directionImportant : false;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            pavedPercentage: observable,  
            markedTrail: observable,  
            shouldStartAtAStartPoint: observable,  
            directionImportant: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new TrailProperties(null,data);
    }
}

export const walkingTrailClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.WalkingTrail",
        verboseName: "Walking Trail",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "dogsAllowed": Yup.boolean().required(),
            "wheelChairAccessible": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "dogsAllowed", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Dogs allowed", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": true, "is_super_class_field": false, "init_value": "true"},
            {"name": "wheelChairAccessible", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Wheelchair accessible", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
        ],

    }
);

export class WalkingTrail extends ContentRole{

    $classDescription = walkingTrailClassDescription;
    
    dogsAllowed = true; 
    wheelChairAccessible = false; 

    constructor(parent, { id,dogsAllowed,wheelChairAccessible }){
        super(parent,{  id  });
        
        this.dogsAllowed = isNotUndefined( dogsAllowed) ? dogsAllowed : true;
        this.wheelChairAccessible = isNotUndefined( wheelChairAccessible) ? wheelChairAccessible : false;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            dogsAllowed: observable,  
            wheelChairAccessible: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new WalkingTrail(null,data);
    }
}

export const cyclingTrailClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.CyclingTrail",
        verboseName: "Cycling Trail",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class CyclingTrail extends ContentRole{

    $classDescription = cyclingTrailClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new CyclingTrail(null,data);
    }
}

export const boatingTrailClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.BoatingTrail",
        verboseName: "Boating Trail",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class BoatingTrail extends ContentRole{

    $classDescription = boatingTrailClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BoatingTrail(null,data);
    }
}

export const carTrailClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.CarTrail",
        verboseName: "Car Trail",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class CarTrail extends ContentRole{

    $classDescription = carTrailClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new CarTrail(null,data);
    }
}

export const horseTrailClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.HorseTrail",
        verboseName: "Horse Trail",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class HorseTrail extends ContentRole{

    $classDescription = horseTrailClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new HorseTrail(null,data);
    }
}

export const notPublicClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.NotPublic",
        verboseName: "Not public",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class NotPublic extends ContentRole{

    $classDescription = notPublicClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new NotPublic(null,data);
    }
}

export const playPropertiesClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.PlayProperties",
        verboseName: "Play Properties",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "onlyOpenContentObjectOnTrigger": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "onlyOpenContentObjectOnTrigger", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Only open when triggered", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
        ],

    }
);

export class PlayProperties extends ContentRole{

    $classDescription = playPropertiesClassDescription;
    
    onlyOpenContentObjectOnTrigger = false; 

    constructor(parent, { id,onlyOpenContentObjectOnTrigger }){
        super(parent,{  id  });
        
        this.onlyOpenContentObjectOnTrigger = isNotUndefined( onlyOpenContentObjectOnTrigger) ? onlyOpenContentObjectOnTrigger : false;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            onlyOpenContentObjectOnTrigger: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new PlayProperties(null,data);
    }
}

export const textPropertyClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.TextProperty",
        verboseName: "None",
        validationSchemas: {
        
            "label": Yup.string().max(60).nullable(),
            "value": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "label", "meta": null, "min_length": null, "max_length": 60, "choices": [], "verbose_name": "label", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "value", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "value", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class TextProperty extends BaseModel{

    $classDescription = textPropertyClassDescription;
    
    label = null; 
    value = null; 

    constructor(parent, { label,value }){
        super(parent,{  });
        
        this.label = label ? label : null;
        this.value = value ? value : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            label: observable,  
            value: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new TextProperty(null,data);
    }
}

export const textPropertiesClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.TextProperties",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "values": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "values", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "values", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.TextProperty", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class TextProperties extends ContentRole{

    $classDescription = textPropertiesClassDescription;
    
    values = []; 

    constructor(parent, { id,values }){
        super(parent,{  id  });
        
        this.values = values ? values.map( item => textPropertyFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            values: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new TextProperties(null,data);
    }
}

export const specialDetailsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.SpecialDetails",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "values": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "values", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "values", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.TextProperty", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class SpecialDetails extends ContentRole{

    $classDescription = specialDetailsClassDescription;
    
    values = []; 

    constructor(parent, { id,values }){
        super(parent,{  id  });
        
        this.values = values ? values.map( item => textPropertyFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            values: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new SpecialDetails(null,data);
    }
}

export const dayOfYearClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.DayOfYear",
        verboseName: "None",
        validationSchemas: {
        
            "day": Yup.number().integer().required(),
            "month": Yup.number().integer().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "day", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Day", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "month", "meta": null, "min_length": null, "max_length": null, "choices": [[1, "January"], [2, "February"], [3, "March"], [4, "April"], [5, "May"], [6, "June"], [7, "Juli"], [8, "August"], [9, "September"], [10, "October"], [11, "November"], [12, "December"]], "verbose_name": "Month", "blank": false, "null": false, "type": "persistent.fields.IntegerField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class DayOfYear extends BaseModel{

    $classDescription = dayOfYearClassDescription;
    
    day = null; 
    month = null; 

    constructor(parent, { day,month }){
        super(parent,{  });
        
        this.day = day ? day : null;
        this.month = month ? month : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            day: observable,  
            month: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new DayOfYear(null,data);
    }
}

export const yearPeriodClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.YearPeriod",
        verboseName: "None",
        validationSchemas: {
        
            "fromDay": Yup.mixed().required(),
            "toDay": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "fromDay", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "From", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.DayOfYear", "is_super_class_field": false, "init_value": "null"},
            {"name": "toDay", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "To", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.DayOfYear", "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class YearPeriod extends BaseModel{

    $classDescription = yearPeriodClassDescription;
    
    fromDay = null; 
    toDay = null; 

    constructor(parent, { fromDay,toDay }){
        super(parent,{  });
        
        this.fromDay = fromDay ? dayOfYearFromJson(this, fromDay): null;
        this.toDay = toDay ? dayOfYearFromJson(this, toDay): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            fromDay: observable,  
            toDay: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new YearPeriod(null,data);
    }
}

export const closingsPeriodClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.model.ClosingsPeriod",
        verboseName: "None",
        validationSchemas: {
        
            "period": Yup.mixed().required(),
            "reason": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "period", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "period", "blank": false, "null": false, "type": "persistent.related.OneOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.YearPeriod", "is_super_class_field": false, "init_value": "null"},
            {"name": "reason", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "reason", "blank": false, "null": false, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class ClosingsPeriod extends BaseModel{

    $classDescription = closingsPeriodClassDescription;
    
    period = null; 
    reason = null; 

    constructor(parent, { period,reason }){
        super(parent,{  });
        
        this.period = period ? yearPeriodFromJson(this, period): null;
        this.reason = reason ? reason : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            period: observable,  
            reason: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ClosingsPeriod(null,data);
    }
}

export const closingsPeriodsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.ClosingsPeriods",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "values": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "values", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "values", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.ClosingsPeriod", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class ClosingsPeriods extends ContentRole{

    $classDescription = closingsPeriodsClassDescription;
    
    values = []; 

    constructor(parent, { id,values }){
        super(parent,{  id  });
        
        this.values = values ? values.map( item => closingsPeriodFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            values: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ClosingsPeriods(null,data);
    }
}

export const openPeriodsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.OpenPeriods",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "values": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "values", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "values", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "content_objects.model.YearPeriod", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class OpenPeriods extends ContentRole{

    $classDescription = openPeriodsClassDescription;
    
    values = []; 

    constructor(parent, { id,values }){
        super(parent,{  id  });
        
        this.values = values ? values.map( item => yearPeriodFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            values: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new OpenPeriods(null,data);
    }
}

export const dogWalkingTrailClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.DogWalkingTrail",
        verboseName: "Dog Walking Trail",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "dogSwimAreaAvailable": Yup.boolean().required(),
            "dogsLeashedArea": Yup.boolean().required(),
            "dogsUnLeashedArea": Yup.boolean().required(),
            "fencedArea": Yup.boolean().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "dogSwimAreaAvailable", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Dogs swim area available", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
            {"name": "dogsLeashedArea", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Dogs leashed area", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
            {"name": "dogsUnLeashedArea", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Dogs unleashed area", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
            {"name": "fencedArea", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Dogs fenced area", "blank": true, "null": false, "type": "persistent.fields.BooleanField", "auto_created": false, "is_group": false, "default": false, "is_super_class_field": false, "init_value": "false"},
        ],

    }
);

export class DogWalkingTrail extends ContentRole{

    $classDescription = dogWalkingTrailClassDescription;
    
    dogSwimAreaAvailable = false; 
    dogsLeashedArea = false; 
    dogsUnLeashedArea = false; 
    fencedArea = false; 

    constructor(parent, { id,dogSwimAreaAvailable,dogsLeashedArea,dogsUnLeashedArea,fencedArea }){
        super(parent,{  id  });
        
        this.dogSwimAreaAvailable = isNotUndefined( dogSwimAreaAvailable) ? dogSwimAreaAvailable : false;
        this.dogsLeashedArea = isNotUndefined( dogsLeashedArea) ? dogsLeashedArea : false;
        this.dogsUnLeashedArea = isNotUndefined( dogsUnLeashedArea) ? dogsUnLeashedArea : false;
        this.fencedArea = isNotUndefined( fencedArea) ? fencedArea : false;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            dogSwimAreaAvailable: observable,  
            dogsLeashedArea: observable,  
            dogsUnLeashedArea: observable,  
            fencedArea: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new DogWalkingTrail(null,data);
    }
}

export const eatDrinkFacilitiesClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.EatDrinkFacilities",
        verboseName: "Eat-drinkFacilities on trail",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class EatDrinkFacilities extends ContentRole{

    $classDescription = eatDrinkFacilitiesClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new EatDrinkFacilities(null,data);
    }
}

export const questClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.Quest",
        verboseName: "Quest",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class Quest extends ContentRole{

    $classDescription = questClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Quest(null,data);
    }
}

export const environmentClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.Environment",
        verboseName: "Environments",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "environmentTypes": Yup.array().required(),
        },
        arrayItemValidationSchema: {
        
            "environmentTypes": Yup.string(),
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "environmentTypes", "meta": null, "min_length": null, "max_length": null, "choices": [["forest", "forest"], ["park", "park"], ["natural-reserve", "natural-reserve"], ["lake", "lake"], ["heath", "heath"], ["sands", "sands"], ["city", "city"], ["village", "village"]], "verbose_name": "environmentTypes", "blank": false, "null": false, "type": "persistent.related.ListOf", "auto_created": false, "is_group": false, "relation_clazz": "string", "is_super_class_field": false, "init_value": "[]"},
        ],

    }
);

export class Environment extends ContentRole{

    $classDescription = environmentClassDescription;
    
    environmentTypes = []; 

    constructor(parent, { id,environmentTypes }){
        super(parent,{  id  });
        
        this.environmentTypes = environmentTypes ? environmentTypes : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            environmentTypes: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Environment(null,data);
    }
}

export const copyRightsClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.CopyRights",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "licenceType": Yup.string().nullable(),
            "copyrightAddition": Yup.string().nullable(),
            "attribution": Yup.string().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
            {"name": "licenceType", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Copyright type", "blank": true, "null": true, "type": "persistent.fields.CharField", "auto_created": false, "is_group": false, "default": "internal-rights", "additional_meta": {"inline_help": "Er wordt gebruik gemaakt van de creative commons licenties. U kunt \n          <a href='https://creativecommons.org/licenses/?lang=nl' \n          target='_new'>hier</a> de creative commons licentiemogelijkheden bekijken. \n          Als u <i>Voor intern gebruik</i> kiest, kan de media alleen door \n          anderen binnen uw organisatie gevonden en gebruikt kunnen worden."}, "is_super_class_field": false, "init_value": "\"internal-rights\""},
            {"name": "copyrightAddition", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Addition to copyright", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "is_super_class_field": false, "init_value": "null"},
            {"name": "attribution", "meta": null, "min_length": null, "max_length": null, "choices": [], "verbose_name": "Attribution", "blank": true, "null": true, "type": "persistent.fields.TextField", "auto_created": false, "is_group": false, "additional_meta": {"inline_help": "Attribution, for example the name of the creator of the media."}, "is_super_class_field": false, "init_value": "null"},
        ],

    }
);

export class CopyRights extends ContentRole{

    $classDescription = copyRightsClassDescription;
    
    licenceType = "internal-rights"; 
    copyrightAddition = null; 
    attribution = null; 

    constructor(parent, { id,licenceType,copyrightAddition,attribution }){
        super(parent,{  id  });
        
        this.licenceType = licenceType ? licenceType : "internal-rights";
        this.copyrightAddition = copyrightAddition ? copyrightAddition : null;
        this.attribution = attribution ? attribution : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            licenceType: observable,  
            copyrightAddition: observable,  
            attribution: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new CopyRights(null,data);
    }
}

export const triggerObjectsInOrderClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.TriggerObjectsInOrder",
        verboseName: "Trigger objects in order",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class TriggerObjectsInOrder extends ContentRole{

    $classDescription = triggerObjectsInOrderClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new TriggerObjectsInOrder(null,data);
    }
}

export const keepScreenOnClassDescription = new ClassDescription(
    {
        clazzName : "content_objects.roles.KeepScreenOn",
        verboseName: "Keep screen on during play",
        validationSchemas: {
        
            "id": Yup.string().max(40),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"name": "id", "meta": null, "min_length": null, "max_length": 40, "choices": [], "verbose_name": "id", "blank": true, "null": false, "type": "persistent.fields.CharField", "auto_created": true, "is_group": false, "is_super_class_field": true, "init_value": "null"},
        ],

    }
);

export class KeepScreenOn extends ContentRole{

    $classDescription = keepScreenOnClassDescription;
    

    constructor(parent, { id }){
        super(parent,{  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new KeepScreenOn(null,data);
    }
}


export function mediaSizeFromJson(parent, data){

    if (data){ 
        return new MediaSize(parent, data);
    }else{
        return null;
    }
}
export function focalPointFromJson(parent, data){

    if (data){ 
        return new FocalPoint(parent, data);
    }else{
        return null;
    }
}
export function copyrightsFromJson(parent, data){

    if (data){ 
        return new Copyrights(parent, data);
    }else{
        return null;
    }
}
export function renditionVariantFromJson(parent, data){

    if (data){ 
        return new RenditionVariant(parent, data);
    }else{
        return null;
    }
}
export function renditionFromJson(parent, data){

    if (data){ 
        return new Rendition(parent, data);
    }else{
        return null;
    }
}
export function locationFromJson(parent, data){

    if (data){ 
        return new Location(parent, data);
    }else{
        return null;
    }
}
export function imageElementFromJson(parent, data){

    if (data){ 
        return new ImageElement(parent, data);
    }else{
        return null;
    }
}
export function contentRoleFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.roles.AugmentedRealityImage":
                return new AugmentedRealityImage(parent, data);
            case "content_objects.roles.TrailLengths":
                return new TrailLengths(parent, data);
            case "content_objects.roles.ExperienceDurations":
                return new ExperienceDurations(parent, data);
            case "content_objects.roles.AudienceRole":
                return new AudienceRole(parent, data);
            case "content_objects.roles.ChildAudience":
                return new ChildAudience(parent, data);
            case "content_objects.roles.FamilyAudience":
                return new FamilyAudience(parent, data);
            case "content_objects.roles.AdultAudience":
                return new AdultAudience(parent, data);
            case "content_objects.roles.Subject":
                return new Subject(parent, data);
            case "content_objects.roles.Nature":
                return new Nature(parent, data);
            case "content_objects.roles.History":
                return new History(parent, data);
            case "content_objects.roles.CulturalHistory":
                return new CulturalHistory(parent, data);
            case "content_objects.roles.Food":
                return new Food(parent, data);
            case "content_objects.roles.Art":
                return new Art(parent, data);
            case "content_objects.roles.TrailProperties":
                return new TrailProperties(parent, data);
            case "content_objects.roles.WalkingTrail":
                return new WalkingTrail(parent, data);
            case "content_objects.roles.CyclingTrail":
                return new CyclingTrail(parent, data);
            case "content_objects.roles.BoatingTrail":
                return new BoatingTrail(parent, data);
            case "content_objects.roles.CarTrail":
                return new CarTrail(parent, data);
            case "content_objects.roles.HorseTrail":
                return new HorseTrail(parent, data);
            case "content_objects.roles.NotPublic":
                return new NotPublic(parent, data);
            case "content_objects.roles.PlayProperties":
                return new PlayProperties(parent, data);
            case "content_objects.roles.TextProperties":
                return new TextProperties(parent, data);
            case "content_objects.roles.SpecialDetails":
                return new SpecialDetails(parent, data);
            case "content_objects.roles.ClosingsPeriods":
                return new ClosingsPeriods(parent, data);
            case "content_objects.roles.OpenPeriods":
                return new OpenPeriods(parent, data);
            case "content_objects.roles.DogWalkingTrail":
                return new DogWalkingTrail(parent, data);
            case "content_objects.roles.EatDrinkFacilities":
                return new EatDrinkFacilities(parent, data);
            case "content_objects.roles.Quest":
                return new Quest(parent, data);
            case "content_objects.roles.Environment":
                return new Environment(parent, data);
            case "content_objects.roles.CopyRights":
                return new CopyRights(parent, data);
            case "content_objects.roles.TriggerObjectsInOrder":
                return new TriggerObjectsInOrder(parent, data);
            case "content_objects.roles.KeepScreenOn":
                return new KeepScreenOn(parent, data);
            case "content_objects.model.ContentRole":
             return new ContentRole(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function objectMetaFromJson(parent, data){

    if (data){ 
        return new ObjectMeta(parent, data);
    }else{
        return null;
    }
}
export function sourceFromJson(parent, data){

    if (data){ 
        return new Source(parent, data);
    }else{
        return null;
    }
}
export function organisationLinkFromJson(parent, data){

    if (data){ 
        return new OrganisationLink(parent, data);
    }else{
        return null;
    }
}
export function locatableFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.Location":
                return new Location(parent, data);
            case "content_objects.model.LocatableMultiLocations":
                return new LocatableMultiLocations(parent, data);
            case "content_objects.model.LocatableArea":
                return new LocatableArea(parent, data);
            case "content_objects.model.Locatable":
             return new Locatable(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function contentRepresentationFromJson(parent, data){

    if (data){ 
        return new ContentRepresentation(parent, data);
    }else{
        return null;
    }
}
export function multipleChoiceAnswerFromJson(parent, data){

    if (data){ 
        return new MultipleChoiceAnswer(parent, data);
    }else{
        return null;
    }
}
export function contentObjectLinkFromJson(parent, data){

    if (data){ 
        return new ContentObjectLink(parent, data);
    }else{
        return null;
    }
}
export function baseAuthorFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.AuthorLink":
                return new AuthorLink(parent, data);
            case "content_objects.model.MaterializedAuthor":
                return new MaterializedAuthor(parent, data);
            case "content_objects.model.BaseAuthor":
             return new BaseAuthor(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function notesFromJson(parent, data){

    if (data){ 
        return new Notes(parent, data);
    }else{
        return null;
    }
}
export function experienceFileVariantFromJson(parent, data){

    if (data){ 
        return new ExperienceFileVariant(parent, data);
    }else{
        return null;
    }
}
export function runnablePackageFileFromJson(parent, data){

    if (data){ 
        return new RunnablePackageFile(parent, data);
    }else{
        return null;
    }
}
export function geoFenceFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.GeoFenceCircle":
                return new GeoFenceCircle(parent, data);
            case "content_objects.model.GeoFence":
             return new GeoFence(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function contentObjectFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.ContentObjectBase":
                return new ContentObjectBase(parent, data);
            case "content_objects.model.RunnableObject":
                return new RunnableObject(parent, data);
            case "content_objects.model.CompoundContentObject":
                return new CompoundContentObject(parent, data);
            case "content_objects.model.BaseFileAsset":
                return new BaseFileAsset(parent, data);
            case "content_objects.model.Image":
                return new Image(parent, data);
            case "content_objects.model.Video":
                return new Video(parent, data);
            case "content_objects.model.Audio":
                return new Audio(parent, data);
            case "content_objects.model.StoryLine":
                return new StoryLine(parent, data);
            case "content_objects.model.BaseExperience":
                return new BaseExperience(parent, data);
            case "content_objects.model.Experience":
                return new Experience(parent, data);
            case "content_objects.model.PackedExperience":
                return new PackedExperience(parent, data);
            case "content_objects.model.PublishedExperience":
                return new PublishedExperience(parent, data);
            case "content_objects.model.EditableExperience":
                return new EditableExperience(parent, data);
            case "content_objects.model.ContentObject":
             return new ContentObject(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function lineStyleFromJson(parent, data){

    if (data){ 
        return new LineStyle(parent, data);
    }else{
        return null;
    }
}
export function routePartFromJson(parent, data){

    if (data){ 
        return new RoutePart(parent, data);
    }else{
        return null;
    }
}
export function datePeriodFromJson(parent, data){

    if (data){ 
        return new DatePeriod(parent, data);
    }else{
        return null;
    }
}
export function mapLayerFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.AreaMap":
                return new AreaMap(parent, data);
            case "content_objects.model.MapLayerAlias":
                return new MapLayerAlias(parent, data);
            case "content_objects.model.ClientAreaMaps":
                return new ClientAreaMaps(parent, data);
            case "content_objects.model.RasterMap":
                return new RasterMap(parent, data);
            case "content_objects.model.VectorMap":
                return new VectorMap(parent, data);
            case "content_objects.model.MapLayer":
             return new MapLayer(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function mapBoundsFromJson(parent, data){

    if (data){ 
        return new MapBounds(parent, data);
    }else{
        return null;
    }
}
export function instructionFromJson(parent, data){

    if (data){ 
        return new Instruction(parent, data);
    }else{
        return null;
    }
}
export function pointFromJson(parent, data){

    if (data){ 
        return new Point(parent, data);
    }else{
        return null;
    }
}
export function experienceMapFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.EditableExperienceMap":
                return new EditableExperienceMap(parent, data);
            case "content_objects.model.ExperienceMap":
             return new ExperienceMap(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function routeFromJson(parent, data){

    if (data){ 
        return new Route(parent, data);
    }else{
        return null;
    }
}
export function representedContentObjectLinkFromJson(parent, data){

    if (data){ 
        return new RepresentedContentObjectLink(parent, data);
    }else{
        return null;
    }
}
export function packageInstallDataFromJson(parent, data){

    if (data){ 
        return new PackageInstallData(parent, data);
    }else{
        return null;
    }
}
export function experienceInstructionsFromJson(parent, data){

    if (data){ 
        return new ExperienceInstructions(parent, data);
    }else{
        return null;
    }
}
export function geoTriggerFromJson(parent, data){

    if (data){ 
        return new GeoTrigger(parent, data);
    }else{
        return null;
    }
}
export function mapConfigLinkFromJson(parent, data){

    if (data){ 
        return new MapConfigLink(parent, data);
    }else{
        return null;
    }
}
export function experienceFileFromJson(parent, data){

    if (data){ 
        return new ExperienceFile(parent, data);
    }else{
        return null;
    }
}
export function baseContentObjectLinkFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.RepresentedContentObjectLink":
                return new RepresentedContentObjectLink(parent, data);
            case "content_objects.model.ContentObjectLink":
                return new ContentObjectLink(parent, data);
            case "content_objects.model.SpotContentObjectLink":
                return new SpotContentObjectLink(parent, data);
            case "content_objects.model.BaseContentObjectLink":
             return new BaseContentObjectLink(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function contentElementFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "content_objects.model.Heading1":
                return new Heading1(parent, data);
            case "content_objects.model.Heading2":
                return new Heading2(parent, data);
            case "content_objects.model.Paragraph":
                return new Paragraph(parent, data);
            case "content_objects.model.FileAssetElement":
                return new FileAssetElement(parent, data);
            case "content_objects.model.VideoElement":
                return new VideoElement(parent, data);
            case "content_objects.model.ImageElement":
                return new ImageElement(parent, data);
            case "content_objects.model.AudioElement":
                return new AudioElement(parent, data);
            case "content_objects.model.ImagesElement":
                return new ImagesElement(parent, data);
            case "content_objects.model.ContentBase":
                return new ContentBase(parent, data);
            case "content_objects.model.ContentRepresentation":
                return new ContentRepresentation(parent, data);
            case "content_objects.model.ContentObject":
                return new ContentObject(parent, data);
            case "content_objects.model.ContentObjectBase":
                return new ContentObjectBase(parent, data);
            case "content_objects.model.RunnableObject":
                return new RunnableObject(parent, data);
            case "content_objects.model.CompoundContentObject":
                return new CompoundContentObject(parent, data);
            case "content_objects.model.BaseFileAsset":
                return new BaseFileAsset(parent, data);
            case "content_objects.model.Image":
                return new Image(parent, data);
            case "content_objects.model.Video":
                return new Video(parent, data);
            case "content_objects.model.Audio":
                return new Audio(parent, data);
            case "content_objects.model.StoryLine":
                return new StoryLine(parent, data);
            case "content_objects.model.BaseExperience":
                return new BaseExperience(parent, data);
            case "content_objects.model.Experience":
                return new Experience(parent, data);
            case "content_objects.model.PackedExperience":
                return new PackedExperience(parent, data);
            case "content_objects.model.PublishedExperience":
                return new PublishedExperience(parent, data);
            case "content_objects.model.EditableExperience":
                return new EditableExperience(parent, data);
            case "content_objects.model.HtmlContentElement":
                return new HtmlContentElement(parent, data);
            case "content_objects.model.MultipleChoiceQuestion":
                return new MultipleChoiceQuestion(parent, data);
            case "content_objects.model.CompoundContentElements":
                return new CompoundContentElements(parent, data);
            case "content_objects.model.ContentElement":
             return new ContentElement(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function textPropertyFromJson(parent, data){

    if (data){ 
        return new TextProperty(parent, data);
    }else{
        return null;
    }
}
export function dayOfYearFromJson(parent, data){

    if (data){ 
        return new DayOfYear(parent, data);
    }else{
        return null;
    }
}
export function yearPeriodFromJson(parent, data){

    if (data){ 
        return new YearPeriod(parent, data);
    }else{
        return null;
    }
}
export function closingsPeriodFromJson(parent, data){

    if (data){ 
        return new ClosingsPeriod(parent, data);
    }else{
        return null;
    }
}