import React from 'react';
//import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
// import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
//import TextField from '@material-ui/core/TextField';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper'
import UploadFileAssetPanel from 'components/fileAssets/UploadFileAssetPanel';
import FileAssetsGrid from 'components/fileAssets/FileAssetsGrid';
import FileAssetEditPanel from 'components/fileAssets/FileAssetEditPanel';
import TabPanel from 'components/TabPanel';

import StoreTablePagination from 'components/StoreTablePagination';
import VirtualizedFileAssetGrid from 'components/fileAssets/VirtualizedFileAssetGrid';
import TextSearch2 from 'components/search/TextSearch2';

import DialogTransition from './DialogTransition';

import { observer } from 'mobx-react'
import { useRootStore } from 'contexts';

import { getClazzForAssetType } from 'model/content-object-utils';
import { CLAZZ_FILTER_NAME } from 'model/const';
import TagsFilter from 'components/search/TagsFilter';

const useStyles = makeStyles(theme => ({
  appBar: {
    //position: 'relative',
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  fileAssetGrid: {
    width: '100%',
    height: '100%',
  }
}));



const findObjectOnId = (store, id) => {
  const objectSet = store.selectObjectsState.objects;
  return objectSet.find(objectResult => (objectResult.instance.id === id));
}

const VirtualizedFileAssetGridStoreWrapper = observer(({ store, onSelect }) => {
  const items = store.selectObjectsState.objects;

  return <VirtualizedFileAssetGrid
    idealItemWidth={300}
    items={items}
    onSelect={onSelect}
  />
});


const SelectFileContentDialog = observer(({ open, handleClose, handleCancel, typeLabel, assetType }) => {

  const classes = useStyles();


  const rootStore = useRootStore();
  const { dialogSelectionFileAssetsStore, authStore } = rootStore;
  const uploadedFileAssets = dialogSelectionFileAssetsStore.uploadedFileAssets;
  const selectedUploadedFileAsset = dialogSelectionFileAssetsStore.selectedObject;


  const selectedUploadedFileAssetChanged = selectedUploadedFileAsset ? selectedUploadedFileAsset.$instanceMeta.isTouched : false;

  const initialFilter = {
    [CLAZZ_FILTER_NAME]: getClazzForAssetType(assetType)
  }

  //dialogSelectionFileAssetsStore.clearSelectObject();
  dialogSelectionFileAssetsStore.loadObjects({initialFilter});


  const organisationId = authStore.selectedOrganisation.id;

  const [selectedTab, setSelectedTab] = React.useState('searchFileAsset');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }



  const onSelectedItem = (id) => {
    dialogSelectionFileAssetsStore.selectObject(id);
    const selectedObject = findObjectOnId(dialogSelectionFileAssetsStore, id)
    handleClose(selectedObject.instance);
  }


  const handleSelectUploaded = async() => {
    if (selectedUploadedFileAssetChanged) {
       const selectedObject = await dialogSelectionFileAssetsStore.saveSelectedObjectAsync()
       handleClose(selectedObject);
    }else{
       handleClose(dialogSelectionFileAssetsStore.selectedObject);
    }
  }

  const labelSelectUploaded = selectedUploadedFileAsset && selectedUploadedFileAssetChanged ? "Save and Select Uploaded" : "Select Uploaded" 

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={DialogTransition}>
      <Box className={classes.headerBox} bgcolor="primary.main">
        <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Box>
          {selectedUploadedFileAsset ?
            <Button
              variant="contained" 
              onClick={() => handleSelectUploaded()} color="primary">
              {labelSelectUploaded}
            </Button> : null}
        </Box>
        <Typography variant="h6" className={classes.title}>
          Select a {typeLabel}
        </Typography>
      </Box>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="select search or upload">
        <Tab value="searchFileAsset" label={`Search ${typeLabel}`} />
        <Tab value="uploadFileAsset" label={`Upload ${typeLabel}`} />
      </Tabs>
      <TabPanel style={{ height: '100%', display: 'flex', flexDirection: 'column' }} value={selectedTab} index="searchFileAsset">
        <div>
          <TextSearch2 store={dialogSelectionFileAssetsStore} />
        </div>
        <TagsFilter
          store={dialogSelectionFileAssetsStore}
          tagsStore={dialogSelectionFileAssetsStore.tags}
          filterName="tag"
        />
        <TagsFilter
          store={dialogSelectionFileAssetsStore}
          tagsStore={dialogSelectionFileAssetsStore.areaTags}
          filterName="areas"
        />
        <div>
          <StoreTablePagination store={dialogSelectionFileAssetsStore} />
        </div>
        <Paper style={{ height: '100%', width: '100%' }}>
          <div className={classes.fileAssetGrid}>
            <VirtualizedFileAssetGridStoreWrapper
              idealItemWidth={300}
              store={dialogSelectionFileAssetsStore}
              onSelect={onSelectedItem}
            />
          </div>
        </Paper>
      </TabPanel>
      <TabPanel value={selectedTab} index="uploadFileAsset">
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <UploadFileAssetPanel fileAssetsStore={dialogSelectionFileAssetsStore} authStore={authStore} organisationId={organisationId} singleFile={true} />
            <FileAssetsGrid
              key="fileAssetGrid"
              fileAssets={uploadedFileAssets}
              col={1}
            />
          </Grid>
          <Grid container item xs={6}>
            <FileAssetEditPanel store={dialogSelectionFileAssetsStore} organisationId={organisationId} />
          </Grid>
        </Grid>
      </TabPanel>
    </Dialog>
  );
});


export default SelectFileContentDialog;

