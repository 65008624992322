
import TagsStore from './TagsStore';
import {CompoundContentObject, contentObjectFromJson} from 'model/contentObjects';

import AbstractBaseInstanceStore from "./AbstractBaseInstanceStore";


export default class ContentObjectStore extends AbstractBaseInstanceStore{

    constructor({rootStore,api}) {
        super({rootStore,api});
        this.tags = new TagsStore(null, 'content-object');
    }

    _createNewObject = (initValues) => {
        return new CompoundContentObject(
            null,
            {
                "id": null,
                "name": "",
                "locale": "nl",
                "tags": [],
                "children": [],
                ...initValues
            }
        ); 
    }

    _transformJsonToObject = (objectAsJson) => {
        return objectAsJson ? contentObjectFromJson(null, objectAsJson): null;
    }

}

