import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
//import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';

import CardActionArea from '@material-ui/core/CardActionArea';

import {
  getImageRendition
} from "model/content-object-utils";

const useStyles = makeStyles(theme => ({


  // card: {
  //   display: 'flex',
  // },
  cardDetails: {
    flex: 1,
  },

  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));



const ExperienceCard = ({resultObject, onClick}) => {
  const classes = useStyles();
  const imageUrl = getImageRendition(resultObject.object.renditions,"image.w500");

  return <Card className={classes.card}>
      <CardActionArea onClick={()=> onClick(resultObject.object.id)}>
      <CardMedia
        className={classes.cardMedia}
        image={imageUrl}
        title="Image"
      />
      <CardContent>
        <Grid item md container direction="column" justify="space-around" alignItems="stretch">
          <Grid item>
            <Typography className={classes.trailCardName} gutterBottom variant="h5" component="h5">
              {resultObject.object.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      </CardActionArea>
  </Card >
}


export default ExperienceCard;
