import React from 'react';

import moment from 'moment';
import { observer } from 'mobx-react'

import ObjectsVirtualizedTable, {createDefaultCellRenderer} from 'components/ObjectsVirtualizedTable';



const RatingsTableVirtualized = observer(({ store, onSort, onSelectObject, className, ...rest }) => {

    const results = store.selectObjectsState.objects;
    
    const getRowData = ({ index }) => {
        const obj = results[index];
        const personaName = obj.personaName;
        const id = obj.id;
        const createdDateTime = moment(obj.created).format('DD/MM/YYYY HH:mm');
        const contentObjectName = obj.publishedContentObject ? obj.publishedContentObject.name : ""
        const email = obj.email ? obj.email : "";
        const rating = obj.rating;
        const text = obj.text;
        return { id, personaName, contentObjectName, createdDateTime, email, text, rating, obj }
    }

    const sort = (column) => {
        if (onSort) {
            onSort(column)
        }
    }

    const columns = [
        {
            width: 200,
            label: 'Name',
            dataKey: 'personaName',
            sort: () => sort('name')
        },
        {
            width: 200,
            label: 'Trail',
            dataKey: 'contentObjectName',
            // sort: () => sort('name') 
        },
        {
            width: 200,
            label: 'Email',
            dataKey: 'email',
            // sort: () => sort('name') 
        },
        {
            width: 40,
            label: 'Rating',
            dataKey: 'rating',
            // sort: () => sort('name') 
        },
        {
            width: 400,
            label: 'Message',
            dataKey: 'text',
            // sort: () => sort('name') 
        },
        {
            width: 200,
            label: 'Created on',
            dataKey: 'createdDateTime',
        },
        {
            width: '100%',
            label: 'Actions',
            dataKey: 'id',
        },
    ]
    
    const onDelete = (objectId) => {
        if (window.confirm("Do you really want to delete your this rating?")) {
            store.delete(objectId);
        }
    }

    const onSelectRow = (rowData)=> {
        onSelectObject(rowData.obj.id);
    }

    const cellRenderer = createDefaultCellRenderer({ columns, actionColumnIndex: 6, onDelete, onSelectRow});


    return  <ObjectsVirtualizedTable 
        store={store} 
        columns={columns} 
        getRowData={getRowData}
        cellRenderer={cellRenderer}
    />

    
});

export default RatingsTableVirtualized;