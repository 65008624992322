import * as React from 'react';
import { useRootStore } from 'contexts';
import { useRouterStore } from 'mobx-state-router';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography,
  TextField
} from '@material-ui/core';


import MinimalLayout from 'layouts/Minimal'

import ErrorsInstance from 'jimbo/ui2/ErrorsInstance';

import { observer } from "mobx-react";


const useStyles = makeStyles(theme => ({
  
  grid: {
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  field: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(3)
  },

  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));


const toErrorString = (errors)=> {
  let errString = null;  
  errors.forEach(error => {
    errString = errString ? errString + '\n' + error.message: error.message;  
  });
  return errString;
}

const MyTextField = observer( ({field, ...rest }) => {

  const touched = field.touched;
  const hasErrors = field.errors;
  
  const showError = touched && hasErrors;
  const helperText = touched && field.errors ? toErrorString(field.errors) : null;

  const onChange = (evt) => {
    field.setValue(evt.target.value);
  } 

  return <TextField value={field.value} helperText={helperText} onChange={onChange} showError={showError} label={field.label} name={field.name} required={field.required} {...rest} />
});




const SignInForm = observer(({formId, signIn, doSignIn, signInErrors}) => {
  const classes = useStyles();
  
  
  const userNameField = signIn.getField("userName");
  const passwordField = signIn.getField("password")

  const canSignin = signIn.touched && signIn.valid;

  return (

    <div className={classes.form}>

          <Typography className={classes.title} variant="h2">Log in</Typography>
          <div className={classes.field}>
            <MyTextField field={userNameField} autoFocus  variant="outlined" />
          </div>
          <div className={classes.field}>
            <MyTextField field={passwordField} variant="outlined" type="password" autoComplete="new-password" />
          </div>
          {signInErrors ? <ErrorsInstance instance={signInErrors} />: null}

          <Button
            onClick={() => doSignIn(signIn)}
            disabled={!canSignin}
            className={classes.signInButton}
            color="primary"
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            variant="contained">Login</Button>
    </div>
  );
});


const SignIn = observer((props) => {

  const classes = useStyles();

  const routerStore = useRouterStore();
  const rootStore = useRootStore();
  const { signInStore } = rootStore;

  
  const signIn = signInStore.selectedObject;

  const doSignIn = async () => {
     await signInStore.doSignIn();
  };


  const forgotPassword = (evt) => {
    routerStore.goTo('forgotPassword');
  }

  return (
    <MinimalLayout>
     
        <Grid
          className={classes.grid}
          container>
          <Grid
            className={classes.quoteContainer}
            item
            lg={5}>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <Grid container direction='column' justifyContent="center">
                  <Grid item container>
                    <SignInForm
                      signInErrors={signInStore.signInErrors}
                      signIn={signIn}
                      doSignIn={doSignIn}
                      formId="signin-form"
                    />
                  </Grid>
                  <Grid item>
                    <Button onClick={forgotPassword} color="secondary">
                      Forgot password
                    </Button>
                  </Grid>
                </Grid>
              </div>

            </div>
          </Grid>
        </Grid>
     
    </MinimalLayout>
  );
});

export default SignIn;
