import React from "react";

import Box from '@material-ui/core/Box';

import FileAssetForm from './FileAssetForm';


import ContentObjectRolesEditor from 'components/contentRoles/ContentObjectRolesEditor';

import { observer } from 'mobx-react'

const FileAssetEditPanel = observer(({ store, organisationId }) => {

  const selectedFileAsset = store.selectedObject;

  return <React.Fragment>
    {selectedFileAsset ?
      <div>
        <FileAssetForm instance={selectedFileAsset} store={store}/>
        <ContentObjectRolesEditor instance={selectedFileAsset} store={store} />
      </div>
      : null}
  </React.Fragment>
});


export default FileAssetEditPanel;
