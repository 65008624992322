import React from 'react';
import {useDropzone} from 'react-dropzone';

import { observer } from 'mobx-react'

const UploadPanel = ({files, onAddFile, onRemoveFile, accept}) => {
  
  const {getRootProps, getInputProps} = useDropzone({
    accept,
    onDrop: acceptedFiles => {
        acceptedFiles.forEach( file => {
            onAddFile(file);
        });
    }
  });
  
  // const thumbs = files.map(file => (
  //   <div style={thumb} key={file.name}>
  //     <div style={thumbInner}>
  //       <img
  //         src={file.preview}
  //         style={img}
  //         // Revoke data uri after image is loaded
  //         onLoad={() => { URL.revokeObjectURL(file.preview) }}
  //       />
  //     </div>
  //   </div>
  // ));

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  // }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>      
    </section>
  );
}

export default observer(UploadPanel);