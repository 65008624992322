

import BaseStore from "jimbo/store";

import { accountsPackageClassFactory } from "schema/accounts";

export default class ForgotPasswordStore extends BaseStore {

    
    constructor({rootStore, baseApi}) {
        super({rootStore, baseApi});
    }

    fromJsonToObject = (data) => {
        const forgotPassword =  accountsPackageClassFactory.fromJson(data);
        console.log("created forgotPassword ", forgotPassword);
        return forgotPassword;
    }

    doCreateNewObject =  ({initValues}) => {
        return accountsPackageClassFactory.createInstance("jimbo_accounts.model.ForgotPassword", {});
    }


}

