import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

import DialogTransition from 'components/dialogs/DialogTransition';

import { observer } from 'mobx-react'

import Preview from 'components/storyblocks/Preview';
import StoryBlockCommonForm from 'components/storyblocks/StoryBlockCommonForm';
import StoryBlockContentForm from 'components/storyblocks/StoryBlockContentForm';

const useStyles = makeStyles(theme => ({
  appBar: {
    //position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttons: {
    margin: theme.spacing(1),
  },
}));


const EditStoryBlockDialog = observer(({ storyBlockStore, open, handleClose, handleCopyStoryBlock, handleCancel }) => {

  const classes = useStyles();

  const storyblock = storyBlockStore.selectedObject;

  const saveStoryBlock = async () => {
    const saved = await storyBlockStore.saveAsync()
    if (saved) {
      handleClose(storyBlockStore.selectedObject);
    }
  }

  const saveStoryBlockAsCopy = async () => {
    const saved = await storyBlockStore.saveAsCopyAsync();
    if (saved) {
      handleCopyStoryBlock();
    }
  }

  const canSave = storyblock.$instanceMeta.isTouched;
    

  return (
    <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={DialogTransition}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Box>
            <Button variant="contained"
              className={classes.buttons}
              color="primary"
              onClick={() => saveStoryBlock()}
              disabled={!canSave}
            >
              Save and close
            </Button>
            <Button variant="contained"
              color="secondary"
              className={classes.buttons}
              onClick={() => saveStoryBlockAsCopy()}
            >
              Save as copy and close
            </Button>
          </Box>
          <Typography variant="h6" className={classes.title}>
            Edit Storyblock
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={8}>

        {storyblock ?
          <Grid container item>
            <Grid item xs={3}>
              <Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
                <StoryBlockCommonForm instance={storyblock} store={storyBlockStore}/>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
                <StoryBlockContentForm instance={storyblock} store={storyBlockStore} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="background.paper" margin={1} padding={1}>
                <Preview storyblock={storyblock} />
              </Box>
            </Grid>
          </Grid> : null}
      </Grid>
    </Dialog>
  )
});


export default EditStoryBlockDialog;