import React from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import StoreIcon from '@material-ui/icons/Store';
import DeleteIcon from '@material-ui/icons/Delete';

import { observer } from "mobx-react";


const usesStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1)
    },
    buttons: {
        marginRight: theme.spacing(1),
    },
  }));


const ActionButtons = ({store, instance}) => {

    const classes = usesStyles();

    
    const saveInstance = () => {
        store.save()
    }

    const id = instance.getFieldValue("id");

    const deleteInstance = () => {
        store.delete(id)
    }

    const canSave = instance.valid && instance.touched;
    const canDelete = id ? true: false;

    return <div className={classes.root} >
        <Button variant="contained"
            color="primary"
            className={classes.buttons}
            onClick={() => saveInstance()}
            disabled={!canSave}
        >
            Save changes
            <StoreIcon />
        </Button>
        {canDelete ?
        <Button
            className={classes.buttons}
            variant="contained"
            color="secondary"
            disabled={false}
            onClick={() => deleteInstance()}
        >
            Delete
            <DeleteIcon />
        </Button>: null}
    </div>
}

export default observer(ActionButtons);