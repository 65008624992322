import { makeObservable, observable, action, runInAction } from "mobx";

import { Image, Video, Audio } from 'model/contentObjects';
import {IMAGE_CLAZZ, VIDEO_CLAZZ, AUDIO_CLAZZ } from 'model/const';

import TagsStore from './TagsStore';
import AbstractBaseStore from './AbstractBaseStore';


export function fileAssetFromJson(data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case IMAGE_CLAZZ:
                return new Image(null, data);
            case VIDEO_CLAZZ:
                return new Video(null, data);
            case AUDIO_CLAZZ:
                return new Audio(null, data);
            default:
                console.log("unknown type ", _clazz);
        }
    }else{
        return null;
    }
}

 
export default class FileAssetsStore extends AbstractBaseStore{

    uploadedFileAssets = {
        objects:[]
    }; 

    constructor({rootStore, api}) {
        super({rootStore, api});
        makeObservable(this, {
            uploadedFileAssets: observable,
            addUploadedFileAssets: action,
            resetUploaded: action,
            setSelectedObject: action,
        });      
        this.tags = new TagsStore(this.rootStore, 'file-asset' );
        this.areaTags = new TagsStore(this.rootStore, 'file-asset', 'areas');
    }

    afterLoadObjects = () => {
        this.tags.loadObjects();
        this.areaTags.loadObjects();
    }

    _transformJsonToObject = (objectAsJson) => {
        return fileAssetFromJson(objectAsJson);;
    }

    _processSavedAsyncObject = (savedObject) => {
        runInAction(() => {
            this.selectedObject = savedObject;
            this.uploadedFileAssets = [];
            this._refreshFromIndex(savedObject.id).then(
                action("merge change in set", tomerge => {
                    this._mergeChangedObjects(tomerge);
                    this.tags.loadTags();
                    this.areaTags.loadTags();
                })
            )
        });
    }

    _processSavedObjectJson = (savedSelecteObjectJson) => {
        this._refreshFromIndex(savedSelecteObjectJson.id).then(
            action("merge change in set", tomerge => {
                this.selectedObject = this._transformJsonToObject(savedSelecteObjectJson);
                this._mergeChangedObjects(tomerge);
                this.tags.loadTags();
                this.areaTags.loadTags();
            })
        ) 
    }

    addUploadedFileAssets = (newUploadedFileAssets) => {
        console.log("add uploaded file assets ", newUploadedFileAssets);
        this.uploadedFileAssets.objects = this.uploadedFileAssets.objects.concat(newUploadedFileAssets);
        if (newUploadedFileAssets.length > 0){
            const selectedId = newUploadedFileAssets[0].instance.id;
            this.selectObject(selectedId);
        }
    }

    resetUploaded = () => {
        this.uploadedFileAssets.objects = [];
    }

    _afterDelete = (id) => {
        this.tags.loadTags();
        this.areaTags.loadTags();
    }

    setSelectedObject = (object) => {
        this.selectedObject = object;
    }
}

