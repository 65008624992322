
import BaseStore from "jimbo/store";

import { notificationPackageClassFactory } from "schema/notifications";

export default class NotificationsStore extends BaseStore {


    constructor({rootStore, baseApi}) {
        super({rootStore, baseApi});
    }

    fromJsonToObject = (initialData) => {
        const notification =  notificationPackageClassFactory.createInstance("Notification", {initialData});
        return notification;
    }

    doCreateNewObject =  ({initValues}) => {
        return notificationPackageClassFactory.createInstance("Notification", {});
    }

}