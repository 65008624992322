import React from 'react';

import UploadTracksDialog from  'components/dialogs/UploadTracksDialog';
import SelectFileContentDialog from 'components/dialogs/SelectFileContentDialog'

import { RoutePart } from 'model/contentObjects';


export const UploadTracksDialogAdapter = ({handleClose, handleCancel, experienceEditorStore, authStore}) => {


    const handleAddRoute = (routeJson) => {
        routeJson.routeParts.forEach( routePartJson => {
            const routePart = new RoutePart(null, routePartJson);
            experienceEditorStore.addRoutePart(routePart);
        });
    }

    return <UploadTracksDialog 
        authStore={authStore}
        open={true} 
        handleClose={handleClose}  
        handleCancel={handleCancel}
        onReceiveRouteJson={(routeJson) => handleAddRoute(routeJson)}
    />
}

export const SelectFileContentDialogAdapter = (props) => {

    return <SelectFileContentDialog
        open={true} 
        handleClose={props.handleClose}  
    />
}