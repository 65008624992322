import * as React from 'react';


import {FormWidget, FormConfigurations} from 'kiini/Forms';
import TextField from 'kiini/TextField';
import LocaleField from 'kiini/LocaleField';

import RenditionWidget from 'components/contentObjects/RenditionWidget';
import RenditionsInputList from 'components/contentObjects/RenditionsInputList';
import WayOfMovementRolesWidget from 'components/contentRoles/WayOfMovementRolesWidget';


const experienceGeneralFormConfigurationsData = {
       "content_objects.model.EditableExperience": {
        groups:  [
            {
                label: "Name Description",
                fields: [
                    {fieldName: "name", label: "Name", widget: TextField},
                    {fieldName: "locale", label: "Locale", widget: LocaleField},
                    {fieldName: "description", label: "Description", widget: TextField, widgetProps: { multiline: true, rows: 16 }},
                ]   
            },
            {
                label: "Images",
                fields: [
                    {fieldName: "renditions", label: "Images", widget: RenditionsInputList},
                ]   
            },
        ]
    },
    "content_objects.model.Rendition":{
        widget: RenditionWidget,
        label: "",
    }
}

const experienceGeneralFormConfigurations = new FormConfigurations(experienceGeneralFormConfigurationsData);

const ExperienceGeneralForm = ({instance, store}) => {
    return <React.Fragment>
        <FormWidget instance={instance} formConfigurations={experienceGeneralFormConfigurations} store={store}/>
        <WayOfMovementRolesWidget instance={instance} propertyName="contentRoles"  store={store}/>
    </React.Fragment>
}


export default ExperienceGeneralForm;