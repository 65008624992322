import * as React from "react";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton  from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography'

import {BiXCircle} from 'react-icons/bi';
import {IoIosAddCircleOutline } from 'react-icons/io';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        maxWidth: 360,
        background: 'linear-gradient(0deg,#ba54f5,#e14eca)',
        overflow: 'scroll'
    },
    mainItemText: {
        marginLeft: 8,
    },
    nested: {
    //    marginLeft: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    insertPanel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    itemHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
        minHeight: 24
    },
    listItemIcon: {
        minWidth: 16
    },
    headingButton: {
            padding: 4
        
    }
}));


const DragHandle = sortableHandle(() => <span style={{ marginLeft: 12, padding: 12 }}>::::</span>);

const SortableItem = sortableElement(({form, label, onRemove, onHandleInsert, id, key, showInsertButtons }) => {
    const classes = useStyles();
    return <div key={key} className={classes.nested}>
        {showInsertButtons ?
        <Box className={classes.insertPanel} color="text.primary">
            <IconButton edge="end" aria-label="insert" onClick={(event) => onHandleInsert(event, id)} >
                <IoIosAddCircleOutline size={16}/>
            </IconButton>
        </Box>: null}
        <Box className={classes.itemHeader} color="text.primary" bgcolor="divider">
            <Typography variant="h6" component="div">{label}</Typography>
            <div>
                <IconButton className={classes.headingButton} edge="end" aria-label="delete" onClick={() => onRemove(id)} >
                    <BiXCircle size={16}/>
                </IconButton>
                <DragHandle />    
            </div>
        </Box>    

        <div>
            {form}
        </div>
        <Divider />
    </div>
});

const SortableList = observer(sortableContainer(({ children }) => {
    return <div>{children}</div>;
}));






const ObjectList = observer(({instance, store, fieldName, path, formConfigurations, itemFilterFuction, onHandleInsert, showInsertButtons }) => {

    const onRemove = (index) => {
        instance.removeFromArray(fieldName, index)
    }

  

    const onSortEnd = ({ oldIndex, newIndex }) => {
        instance.moveItemInArray(fieldName, oldIndex, newIndex);
    }

    const items = instance[fieldName];

    const getForm = (instance, index) => {

        const itemPath = `${path}[${index}]`;
        return formConfigurations.createFormWidget({instance, store, path: itemPath})
        
        //return <Widget instance={instance}  path={path + "[" + index + "]"} />;
    }

    const getLabel = (instance, index) => {
        return formConfigurations.getLabel(instance);
    }

    const sortableItems = items.map((instance, i) => {
        const putInList = itemFilterFuction ? itemFilterFuction(instance): true;
        if (putInList){ 
            return <SortableItem
                key={i}
                index={i}
                form={getForm(instance, i)}
                label={getLabel(instance, i)}
                id={i}
                onRemove={onRemove}
                showInsertButtons={showInsertButtons}
                onHandleInsert={onHandleInsert}
            />
        }else{
            return null;
        }
    });



    return <SortableList useDragHandle lockAxis="y" onSortEnd={onSortEnd}>
        {sortableItems}
    </SortableList>
});

export default ObjectList;