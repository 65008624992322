import React from 'react';

//import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Button';

import { observer } from 'mobx-react'

import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts'

import MainLayoutVh from 'layouts/MainVh';

import FixedToolbar from 'components/FixedToolbar'
import TextSearch from 'components/search/TextSearch';

import RunnableObjectDialog from './RunnableObjectDialog';

import TagsFilter from 'components/search/TagsFilter';
import AdvancedSearchButton from 'components/search/AdvancedSearchButton';

import RunnableObjectVirtualizedTable from './RunnableObjectVirtualizedTable';


const RunnableObjectsScreen = observer(() => {

  const rootStore = useRootStore();
  const routerStore = useRouterStore();
  const [showObjectDialog, setShowObjectDialog] = React.useState(false);


  const store = rootStore.runnableObjectsStore;
  const selectedObject = store.selectedObject;
  
  const toNewObject = () => {
    routerStore.goTo('newRunnableObject');
  }

  const onSelectObject = (id) => {
    store.selectObject(id);
    setShowObjectDialog(true);
  }

  const toolbar = <FixedToolbar>
    <TextSearch store={store} />
    <AdvancedSearchButton store={store} />
    <Button color="primary" variant="contained" onClick={() => toNewObject()}>
      Add App Object
    </Button>
  </FixedToolbar>

return <MainLayoutVh toolbar={toolbar}>
  
    {showObjectDialog && selectedObject ?
      <RunnableObjectDialog
        key="dialog"
        open={showObjectDialog}
        store={store}
        handleClose={() => setShowObjectDialog(false)} /> : null}
    <div>
      {store.showAdvancedSearch ? 
        <Box>
          <TagsFilter 
            tagsStore={store.tags}
            store={store} 
            filterName="tag"/>
          <TagsFilter 
            tagsStore={store.areaTags}
            store={store} 
            filterName="areas"/>

        </Box>: null}
    </div>
    <RunnableObjectVirtualizedTable store={store} onSelectObject={onSelectObject}/>
  </MainLayoutVh>;
});

export default RunnableObjectsScreen;
