import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '../Select';

import { observer } from "mobx-react";
//import BaseInput from '../BaseInput';

import {inputLabelStyle, formControlStyle } from '../Style';

const daySelectOptions = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12},
    {label: '13', value: 13},
    {label: '14', value: 14},
    {label: '15', value: 15},
    {label: '16', value: 16},
    {label: '17', value: 17},
    {label: '18', value: 18},
    {label: '19', value: 19},
    {label: '20', value: 20},
    {label: '21', value: 21},
    {label: '22', value: 22},
    {label: '23', value: 23},
    {label: '24', value: 24},
    {label: '25', value: 25},
    {label: '26', value: 26},
    {label: '27', value: 27},
    {label: '28', value: 28},
    {label: '29', value: 29},
    {label: '30', value: 30},
    {label: '31', value: 31},
]

const monthSelectOptions = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12},
]

const DayOfYearWidget = observer(({instance, fieldName, ...rest }) => {

    const dayOfYearInstance = instance[fieldName];
    
    return <React.Fragment>
        <Select 
        label="day"
        fieldName="day"
        instance={dayOfYearInstance}
        selectOptions={daySelectOptions}/>
    <Select 
        label="month"
        fieldName="month"
        instance={dayOfYearInstance}
        selectOptions={monthSelectOptions}/>
    </React.Fragment>

});


const YearPeriodField = ({id, label, instance, fieldName, ...rest }) => {
    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const period = instance[fieldName];

    const fieldMeta = instance.$fieldsMeta.get(fieldName);
    const isTouched = fieldMeta.isTouched;
    const hasErrors = fieldMeta.errors;
    
    const showError = isTouched && hasErrors;

    return <FormControl error={showError} fullWidth classes={formControlClasses}>
            <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
            </InputLabel>
            <DayOfYearWidget  instance={period} fieldName="fromDay" /> _
            <DayOfYearWidget  instance={period} fieldName="toDay" />          
    </FormControl>
}


export default observer(YearPeriodField);



export const YearPeriodWidget = observer(({id, label, instance, ...rest }) => {
    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const instanceMeta = instance.$instanceMeta;
    const isTouched = instanceMeta.isTouched;
    const hasErrors = instanceMeta.errors;
    
    const showError = isTouched && hasErrors;

    return <FormControl error={showError} fullWidth classes={formControlClasses}>
            <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
            </InputLabel>
            <DayOfYearWidget  instance={instance} fieldName="fromDay" /> _
            <DayOfYearWidget  instance={instance} fieldName="toDay" />          
    </FormControl>
});
