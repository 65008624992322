import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';
import {InstancePropertyFormWidget} from 'kiini/Forms';
//import DefaultTagRoleWidget from './DefaultTagRoleWidget';
import { 
    History,
    CulturalHistory, 
    Food, 
    Nature,
    Art,
} from 'model/contentObjects';

import {contentRoleFormConfigurations} from './configuration';


const themeRolesClazzNames = [
    'content_objects.roles.Nature',
    'content_objects.roles.History',
    'content_objects.roles.CulturalHistory',
    'content_objects.roles.Food',
    'content_objects.roles.Art',
];


const themeRolesCreateOptions = {
    'content_objects.roles.Nature': {
        label: "Nature", 
        creator: () => (new Nature(null, {id: uuidv4()}))
    },
    'content_objects.roles.History': {
        label: "History", 
        creator: () => (new History(null,{id: uuidv4()}))
    },
    'content_objects.roles.CulturalHistory': {
        label: "Cultural History", 
        creator: () => (new CulturalHistory(null,{id: uuidv4()}))
    },
    'content_objects.roles.Food': {
        label: "Food", 
        creator: () => (new Food(null,{id: uuidv4()}))
    },
    'content_objects.roles.Art': {
        label: "Art", 
        creator: () => (new Art(null,{id: uuidv4()}))
    }
}

const ThemeRolesForm = ({instance}) => {
    return <InstancePropertyFormWidget 
        label="Theme"
        instance={instance}
        fieldName="contentRoles"
        Widget={ObjectListWithPanel}
        widgetProps={{
            creationOptions: themeRolesCreateOptions, 
            itemFilterFuction: instance =>  themeRolesClazzNames.some( name => name === instance.$classDescription.clazzName),
            oneInstancePerClassInList: true,
        }} 
        formConfigurations={contentRoleFormConfigurations}/>
}


export default ThemeRolesForm;