import BaseStore from "jimbo/store";


export default class PublishedContentObjectsStore extends BaseStore {


    constructor({rootStore, baseApi}) {
        super({rootStore, baseApi});
    }

}