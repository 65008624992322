
import { experiencesApi, analyticsApi } from 'Api';
import { makeObservable, observable, action } from "mobx";

export default class HomeStore {

    experiences = [];
    analyticsDownloadTimeseries = [];
    analyticsSessionTimeseries = [];

    defaultHomeAnalyticsParams = {
        'period': '7d',
        'metrics': 'count',
    }
    

    constructor(rootStore) {
        //this.experience = experience;
        this.rootStore = rootStore;
        makeObservable(this, {
            experiences: observable,
            analyticsDownloadTimeseries: observable,
            analyticsSessionTimeseries: observable,
            loadObjects: action,
            //newObject: action,
        });

    }

    loadObjects = () => {

        const publisherId = this.rootStore.authStore.selectedOrganisation ? 
        this.rootStore.authStore.selectedOrganisation.id: null;

        if (publisherId) {


            experiencesApi.getObjects(0, 6, null, null).then(
                action("loadobjects", results => {
                    if (results){
                        this.experiences = results.objects;
                    }
                })
            );

        
            const downloadTimeSeriesFilter = { 
                'event:publisher_id': publisherId, 
                'event:name': 'download',
                'event:domain': 'storytrails'
            }
            analyticsApi.getTimeSeries(this.defaultHomeAnalyticsParams, downloadTimeSeriesFilter).then(
                action("analyticsDownloadTimeseriesResults", results => {
                    this.analyticsDownloadTimeseries = results;
                })
            );

            const sessionTimeSeriesFilter = { 
                'event:name': 'session-start',
                'event:domain': 'storytrails'
            }
            analyticsApi.getTimeSeries(this.defaultHomeAnalyticsParams, sessionTimeSeriesFilter).then(
                action("analyticsSessionTimeseriesResults", results => {
                    this.analyticsSessionTimeseries = results;
                })
            );
            

        }
    }

}

