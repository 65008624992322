import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import IconButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined'

import VirtualizedTable from 'components/VirtualizedTable';


import { observer } from 'mobx-react'


const InlineBlockMenu = ({ objectId, onDelete }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        onDelete(objectId);
    }

    return (
        <div>
            <IconButton aria-label="delete" onClick={handleClick}>
                <MoreHorizOutlined fontSize="small" />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </div>
    );
}


export const createDefaultCellRenderer = ({columns, actionColumnIndex, onDelete, onSelectRow}) => {

    const cellRendererFn = ({ cellData, columnIndex, rowData, ...rest }) => {

        const rowHeight = 48;
        const columnData = columns[columnIndex]
        const widget = columnData.renderCell ? columnData.renderCell({ rowData, cellData }) : null;

        if (columnIndex === actionColumnIndex) {
            return <InlineBlockMenu objectId={cellData} onDelete={() => onDelete(cellData)} />
        } else {
            return (
                <TableCell
                    onClick={() => onSelectRow(rowData)}
                    style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box', flex: 1, height: rowHeight }}
                    component="div"
                    variant="body"
                    //          style={{  }}
                    align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
                >
                    {widget ? widget : cellData}
                </TableCell>
            );
        }
    };
    return cellRendererFn;
}


const getPage = (meta) => {
    return Math.floor(meta.start / meta.count);
}

const ObjectsVirtualizedTable = observer(({ store, onSort, getRowData, columns, cellRenderer, onSelectObject, ...rest }) => {

    const rowsPerPage = store.selectObjectsState.count;
    const results = store.selectObjectsState.objects;
    const meta = store.selectObjectsState.meta;
    const count = meta ? meta.numFound : 0;
    const page = meta ? getPage(meta) : 0;


    const handlePageChange = (event, page) => {
        store.fetchPage(page)
    };

  

    return (
        <React.Fragment>
            <div>
                <TablePagination
                    component="div"
                    count={count}
                    onChangePage={handlePageChange}
                    //onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                />
            </div>
            <Paper style={{ height: '100%', width: '100%' }}>
                <VirtualizedTable
                    rowCount={results.length}
                    rowGetter={getRowData}
                    onRowClick={onSelectObject}
                    cellRenderer={cellRenderer}
                    columns={columns}
                />

            </Paper>
            <div>
                <TablePagination
                    component="div"
                    count={count}
                    onChangePage={handlePageChange}
                    //onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                />
            </div>
        </React.Fragment>
    );
});

export default ObjectsVirtualizedTable;