

import { makeObservable, observable, action } from "mobx";
import AbstractBaseStore from './AbstractBaseStore';


export default class ExperiencesStore extends AbstractBaseStore{

    selectedQRCodeData = null;

    constructor({rootStore, api}) {
        super({rootStore, api})
        //this.experience = experience;
        this.rootStore = rootStore;
        makeObservable(this, {
            selectedQRCodeData: observable.ref,
            setSelectedQrCodeData: action,
            createDraft: action,
        });
        
    }

    createDraft = (id) => {
        this.api.createDraft(id).then(
            () => this.loadObjects()
            // action("selected Object", selectedObject => {
            //     this.selectedObject = selectedObject;
            // })
        );
    }

    repackage = (id) => {
        this.api.repackage(id).then(
            () => this.loadObjects()
            // action("selected Object", selectedObject => {
            //     this.selectedObject = selectedObject;
            // })
        );
    }

    publish = (id) => {
        this.api.publish(id).then(
            () => this.loadObjects()
            // action("selected Object", selectedObject => {
            //     this.selectedObject = selectedObject;
            // })
        );
    }

    removePublished = (id)=> {
        this.api.removePublished(id).then(
            () => this.loadObjects()
            // action("selected Object", selectedObject => {
            //     this.selectedObject = selectedObject;
            // })
        );
    }

    copy = async(id) => {
        const objectCopy = this.api.copy(id);
        return objectCopy;
    }

    
    setSelectedQrCodeData = ({contentObjectId, name, organisationId, isPublished}) =>{
        this.qrCodeData = {contentObjectId, name, organisationId, isPublished};
    }   
}

