import * as React from 'react';

import { FormWidget, FormConfigurations } from 'kiini/Forms';
import TextField from 'kiini/TextField';
import GeoFenceWidget from './GeoFenceWidget';

const formConfigurationData = {
    "content_objects.model.Instruction": {
        groups: [
            {
                label: "Triggering",
                fields: [
                    { fieldName: "geoFence", label: "Trigger circle radius", widget: GeoFenceWidget }
                ]
            },
            {
                label: "Instruction",
                fields: [
                    { fieldName: "title", label: "Title", widget: TextField },
                    { fieldName: "text", label: "Description", widget: TextField, widgetProps: { multiline: true, rows: 3 } },
                ]
            },
        ]
    }
}

const formConfiguration = new FormConfigurations(formConfigurationData);

const InstructionForm = ({ instance, store }) => {
    return <FormWidget instance={instance} formConfigurations={formConfiguration} store={store}/>
}


export default InstructionForm;