import { makeObservable, action } from 'mobx';
//import { observable, makeObservable, observe, autorun, autorunAsync, action, computed, isComputedProp, isObservableArray } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
    Base, CharField, TextField, DateField, DateTimeField, IntegerField, BooleanField,
    DecimalField, FloatField, ListOfField, MapOfField, OneOfField
} from './fields';

import { createPrimitiveFieldValidation, createPrimitiveItemValidation, createRelatedFieldValidation } from "./validation";

import { isNotUndefined, ERRORS_NAME } from '../utils';

export const parseMapValues = (owner, amap, parseFunction) => {

    return Object.keys(amap).reduce((result, key) => {
        result[key] = parseFunction(owner, amap[key]);
        return result;
    }, {})
}


export const DEFAULT_FIELD_VALUES = {
    "auto_created": false,
    "choices": [],
    "blank": false,
    "null": false,
    "meta": null,
    "max_length": 200,
    "init_value": "null",
    "min_length": null,
    "is_super_class_field": false,
    "is_group": false
}

const PRIMITIVE_RELATION_CLAZZES = ['string', 'float', "int", "{}"];

const PRIMITIVE_FIELDS = ['CharField', 'TextField', 'DateTimeField', 'IntegerField', 'BooleanField', 'DecimalField', 'FloatField', 'DateField'];

const FIELD_CLAZZ_MAPPING = {
    'CharField': CharField,
    'TextField': TextField,
    'DateTimeField': DateTimeField,
    'IntegerField': IntegerField,
    'BooleanField': BooleanField,
    'DecimalField': DecimalField,
    'FloatField': FloatField,
    'DateField': DateField,
    'OneOf': OneOfField,
    'ListOf': ListOfField,
    'MapOf': MapOfField,
}

const PRIMITIVE_TYPE = 'Primitive'; // one of the primitive fields
const ONE_OF_TYPE = 'OneOf';
const LIST_OF_TYPE = 'ListOf';
const MAP_OF_TYPE = 'MapOf';


const isPrimitiveField = (fieldType) => {
    return PRIMITIVE_FIELDS.indexOf(fieldType) >= 0;
}

const hasPrimitiveRelationClazz = (relationClazz) => {
    return PRIMITIVE_RELATION_CLAZZES.indexOf(relationClazz) >= 0;
}

const toShortTypeName = (fullTypeName) => {
    if (hasPrimitiveRelationClazz(fullTypeName)) {
        return fullTypeName;
    }
    const typeNames = fullTypeName.split(".");
    return typeNames[typeNames.length - 1];
}




export const convertYupError = (err) => {
    return err.inner.map(innerError => ({ message: innerError.message, value: innerError.value, type: innerError.type }));
}


export class ModelFactory {

    clazzDescriptionMap = new Map();

    // constructor(){

    // }

    addClassDescription = (classDescription) => {
        const clazzName = classDescription.clazzName
        this.clazzDescriptionMap.set(clazzName, classDescription);
    }

    addClassDescriptions = (classDescriptions) => {
        classDescriptions.forEach(classDescription => {
            const clazzName = classDescription.clazzName
            this.clazzDescriptionMap.set(clazzName, classDescription);
        });
    }

    addClassDescriptionData = (classDescriptionData) => {
        const classDescription = new ClassDescription(classDescriptionData);
        this.addClassDescription(classDescription);
    }


    addClassDescriptionsData = (classDescriptionsData) => {
        classDescriptionsData.forEach(classDescriptionData => {
            this.addClassDescriptionData(classDescriptionData);
        });
    }


    getClassDescription = (clazzName) => {
        return this.clazzDescriptionMap.get(clazzName);
    }

    createInstance = (clazzName, options = {}) => {
        const { owner, initialData, ...rest } = options;
        const classDescription = this.getClassDescription(clazzName);
        if (!classDescription) {
            throw new Error(`No classdescription found for ${clazzName}`)
        }
        const instance = new BaseModel(this, classDescription, { owner, initialData, ...rest });
        return instance;
    }

    fromJson = (jsonData) => {
        const clazzName = jsonData._clazz;
        if (clazzName) {
            const classDescription = this.getClassDescription(clazzName);
            if (classDescription) {
                return new BaseModel(this, classDescription, { initialData: jsonData });
            } else {
                throw new Error("No clazz with name ", clazzName, " registered in class factory");
            }
        } else {
            throw new Error("_clazz expected in json data ", jsonData);
        }
    }
}

export const modelFactory = new ModelFactory();




export class BaseDescriptor {

    skipClientValidation = false;
    maxLength = undefined;
    minLenght = undefined;
    choices = [];
    initValue = undefined;
    isNull = undefined;
    required = undefined;
    isBlank = undefined;
    type = undefined;
    autoCreated = undefined;
    additionalValidationFuction = undefined;
    label = undefined;

    constructor(props) {
        this.initValue = props.init_value;
        this.isNull = isNotUndefined(props.null)? props.null: false;
        this.isBlank = props.blank;
        this.choices = props.choices;
        this.minLength = props.min_length;
        this.maxLength = props.max_length;
        this.type = toShortTypeName(props.type);
        this.required = !this.isNull;
        this.label = props.label;
    }
}

export class ItemFieldDescriptor extends BaseDescriptor {

    constructor(props) {
        super(props);
        this.validationSchema = createPrimitiveItemValidation(this);
    }

    validate = (value) => {

        if (this.validationSchema) {
            try {
                this.validationSchema.validateSync(value, { abortEarly: false });
            } catch (err) {
                return convertYupError(err);
            }
        }
        return null;
    }
}


export class FieldDescriptor extends BaseDescriptor {

    name = undefined;
    isGroup = undefined;
    isSuperClassField = undefined;
    verboseName = '';
    relationClazz = undefined; // only for oneOf, listOf, mapOf

    baseType = undefined;
    isPrimitive = undefined;
    containsPrimitiveValues = undefined;
    skipClientValidation = false;

    constructor(props) {
        super(props);
        this.$classDescription = props.classDescription;
        this.name = props.name;
        this.verboseName = props.verbose_name;
        this.isGroup = props.is_group;
        this.relationClazz = props.relation_clazz;
        this.jsValidateRegex = props.js_validate_regex ? props.js_validate_regex : null;
        this.additionalValidationFuction = props.additionalValidationFuction ? props.additionalValidationFuction : null;

        this.isPrimitive = isPrimitiveField(this.type)

        this.containsPrimitiveValues = !this.isPrimitive ? hasPrimitiveRelationClazz(this.relationClazz) : true;

        this.autoCreated = props.auto_created ? props.auto_created : false;
        this.skipClientValidation = props.additional_meta && props.additional_meta.skipClientValidation ? props.additional_meta.skipClientValidation : false;
        this.skipClientValidation = this.skipClientValidation || this.autoCreated;
        // construct validation
        this.validationSchema = this.isPrimitive ? createPrimitiveFieldValidation(this) : createRelatedFieldValidation(this);

        this.itemFieldDescriptor = !this.isPrimitive && this.containsPrimitiveValues ?
             new ItemFieldDescriptor({type: this.relationClazz}) : null;
    }

    createField = (modelFactory, owner, initialData) => {
        let FieldClazz
        FieldClazz = FIELD_CLAZZ_MAPPING[this.type];
        return new FieldClazz({ internalId: this.name, modelFactory, fieldDescriptor:this, owner, initialData });
    }

    getPath = (parentPath) => {
        return parentPath ? `${parentPath}.${this.name}` : `${this.name}`;
    }

    validate = (value) => {
        const validationSchema = this.validationSchema; //this.$classDescription.validationSchemas[this.name];
        if (validationSchema && !this.skipClientValidation) {
            let validationErrors = this.additionalValidationFuction ? this.additionalValidationFuction(value) : [];
            try {
                validationSchema.validateSync(value, { abortEarly: false });
            } catch (err) {
                const schemaValidationErrors = convertYupError(err);
                validationErrors = validationErrors.concat(schemaValidationErrors);
            }
            if (validationErrors.length > 0) {
                return validationErrors;
            }
        }
        return null;
    }

 

    getChoices = (name) => {
        const choices = this.choices;
        if (choices) {
            return choices.map(choiceTuple => ({ label: choiceTuple[1], value: choiceTuple[0] }));
        }
        return null;
    }
}

export class ClassDescription {

    clazzName = null;
    verboseName = null;
    fieldDescriptors = [];
    fieldDescriptorMap = null;
    additionalValidationFuction = null;

    constructor({ clazzName, verboseName, fields, additionalValidationFuction }) {
        
        this.clazzName = clazzName;
        this.verboseName = verboseName ? verboseName : null;
        this.fields = fields ? fields : [];
        this.additionalValidationFuction = additionalValidationFuction;

        this.fieldDescriptors = this.fields.map(fieldDescriptionProps => this.createFieldDescriptor(fieldDescriptionProps));
        this.fieldDescriptorMap = this.fieldDescriptors.reduce(
            (mapToCreate, fieldDescriptor) => {
                mapToCreate.set(fieldDescriptor.name, fieldDescriptor)
                return mapToCreate;
            },
            new Map()
        );
    }

    createFieldDescriptor = (fieldDescriptionProps) => {

        const descriptorProps = { classDescription: this, ...fieldDescriptionProps }
        return new FieldDescriptor(descriptorProps);
    }

    getFieldDescriptor = (name) => {
        return this.fieldDescriptorMap.get(name);
    }

    getFieldChoices = (name) => {
        const fieldDescriptor = this.getFieldDescriptor(name);
        return fieldDescriptor.getChoices();
    }

    createFields = (modelFactory, fields, owner, initialData) => {

        this.fieldDescriptors.forEach((fieldDescriptor) => {
            const fieldName = fieldDescriptor.name
            const initialFieldData = initialData ? initialData[fieldName] : null; //check which default is needed.
            const field = fieldDescriptor.createField(modelFactory, owner, initialFieldData);
            fields.set(fieldName, field);
        });
        return fields;
    }

}


export class BaseModel extends Base {

    $fields = new Map();
    $classDescription = null;

    invalidChilds = [];




    constructor(modelFactory, classDescription, {internalId, owner, initialData }) {
        super({internalId, owner })
        this.$classDescription = classDescription;
        this.$fields = classDescription.createFields(modelFactory, this.$fields, this, initialData);

        makeObservable(this, {
            setValue: action,
            setValues: action,
            addToArray: action,
            validate: action,
            removeFromArray: action,
            moveItemInArray: action,
            setArrayValue: action,
            //notifyDirty:action,
            addToMap: action,
            removeFromMap: action,
        })
        this.validateInstance();
    }


    validateFields = () => {
        let valid = true;
        this.$classDescription.fieldDescriptors.forEach(fieldDescriptor => {
            const name = fieldDescriptor.name;
            const field = this.$fields.get(name)
            const fieldValid = field.validate();
            valid = valid && fieldValid;
        });
        return valid;
    }

    validateInstance = (options) => {
        if (this.$classDescription.additionalValidationFuction) {
            const validationErrors = this.$classDescription.additionalValidationFuction(this);
            this.setErrors(validationErrors);
            if (validationErrors && validationErrors.length > 0) {
                return false;
            }
        }
        return true;
    }

    validate = () => {
        const validFields = this.validateFields();
        const validInstance = this.validateInstance();
        const valid = validFields && validInstance
        this.setValid(valid);
        return this.valid;
    }


    setValue = (name, value, options) => {
        const field = this.getField(name)
        field.setValue(value, options);
    }

    /*
    Set multipe values in once. nameValues is a map with name/value
    */
    setValues = (nameValues, options) => {
        const fieldNames = Object.keys(nameValues);

        const doNotifyTouched = options ? options.touched : true;

        fieldNames.forEach(name => {
            const field = this.$fields.get(name)
            const newValue = nameValues[name];
            field.setValue(newValue, options);
        })


        if (doNotifyTouched) {
            this.notifyTouched();
        }
    }


   


    notify = ({ internalId, touched, valid, changed, inConstructor }) => {
        // uhm, one of my fields has changed so i'm touched
        const childTouched = isNotUndefined(touched) ? touched : false;
        
        let propagatedMessage = {internalId: this.internalId}
        

        if (isNotUndefined(valid)){
            if (!inConstructor){
                this.validateInstance();
            }
            this._updateInvalidChildsState(internalId, valid);
            propagatedMessage.valid = this.valid;
        }

        // if (isNotUndefined(changed)){
        //     const isChanged = this._updateChangedChildsState(internalId, changed);
        //     propagatedMessage.changed = isChanged;
        // }

        if (childTouched) {
            this.setTouched(childTouched);
            propagatedMessage.touched = childTouched;
            
        }

        if (this.owner) {
            this.owner.notify(propagatedMessage);
        }

    }

    addToMap(mapName, key, value, options) {
        const field = this.getField(mapName);
        field.set(key, value, options);
    }

    removeFromMap(mapName, key, options) {
        const field = this.getField(mapName);
        field.remove(key, options);
    }



    /*
      set  value of array with primitive types
    */
    addToArray(arrayName, value, options) {
        const field = this.getField(arrayName);
        field.add(value, options);
    }

    setArrayValue(arrayName, index, value, options) {
        const field = this.getField(arrayName);
        field.set(index, value, options);
    }

    removeFromArray(arrayName, indx, options) {
        const field = this.getField(arrayName);
        field.remove(indx, options);
        //this.notifyTouched();
    }

    moveItemInArray(arrayName, from, to, options) {
        const field = this.getField(arrayName);
        field.move(from, to, options);
        //this.notifyTouched();
    }



    toJSON() {
        const propertyNames = Array.from(this.$fields.keys());
        const json = propertyNames.reduce((json, propertyName) => {
            const field = this.$fields.get(propertyName)
            json[propertyName] = field.toJSON();
            return json;
        }, {});
        json["_clazz"] = this.$classDescription.clazzName;
        return json;
    }

    renewIds() {
        this.$classDescription.fieldDescriptors.forEach(fieldDescriptor => {
            const name = fieldDescriptor.name;
            
            const value = this[name];
            if (fieldDescriptor.type === LIST_OF_TYPE && !fieldDescriptor.containsPrimitiveValues) {
                if (value) {
                    value.forEach((elementValue, index) => {
                        elementValue.renewIds();
                    });
                }
            } else if (fieldDescriptor.type === MAP_OF_TYPE && !fieldDescriptor.containsPrimitiveValues) {
                if (value) {
                    const keys = Object.keys(value);
                    keys.forEach(key => {
                        const mapKeyValue = value[key];
                        if (mapKeyValue) {
                            mapKeyValue.renewIds();
                        }
                    });
                }
            } else if (fieldDescriptor.type === ONE_OF_TYPE && !fieldDescriptor.relationClazz !== "{}") {
                if (value) {
                    value.renewIds();
                }
            } else if (value && fieldDescriptor.type === PRIMITIVE_TYPE && name === 'id') {
                this[name] = uuidv4();
            }
        });
    }

    getErrors(path) {
        let errors = [];
        this.$classDescription.fieldDescriptors.forEach(fieldDescriptor => {
            const name = fieldDescriptor.name;
            const currentPath = path ? `${path}.${name}` : name;
            const fieldMeta = this.$fieldsMeta.get(name);

            const fieldErrors = fieldMeta.getErrors(currentPath);
            errors = errors.concat(fieldErrors);
            const value = this[name];
            if (fieldDescriptor.type === LIST_OF_TYPE && !fieldDescriptor.containsPrimitiveValues) {
                if (value) {
                    value.forEach((elementValue, index) => {
                        const arrayPath = `${currentPath}[${index}]`;
                        if (elementValue) {
                            const elementErrors = elementValue.getErrors(arrayPath);
                            errors = errors.concat(elementErrors);
                        }
                    });
                }
            } else if (fieldDescriptor.type === MAP_OF_TYPE && !fieldDescriptor.containsPrimitiveValues) {
                if (value) {
                    const keys = Object.keys(value);
                    keys.forEach(key => {
                        const mapPath = `${currentPath}[${key}]`;
                        const mapKeyValue = value[key];
                        if (mapKeyValue) {
                            const mapElementErrors = mapKeyValue.getErrors(mapPath);
                            errors = errors.concat(mapElementErrors);
                        }
                    });
                }
            } else if (fieldDescriptor.type === ONE_OF_TYPE && !fieldDescriptor.relationClazz !== "{}") {
                if (value) {
                    const instanceErrors = value.getErrors(currentPath);
                    errors = errors.concat(instanceErrors);
                }
            }
        });
        console.log("return errors ", this.$classDescription.clazzName, " ", errors);
        return errors;
    }

    getField = (fieldName) => {
        const field = this.$fields.get(fieldName);
        if (field) {
            return field;
        } else {
            throw new Error("No field found with name ", fieldName);
        }
    }

    getFieldValue = (fieldName) => {
        const field = this.getField(fieldName);
        const value = field.value;
        return value;
    }

    mergePathError = (errorPathAsArray, errors) => {
        if (errorPathAsArray.length > 0) {
            const path = errorPathAsArray.shift();
            if (path === "") {
                this.setErrors(errors);
            } else {
                const field = this.getField(path);
                field.mergeErrors(errors);
            }
        } else {
            this.setValid(false);
        }
    }

    mergeErrors = (validationErrors) => {
        if (validationErrors[[ERRORS_NAME]]) {
            this.setErrors(validationErrors[[ERRORS_NAME]]);
        }
        const fieldNames = Object.keys(validationErrors);
        fieldNames.forEach(fieldName => {
            if (!(fieldName === ERRORS_NAME)) {
                const field = this.getField(fieldName);
                const fieldValidationErrors = validationErrors[fieldName];
                if (field) {
                    field.mergeErrors(fieldValidationErrors)
                }
            }
        });

    }

}

