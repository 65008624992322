const CATEGORIES = {
    "rootCategories":[
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Slapen",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"sleep",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Hotel",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Hotel",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"H\u00f4tel",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Hotel",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Hotel",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Hotel",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"hotel_pension",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Bedandbreakfast",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"B\u00ead&brochje",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Bed&Breakfast",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Bed&Breakfast",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Bed & Breakfast",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Bed&Breakfast",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"bed_breakfast",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Camping",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kemping",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Camping",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Campingpl\u00e4tze",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Camping",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Campsite",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"camping",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.MotorHomePark",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kemperplak",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Emplacement pour campingcar",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Reisemobil-Stellpl\u00e4tze",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Camper plek",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Motorhome park",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"motorhomepark",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.SelfCateringAccommodation",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Partikuliere fak\u00e2nsjewente",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Logement de vacances particulier",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Privates Ferienhaus",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Particuliere vakantiewoning",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Self-catering accomodation",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"self_catering_accomodation",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.GroupAccommodation",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Groepsakkomodaasje",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Logement pour groupes",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Gruppenunterkunft",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Groepsaccommodatie",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Group accommodation",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"group_accomodation",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BungalowPark",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Bungalowpark",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Parc de bungalows",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Ferienpark/Wohnungen",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Bungalowpark",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Bungalow park",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"bungalowpark",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Eten en drinken",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"food_drink",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.FastFood",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Fast food",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Fast Food",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Fast Food",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Fast Food",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Fast Food",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"fast_food",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Restaurant",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Restaurant",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Restaurant",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Restaurant",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Restaurant",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Restaurant",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"restaurant",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.IceCreamParlour",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Eisdiele",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"IJssalon",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Ice cream parlor",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"icecreamparlour",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.DiningCafe",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Eslokal",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Eetcaf\u00e9",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Dining cafe",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"eetcafe",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Cafe",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Cafe oder Bar",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Caf\u00e9 of bar",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Cafe or bar",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"cafe",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.CoffeeOrTeaHouse",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kaffee- oder Teehaus",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Koffie- of theehuis",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Coffee or Teagarden",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"coffee_teahouse",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Lunchroom",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Sandwichgesch\u00e4ft",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Lunchroom",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Lunchroom",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"sandwich_lunch_rooms",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Dagje uit",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"dagje_uit",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Zoo",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Tierpark oder Tiergarten",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Dierenpark/tuin",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Animal park/zoo",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"zoos",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.HorseCartRental",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.HorseRental",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Pferdekutsche vermietung",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Paarden wagen verhuur",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Horse cart rental",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"horse_cart_rental",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BoatTrip",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kreuzfahrt",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Rondvaart",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Cruise",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"boat_trips",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.ThemeOrAttractionPark",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Themen- oder Freizeitparks",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Thema- of attractiepark",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Theme or attraction park",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"thema_pretparken",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Playground",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kinderspielplatz",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Speeltuin",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Playground",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"playgrounds",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Casino",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.AmusementHall",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.PoolBilliardsCenter",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BowlingSkittleAlley",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Spielhalle",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Casino",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Casino",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"casino_amusementhall",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BeautyAndWellnessCenter",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BeautyParlor",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Massage",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.SolarStudio",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Beauty en wellness",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Beaut\u00e9 et bien-\u00eatre",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Beauty und Wellness",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Beauty en wellness",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Beauty and wellness",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"beauty_wellness",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Cultuur",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"cultuur",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.HistoricArtAndCrafts",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Havenzaten",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Mill",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Castle",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Fortress",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.IndustrialHeritage",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Attractionculturalhistory",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Monastery",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Historische Handwerksbetriebe",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Historische ambachtelijke bedrijven",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Historic art and crafts",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"culture",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Church",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Mosque",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Synagoge",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kirche",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Kerk",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Church",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"churches",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.MusicVenue",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Konzertb\u00fchne",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Concert podium",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Music Venue",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"music",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Dolmen",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"H\u00fcnengr\u00e4ber",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Hunebed",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Dolmen",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"dolmen",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Galery",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Atelier",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Galerie",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Galerij",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Gallery",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"galery_atelier",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Library",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Bibleteek",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Biblioth\u00e8que",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Bibliothek",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Bibliotheek",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Library",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"libraries",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Cinema",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kino",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Bioscoop",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Cinema",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"cinemas",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Theatre",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Teater",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Th\u00e9\u00e2tre",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Theater",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Theater",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Theatre",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"theaters",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Museum",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.HeritageRailway",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Museum",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Mus\u00e9e",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Museum",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Museum",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Museen",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"musea",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Actief",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"active",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.CycleRental",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.CycleRoute",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Fytsferhier",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Location de v\u00e9los",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Fahrradverleih",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Fietsverhuur",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Bicycle rental",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"cycling",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.GolfVariant",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Golf",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Golf",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Golf",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Golf",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Golf",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"golf",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.SwimmingPool",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.OpenWaterSwimmingArea",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Swimbad",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Piscine",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Schwimmbad",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Zwembad",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Swimming pool",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"swimming",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.SquashVenue",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.TennisVenue",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Squash",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Squasbaan",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Squash court",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"tennis-squash",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.StoppingPlaceHorsesRiders",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.HorseCartRental",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.LiveryStable",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.HorseRental",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Ruhest\u00e4tte f\u00fcr Reiter",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Pleisterplaats voor ruiters",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Stopping place for horses and riders",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"horsesports",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.IceskatingRank",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Keunstiisbaan",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Patinoire",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Eislauffl\u00e4che",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"KunstIJsSchaatsbaan",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Ice skating rink",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"iceskatingrank",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.ClimbingPark",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kletterpark",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Klimpark/klimhal",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Climbing park",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"climbingpark",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.GroupActivities",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Groepsaktiviteiten",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Activit\u00e9s de groupe",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Gruppenaktivit\u00e4ten",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Groepsactiviteiten",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Group activities",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"groupactivities",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Skislope",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Skipiste",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"(Kunst)Skibaan",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Ski Slope",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"skislope",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.ToursAndTrips",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Touren und Reisen",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Tours en tochten",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Tours and trips",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"tours_trips",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Touristinformation",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"touristinformation",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.TouristInformation",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Toeristeynformaasje",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Informations touristiques",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Toeristisch informatiepunt",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"TouristInformation",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Touristeninformation",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"tourist_info_point",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Winkels",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"shops",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Shop",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Winkel",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Magasin",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Gesch\u00e4fte",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Winkel",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Shop",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"shops",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Natuur en Tuinen",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"nature",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.InformationOrHikingCenter",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.NatureMonument",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Viewpoint",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Informations- oder Wanderzentrum",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Informatie- of wandelcentrum",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Information or hiking center",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"nature",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Garden",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"T\u00fan",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Jardin",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"G\u00e4rten",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Tuin",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Garden",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"gardens",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Watersport/Nautisch",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"nautical",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BoatRental",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Boatferhier",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Location de bateaux",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Bootsvermietung",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Bootverhuur",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Boat rental",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"boat_rental",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.CanoeRental",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.SurfingSchool",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.SailingSchool",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BoatRental",
                      "name":"roles"
                   },
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.WaterSkiTrack",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Kanuverleih",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Kanoverhuur",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Canoe rental",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"watersports",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.BoatLaunchRamp",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Trailerhelling",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Rampe de lanc\u00e9e pour bateaux",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Traillerhelling",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"BoatLaunchRamp",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Bootsrampe",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"boat_launch_ramp",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Marina",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Jachthaven",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Marina",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Yachthafen",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Jachthaven",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Marina",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"marina",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             },
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.Mooring",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"Oanlisplak",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fy"
                   },
                   {
                      "translated":null,
                      "value":"Poste d'amarrage",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"fr"
                   },
                   {
                      "translated":null,
                      "value":"Motorboot Anleger",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"Aanlegplek",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"Mooring",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"mooring",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       },
       {
          "_clazz":"uso.db.categorization.Category",
          "mapIcon":[
             
          ],
          "archeType":null,
          "enabled":true,
          "filters":[
             
          ],
          "verbose_name":[
             {
                "translated":null,
                "value":"Praktisch",
                "_clazz":"uso.db.data_aspects.Description",
                "language":"nl"
             }
          ],
          "id":"practical",
          "categories":[
             {
                "_clazz":"uso.db.categorization.Category",
                "mapIcon":[
                   
                ],
                "archeType":null,
                "enabled":true,
                "filters":[
                   {
                      "_clazz":"uso.db.categorization.RoleFilter",
                      "value":"uso.db.roles.EBikeChargingStation",
                      "name":"roles"
                   }
                ],
                "verbose_name":[
                   {
                      "translated":null,
                      "value":"e-Bike Ladestation",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"de"
                   },
                   {
                      "translated":null,
                      "value":"e-bike oplaadpunt",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"nl"
                   },
                   {
                      "translated":null,
                      "value":"e-bike charging station",
                      "_clazz":"uso.db.data_aspects.Description",
                      "language":"en"
                   }
                ],
                "id":"ebikechargingstation",
                "categories":[
                   
                ],
                "icon":[
                   
                ]
             }
          ],
          "icon":[
             
          ]
       }
    ],
    "_clazz":"uso.db.categorization.Categorisation",
    "site":"drenthe2",
    "is_default":false,
    "meta":{
       "_clazz":"uso.db.data_aspects.Meta",
       "creator":"1",
       "creationTime":"2016-06-07 16:48:28",
       "modificator":"1",
       "modificationTime":"2016-06-07 16:48:28",
       "version":1,
       "owner":"1"
    },
    "type":"spot",
    "id":"027549f5-6b5d-4bd8-8df7-2958d2cf3285",
    "name":"spots"
 }


export default CATEGORIES;