import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import DropzoneComponent from 'react-dropzone-component';
import './dropzone.css';

import {JIMBOGO_SERVICE_API_HOST} from "Api"

import { observer } from 'mobx-react'




const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        // padding: theme.spacing.unit * 2,
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
        margin: 8
    },
    uploadButton: {
        marginTop: 8,
        marginBotton: 8,
    }
});

class UploadFileAssetPanel extends Component {

    constructor(props) {
        super(props);
        this.dropZone = null;
        this.state = { filesToUpload: [], newUploadedDocuments: [] }; 
    }

    _afterUpload = (fileAssetResultList) => {
        this.props.fileAssetsStore.addUploadedFileAssets(fileAssetResultList.objects);
        const queudedFiles = this.dropZone.getQueuedFiles();
        if (queudedFiles.length > 0) {
            this.dropZone.processQueue();
        }
    }

    render() {
     
        const singleFile = this.props.singleFile !=null ? this.props.singleFile : false;
        

        const defaultMessage = singleFile ? 
            "Drop one file here to upload or click here to select a file, after selection press the upload button"
            : "Drop files here to upload or click here to select files, after selection press the upload button"


        const initDropZone = (dropZone) => {
            this.dropZone = dropZone;
        }

        const getUploadParams = () => {
            return {
                organisationId: this.props.organisationId
            }
        }

        
        const addedFile = (file) => {
            console.log(file);
            let filesToUpload = this.state.filesToUpload;
            
            if (singleFile && filesToUpload.length >= 1){
                console.log("FILES ", filesToUpload);
                console.log("remove it file")
                this.dropZone.removeFile(file);
            }else{
                filesToUpload.push(file);
            }
            // const filesToUpload = this.dropZone.getQueuedFiles(); NOTE THIS
            console.log("IN QUEUED ", this.dropZone.getQueuedFiles());
            this.setState({ filesToUpload });
        }

        const processing = (file) => {
            console.log("processing ", file);
            //console.log(args);
            console.log("processing ", this.dropZone.getQueuedFiles());
        }

        const removedFile = (file) => {
            const filesToUpload = this.dropZone.getQueuedFiles();
            this.setState({ filesToUpload });
        }

        const onSuccess = (file, response) => {
            this._afterUpload(response)
            this.dropZone.removeFile(file);
        }

        const onSending = (file, xhr, formData) => {
            //set();
            const token = this.props.authStore.access;
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }

        const eventHandlers = {
            success: onSuccess,
            init: initDropZone,
            addedfile: addedFile,
            removedfile: removedFile,
            processing: processing,
            sending: onSending
        }

        const uploadFileQueue = () => {
            console.log("upload it");
            this.dropZone.processQueue();
        }

        const uploadButtonDisabled = this.state.filesToUpload.length === 0;


        const componentConfig = {
            iconFiletypes: ['.jpg', '.png', '.gif'],
            showFiletypeIcon: false,
          
            // postUrl: `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/file-assets/`,
        };

        const getUrl = (files, data) => {
            return `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/file-assets/`
        }

        const djsConfig = {
            // addRemoveLinks: false,
            timeout: 120000000,
            autoProcessQueue: false,
            // autoQueue: false, doesn't work, get a file already enqued when calling manually 
            addRemoveLinks: true,
            params: getUploadParams(),
            maxFiles: singleFile ? 1 : null,
            url: getUrl,
            dictDefaultMessage: defaultMessage,
        };


        return (
            <Paper className={this.props.classes.paper}>
                <DropzoneComponent eventHandlers={eventHandlers} config={componentConfig} djsConfig={djsConfig} />
                <Button
                    className={this.props.classes.uploadButton}
                    disabled={uploadButtonDisabled}
                    onClick={() => uploadFileQueue()}
                    variant="contained"
                    color="primary">
                    Upload
                </Button>
            </Paper>
        )
    }
}

const StyledUploadFileAssetPanel = withStyles(styles)(UploadFileAssetPanel);

export default observer(StyledUploadFileAssetPanel);
