import * as React from 'react';
import { useRootStore } from 'contexts';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';


import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Box,
    Button,
//    Typography
} from '@material-ui/core';


import MinimalLayout from 'layouts/Minimal'

import { FormWidget, FormConfigurations } from 'jimbo/ui/Forms';
import TextField from 'jimbo/ui/TextField';


import ErrorsInstance from 'jimbo/ui2/ErrorsInstance';


      

const useStyles = makeStyles(theme => ({
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    content: {
        height: '100%',
        justifyContent: 'center'
    },
    // textField: {
    //   marginTop: theme.spacing(2)
    // },
    buttons: {
        margin: theme.spacing(1)
    }
}));





const resetPasswordFormConfigurations = new FormConfigurations({
    "jimbo_accounts.model.PasswordReset": {
        fields: [
            {
                fieldName: "password",
                label: "Password",
                widget: TextField,
                widgetProps: { "type": "password" },
            },
            {
                fieldName: "passwordRepeat",
                label: "Repeat password",
                widget: TextField,
                widgetProps: { "type": "password" },
            },
        ]
    }
});


const ResetPasswordForm = ({ instance, store }) => {
    return <React.Fragment>
        <FormWidget label="Reset Password" instance={instance} formConfigurations={resetPasswordFormConfigurations} store={store} />
        <ErrorsInstance instance={instance} />
    </React.Fragment>
}


const ServerErrorWidget = ({ error, routerStore }) => {
    if (error.code === 404) {
        return <Box>
            <p>No password reset found, it can be expired or you have done a more recent request.</p>

            <Button onClick={() => routerStore.goTo('forgotPassword')} color="secondary">
                Request new password reset
            </Button>
        </Box>
    }
    return <Box>{error.message}</Box>
}

const ResetPasswordScreen = observer((props) => {

    const classes = useStyles();

    const routerStore = useRouterStore();
    const rootStore = useRootStore();
    const { resetPasswordStore } = rootStore;

    const resetPassword = resetPasswordStore.selectedObject;

    // const onBackToSignin = () => {
    //     routerStore.goTo("signin")
    // }

    const saveInstance = () => {
        resetPasswordStore.save(resetPassword)
    }

    const canSave = resetPassword ? resetPassword.valid : false;

    const errorWidget = resetPasswordStore.objectState.error ?
        <ServerErrorWidget routerStore={routerStore} error={resetPasswordStore.objectState.error} /> : null;
    return (
        <MinimalLayout>
            <Grid
                className={classes.grid}
                container>
                <Grid
                    className={classes.quoteContainer}
                    item
                    quoteContainer
                    lg={4}>
                </Grid>
                <Grid
                    className={classes.content}
                    container
                    item
                    direction="column"
                    lg={4}
                    xs={12}
                >
                    <Grid item>
                        <Grid container
                            item
                            direction="column">
                            {errorWidget ? <Grid item>{errorWidget}</Grid> : null}
                            {resetPassword ?
                                <React.Fragment>
                                    <Grid item>
                                        <ResetPasswordForm instance={resetPassword} />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained"
                                            color="primary"
                                            className={classes.buttons}
                                            onClick={() => saveInstance()}
                                            disabled={!canSave}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </React.Fragment> : null}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    className={classes.quoteContainer}
                    item
                    quoteContainer
                    lg={4}>
                </Grid>
            </Grid>

        </MinimalLayout>
    );
});

export default ResetPasswordScreen;



