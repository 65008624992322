import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
    },
    contentMain: {
        padding: theme.spacing(3)
    }
}));

const MainContentLayout = (props) => {
    const { children, toolbar } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <main className={classes.content}>
                {toolbar ? toolbar: null}
                <div className={classes.contentMain}>
                    {children}
                </div>
            </main>
        </div>
    );
}

export default MainContentLayout;