import React from 'react'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress  from '@material-ui/core/CircularProgress';

import ExperienceDialogSelector from './components/ExperienceDialogsSelector'
import ExperienceMap from 'screens/ExperienceScreen/components/ExperienceMap'

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';


import { OBJECT_TYPE_TRACK } from 'model/const';

import FixedToolbar from 'components/FixedToolbar'
import MainLayout from 'layouts/Main';

import ExperienceGeneralForm from './components/ExperienceGeneralForm';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        //padding: theme.spacing(3),
    },

    route: {
    },
    mapContainer: {
        //flex: '1 1 auto',
        height: 500,
        width: "100%",
        position: 'relative',
        backgroundColor: 'red'
    },
    loading: {
        height: '100%'
    }
}));


const ExperienceGeneralEditor = observer(({ experienceEditorStore }) => {
    return <ExperienceGeneralForm instance={experienceEditorStore.experience} />
});


const NewExperienceScreen = observer(() => {

    const classes = useStyles()

    const routerStore = useRouterStore();
    const rootStore = useRootStore();
    const { experienceStore, authStore } = rootStore;
    const { experienceEditorStore } = experienceStore;


    const onBackToOverview = () => {
        routerStore.goTo('experiences')
    }

    const addTrack = () => experienceEditorStore.showDialogForAddingExperienceObject(OBJECT_TYPE_TRACK);

    const save = async() => { 
        const isSaved = await experienceEditorStore.saveExperienceAsync();
        if (isSaved){
            const id = experienceEditorStore.experience.id;
            routerStore.goTo('experience', { params: { id } });
        }
    };


    const backButton =  <Button
		onClick={() => onBackToOverview() }
		size="small"
		variant="text">
		<ArrowBackIcon />
			Back to Overview
		</Button>

    const toolbar = <FixedToolbar back={backButton}>
    
    </FixedToolbar>

    if (experienceEditorStore.experience){
        return <MainLayout toolbar={toolbar}>
                <ExperienceDialogSelector authStore={authStore} experienceEditorStore={experienceEditorStore} />
   
                <div className={classes.route}>
                    <Container fixed>
                        <Grid container spacing={2}>
                            <Grid item container sm={12} md={12}>
                                <Grid sm={6} md={6} container item spacing={2}>
                                    <Grid item sm={12} md={12}>
                                    <ExperienceGeneralEditor experienceEditorStore={experienceEditorStore} />
                                    </Grid>
                                    <Grid item sm={12} md={12}>
                                    
                                    </Grid>
                                    <Grid item sm={12} md={12}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => addTrack()}>
                                            Add track
                                            </Button>
                                    </Grid>
                                    <Grid item sm={12} md={12}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => save()}>
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container item sm={6} md={6}>
                                    <Grid item sm={12} md={12}>
                                        <div className={classes.mapContainer}>
                                        <ExperienceMap experienceEditorStore={experienceEditorStore}/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </MainLayout>
    }else{
        return <MainLayout  toolbar={toolbar}>
            <Grid className={classes.loading} container direction="row" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="button" display="block" gutterBottom>
                        Loading
                    </Typography>
                    <CircularProgress />
                </Grid>
            </Grid>
        </MainLayout>
    }
});


export default NewExperienceScreen;

