import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';

import { useRootStore } from 'contexts';

import { observer } from 'mobx-react'

import Transition from './DialogTransition';
import ObjectsVirtualizedTable from 'components/ObjectsVirtualizedTable';
import BaseSelectDialog from './BaseSelectDialog';

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        padding: 32,
        flexGrow: 1,
        flexDirection: 'column',
        display: 'flex'
    },
}));


const runnableObjectTableCellRenderer = ({ cellData, columnIndex, rowData }) => {
    const rowHeight = 48;
    const align = 'right'; //(columnIndex != null && experienceColumns[columnIndex].numeric) || false ? 'right' : 'left'
    return (
        <TableCell
            style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box', flex: 1, height: rowHeight }}
            component="div"
            variant="body"
            //          style={{  }}
            align={align}
        >
            {cellData}
        </TableCell>
    );
};

const getRowDataFn = ({store}) => {
    return ({ index }) => {
        const resultObject = store.selectObjectsState.objects[index];

        const instance = resultObject.instance;
        const id = instance.id;
        const name = instance.name;
        return { id, name, resultObject }
    }
}


const SelectRunnableObjectDialog = observer(({handleClose, handleCancel, open, providedLocation, experienceEditorStore  }) => {

    const rootStore = useRootStore()
    const runnableObjectsStore = rootStore.runnableObjectsStore;

    const classes = useStyles();

    
    const columnsCreator = ({ sort }) => {
        return [
            {
                width: 400,
                label: 'Name',
                dataKey: 'name',
                sort: () => sort('name')
            }
    
        ]
    };
    
    return <BaseSelectDialog
            store={runnableObjectsStore}
            clearable={false}
            label="Select an App Object"
            open={open} 
            handleCancel={handleCancel}
            handleClose={handleClose} 
            columnsCreator={columnsCreator}
            cellRenderer={runnableObjectTableCellRenderer}
            getRowDataFn={getRowDataFn}
            selectedRowToId={(rowData) => (rowData.id)} 
            />

    
});

export default SelectRunnableObjectDialog;
