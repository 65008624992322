import { observable, makeObservable, action } from 'mobx';
//import { observable, makeObservable, observe, autorun, autorunAsync, action, computed, isComputedProp, isObservableArray } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

const moveItem = (target, fromIndex, toIndex) => {
    if (fromIndex === toIndex) {
        return
    }
    const oldItems = target.slice()
    let newItems = []
    if (fromIndex < toIndex) {
        newItems = [
            ...oldItems.slice(0, fromIndex),
            ...oldItems.slice(fromIndex + 1, toIndex + 1),
            oldItems[fromIndex],
            ...oldItems.slice(toIndex + 1),
        ]
    } else {
        // toIndex < fromIndex
        newItems = [
            ...oldItems.slice(0, toIndex),
            oldItems[fromIndex],
            ...oldItems.slice(toIndex, fromIndex),
            ...oldItems.slice(fromIndex + 1),
        ]
    }
    target.replace(newItems)
    return target
}


export const parseMapValues = (parent, amap, parseFunction) =>{

    return Object.keys(amap).reduce((result, key) => { 
        result[key] = parseFunction(parent, amap[key]);
        return result;
    }, {})
}


const PRIMITIVE_RELATION_CLAZZES =  ['string', 'float', "int", "{}"];

const PRIMITIVE_FIELDS = ['CharField','TextField','DateTimeField','IntegerField','BooleanField','DecimalField','FloatField'];

const PRIMITIVE_TYPE = 'Primitive'; // one of the primitive fields
const ONE_OF_TYPE = 'OneOf';
const LIST_OF_TYPE = 'ListOf';
const MAP_OF_TYPE = 'MapOf';

//const RELATED_FIELDS = [LIST_OF_TYPE,ONE_OF_TYPE,MAP_OF_TYPE];
    

const isPrimitiveField = (fieldType) => {
    return PRIMITIVE_FIELDS.indexOf(fieldType) >= 0;
}

const hasPrimitiveRelationClazz = (relationClazz) => {
    return PRIMITIVE_RELATION_CLAZZES.indexOf(relationClazz) >= 0;
}

const toShortTypeName = (fullTypeName) => {
    const typeNames = fullTypeName.split(".");
    return typeNames[typeNames.length-1];
}

export const isNotUndefined = (value) => {
    return (typeof value !== 'undefined');
}


export class FieldDescription{

    name = undefined;
    isGroup = undefined;
    isSuperClassField = undefined;
    verboseName = '';
    maxLength = undefined;
    minLenght = undefined;
    choices = [];
    initValue = undefined;
    isNull = undefined;
    isBlank = undefined;
    type = undefined;
    autoCreated = undefined;
    relationClazz = undefined; // only for oneOf, listOf, mapOf

    baseType = undefined;
    isPrimitive = undefined;
    containsPrimitiveValues = undefined; // only for listOf, mapOf
    skipClientValidation = false;

    constructor(props){
        this.$classDescription = props.classDescription;
        this.name = props.name;
        this.verboseName = props.verbose_name;
        this.isGroup = props.is_group;
        this.initValue = props.init_value;
        this.isNull = props.null;
        this.isBlank = props.blank;
        this.choices = props.choices;

        this.type = toShortTypeName(props.type);
        this.relationClazz = props.relation_clazz;
        this.isPrimitive = isPrimitiveField(this.type)
        this.baseType = this.isPrimitive ? PRIMITIVE_TYPE : this.type;
        if (!this.isPrimitive){
            this.relationClazz = props.relation_clazz;
            this.containsPrimitiveValues =  hasPrimitiveRelationClazz(this.relationClazz);
        }
        this.autoCreated = props.auto_created ? props.auto_created: false; 
        this.skipClientValidation = props.additional_meta && props.additional_meta.skipClientValidation  ? props.additional_meta.skipClientValidation : false;
        this.skipClientValidation = this.skipClientValidation || this.autoCreated;
    }

 
    validateArrayElementValues = (value) => {
        const arrayValueValidationSchema = this.$classDescription.arrayItemValidationSchema[this.name]
        const errorArray = [];
        let hasItemWithError = false;
        if (value && arrayValueValidationSchema){
            value.forEach(value => {
                try{
                    arrayValueValidationSchema.validateSync(value, {abortEarly: false});
                    errorArray.push(null);
                }catch(err){
                    hasItemWithError = true;
                    errorArray.push(err);
                }
            });
            if(hasItemWithError){
                return errorArray;
            }
        }
        return null;
    }

    validatePrimitiveItemValue = (value) => {
        const arrayValueValidationSchema = this.$classDescription.arrayItemValidationSchema[this.name]
        if (arrayValueValidationSchema){
            try{
                arrayValueValidationSchema.validateSync(value, {abortEarly: false});
            }catch(err){
                return err;
            }
        }
        return null;
    }

    validateMapElementValues = (value) => {
        const arrayValueValidationSchema = this.$classDescription.arrayItemValidationSchema[this.name]
        const errorMap = {};
        let hasItemWithError = false;
        if (value && arrayValueValidationSchema){
            Object.keys(value).forEach( key => {
                try{
                    const itemValue = value[key]; 
                    arrayValueValidationSchema.validateSync(itemValue, {abortEarly: false});
                }catch(err){
                    hasItemWithError = true;
                    errorMap[key] = err;
                }
            });
            if(hasItemWithError){
                return errorMap;
            }
        }
        return null;
    }

    validateField = (value) => {
        const validationSchema = this.$classDescription.validationSchemas[this.name];
        if (validationSchema && !this.skipClientValidation){
            try{
                validationSchema.validateSync(value, {abortEarly: false});
                return null;
            }catch(err){
                return err;
            }
        }
    }

    getChoices = (name) => {
        const choices = this.choices;
        if (choices){
            return choices.map(choiceTuple => ({label: choiceTuple[1], value: choiceTuple[0]}));
        }
        return null;
    }
}



export class ClassDescription{
    
    validationSchemas = {}
    arrayItemValidationSchema = {}
    clazzName = null;
    verboseName = null;
    fieldDescriptors = [];
    fieldDescriptorMap = null;
    
    constructor({validationSchemas, arrayItemValidationSchema, clazzName, verboseName, fields}){
        this.validationSchemas = validationSchemas ? validationSchemas: {};
        this.clazzName = clazzName;
        this.verboseName = verboseName ? verboseName: null;
        this.fields = fields ? fields: [];
        this.arrayItemValidationSchema = arrayItemValidationSchema ? arrayItemValidationSchema : {};
        this.fieldDescriptors = this.fields.map( fieldDescriptionProps => new FieldDescription({classDescription: this, ...fieldDescriptionProps}));
        this.fieldDescriptorMap = this.fieldDescriptors.reduce(
            (mapToCreate, fieldDescriptor) => { 
                mapToCreate.set(fieldDescriptor.name, fieldDescriptor)
                return mapToCreate;
            }, 
            new Map()
        );
    }

    getFieldDescriptor = (name) =>{
        return this.fieldDescriptorMap.get(name);
    }

    getFieldChoices = (name) => {
        const fieldDescriptor = this.getFieldDescriptor(name);
        return fieldDescriptor.getChoices();
    }


    createFieldsMeta = (value, fieldsMeta) => {

        this.fieldDescriptors.forEach( (fieldDescriptor) => {
            const fieldName = fieldDescriptor.name
            const initialValue = value[fieldName];
            fieldsMeta.set(fieldName, new FieldMeta(fieldDescriptor, initialValue));
        });
        return fieldsMeta;
    }
}

export class FieldMeta{
    
    isDirty = false;
    isTouched = false;
    errors = null;
    
    elementsMeta = null;  // only in case of ListOf (array) or MapOf (map) of primitive values.

    fieldDescriptor = null; // null if primitive value from array or map
    initialValue= null;

    constructor(fieldDescriptor, initialValue ){
        this.fieldDescriptor = fieldDescriptor;
        this.initialValue = initialValue;

        if (fieldDescriptor){
            if (fieldDescriptor.containsPrimitiveValues && toShortTypeName(fieldDescriptor.baseType) === LIST_OF_TYPE && !fieldDescriptor.skipClientValidation){
                //this.validationMessages = new ArrayValidationMessages({length: initialValue ? initialValue.length: 0});
                const nrElements = initialValue ? initialValue.length: 0;
                this.elementsMeta = [];
                for(let i = 0; i < nrElements; i++){
                    this.elementsMeta.push(new FieldMeta(null, initialValue[i]));
                }
            }else if (fieldDescriptor.containsPrimitiveValues && toShortTypeName(fieldDescriptor.type) === MAP_OF_TYPE){
                //this.validationMessages = new MapValidationMessages({keys: initialValue ? Object.keys(initialValue): []});
                const keys = initialValue ? Object.keys(initialValue): [];
                this.elementsMeta = keys.reduce(
                    (elementsMeta, key) => { 
                        elementsMeta[key] = new FieldMeta(null, initialValue[key]); 
                        return elementsMeta;
                    }, {}
                );
                
            }
            //console.log("elementsMeta for ", this.fieldDescriptor.name, " elementMeta ", this.elementsMeta  )
        }

        makeObservable(this,{
            isDirty: observable,
            isTouched: observable,
            errors: observable,
            elementsMeta: observable,
            setErrors: action,
            setTouched: action,
            checkDirty: action,
            addArrayElement: action,
            setElementErrors: action,
            setElementsTouched: action,
            setElementError: action,
            removeElement: action,
            moveElement: action,
            addMapElement: action,
            setMapElementsErrors: action,
            setMapElementsTouched: action,
            setMapElementError: action,
            removeMapElement: action,
        });
    }


    setElementErrors = ( arrayErrors ) => {
        if (arrayErrors){
            arrayErrors.forEach( (elementErrors, indx) => {
                if (elementErrors){
                    this.elementsMeta[indx].setErrors(elementErrors)
                }else{
                    this.elementsMeta[indx].setErrors(null)
                }
            });
        }else{
            this.elementsMeta.forEach((elementMeta, indx)=> {
                this.elementsMeta[indx].setErrors(null);
            });
        }
    }

    setElementsTouched = (value) => {
        const isMapType = toShortTypeName(this.fieldDescriptor.type) === MAP_OF_TYPE
        if(isMapType){
            const keys = Object.keys(this.elementsMeta);
            keys.forEach(key => {
                this.elementsMeta[key].setTouched(value);
            });
        }else{ // is an array
            this.elementsMeta.forEach((elementMeta, indx)=> {
                this.elementsMeta[indx].setTouched(value);
            });
        }
        
    }

    setMapElementsErrors = ( mapElementsErrors ) => {
        const keys = Object.keys(this.elementsMeta);
        if (mapElementsErrors){
            keys.forEach(key => {
                const errors = mapElementsErrors[key] ? mapElementsErrors[key] : null;
                this.elementsMeta[key].setErrors(errors); 
            });
        }else{
            keys.forEach(key => {
                this.elementsMeta[key].setErrors(null); 
            });
        }
    }
    
    setMapElementsTouched = ( value ) => {
        const keys = Object.keys(this.elementsMeta);
        keys.forEach(key => {
            this.elementsMeta[key].setTouched(value); 
        });
        
    }

    setMapElementError = (key, errors) => {
        this.elementsMeta[key].setErrors(errors)
    }

    setElementError = (indx, errors) => {
        this.elementsMeta[indx].setErrors(errors)
    }

    setErrors = (errors) => {
        
        this.errors = errors;
    }

    setTouched = (touched) => {
        this.isTouched = touched;
    }


    checkDirty = (value) => {
        this.isDirty =  this.initialValue !== value;
    }

    /*

    */
    addArrayElement = (value, options) => {
        const elementFieldMeta = new FieldMeta(null, value);
        this.elementsMeta.push(elementFieldMeta);

        const doNotifyTouched = options ? options.touched: true; 
        // run validation
        if (doNotifyTouched){
            elementFieldMeta.setTouched(true);
        }
    }

    insertArrayElement = (indx, value, options) => {
        const elementFieldMeta = new FieldMeta(null, value);
        this.elementsMeta.splice(indx, 0, elementFieldMeta);

        const doNotifyTouched = options ? options.touched: true; 
        // run validation
        if (doNotifyTouched){
            elementFieldMeta.setTouched(true);
        }
    }

    addMapElement = (key, value, options) => {
        const elementFieldMeta = new FieldMeta(null, value);
        this.elementsMeta[key] = elementFieldMeta;
        const doNotifyTouched = options ? options.touched: true; 
        if (doNotifyTouched){
            elementFieldMeta.setTouched(true);
        }
    }
    
    moveElement = (fromIndex, toIndex)=> {
        this.elementsMeta = moveItem(this.elementsMeta, fromIndex, toIndex);
    }

    removeElement(indx){
        this.elementsMeta.splice(indx, 1);
    }

    removeMapElement(key){
        delete this.elementsMeta[key];
    }

    getErrors(currentPath){
        let fieldErrors = []
        if (this.errors){
            fieldErrors.push({path: currentPath, errors: this.errors});
        }
        if (this.elementsMeta){
            if (Array.isArray(this.elementsMeta)){
                this.elementsMeta.forEach( (elementFieldMeta, index) => {
                    const elementPath = `${currentPath}[${index}]`;
                    const elementErrors = elementFieldMeta.getErrors(elementPath);
                    fieldErrors.push({path: elementPath,errors: elementErrors});
                });
            }else{
                // asume map
                const keys = Object.keys(this.elementsMeta);
                keys.forEach( key => {
                    const elementPath = `${currentPath}[${key}]`;
                    const elementErrors = this.elementsMeta[key].getErrors();
                    fieldErrors.push({path: elementPath,errors: elementErrors});
                }); 
            }
        }
        return fieldErrors;
    }
}


class InstanceMeta {

    isDirty = false;
    isTouched = false;
    isValid = undefined;

    errors = null;
   
    constructor(){
        
        makeObservable(this,{
            isDirty: observable,
            isTouched: observable,
            errors: observable,
            isValid: observable,
            setErrors: action,
            setValid: action,
            setTouched: action,
        });
    }

    setErrors = (errors) => {
        this.errors = errors;
    }

    setValid = (valid) => {
        this.isValid = valid;
    }

    setDirty = (dirty) => {
        this.isDirty = dirty;
    }


    setTouched = (touched) => {
        this.isTouched = touched;
    }
}



export class BaseModel{

    $fieldsMeta = new Map(); 
    $instanceMeta = new InstanceMeta();        
    $classDescription = null;
    $parent=null;
    
    static noJsonProperties = ["$classDescription", "$parent","$instanceMeta","$fieldsMeta"]

    constructor(parent){
        makeObservable(this,{
            //$instanceMeta: observable,
            setValue: action,
            setValues: action,
            addToArray: action,
            validate: action,
            removeFromArray: action,
            moveItemInArray:action,
            setArrayValue:action,
            //notifyDirty:action,
            addToMap: action,
            removeFromMap: action,
        })
        this.$parent = parent
    }

    static excludeAsJsonProperty(name){
        return BaseModel.noJsonProperties.indexOf(name) >= 0;
    }


    _validateField = (fieldDescriptor, value, options) => {
        let isValid = true;
        
        const setTouched = options ? options.touched: false;

        if (!fieldDescriptor.skipClientValidation){
            const name = fieldDescriptor.name;
            const fieldMeta = this.$fieldsMeta.get(name)
                
            if (fieldDescriptor.isPrimitive){
                const errors = fieldDescriptor.validateField(value);
                if (setTouched){
                    fieldMeta.setTouched(true); // @ 
                }
                fieldMeta.setErrors(errors);
                if (errors){
                    isValid = false;
                }
            }else if (fieldDescriptor.type === LIST_OF_TYPE && fieldDescriptor.containsPrimitiveValues){
                const errors = fieldDescriptor.validateArrayElementValues(value);
                fieldMeta.setElementErrors(errors);    
                if (setTouched){
                    fieldMeta.setElementsTouched(true);
                }
                if (errors){
                    isValid = false;
                }
            }else if (fieldDescriptor.type === MAP_OF_TYPE && fieldDescriptor.containsPrimitiveValues){
                const errors = fieldDescriptor.validateMapElementValues(value);
                fieldMeta.setMapElementsErrors(errors);    
                if (setTouched){
                    fieldMeta.setElementsTouched(true);
                }
                if (errors){
                    isValid = false;
                }
            }else if (fieldDescriptor.type === ONE_OF_TYPE && fieldDescriptor.relationClazz !== "{}") {
                // check first the instance values
                const isValidInstance = value ? value.validate(options): true;
                isValid = isValid && isValidInstance;
                // checks if instance can be null, ....
                const errors = fieldDescriptor.validateField(value);
                fieldMeta.setErrors(errors);    
                if (errors){
                    isValid = false;
                }
            }else if (fieldDescriptor.type === LIST_OF_TYPE){
                if (value){
                    value.forEach( itemValue => {
                        const isValidInstance = itemValue ? itemValue.validate(options): true;
                        isValid = isValid && isValidInstance;
                    });
                }
                // @todo check if may be null, min and max lenght array
            }else if (fieldDescriptor.type === MAP_OF_TYPE){
                if (value){
                    Object.values(value).forEach( itemValue => {
                        const isValidInstance = itemValue ? itemValue.validate(options): true;
                        isValid = isValid && isValidInstance;
                    });
                }
                // @todo check if may be null, min and max keys
            }
        }
        return isValid;
    }

    validateFields = (options) => {
        let isValid = true;
        this.$classDescription.fieldDescriptors.forEach( fieldDescriptor => {
            if (!fieldDescriptor.skipClientValidation){
                const name = fieldDescriptor.name;
                const value = this[name];
                isValid = isValid && this._validateField(fieldDescriptor, value, options);
            }
        });
        return isValid;
    }


    validate = (options) => {
        const validFields = this.validateFields(options);
        this.$instanceMeta.setValid(validFields);
        return this.$instanceMeta.isValid;
        // @todo add instance level related checks 
    }


    setValue = (name, value, options)=>{
        this[name] = value;
        const doNotifyTouched = options ? options.touched: true; 
        // run validation
        const fieldMeta = this.$fieldsMeta.get(name)
        if (doNotifyTouched){
            fieldMeta.setTouched(true);
            this.notifyTouched();
        }
        const checkDirty = options ? options.dirty: true;
        if (checkDirty){
            fieldMeta.checkDirty(value);
        }

        const validate = options ? options.validate: true;

        if (validate){
            const fieldDescriptor = this.$classDescription.getFieldDescriptor(name);
            this._validateField(fieldDescriptor, value);
        }
    }

    /*
    Set multipe values in once. nameValues is a map with name/value
    */
    setValues = (nameValues, options)=> {
        const fieldNames = Object.keys(nameValues);

        const doNotifyTouched = options ? options.touched: true; 
        const checkDirty = options ? options.dirty: true;
        

        fieldNames.forEach(name => {
            const fieldMeta = this.$fieldsMeta.get(name)
            const newValue = nameValues[name];
            this[name] = newValue;
            if (doNotifyTouched){
                fieldMeta.setTouched(true);
            }
            if (checkDirty){
                fieldMeta.checkDirty(newValue);
            }
        })

        
        if (doNotifyTouched){
            this.notifyTouched();
        }
        

        const validate = options ? options.validate: true;
        if (validate){
            fieldNames.forEach(name => {
                const value = this[name];
                const fieldDescriptor = this.$classDescription.getFieldDescriptor(name);
                this._validateField(fieldDescriptor, value);
            })
        }
    }


    _validateArrayValue(fieldDescriptor, value, index){
        const arrayName = fieldDescriptor.name;
        if (!fieldDescriptor.containsPrimitiveValues){
            if (value){
                value.validate();
            }
        }else{
            const fieldMeta = this.$fieldsMeta.get(arrayName);
            const errors = fieldDescriptor.validatePrimitiveItemValue(value);
            fieldMeta.setElementError(index, errors);
        } 
    } 

    addToArray(arrayName, value, options){
        const fieldDescriptor = this.$classDescription.getFieldDescriptor(arrayName);
        if (!fieldDescriptor.containsPrimitiveValues){
            if (value.parent){
                value.parent = this;
            }
        }
        const count = this[arrayName].push(value);
        const index = count - 1;

        const doNotifyTouched = options ? options.touched: true; 
        
        if (doNotifyTouched){
            this.notifyTouched()
        }

        const validate = options ? options.validate: true;
        
        if (fieldDescriptor.containsPrimitiveValues){
            const fieldMeta = this.$fieldsMeta.get(arrayName);
            fieldMeta.addArrayElement(value, options);
        }

        if (validate){
            this._validateArrayValue(fieldDescriptor, value, index)
        }
    }

    insertIntoArray(arrayName, indx, value, options){
        

        const fieldDescriptor = this.$classDescription.getFieldDescriptor(arrayName);
        if (!fieldDescriptor.containsPrimitiveValues){
            if (value.parent){
                value.parent = this;
            }
        }
        const count = this[arrayName].splice(indx, 0, value);
        const index = indx;

        const doNotifyTouched = options ? options.touched: true; 
        
        if (doNotifyTouched){
            this.notifyTouched()
        }

        const validate = options ? options.validate: true;
        
        if (fieldDescriptor.containsPrimitiveValues){
            const fieldMeta = this.$fieldsMeta.get(arrayName);
            fieldMeta.insertArrayElement(indx, value, options);
        }

        if (validate){
            this._validateArrayValue(fieldDescriptor, value, index)
        }
    }

    addToMap(mapName, key, value, options){
        const fieldDescriptor = this.$classDescription.getFieldDescriptor(mapName);
        if (!fieldDescriptor.containsPrimitiveValues){
            if (value.parent){
                value.parent = this;
            }
        }
        
        this[mapName][key] = value;
        
        const doNotifyTouched = options ? options.touched: true; 
        
        if (doNotifyTouched){
            this.signalTouchedToParent()
        }

        const validate = options ? options.validate: true;
        
        if (fieldDescriptor.containsPrimitiveValues){
            const fieldMeta = this.$fieldsMeta.get(mapName);
            fieldMeta.addMapElement(key, value, options);
        }

        if (validate){
            if (!fieldDescriptor.containsPrimitiveValues){
                if (value){
                    value.validate();
                }
            }else{
                const fieldMeta = this.$fieldsMeta.get(mapName);
                const errors = fieldDescriptor.validatePrimitiveItemValue(value);
                fieldMeta.setMapElementError(key, errors);
            }            
        }
    }

    removeFromMap(mapName, key, options){
        const fieldDescriptor = this.$classDescription.getFieldDescriptor(mapName);
        delete this[mapName][key];
        
        const doNotifyTouched = options ? options.touched: true; 
        
        if (doNotifyTouched){
            this.notifyTouched()
        }

        if (fieldDescriptor.containsPrimitiveValues){
            const fieldMeta = this.$fieldsMeta.get(mapName);
            fieldMeta.removeMapElement(key);
        }
    }


    /*
      set  value of array with primitive types
    */
    setArrayValue(arrayName, index, value, options){
        const fieldDescriptor = this.$classDescription.getFieldDescriptor(arrayName);
        const validate = options ? options.validate: true;
        
        this[arrayName][index] = value
       
        if (validate){
            this._validateArrayValue(fieldDescriptor, value,index)
        }
        const doNotifyTouched = options ? options.touched: true; 

        if (doNotifyTouched){

            if (fieldDescriptor.containsPrimitiveValues){
                const fieldMeta = this.$fieldsMeta.get(arrayName);
                fieldMeta.elementsMeta[index].setTouched(true)


            }

            this.notifyTouched();
        }
        if (fieldDescriptor.containsPrimitiveValues){
            const fieldMeta = this.$fieldsMeta.get(arrayName);
            fieldMeta.elementsMeta[index].checkDirty(value);
        }
        
    }

    removeFromArray(arrayName, indx){

        this[arrayName].splice(indx, 1);

        const fieldDescriptor = this.$classDescription.getFieldDescriptor(arrayName);
        const fieldMeta = this.$fieldsMeta.get(arrayName);
        fieldMeta.setTouched(true);    
            
        if (fieldDescriptor.containsPrimitiveValues){
            if (fieldMeta.validationMessages){
                fieldMeta.validationMessages.removeFromArray(indx);
            }
        }
        this.notifyTouched();
    }

    moveItemInArray(arrayName, from, to){
        moveItem(this[arrayName], from, to);
        const fieldDescriptor = this.$classDescription.getFieldDescriptor(arrayName);
        const fieldMeta = this.$fieldsMeta.get(arrayName);
        fieldMeta.setTouched(true);    
        if (fieldDescriptor.containsPrimitiveValues){
            if (fieldMeta.validationMessages){
                fieldMeta.validationMessages.moveItemInArray(from, to);
            }
        }
        this.notifyTouched();
    }

    signalTouchedToParent(){
        if(this.$parent){
            this.$parent.notifyTouched();
        }
    }

    notifyTouched = () =>{
        this.$instanceMeta.setTouched(true);
        this.signalTouchedToParent();
    }

    toJSON() {
        const json = Object.getOwnPropertyNames(this).reduce((json, property) => {
            if(typeof this[property] === 'function'){
                if ( property === 'toJSON'){
                    json[property] = this[property].toJSON();
                }
                // else skip
            }else if (!BaseModel.excludeAsJsonProperty(property)){
                json[property] = this[property];
           }
          return json;
        }, {});
        json["_clazz"] = this.$classDescription.clazzName;
        return json;
    }

    renewIds(){
        this.$classDescription.fieldDescriptors.forEach( fieldDescriptor => {
            const name = fieldDescriptor.name;
            const fieldMeta = this.$fieldsMeta.get(name);
            
            const value = this[name];
            if (fieldDescriptor.type === LIST_OF_TYPE && !fieldDescriptor.containsPrimitiveValues){
                if (value){
                    value.forEach((elementValue, index) => {
                        elementValue.renewIds();
                    });
                }
            }else if (fieldDescriptor.type === MAP_OF_TYPE && !fieldDescriptor.containsPrimitiveValues){
                if (value){
                    const keys = Object.keys(value);
                    keys.forEach( key => {
                        const mapKeyValue = value[key];
                        if(mapKeyValue){
                            mapKeyValue.renewIds();
                        }
                    });
                }
            }else if (fieldDescriptor.type === ONE_OF_TYPE && !fieldDescriptor.relationClazz !== "{}") {
                if (value){
                    value.renewIds();
                }
            }else if (value && fieldDescriptor.type === PRIMITIVE_TYPE && name === 'id'){
                this[name] = uuidv4();
            }
        });        
    }

    getErrors(path){
        let errors = [];
        this.$classDescription.fieldDescriptors.forEach( fieldDescriptor => {
            const name = fieldDescriptor.name;
            const currentPath = path ? `${path}.${name}`: name;
            const fieldMeta = this.$fieldsMeta.get(name);
            
            const fieldErrors = fieldMeta.getErrors(currentPath);
            errors = errors.concat(fieldErrors);
            const value = this[name];
            if (fieldDescriptor.type === LIST_OF_TYPE && !fieldDescriptor.containsPrimitiveValues){
                if (value){
                    value.forEach((elementValue, index) => {
                        const arrayPath = `${currentPath}[${index}]`;
                        if(elementValue){
                            const elementErrors = elementValue.getErrors(arrayPath);
                            errors = errors.concat(elementErrors);
                        }
                    });
                }
            }else if (fieldDescriptor.type === MAP_OF_TYPE && !fieldDescriptor.containsPrimitiveValues){
                if (value){
                    const keys = Object.keys(value);
                    keys.forEach( key => {
                        const mapPath = `${currentPath}[${key}]`;
                        const mapKeyValue = value[key];
                        if(mapKeyValue){
                            const mapElementErrors = mapKeyValue.getErrors(mapPath);
                            errors = errors.concat(mapElementErrors);
                        }
                    });
                }
            }else if (fieldDescriptor.type === ONE_OF_TYPE && !fieldDescriptor.relationClazz !== "{}") {
                if (value){
                    const instanceErrors = value.getErrors(currentPath);
                    errors = errors.concat(instanceErrors);
                }
            }
        });
        console.log("return errors ", this.$classDescription.clazzName, " ", errors);    
        return errors;
    }
}

