import React from 'react';

import { Line } from "react-chartjs-2";

import moment from 'moment';

import { observer } from 'mobx-react'




const charOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    ],
  },
};


const DATE_INPUT_FORMAT = "YYYY-MM-DD";
const DATE_DISPLAY_FORMAT = "D MMM";

const getLabels = (results) => {
  return results.map(result => moment(result.date, DATE_INPUT_FORMAT).format(DATE_DISPLAY_FORMAT))
}

const getData = (results) => {
  return results.map(result => result.count);
}

const TimeLine = observer(({ timeseries, label }) => {

  const hasData = timeseries && timeseries.results ? true : false;

  const labels = hasData ? getLabels(timeseries.results) : [];
  const data = hasData ? getData(timeseries.results) : [];

  const chartData = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    return {
      labels,
      datasets: [
        {
          label,
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data,
          tension: 0.4
        },
      ],
    };
  };

  return <div className="chart-area">
      {hasData ? <Line
        data={chartData}
        options={charOptions}
      /> : null}
    </div>

});

export default TimeLine;