
import * as React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import {inputLabelStyle, formControlStyle } from 'kiini/Style';

import ExpendedTiptapEditor from './richContent/ExtendedTipTapEditor';



const useStyles = makeStyles(theme => ({
    htmlContentContainer: {
       marginBottom: 48,
       color: theme.palette.text.primary,
       flexGrow: 1
    }
}));

const HtmlContentElementWidget = observer(({id, label, instance }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();
    const classes = useStyles();
    
    const setValue = (json, html) => {
        instance.setValue("value", html);
    }
    
    const value = instance ?  instance.value: "";

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        <div className={classes.htmlContentContainer}>
            <ExpendedTiptapEditor content={value} onContentChange={setValue} />
        </div>
    </FormControl>
});

export default HtmlContentElementWidget;