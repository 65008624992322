import * as React from 'react';

import { observer } from 'mobx-react';

import BaseInput from 'jimbo/ui/BaseInput';

export const onSpaceOrEnter = (innerFn, onFocus) => (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
        innerFn();

        // prevent any side effects
        event.preventDefault();
        event.stopPropagation();
    }

    if (onFocus) {
        onFocus(event);
    }
};

const ObjectSelector = observer(({ value, clearable, onValueChange, hasError, ObjectSelectDialog, objectSelectDialogProps }) => {

    const [open, setOpen] = React.useState(false);


    const handleCancel = () => {
        setOpen(false);
    }

    const handleClose = (selectedObject) => {
        console.log("Dialog selected ", selectedObject);
        setOpen(false);
        if (onValueChange) {
            onValueChange(selectedObject);
        }

    };

    const handleClear = () => {
        setOpen(false);
        if (onValueChange) {
            onValueChange(null);
        }
    }


    //const showError = isTouched && hasErrors;

    const onOpen = () => {
        setOpen(true);
    }

    return <React.Fragment>
        {open ? <ObjectSelectDialog
            clearable={clearable}
            open={open}
            handleCancel={handleCancel}
            handleClose={handleClose}
            handleClear={handleClear}
            {...objectSelectDialogProps} />
            : null}
        <BaseInput
            readonly={true}
            value={value}
            onClick={onOpen}
            onKeyDown={onSpaceOrEnter(onOpen)} />

    </React.Fragment>
});


export default ObjectSelector;