export const moveItem = (target, fromIndex, toIndex) => {
    if (fromIndex === toIndex) {
        return
    }
    const oldItems = target.slice()
    let newItems = []
    if (fromIndex < toIndex) {
        newItems = [
            ...oldItems.slice(0, fromIndex),
            ...oldItems.slice(fromIndex + 1, toIndex + 1),
            oldItems[fromIndex],
            ...oldItems.slice(toIndex + 1),
        ]
    } else {
        // toIndex < fromIndex
        newItems = [
            ...oldItems.slice(0, toIndex),
            oldItems[fromIndex],
            ...oldItems.slice(toIndex, fromIndex),
            ...oldItems.slice(fromIndex + 1),
        ]
    }
    target.replace(newItems)
    return target
}

export const isNotUndefined = (value) => {
    return (typeof value !== 'undefined');
}

export const isUndefined = (value) => {
    return (typeof value === 'undefined');
}

export const isNotUndefinedAndNotNull = (value) => {
    return isNotUndefined(value) && (value !== null);
}


export const getErrorPathAsArray = (path) => {
    return path.toString().match(/[^.[\]]+/g) || [];
}

export const ERRORS_NAME = '$errors'

export const normalizedErrorToErrorDict = (normalizedErrors) => {


    const regexpListItem = /^\[([0-9]+)\]$/;


    const putToErrorDict = (pathParts, errors, errorDict) => {
        let lastPart = errorDict;

        if (pathParts.length === 0) {
            lastPart[[ERRORS_NAME]] = errors;
            //lastPart = lastPart[""]
        } else {
            pathParts.forEach((pathPart, indx) => {
                const match = pathPart.match(regexpListItem);

                const key = match ? match[0] : pathPart;

                let newPart = lastPart;
                if (indx === pathParts.length - 1) {
                    let current = newPart[key];
                    if (!current){
                        newPart[key] = {};    
                    }
                    newPart[key][[ERRORS_NAME]] = errors;
                } else {
                    if (isUndefined(newPart[key])) {
                        newPart[key] = {};
                    }
                    lastPart = newPart[key];
                }
            })
        }
        return errorDict
    }

    let convertedErrorDict = {};


    const errorPaths = Object.keys(normalizedErrors);
    errorPaths.forEach(errorPath => {
        const errors = normalizedErrors[errorPath];
        const errorPathAsArray = getErrorPathAsArray(errorPath);
        convertedErrorDict = putToErrorDict(errorPathAsArray, errors, convertedErrorDict);
    });
    return convertedErrorDict;
}