import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';
import {InstancePropertyFormWidget} from 'kiini/Forms';



import { 
    TrailLengths,
    ExperienceDurations,
} from 'model/contentObjects';

import {contentRoleFormConfigurations} from './configuration';


const lengthDurationRoleClazzNames = [
    'content_objects.roles.TrailLengths',
    'content_objects.roles.ExperienceDurations',
]

const lengthDurationsCreationOptions = {
    'content_objects.roles.TrailLengths': {
        label: "Distances", 
        creator: () => (new TrailLengths(null, {id: uuidv4(), lengths:[null]}))
    },
    'content_objects.roles.ExperienceDurations': {
        label: "Durations", 
        creator: () => (new ExperienceDurations(null,{id: uuidv4(),durations:[null]}))
    } 
}


const LenghtDurationRolesForm = ({instance}) => {
    return <InstancePropertyFormWidget 
        instance={instance} 
        fieldName="contentRoles" 
        label="Length Durations"
        Widget={ObjectListWithPanel}
        widgetProps={{
            creationOptions: lengthDurationsCreationOptions, 
            itemFilterFuction: instance => lengthDurationRoleClazzNames.some( name => name === instance.$classDescription.clazzName),
            oneInstancePerClassInList: true,
        }}
        formConfigurations={contentRoleFormConfigurations}/>
}


export default LenghtDurationRolesForm;
