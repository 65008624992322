import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';

//import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { observer } from 'mobx-react'


const TextSearch2 = observer(({store}) => {


    const onSearchInputChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            store.setFilterPart({ "search.all": null });
        } else {
            store.setFilterPart({ "search.all": value });
        }
    }

    const searchTerm = store.selectObjectsState.filter["search.all"] ? store.selectObjectsState.filter["search.all"] : "";


    return <TextField variant='outlined' label="Search"  value={searchTerm} onChange={(value => onSearchInputChange(value))} />;
});

export default TextSearch2;
