import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Button';

import { observer } from 'mobx-react'

import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts'

import MainLayoutVh from 'layouts/MainVh';
import VirtualizedFileAssetGrid from 'components/fileAssets/VirtualizedFileAssetGrid';

import FixedToolbar from 'components/FixedToolbar'
import TextSearch from 'components/search/TextSearch';
import StoreTablePagination from 'components/StoreTablePagination';
import FileAssetDialog from 'components/fileAssets/FileAssetDialog';

import TagsFilter from 'components/search/TagsFilter';
import AdvancedSearchButton from 'components/search/AdvancedSearchButton';
import SearchFilterSelect from 'components/search/SearchFilterSelect';


const useStyles = makeStyles(theme => ({
  fileAssetGrid: {
    width: '100%',
    height: '100%',
  }
}));

const fileTypeSearchValues = [
  ["content_objects.model.Image","Image"],
  ["content_objects.model.Audio","Audio"],
  ["content_objects.model.Video","Video"],
  ["content_objects.model.Godot","Godot"]
]

const FileAssetsScreen = observer(() => {

  const classes = useStyles();

  const rootStore = useRootStore();
  const routerStore = useRouterStore();
  const { fileAssetsStore } = rootStore;
  const [showFileAssetDialog, setShowFileAssetDialog] = React.useState(false);

  const selectedFileAsset = fileAssetsStore.selectedObject;
  const contentSet = fileAssetsStore.selectObjectsState.objects

  const toFileUpload = () => {
    routerStore.goTo('fileUpload');
  }

  const onSelect = (id) => {
    fileAssetsStore.selectObject(id);
    setShowFileAssetDialog(true);
  }

  const toolbar = <FixedToolbar>
    <TextSearch store={fileAssetsStore} />
    <AdvancedSearchButton store={fileAssetsStore} />
    <Button color="primary" variant="contained" onClick={() => toFileUpload()}>
      Add files
    </Button>
  </FixedToolbar>

return <MainLayoutVh toolbar={toolbar}>
    {showFileAssetDialog && selectedFileAsset ?
      <FileAssetDialog
        key="dialog"
        open={showFileAssetDialog}
        store={fileAssetsStore}
        handleClose={() => setShowFileAssetDialog(false)} /> : null}
    <div>
      {fileAssetsStore.showAdvancedSearch ? 
        <Box>
          <SearchFilterSelect  store={fileAssetsStore} filterName="clazz" label="Type File" filterValues={fileTypeSearchValues}/>
          <TagsFilter 
            tagsStore={fileAssetsStore.tags}
            store={fileAssetsStore} 
            filterName="tag"/>
          <TagsFilter 
            tagsStore={fileAssetsStore.areaTags}
            store={fileAssetsStore} 
            filterName="areas"/>

        </Box>: null}
      <StoreTablePagination store={fileAssetsStore} />
    </div>
    <Paper style={{ height: '100%', width: '100%' }}>
      <div className={classes.fileAssetGrid}>
        <VirtualizedFileAssetGrid
          idealItemWidth={300}
          items={contentSet}
          onSelect={onSelect}
        />
      </div>
    </Paper>
    <div>
      <StoreTablePagination store={fileAssetsStore} />
    </div>
  </MainLayoutVh>;
});

export default FileAssetsScreen;
