import React from 'react';
import Box from '@material-ui/core/Box';


export default function TabPanel(props) {
    const { children, value, index, style} = props;
    const isHidden = value !== index;
    if (!isHidden){
      return <Box
        style={style}
        p={3}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
      >
        {children}
      </Box>
    }else{
      return null;
    }
}