import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';


import { observer } from 'mobx-react'

import { useRootStore } from 'contexts';

import Preview from 'components/storyblocks/Preview';

import Transition from 'components/dialogs/DialogTransition';
import StoryBlocksTableVirtualized from 'components/storyblocks/StoryBlocksTableVirtualized';
import TagsFilter from 'components/search/TagsFilter';
import TextSearch2 from 'components/search/TextSearch2';

const useStyles = makeStyles(theme => ({
    appBar: {
        //position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttons: {
        marginRight: theme.spacing(2),
    }
}));


// function a11yProps(index) {
//   return {
//     id: `wrapped-tab-${index}`,
//     'aria-controls': `wrapped-tabpanel-${index}`,
//   };
// }




const SelectStoryBlockDialog = observer(({ storyBlocksStore, open, handleClose, handleCancel }) => {

    const classes = useStyles();

   

    const selectedStoryBlock = storyBlocksStore.selectedObject;

    const handleSelect = () => {
        handleClose(selectedStoryBlock);
    }

    const handleRemoveSelection = () => {
        handleClose(null);
    }

    const onSelectObject = (id) => {
        storyBlocksStore.selectObject(id);
    }


    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar position='sticky' className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Button className={classes.buttons} variant="contained" color="secondary" onClick={handleRemoveSelection}>Remove selection</Button>
                    {selectedStoryBlock ?
                            <Button variant="contained" color="primary" onClick={handleSelect}>Select</Button> : null}
                    <Typography variant="h6" className={classes.title}>
                        Select a Storyblock
                    </Typography>
                </Toolbar>
            </AppBar>

            <div style={{
                width: 'calc(100% + 64px)',
                margin: 0,
                height: '100%',
                display: 'flex',
                flexDirection: 'row'
            }}>
                <div style={{
                    padding: 42,
                    flexGrow: 1,
                    maxWidth: '66.666667%',
                    flexBasis: '66.666667%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <TextSearch2 store={storyBlocksStore} />    
                    <TagsFilter 
                        store={storyBlocksStore} 
                        tagsStore={storyBlocksStore.tags}
                        filterName="tag" 
                    />
                  <TagsFilter 
                     tagsStore={storyBlocksStore.areaTags}
                     store={storyBlocksStore} 
                     filterName="areas"/>
                    <div style={{ height: '100%', "width": '100%', display: "flex", flexDirection: "column" }}>
                        <StoryBlocksTableVirtualized storyBlocksStore={storyBlocksStore} onSelectObject={onSelectObject} />
                    </div>
                </div>

                <div style={{
                    padding: 32,
                    flexGrow: 1,
                    maxWidth: '33.333333%',
                    flexBasis: '33.333333%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <div>
                        
                    </div>
                    {selectedStoryBlock ?
                        <div style={{ overflowY: 'auto' }}>

                            <h1>{selectedStoryBlock.name}</h1>

                            <Preview storyblock={selectedStoryBlock} />

                        </div>
                        : null}
                </div>
            </div>



        </Dialog>
    )
});


const SelectStoryblockForLinkDialog = ({ open, handleClose, handleCancel }) => {

    const rootStore = useRootStore();

    const storyBlocksStore = rootStore.createStoryBlocksStore();

    storyBlocksStore.loadObjects();

    return <SelectStoryBlockDialog storyBlocksStore={storyBlocksStore} open={open} handleClose={handleClose} handleCancel={handleCancel} />
}

export default SelectStoryblockForLinkDialog;