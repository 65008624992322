import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import { observer } from 'mobx-react'

const useStyles = makeStyles(theme => ({
    advancedSearchBtn: {
        color: "#fff",
    }
}));


const AdvancedSearchButton = observer(({ store }) => {

    const classes = useStyles();

    const handleAdvancedSearch = (event) => {
        store.toggleAdvancedSearch();
    }

    const advancedSearchLabel = store.showAdvancedSearch ? "Hide advanced search" : "Show advanced search";
    return <Button className={classes.advancedSearchBtn} size="small" onClick={handleAdvancedSearch}>
        {advancedSearchLabel}
    </Button>
});

export default AdvancedSearchButton;