import BaseStore from "jimbo/store";

import { accountsPackageClassFactory } from "schema/accounts";
import { authenticationApi } from 'Api'
import { makeObservable, observable, action } from "mobx";


export default class SignInStore extends BaseStore {

    signInErrors = null;

    constructor({ rootStore, baseApi, authenticationApi }) {
        super({ rootStore, baseApi });
        this.authStore = rootStore.authStore;
        this.authenticationApi = authenticationApi;
        makeObservable(this, {
            doSignIn: action,
            signInErrors: observable
        });
    }

    fromJsonToObject = (data) => {
        throw Error("not expected to be called")
    }

    doCreateNewObject = ({ initValues }) => {
        return accountsPackageClassFactory.createInstance("jimbo_accounts.model.SignIn", { initialData: { userName: "", password: "" } });
    }

    afterSave = () => {
        throw Error("not expected to be called")
    }

    doSignIn = () => {
        const signIn = this.selectedObject;
        const userName = signIn.getField("userName").value;
        const password = signIn.getField("password").value;

        authenticationApi.authenticate(userName, password).then(
            action("authenticate", ({ access, refresh }) => {
                this.authStore.setAccess(access, refresh);

                authenticationApi.getUserData().then(
                    action("action getting userdata", ({ user, organisations }) => {
                        this.authStore.setUser(user, organisations, {})
                    })
                ).catch(

                );
            })
        ).catch(
            action("error auth", error => {
                this.selectedObject.getField("password").setValue("")
                this.signInErrors = {errors: [{ message: "Username and/or password are not correct" }]};
                //this.selectedObject.setErrors([{ message: "Authentication Failed" }])
            })
        );
    };

}