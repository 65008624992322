import * as React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import SelectFileContentDialog from 'components/dialogs/SelectFileContentDialog';
import {inputLabelStyle, formControlStyle } from 'kiini/Style';
import { Rendition } from 'model/contentObjects';

const useStyles = makeStyles({
    card: {
        maxWidth: 345,
    },
});


const ImageElementWidget = observer(({id, label, instance }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();


    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    }

    const handleClose = (selectedImage) => {
        console.log("Dialog selected ", selectedImage);
        setOpen(false);
        const rendition = new Rendition(null, selectedImage.renditions[0])
        instance.setValues(
            {
                contentObjectId: selectedImage.id,
                rendition
            }
        );
    };

    const makeEmpty = () => {

        const rendition = new Rendition(null, {
            base: {url: null,size: { width: null, height: null },type: "image"},
            defaultImage: {url: null,size: { width: null, height: null },type: "image"},
        });

        instance.setValues({
            contentObjectId: null,
            rendition
        });
    }

    const imageUrl = instance ?  instance.rendition.defaultImage.url: null;

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        {open ?
            <SelectFileContentDialog assetType="image" typeLabel="Image" open={open} handleCancel={handleCancel} handleClose={handleClose}></SelectFileContentDialog>
            :null}
        <Card className={classes.card}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={imageUrl}
                />
            </CardActionArea>
            <CardActions>
                <Button variant="contained" onClick={() => handleClickOpen()}>Select Image</Button>
                <Button variant="contained" onClick={() => makeEmpty()} color="primary">
                    Reset
                </Button>
            </CardActions>
        </Card>
    </FormControl>
});

export default ImageElementWidget;