import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';


import DialogTransition from 'components/dialogs/DialogTransition';

import { observer } from 'mobx-react'

import FileAssetRenderer from 'components/fileAssets/FileAssetRenderer';
import FileAssetForm from 'components/fileAssets/FileAssetForm';
import ContentObjectRolesEditor from 'components/contentRoles/ContentObjectRolesEditor';

const useStyles = makeStyles(theme => ({
  appBar: {
    //position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttons: {
    margin: theme.spacing(1),
},
}));


const EditFileAssetDialog = observer(({ store, handleClose, handleCancel }) => {

  const classes = useStyles();

  // note store is a special coupled contentObjectStore!! 
  const fileAsset = store.selectedObject;

  const save = async () => {
    const saved = await store.saveSelectedObjectAsync()
    if (saved) {
      handleClose(store.selectedObject);
    }
  }

  const canSave = fileAsset.$instanceMeta.isTouched;

  return (
    <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={DialogTransition}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Box>
          <Button variant="contained"
            className={classes.buttons}
            color="primary"
            onClick={() => save()}
            disabled={!canSave}
          >
            Save and close
          </Button>
        </Box>
          <Typography variant="h6" className={classes.title}>
            Edit FileAsset
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={8}>
        {fileAsset ?
          <Grid item container spacing={16}>
          <Grid item xs={9}>
              <FileAssetRenderer fileAsset={fileAsset} />
          </Grid>
          <Grid item xs={3}>
              <Paper className={classes.paperStyle}>
              <FileAssetForm instance={fileAsset} />
              <ContentObjectRolesEditor instance={fileAsset} />
              </Paper>
          </Grid>
      </Grid> : null}
      </Grid>
    </Dialog>
  )
});


export default EditFileAssetDialog;