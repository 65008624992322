import React from 'react'

import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import ExperienceAppBar from './components/ExperienceAppBar';
import ObjectSelector from './components/ObjectSelector'

import ExperienceDialogSelector from './components/ExperienceDialogsSelector'

//import FixedToolbar from 'components/FixedToolbar'

import ExperienceMap from 'screens/ExperienceScreen/components/ExperienceMap'
import ObjectEditPanel from './components/ObjectEditPanel'
import PanelGroup from "react-panelgroup";

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';


import AccountMenu from 'components/AccountMenu';
import { Max as MaxLayout} from 'layouts';

import { useRootStore } from 'contexts';

const experienceBarHeight = 64;

/*
The layout uses a position absolute, fixed height and a paddingTop to get a correct layout in which the objectselector has properly scrollbars.
*/

const useStyles = makeStyles(theme => ({
    top: {
        position: 'absolute',
        height: experienceBarHeight,
        width: '100%',
    },
    main: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        paddingTop: experienceBarHeight,
    },
    mapContainer: {
        flex: '1 1 auto',
        position: 'relative',
        backgroundColor: 'red'
    },
    loading: {
        height: '100%'
    },
    topHolder: {
        display: 'flex',
        width: '100vw',
        paddingRight: 24
    },
    back: {
        width: 300,
        display: 'flex',
    },
    mainTop: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1,
    }
}));


const useSidePanelStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.menu.default,
        width: '100%',
        height: '100%',
        //maxWidth: 360,
        background: 'linear-gradient(0deg,#3358f4,#1d8cf8)',// 'linear-gradient(0deg,#2200c6,#6619d7)', // 'linear-gradient(0deg,#ba54f5,#e14eca)',
        overflowY: 'auto'
    },
}));

const SidePanel = observer(({experienceEditorStore})=>{
    const classes = useSidePanelStyles();
    const experience = experienceEditorStore.experience;
   return <div className={classes.root}>
         <Box border={1} padding={1} margin={2}>{experience.name}</Box>

        <ObjectSelector experienceEditorStore={experienceEditorStore} />                    
    </div> 
});


const ExperienceScreen = observer(() => {

    const routerStore = useRouterStore();
    const rootStore = useRootStore();
    const { experienceStore, authStore } = rootStore;
    const { experienceEditorStore } = experienceStore;

    const onCloseScreen = () => {
        routerStore.goTo("experiences");
    }
  
    const classes = useStyles();
    if (experienceEditorStore && experienceEditorStore.experienceEditorState.loadedExperienceId){
        return  <MaxLayout>
            <ExperienceDialogSelector authStore={authStore} experienceEditorStore={experienceEditorStore} />
            <div className={classes.top}>
                <div className={classes.topHolder}>                
                    <div className={classes.back}>
                        <Button aria-label="close screen" onClick={onCloseScreen} >
                            <ArrowBackIcon /> Back to overview
                        </Button>
                    </div>
                    <div className={classes.mainTop}>
                        <ExperienceAppBar experienceEditorStore={experienceEditorStore} />
                        <AccountMenu  />
                    </div>
                </div>
            </div>
            <div className={classes.main}>
                <PanelGroup direction="row"
                    panelWidths={[
                        { size: 300, minSize: 200, resize: 'dynamic' },
                        { minSize: 200, resize: 'stretch' },
                        { size: 380, minSize: 240, resize: 'dynamic' }
                    ]}>
                    <SidePanel experienceEditorStore={experienceEditorStore} />
                    <div className={classes.mapContainer}>
                        <ExperienceMap experienceEditorStore={experienceEditorStore} />
                    </div>
                    <div style={{display: 'flex', flexGrow: 1, marginRight: 2, overflowY: 'auto'}}>
                        <ObjectEditPanel  experienceEditorStore={experienceEditorStore} />
                    </div>
                </PanelGroup>
            </div>
        </MaxLayout>
    }else{
        return <MaxLayout>
            <Grid className={classes.loading} container direction="row" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="button" display="block" gutterBottom>
                        Loading
                    </Typography>
                    <CircularProgress />
                </Grid>
            </Grid>
        </MaxLayout>
    }
});

export default ExperienceScreen;