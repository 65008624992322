import React from 'react';

import { observer } from "mobx-react";

import SelectionField from '../SelectionField';


const getFieldChoiceOptions = (field) => {
  const fieldDescriptor = field.fieldDescriptor;
   if (fieldDescriptor.choices){
      return fieldDescriptor.choices.map( choice => ({label: choice[1], value: choice[0]}));
   }
   throw Error(`No field or field.choices`)
}



const SelectionChoicesField = observer(({id, label, instance, store, fieldName, ...rest }) =>{


    const field = instance.$fields.get(fieldName);

    if (field){
      const selectOptions = getFieldChoiceOptions(field);

      return <SelectionField 
        id={id}
        label={label}
        instance={instance}
        fieldName={fieldName}
        selectOptions={selectOptions}
        {...rest}
      />
    }else{
      return <div>ERROR: {fieldName} not found</div>
    }
});

export default SelectionChoicesField;