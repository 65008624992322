
// import {CompoundContentObject} from 'model/contentObjects';
import AbstractBaseStore from './AbstractBaseStore';

import {CompoundContentObject} from 'model/contentObjects';
import TagsStore from './TagsStore';


export default class StoryBlocksStore extends AbstractBaseStore{


    constructor({rootStore, api}) {
        super({rootStore, api})
        this.tags = new TagsStore(null, 'content-object');
        this.areaTags = new TagsStore(null, 'content-object','areas')
    }

    afterLoadObjects = () => {
        this.tags.loadObjects();
        this.areaTags.loadObjects();
    }

    _transformJsonToObject = (objectAsJson) => {
        return new CompoundContentObject(null, objectAsJson);
    }

    copy = async(id) => {
        const objectCopy = this.api.copy(id);
        return objectCopy;
    }

}

