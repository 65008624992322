import React from 'react'

import { v4 as uuidv4 } from 'uuid';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { makeStyles } from '@material-ui/styles';


import { WALKINGTRAILTYPE, CYCLINGTRAILTYPE, BOATINGTRAILTYPE, CARTRAILTYPE, HORSETRAILTYPE} from 'model/const';

import GroupPanel from 'kiini/GroupPanel';

//import DefaultTagRoleWidget from './DefaultTagRoleWidget';
//import ObjectListWithPanel from 'kiini/ObjectListWithPanel';
import {FormWidget, FormConfigurations} from 'kiini/Forms';


import BooleanField from 'kiini/BooleanField';


import { 
    WalkingTrail,
    CyclingTrail,
    BoatingTrail,
    CarTrail,
    HorseTrail
} from 'model/contentObjects';

import { observer } from 'mobx-react'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flexStart'
    }
}));


export const wayOfMovementCreationOptions = {
    'content_objects.roles.WalkingTrail':{
        label: "Walking trail", 
        creator: () => (new WalkingTrail(null, {id: uuidv4(), dogsAllowed: true}))
    },
    'content_objects.roles.CyclingTrail': {
        label: "Cycling trail", 
        creator: () => (new CyclingTrail(null, {id: uuidv4()}))
    },
    'content_objects.roles.BoatingTrail':{
        label: "Boatin trail", 
        creator: () => (new BoatingTrail(null, {id: uuidv4()}))
    },
    'content_objects.roles.CarTrail': {
        label: "Car trail", 
        creator: () => (new CarTrail(null, {id: uuidv4()}))
    },
    'content_objects.roles.HorseTrail': {
        label: "Horse trail", 
        creator: () => (new HorseTrail(null, {id: uuidv4()}))
    },
}


const createInstance = (clazzName) => {
    const {creator} = wayOfMovementCreationOptions[clazzName];
    return creator();
}


const walkingTrailFormConfigurationData = {
    'content_objects.roles.WalkingTrail':{
        groups:  [
            {
                label: "Walking Trail",
                fields: [
                    {fieldName: "dogsAllowed", label: "Dogs allowed", widget: BooleanField},
                    {fieldName: "wheelChairAccessible", label: "WheelChair accessible", widget: BooleanField},
                ]   
            }
        ]
    }
};


const formConfigurations = new FormConfigurations(walkingTrailFormConfigurationData);


const WayOfMovementRolesWidget = observer(({ instance, store, propertyName }) => {

    const classes = useStyles();
    const value = instance[propertyName];
    const hasWayOfMovementType = (wayOfMovementType) => {
        return value ? value.findIndex(role => role.$classDescription.clazzName === wayOfMovementType) >= 0 : false;
    }

    const walkingTrailRole = value ? value.find(role => role.$classDescription.clazzName === WALKINGTRAILTYPE) : true;

    const handleChange = (checked, wayOfMovementType) => {
        if (checked) {
            const wayOfMovement = createInstance(wayOfMovementType);
            instance.addToArray(propertyName, wayOfMovement);
        } else {
            const indx = value.findIndex(role => role.$classDescription.clazzName === wayOfMovementType);
            instance.removeFromArray(propertyName, indx);
        }
    };

    return <div className={classes.root}>
        <GroupPanel label="Trail Types" />
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    onChange={(event) => handleChange(event.target.checked, WALKINGTRAILTYPE)}
                    checked={hasWayOfMovementType(WALKINGTRAILTYPE)}
                />
            }
            label="Walk"
        />
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    onChange={(event) => handleChange(event.target.checked, CYCLINGTRAILTYPE)}
                    checked={hasWayOfMovementType(CYCLINGTRAILTYPE)}
                />
            }
            label="Bike"
        />
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    onChange={(event) => handleChange(event.target.checked, BOATINGTRAILTYPE)}
                    checked={hasWayOfMovementType(BOATINGTRAILTYPE)}
                />
            }
            label="Boat"
        />
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    onChange={(event) => handleChange(event.target.checked, HORSETRAILTYPE)}
                    checked={hasWayOfMovementType(HORSETRAILTYPE)}
                />
            }
            label="Horse"
        />
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    onChange={(event) => handleChange(event.target.checked, CARTRAILTYPE)}
                    checked={hasWayOfMovementType(CARTRAILTYPE)}
                />
            }
            label="Car"
        />
        {walkingTrailRole ? <FormWidget instance={walkingTrailRole} store={store} formConfigurations={formConfigurations}/> : null}
       
    </div>
});

export  default WayOfMovementRolesWidget;




