import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
//import FileCopyIcon from '@material-ui/icons/Close';

import Typography from '@material-ui/core/Typography';

import { Box } from '@material-ui/core';

import QRCode from 'qrcode.react';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

const getUrlEncodedStr = (value) => {
  return encodeURIComponent(value.replace(/ /g, "-"));
}

const useStyles = makeStyles(theme => ({
  buttons: {
    marginRight: theme.spacing(1),
  },
}));

const saveQr = () => {
  //const img = new Image();
  const canvas = document.getElementById('qrCode');
  const saveLinkElement = document.getElementById('qrCode-save-link') ? document.getElementById('qrCode-save-link') : document.createElement('a');;
  saveLinkElement.setAttribute("id", "qrCode-save-link");

  saveLinkElement.href = canvas.toDataURL("image/png");
  saveLinkElement.download = "qr.png";
  document.body.appendChild(saveLinkElement);
  saveLinkElement.click();
}

const ExperienceQRDialog = ({ name, sharingHost, organisationId, contentObjectId, isPublished, open, handleClose }) => {
  
  const nameEncoded = getUrlEncodedStr(name);
  const publishedContentObjectId = contentObjectId.startsWith("E-") ? contentObjectId.substr(2) : name;
  const url = `${sharingHost}/storytrail/${nameEncoded}/${organisationId}/${publishedContentObjectId}`;

  const classes = useStyles();

  const copyExperienceLink = () => {
    navigator.clipboard.writeText(url);
  }

  const mailExperienceLink = () => {
    window.open(`mailto:?subject=Storytrail Link&body=${url}`);
  }


  return <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      Storytrail QR code
    </DialogTitle>
    <DialogContent dividers>
      {!isPublished ? <Typography gutterBottom>
        This storytrail isn't published yet. Note that is must be published before qr-code can be used.
      </Typography> : null}
      <div>
        <QRCode id="qrCode" value={url} size={512}  includeMargin={true} />
      </div>
      <Box margin={1}>
        <Typography onClick={() => copyExperienceLink()} >{url}</Typography>
      </Box>
      <Box margin={1}>
          <Button className={classes.buttons} variant='outlined' onClick={() => saveQr()}>Save QR</Button>  
          <Button className={classes.buttons} variant='outlined' onClick={() => copyExperienceLink()}>Copy link to clipboard</Button>
          <Button className={classes.buttons} variant='outlined' onClick={() => mailExperienceLink()}>Mail link</Button>
      </Box>
    </DialogContent>
  </Dialog>;
}

export default ExperienceQRDialog;
