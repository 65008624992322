
import BaseStore from "jimbo/store";

export default class PublishedContentObjectsStore extends BaseStore {


    constructor({rootStore, baseApi}) {
        super({rootStore, baseApi});
        this.defaultFilter = {
            alias:"jimbogo",
            site:"jimbogo"
        };
    }

    getFilter = () => {
        const organisationId = this.rootStore.authStore.selectedOrganisation ?
            this.rootStore.authStore.selectedOrganisation.id : null;

        return {...this.defaultFilter, ...this.selectObjectsState.filter,organisationId };   
    }

}