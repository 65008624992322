import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import ReadyToPublishIndicator from 'components/experiences/ReadyToPublishIndicator';
//import Grid from '@material-ui/core/Grid';

//import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PointerModusIcon from '@material-ui/icons/NearMe';
import InstructionIcon from '@material-ui/icons/LabelImportant';
import StartSpotIcon from '@material-ui/icons/GolfCourse';
import BusIcon from '@material-ui/icons/DirectionsBus';
import ParkingIcon from '@material-ui/icons/LocalParking';
import TrainStationIcon from '@material-ui/icons/Train';

import { observer } from 'mobx-react'

import objectTypeRegistry from './ExperienceObjectTypes'
import {
  OBJECT_TYPE_INSTRUCTION,
  OBJECT_TYPE_START_SPOT,
  OBJECT_TYPE_PARKING_SPOT,
  OBJECT_TYPE_BUS_STOP,
  OBJECT_TYPE_RAILWAY_STATION
} from 'model/const';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // flexGrow: 0,
    // flexShrink: 0,
    // flexBasis: 'auto',
    //position: 'absolute',
    //height: 60
    //height: 260
    //position: 'relative'
  },
  toggleContainer: {
    margin: theme.spacing(1, 1),
    display: 'inline-block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  accountMenu: {
    display: 'inline-block'
  }
}));


const ExperienceAppBar = observer(({ experienceEditorStore }) => {

  const experienceEditorState = experienceEditorStore.experienceEditorState

  const experienceObjectsTypeNameToAdd = experienceEditorState.experienceObjectsTypeNameToAdd;

  const classes = useStyles();

  const showDialogForAddingExperienceObject = (experienceObjectsTypeNameToAdd) => {
    experienceEditorStore.showDialogForAddingExperienceObject(experienceObjectsTypeNameToAdd);
  }

  const startAddingLocationObjectWithDialog = (locationObjectsTypeNameToAdd) => {
    experienceEditorStore.startAddingLocationObjectWithDialog(locationObjectsTypeNameToAdd);
  }

  const stopAddingLocationObjectWithDialog = () => {
    experienceEditorStore.stopAddingLocationObjectWithDialog();
  }

  const saveExperience = () => {
    experienceEditorStore.saveExperience()
  }

  const addExperienceObjectsTypes = objectTypeRegistry.getExperienceObjectTypes('nl');
  const addExperienceObjectsTypesToggleButtons = addExperienceObjectsTypes.map(addExperienceObjectsType => {
    const selected = experienceObjectsTypeNameToAdd === addExperienceObjectsType.typeName;
    return <Tooltip key={addExperienceObjectsType.typeName}
      title={addExperienceObjectsType.tooltipTitle}>
      <ToggleButton
        value={addExperienceObjectsType.typeName}
        key={addExperienceObjectsType.typeName}
        selected={selected}
        onClick={() => showDialogForAddingExperienceObject(addExperienceObjectsType.typeName)} >
        <addExperienceObjectsType.Icon />
      </ToggleButton>
    </Tooltip>
  });

  const addLocationObjectTypes = objectTypeRegistry.getLocationObjectTypes('nl');
  const addLocationObjectsTypesToggleButtons = addLocationObjectTypes.map(addLocationObjectsType => {
    const selected = experienceObjectsTypeNameToAdd === addLocationObjectsType.typeName;
    return <Tooltip key={addLocationObjectsType.typeName}
      title={addLocationObjectsType.tooltipTitle}>
      <ToggleButton
        value={addLocationObjectsType.typeName}
        key={addLocationObjectsType.typeName}
        selected={selected}
        onClick={() => startAddingLocationObjectWithDialog(addLocationObjectsType.typeName)} >
        <addLocationObjectsType.Icon />
      </ToggleButton>
    </Tooltip>
  });

  const handleShowLibrary = (event) => {
    const showLibaryObjectsOnmap = event.target.checked;
    experienceEditorStore.showLibraryObjectOnMap(showLibaryObjectsOnmap, {})
  };

  const handleShowMediaAssets = (event) => {
    experienceEditorStore.showMediaAssetsOnMap(event.target.checked, {})
  }

  const handleShowSpots = (event) => {
    experienceEditorStore.showSpotsOnMap(event.target.checked, {})
  }

  const isInPointerModus = experienceObjectsTypeNameToAdd === null ? false : true;

  const canSave = experienceEditorStore.experience.$instanceMeta.isTouched;
  //const canDelete = experienceEditorStore.experience.id;

  const showAddAmenities = experienceEditorStore.experienceEditorState.spotsShow;

  return (
    <div className={classes.root}>

      <div className={classes.toggleContainer}>
        <Button
          onClick={() => saveExperience()}
          variant="contained"

          disabled={!canSave}
          color="primary">
          Save
        </Button>
      </div>
      <ReadyToPublishIndicator experienceEditorStore={experienceEditorStore} />

      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          exclusive
          size="small"
          aria-label="add tracks"
        >
          {addExperienceObjectsTypesToggleButtons}
        </ToggleButtonGroup>
      </div>

      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          size="small"
          exclusive
        >
          <Tooltip title="To pointer modus">
            <ToggleButton
              value={""}
              selected={isInPointerModus}
              onClick={() => stopAddingLocationObjectWithDialog()} >
              <PointerModusIcon />
            </ToggleButton>
          </Tooltip>

        </ToggleButtonGroup>
      </div>

      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          size="small"
          exclusive
        >
          {addLocationObjectsTypesToggleButtons}
          <Tooltip title="Add an instruction">
            <ToggleButton
              value={OBJECT_TYPE_INSTRUCTION}
              selected={experienceObjectsTypeNameToAdd === OBJECT_TYPE_INSTRUCTION}
              onClick={() => experienceEditorStore.startAddingLocationObjectWithDialog(OBJECT_TYPE_INSTRUCTION)} >
              <InstructionIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Add (main/secondary)start spot">
            <ToggleButton
              value={OBJECT_TYPE_START_SPOT}
              selected={experienceObjectsTypeNameToAdd === OBJECT_TYPE_START_SPOT}
              onClick={() => experienceEditorStore.startAddingLocationObjectWithDialog(OBJECT_TYPE_START_SPOT)} >
              <StartSpotIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </div>

      <div className={classes.toggleContainer}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="showStoryblocks"
              color="primary"
              onChange={handleShowLibrary}
            />
          }
          label="Show story blocks"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="showMediaAssets"
              color="primary"
              onChange={handleShowMediaAssets}
            />
          }
          label="Show media assets"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="showSpots"
              color="primary"
              onChange={handleShowSpots}
            />
          }
          label="Show spots"
        />
      </div>
      {showAddAmenities ?
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup
            size="small"
            exclusive
          >
            <Tooltip title="Add a parkingplace">
              <ToggleButton
                value={OBJECT_TYPE_PARKING_SPOT}
                selected={experienceObjectsTypeNameToAdd === OBJECT_TYPE_PARKING_SPOT}
                onClick={() => experienceEditorStore.startAddingLocationObjectWithDialog(OBJECT_TYPE_PARKING_SPOT)} >
                <ParkingIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Add a busstop">
              <ToggleButton
                value={OBJECT_TYPE_BUS_STOP}
                selected={experienceObjectsTypeNameToAdd === OBJECT_TYPE_BUS_STOP}
                onClick={() => experienceEditorStore.startAddingLocationObjectWithDialog(OBJECT_TYPE_BUS_STOP)} >
                <BusIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Add a trainstation">
              <ToggleButton
                value={OBJECT_TYPE_RAILWAY_STATION}
                selected={experienceObjectsTypeNameToAdd === OBJECT_TYPE_RAILWAY_STATION}
                onClick={() => experienceEditorStore.startAddingLocationObjectWithDialog(OBJECT_TYPE_RAILWAY_STATION)} >
                <TrainStationIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </div> : null}


    </div>

  );
});


export default ExperienceAppBar;