
import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

// import IconButton from '@material-ui/core/IconButton';
// import Loupe from '@material-ui/icons/Loupe';

import { observer } from 'mobx-react'

import {getDefaultImage} from 'model/content-object-utils';

const useStyles = makeStyles({
    icon: {
        color: 'rgba(255, 255, 255, 0.8)',
    },
});


const FileAssetGrid = observer((props) => {

  const classes = useStyles();
  
  const fileAssets = props.fileAssets;
  const selectedFileAsset = props.selectedFileAsset;
  const items = fileAssets.objects ? fileAssets.objects.map((resultObject, i) => {
    let asset = resultObject.instance;
    const isSelected = selectedFileAsset ?  asset.id ===  selectedFileAsset.id : false;
    const name = asset.name;

    const defaultImage = getDefaultImage(asset);

    const tilestyle = isSelected ? { border: "2px solid red" } : {};
    // const iconButton = <IconButton 
    //   className={classes.icon} 
    //   onClick={() => props.setFileAssetDialogState({showDialog: true, selectedDialogAsset: asset})} >
    //   <Loupe />
    // </IconButton>;
    return(
      <GridListTile
        style={tilestyle}
        key={i}
        onClick={props.setSelectedFileAsset ? () => props.setSelectedFileAsset(asset): null}
        title={name}
      >
        <img src={defaultImage.url} alt={asset.name} />
        <GridListTileBar
          title={name}
          //actionIcon={iconButton}
        />   
      </GridListTile>
    );
  }): [];

  const cols = props.cols ?  props.cols : 3;

  return <GridList cols={cols} cellHeight={150} className={classes.gridList}>
    {items}
  </GridList>

});


export default FileAssetGrid;