import React from 'react';
import { observer } from 'mobx-react'

import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'

import {makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  }
})
);


const TagChip = ({tag, selected , onClick}) => {

  const tagName = tag.name;
  const color = selected ? "secondary": "primary";
  return <Chip key={tag.name} size="small" label={tag.label}  color={color} onClick={() => onClick(tagName, !selected)} />

}


const TagSelection = observer(({store, tagsStore, filterName})=> {
  
  const selectedTags = store.selectObjectsState.filter[filterName] ? store.selectObjectsState.filter[filterName] : [];
  const searchTags = tagsStore.values;

  const classes = useStyles();

  const onTagClick = (tagName, select) => {
    if (select){
      selectedTags.push(tagName);
    }else{
      const indexToRemove = selectedTags.indexOf(tagName);
      selectedTags.splice(indexToRemove, 1);
    }
    store.setFilterPart({ [filterName]: selectedTags});
  }

  const isSelectedTag = (tag) => {
      return selectedTags ? selectedTags.indexOf(tag.name) >= 0 : true;
  }

  const tagChips = searchTags ? searchTags.map( tag => ( <TagChip selected={isSelectedTag(tag)} tag={tag} onClick={onTagClick}/>)): null;


  return <Box className={classes.root}>
      {tagChips}
    </Box>
});

export default TagSelection;