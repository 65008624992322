import * as React from 'react';

import { FormWidget, FormConfigurations } from 'kiini/Forms';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import SelectionChoicesField from 'kiini/SelectionChoicesField';
import MultipleChoicesField from 'kiini/MultipleChoicesField';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';





import {inputLabelStyle, formControlStyle } from 'kiini/Style'

const ShowPackageWidget = ({id, label, instance, fieldName, createItem, creationOptions, path, formConfigurations, ...rest }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const value = instance[fieldName]



    return <FormControl error={false} fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        <div>{value}</div>
    </FormControl>
}


const commonRunnableObjectBlockConfiguration = {
    groups: [
        {
            label: "Packages",
            fields: [
                { fieldName: "packages", label: "Packages", widget: ObjectListWithPanel },
            ]
        },
        
    ]
}

const runnablePackageFileConfiguration = {
    groups: [
        {
            label: "Package",
            fields: [
                { fieldName: "name", label: "Name", widget: ShowPackageWidget},
                { fieldName: "action", label: "Action", widget: SelectionChoicesField },
                { fieldName: "platforms", label: "Platforms", widget: MultipleChoicesField },
            ]
        },
        
    ]
}

//{ fieldName: "action", label: "Action", widget: SelectionChoicesField },


const runnableObjectFormConfigurationsData = {
    "content_objects.model.RunnableObject": commonRunnableObjectBlockConfiguration,
    "content_objects.model.RunnablePackageFile": runnablePackageFileConfiguration,
}


const runnableObjectFormConfigurations = new FormConfigurations(runnableObjectFormConfigurationsData);


const RunnableObjectPackagesForm = ({ instance, store }) => {
    return <React.Fragment>
        <FormWidget instance={instance} store={store} formConfigurations={runnableObjectFormConfigurations} />
      
    </React.Fragment>
}


export default RunnableObjectPackagesForm;