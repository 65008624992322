import React from 'react';

import { observer } from "mobx-react";
import BaseInput from '../BaseInput';


const FieldInput = ({id, field, createItem, creationOptions, path, formConfigurations, ...rest }) => {

    
    const onChange = (evt) => {
        field.setValue(evt.target.value);
    } 

    const onBlur = (evt) => {
        console.log("blurred ", field.name )
    }

    const touched = field.touched;
    const hasErrors = field.errors;
    const value = field.value ? field.value: "";

    const error = touched && hasErrors;

    return <BaseInput id={id} error={error} value={value} onChange={(evt) => onChange(evt)} onBlur={(evt) => onBlur(evt)} {...rest}/>
}

export default observer(FieldInput);