
import { tagsApi } from 'Api';
//import { debug } from 'debug';
import { makeObservable, observable, action } from "mobx";




export default class TagsStore {

    values = null;
    baseType = null;
    setName = null;

    constructor(rootStore, baseType, tagSet) {
        //this.experience = experience;
        this.rootStore = rootStore;
        this.baseType = baseType;
        makeObservable(this, {
            values: observable,
        });      
        this.tagSet = tagSet;
    }

    loadObjects = () => {
        if(!this.values){
            this.loadTags();
        }
    }

    loadTags = () => {
        tagsApi.getObjects({baseType: this.baseType, tagSet: this.tagSet}).then(
            action("loadTags", results => {
                console.log("retrieved tags ", results);
                this.values = results;
            })
        );
    }

}

