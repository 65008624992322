import {roleToMainCategoryId} from 'data/categoriesUtils';

import spotIconUrl from 'icons/spot.png'
import selectedSpotIconUrl from 'icons/selected-spot.png'

import sleepIconUrl from 'icons/sleep.png'
import sleepsSelectedIconUrl from 'icons/sleep-selected.png'
import foodDrinkIconUrl from 'icons/food_drink.png'
import foodDrinkSelectedIconUrl from 'icons/food_drink-selected.png'

import dayOutIconUrl from 'icons/day_out.png'
import dayOutSelectedIconUrl from 'icons/day_out-selected.png'

import cultureIconUrl from 'icons/culture.png'
import cultureSelectedIconUrl from 'icons/culture-selected.png'

import activeIconUrl from 'icons/active.png'
import activeSelectedIconUrl from 'icons/active-selected.png'

import touristInformationIconUrl from 'icons/touristinformation.png'
import touristInformationSelectedIconUrl from 'icons/touristinformation-selected.png'

import shopsIconUrl from 'icons/shops.png'
import shopsSelectedIconUrl from 'icons/shops-selected.png'

import natureIconUrl from 'icons/nature.png'
import natureSelectedIconUrl from 'icons/nature-selected.png'

import nauticalIconUrl from 'icons/nautical.png'
import nauticalSelectedIconUrl from 'icons/nautical-selected.png'

import practicalIconUrl from 'icons/practical.png'
import practicalSelectedIconUrl from 'icons/practical-selected.png'

import audioIconUrl from 'icons/audio.png'
import audioSelectedIconUrl from 'icons/audio-selected.png'

import videoIconUrl from 'icons/video.png'
import videoSelectedIconUrl from 'icons/video-selected.png'

import imageIconUrl from 'icons/image.png'
import imageSelectedIconUrl from 'icons/image-selected.png'

import storyblockIconUrl from 'icons/storyblock.png'
import storyblockSelectedIconUrl from 'icons/storyblock-selected.png'

import busStopIconurl from 'icons/BusStop.png'
import busStopSelectedIconurl from 'icons/BusStop-selected.png'

import parkingPlaceIconUrl from 'icons/ParkingPlace.png'
import parkingPlaceSelectedIconUrl from 'icons/ParkingPlace-selected.png'

import railwayStationIconUrl from 'icons/RailwayStation.png'
import railwayStationSelectedIconUrl from 'icons/RailwayStation-selected.png'

import runnableObjectSelectedIconUrl from 'icons/RunnableObject-selected.png'
import runnableObjectIconUrl from 'icons/RunnableObject.png'


const ROLES_TO_IGNORE = [
  "uso.db.roles.LocationAccomodationFacilities",
  "uso.db.roles.LocationAccomodationFacilities2"];
  

const filterRoles = (roles) =>  roles.filter( role => ROLES_TO_IGNORE.indexOf( role._clazz) < 0  );


const CATEGORY_ICON_MAPPING =  {
  'sleep': sleepIconUrl,
  'food_drink': foodDrinkIconUrl,
  'dagje_uit': dayOutIconUrl,
  'cultuur': cultureIconUrl,
  'active': activeIconUrl,
  'touristinformation': touristInformationIconUrl,
  'shops': shopsIconUrl,
  'nature': natureIconUrl,
  'nautical': nauticalIconUrl,
  'practical': practicalIconUrl,
}

const CATEGORY_ICON_MAPPING_SELECTED =  {
  'sleep': sleepsSelectedIconUrl,
  'food_drink': foodDrinkSelectedIconUrl,
  'dagje_uit': dayOutSelectedIconUrl,
  'cultuur': cultureSelectedIconUrl,
  'active': activeSelectedIconUrl,
  'touristinformation': touristInformationSelectedIconUrl,
  'shops': shopsSelectedIconUrl,
  'nature': natureSelectedIconUrl,
  'nautical': nauticalSelectedIconUrl,
  'practical': practicalSelectedIconUrl,
}


const CONTENTOBJECT_ICON_MAPPING =  {
  'content_objects.model.RunnableObject': runnableObjectIconUrl,
  'content_objects.model.CompoundContentObject': storyblockIconUrl,
  'content_objects.model.Audio': audioIconUrl,
  'content_objects.model.Video': videoIconUrl,
  'content_objects.model.Image': imageIconUrl,
}

const CONTENTOBJECT_ICON_MAPPING_SELECTED =  {
  'content_objects.model.RunnableObject': runnableObjectSelectedIconUrl,
  'content_objects.model.CompoundContentObject': storyblockSelectedIconUrl,
  'content_objects.model.Audio': audioSelectedIconUrl,
  'content_objects.model.Video': videoSelectedIconUrl,
  'content_objects.model.Image': imageSelectedIconUrl,
}

const NOT_ON_CATEGORY_MAPPING = {
  'uso.db.roles.ParkingPlace': {default: parkingPlaceIconUrl, selected: parkingPlaceSelectedIconUrl},
  'uso.db.roles.BusStop': {default:  busStopIconurl,selected: busStopSelectedIconurl},
  'uso.db.roles.RailwayStation': {default:  railwayStationIconUrl, selected: railwayStationSelectedIconUrl},
}



export function getMapIconImageForSpotObject({spot, selected}){

  const filtedRoles = filterRoles(spot.roles);

  if (filtedRoles.length > 0){
    const role = filtedRoles[0]._clazz    

    const notOnCategoryMapping = NOT_ON_CATEGORY_MAPPING[role];
    if (notOnCategoryMapping){
      const roleIconUrl = selected ? notOnCategoryMapping.selected : notOnCategoryMapping.default;
      return roleIconUrl;
    }

    const categoryId = roleToMainCategoryId(role);
//    console.log("categoryId ", categoryId, "selected", selected);
    const iconUrl = selected ? CATEGORY_ICON_MAPPING_SELECTED[categoryId] :CATEGORY_ICON_MAPPING[categoryId];
    if (iconUrl){

      return iconUrl;
    }
  }

  return selected ? selectedSpotIconUrl : spotIconUrl;
}


export function getMapIconImageForContentObject({contentObject, selected}){

  const clazzName = contentObject.$classDescription.clazzName;
  console.log("Getting icon for ", clazzName);
  const iconUrl = selected ? CONTENTOBJECT_ICON_MAPPING_SELECTED[clazzName] :CONTENTOBJECT_ICON_MAPPING[clazzName];
  if (iconUrl){
    return iconUrl;
  }
  return selected ? selectedSpotIconUrl :  spotIconUrl;

}

export function getMapIconImageForContentObjectOnClazzName(clazzName){
  const iconUrl = CONTENTOBJECT_ICON_MAPPING[clazzName];
  if (iconUrl){
    return iconUrl;
  }
  return spotIconUrl;
}