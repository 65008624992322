import { createRouterState, RouterStore } from 'mobx-state-router';
import { routes } from './routes';

import { AuthStore } from './AuthStore';
import { PrefStore } from './PrefStore';
import ExperiencesStore from './ExperiencesStore';
import ExperienceStore from './ExperienceStore';
import StoryBlocksStore from './StoryBlocksStore';
import StoryBlockStore from './ContentObjectStore';
import FileAssetsStore from './FileAssetsStore';
import HomeStore from './HomeStore';
import AnalyticsStore from './AnalyticsStore';
import NotificationsStore from './NotificationsStore';
import RatingsStore from './RatingsStore';
import PublishedContentObjectsStore from './PublishedContentObjectsStore';
import ForgotPasswordStore from './ForgotPasswordStore';
import MapConfigObjectsStore from './MapConfigObjectsStore';

import { 
    experiencesApi, 
    contentObjectsApi,
    notificationsApi, 
    forgotPasswordApi, 
    resetPasswordApi, 
    authenticationApi, 
    mapConfigObjectsApi, 
    runnableObjectsApi,
    publishedContentObjectsApi,
    fileAssetsApi,
    ratingsApi
} from 'Api';

import ResetPasswordStore from './ResetPasswordStore';
import SignInStore from './SignInStore';
import RunnableObjectsStore from './RunnableObjectsStore';
import ContentObjectStore from './ContentObjectStore';




const notFound = createRouterState('notFound');

// {message, severity}

export class RootStore {
    
    authStore = new AuthStore(this);
    prefStore = new PrefStore(this);
    experiencesStore = new ExperiencesStore({rootStore: this, api: experiencesApi});
    homeStore = new HomeStore(this);
    experienceStore = new ExperienceStore({rootStore: this});
    storyBlocksStore = new StoryBlocksStore({rootStore: this, api: contentObjectsApi});
    storyBlockStore = new StoryBlockStore({rootStore: this, api:contentObjectsApi});
    fileAssetsStore = new FileAssetsStore({rootStore:this, api: fileAssetsApi});
    dialogSelectionFileAssetsStore = new FileAssetsStore({rootStore:this, api: fileAssetsApi});
    runnableObjectsStore = new RunnableObjectsStore({rootStore: this, api: runnableObjectsApi});
    //runnableObjectsDialogStore = new RunnableObjectsStore({rootStore: this, api: runnableObjectsApi});
    analyticsStore = new AnalyticsStore(this);
    notificationsStore = new NotificationsStore({rootStore:this, baseApi:notificationsApi });
    ratingsStore = new RatingsStore({rootStore:this, baseApi:ratingsApi });
    forgotPasswordStore = new ForgotPasswordStore({rootStore:this, baseApi:forgotPasswordApi });
    resetPasswordStore = new ResetPasswordStore({rootStore:this, baseApi:resetPasswordApi, authenticationApi });
    signInStore = new SignInStore({rootStore:this, authenticationApi });
    publishedContentObjectsStore = new PublishedContentObjectsStore({rootStore: this, baseApi: publishedContentObjectsApi});
    mapConfigObjectsStore = new MapConfigObjectsStore({rootStore: this, baseApi: mapConfigObjectsApi});
    // Pass rootStore as an option to RouterStore
    routerStore = new RouterStore(routes, notFound, {
        rootStore: this,
    });


    createStoryBlocksStore = () => {
        return new StoryBlocksStore({rootStore: this, api: contentObjectsApi});
    }

    
    createContentObjectStore = () => {
        return new ContentObjectStore({rootStore: this, api: contentObjectsApi});
    }

}
