
import { makeObservable, observable, action, runInAction, computed } from "mobx";

import { RunnableObject } from 'model/contentObjects';
import AbstractBaseStore from "./AbstractBaseStore";

import TagsStore from './TagsStore';


export default class RunnableObjectsStore extends AbstractBaseStore{

    files = [];
 
    constructor({rootStore, api}) {
        super({rootStore,api});
        //this.experience = experience;
        this.rootStore = rootStore;
        this.api = api;
        makeObservable(this, {
            files: observable,
            addFile: action,
            removeFile: action,
            saveWithUploadFiles: action,
            enableSaveSelectedObject: computed,
            //addUploadedFileAssets: action,
            //resetUploaded: action,
        });      
        this.tags = new TagsStore(this.rootStore, 'ar-object' );
        this.areaTags = new TagsStore(this.rootStore, 'ar-object', 'areas');
    }

    get enableSaveSelectedObject() {
        const filesAdded = this.files.length > 0;
        const isSelectedObjectChanged = this.selectedObject ? this.selectedObject.$instanceMeta.isTouched : false;
        return filesAdded || isSelectedObjectChanged;
    }

    _resetFiles = () => {
        this.files = []
    }

    _afterSelectObject = () => {
        this._resetFiles();
    }

    _afterUnselectObject = () => {
        this._resetFiles();
    }

    _processSavedAsyncObject = (savedObject) => {
        this._refreshFromIndex(savedObject.id).then(
            action("merge change in set", tomerge => {
                    this._mergeChangedObjects(tomerge);
                    this.tags.loadTags();
                    this.areaTags.loadTags();
            })
        )
    }

    _processSavedObjectJson = (savedSelecteObjectJson) => {
        this._refreshFromIndex(savedSelecteObjectJson.id).then(
            action("merge change in set", tomerge => {
                
                this.selectedObject = this._transformJsonToObject(savedSelecteObjectJson);
                this._mergeChangedObjects(tomerge);
                this.tags.loadTags();
                this.areaTags.loadTags();
            })
        ) 
    }

    _transformJsonToObject = (objectAsJson) => {
        return  objectAsJson ? new RunnableObject(null, objectAsJson): null; 
    }

    _createNewObject = (initValues={}) => {
        this._resetFiles();
        return new RunnableObject(
            null,
            {
                "id": null,
                "name": "",
                "locale": "nl",
                "tags": [],
                "packages": [],
                ...initValues
            }
        ); 
        
    }

    _afterDelete = (id) => {
        this.tags.loadTags();
        this.areaTags.loadTags();
    }


    addFile = (file) => {
        console.log("pushed file ", file);
        this.files.push(file);
    }

    removeFile = (file) => {
        const indxToRemove = this.files.indexOf(file);
        this.files.splice(indxToRemove, 1);
    }

    saveWithUploadFiles = () => {
        
        const isValid = this.selectedObject.validate({touched:true});
        console.log("isValid ", isValid);        
        if(isValid){
            const instance = this.selectedObject;
            
            const onUploadProgress = (event) => {
                const percentage = Math.round((100 * event.loaded) / event.total);
                console.log("upload progress ", percentage);
            };
            
            const files = this.files.slice()
                
            this.api.uploadFiles({files, instance, onUploadProgress}).then(
                action("selected Object", returnedObject => {
                    this._resetFiles();
                    this.selectedObject = this._transformJsonToObject(returnedObject);
                })
            )
        }
    }

}

