
import React from 'react';
import { Grid, AutoSizer } from 'react-virtualized';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { observer } from 'mobx-react'
import { getDefaultImageAttributes} from 'model/content-object-utils';


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 160,
  },
});

const ImageCell = observer(({instance, onSelect})=>{

  const classes = useStyles();
  const name = instance.name;

  const { id, url } = getDefaultImageAttributes(instance, 'image.w500');
  // alt,width, height,  defaultImage
  return <Card className={classes.root} onClick={()=>onSelect(id)}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={url}
          title={name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
})


// NOTE: Component is intentionally used instead of PureComponent,
// as renderItem's internals may adjust independent of props provided to
// this component
const VirtualizedFileAssetGrid = observer(({ className, items, onSelect, overscanRowCount}) => {
  

  const _cellRenderer = ({columnIndex, key, rowIndex, style}) => {


    const normalizedRowIndex = rowIndex;
    const index = (normalizedRowIndex * 4) + columnIndex;
    if (index >= items.length) {
      return null;
    }

    const objectResult = items[index]; 
    const fileAsset = objectResult.instance;
    
    return (
      <div key={key} style={style}>
         <ImageCell instance={fileAsset} onSelect={onSelect}/> 
      </div>
    );
  }

  
  const renderWithKnownSize = (containerWidth, containerHeight) => {

    const itemCount = items.length;

    const columnCount = 4;
    const columnWidth = (containerWidth / columnCount) - 8;  // -8 to prevent scrollbar
    
    //const columnCountEstimate = Math.max(1, Math.floor(containerWidth / idealItemWidth));
    //const rowCount = Math.ceil(itemCount / columnCountEstimate);
    const rowCount = Math.ceil(itemCount / 4);
    const firstItemId = items && items.length > 0 ? items[0].instance.id : "_"; // hack to force rerender after list is changed 

    //console.log("first ", firstItemId);

    return <Grid  
      aria-label={firstItemId}
      cellRenderer={_cellRenderer}
      columnWidth={columnWidth}
      columnCount={columnCount}
      height={containerHeight}
      overscanRowCount={4}
      rowHeight={300}
      rowCount={rowCount}
      width={containerWidth}
      //noContentRenderer={this._noContentRenderer}
      //overscanColumnCount={overscanColumnCount}
      //scrollToColumn={scrollToColumn}
      scrollToRow={0}
      />
  }

  return (
      <AutoSizer>
        {({ width, height }) => renderWithKnownSize(width, height)}
      </AutoSizer>
  );
  
});

export default VirtualizedFileAssetGrid;