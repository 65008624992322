import * as Yup from 'yup';

import {isNotUndefinedAndNotNull} from '../utils'


const addNeededNullRequired = (fieldDescriptor, schemaIn) => {
    let schema = schemaIn;
    if (fieldDescriptor.isNull) {
        schema = schema.nullable()
    }else{
        if (fieldDescriptor.autoCreated){
            schema = schema.nullable()
        }else{
            schema = schema.required()
        }
    }
    return schema;
}


export const createCharFieldSchema = (fieldDescriptor) => {
    let charFieldValidator = Yup.string();
    charFieldValidator = addNeededNullRequired(fieldDescriptor, charFieldValidator);
    
    if (isNotUndefinedAndNotNull(fieldDescriptor.minLength) ) {
        charFieldValidator = charFieldValidator.min(fieldDescriptor.minLength)
    }
    if (isNotUndefinedAndNotNull(fieldDescriptor.maxLength)) {
        charFieldValidator = charFieldValidator.max(fieldDescriptor.maxLength)
    }
    if (fieldDescriptor.choices && fieldDescriptor.choices.length > 0 ) {
//        console.log("choices ", fieldDescriptor.choices);
        const choiceValues = fieldDescriptor.choices.map(choiceDesc => choiceDesc[0]);

        const choicesStr = "(" + choiceValues.join('|') + ")";
//        console.log("choices regex", choicesStr);
        
        const choicesRegex = new RegExp(choicesStr)
        charFieldValidator = charFieldValidator.matches(choicesRegex)
    }
    if (fieldDescriptor.jsValidateRegex){
        const choicesRegex = new RegExp(fieldDescriptor.jsValidateRegex)
                charFieldValidator = charFieldValidator.matches(choicesRegex)
    }
    return charFieldValidator
}

export const createDateTimeFieldSchema = (fieldDescriptor) => {
    let validator = Yup.date();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator;
}

export const createDateFieldSchema = (fieldDescriptor) => {
    let validator = Yup.date();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator;
}

export const createIntegerFieldSchema = (fieldDescriptor) => {
    let validator = Yup.number().integer();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator
}

export const createDecimalFieldSchema = (fieldDescriptor) => {
    let validator = Yup.number();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator
}

export const createBooleanFieldSchema = (fieldDescriptor) => {
    let validator = Yup.boolean();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator
}



export const createOneOfFieldSchema = (fieldDescriptor) => {
    let validator = Yup.mixed();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator
}

export const createListOfFieldSchema = (fieldDescriptor) => {
    let validator = Yup.mixed();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator
}

export const createMapOfFieldSchema = (fieldDescriptor) => {
    let validator = Yup.mixed();
    validator = addNeededNullRequired(fieldDescriptor, validator);    
    return validator
}


const SCHEMA_CREATORS = {
    'CharField': createCharFieldSchema,
    'TextField': createCharFieldSchema,
    'DateTimeField': createDateTimeFieldSchema,
    'DateField': createDateFieldSchema,    
    'IntegerField': createIntegerFieldSchema,
    'BooleanField': createBooleanFieldSchema,
    'DecimalField': createDecimalFieldSchema,
    'FloatField': createDecimalFieldSchema,
}


const RELATED_SCHEMA_CREATORS = {
    'OneOf': createOneOfFieldSchema,
    'ListOf': createListOfFieldSchema,
    'MapOf': createMapOfFieldSchema,
    
}

export const createPrimitiveFieldValidation = (fieldDescriptor)=>{
    const schemaCreator = SCHEMA_CREATORS[fieldDescriptor.type];
    if (!schemaCreator){
        throw new Error("no schema creator found for type " + fieldDescriptor.type);
    }
    return schemaCreator(fieldDescriptor)
}

export const createRelatedFieldValidation = (fieldDescriptor)=>{
    const schemaCreator = RELATED_SCHEMA_CREATORS[fieldDescriptor.type];
    if (!schemaCreator){
        throw new Error("no schema creator found for type " + fieldDescriptor.type);
    }
    return schemaCreator(fieldDescriptor)
}



export const createStringSchema = (fieldDescriptor) => {
    return Yup.string()
}

export const createFloatSchema = (fieldDescriptor) => {
    return Yup.number();
}

export const createIntSchema = (fieldDescriptor) => {
    return Yup.number().integer();
}

export const createDictSchema = (fieldDescriptor) => {
    return Yup.mixed();
}

const PRIMITIVE_RELATION_CLAZZ_CREATORS = {
    'string': createStringSchema,
    'float': createFloatSchema,
    'int': createIntSchema,
    '{}': createDictSchema,
}



export const createPrimitiveItemValidation = (fieldDescriptor)=>{
    const schemaCreator = PRIMITIVE_RELATION_CLAZZ_CREATORS[fieldDescriptor.type];
    if (!schemaCreator){
        throw new Error("no schema creator found for type " + fieldDescriptor.type);
    }
    return schemaCreator(fieldDescriptor)
}


// const PRIMITIVE_VALIDATION_TYPE_MAPPING = {
//     'CharField': Yup.string,
//     'TextField': Yup.string,
//     'DateTimeField': Yup.date,
//     'IntegerField': Yup.number().integer,
//     'BooleanField':  Yup.boolean,
//     'DecimalField':  Yup.number,
//     'FloatField': Yup.number, 
//     'DateField': Yup.date,
// }

// const typeToValidation = (type) => {
//     const typeMapping = PRIMITIVE_VALIDATION_TYPE_MAPPING[type]
//     return typeMapping();
// }