import CATEGORIES from 'data/categories';

let categorieIdRoles = null;
let roleToCategoryId = null;



function createMapping(){
    
    categorieIdRoles = {}; 
    roleToCategoryId = {};

    const rootCategories = CATEGORIES['rootCategories'];
    
    rootCategories.forEach( rootCategory => {
        const categoryId = rootCategory.id;
        categorieIdRoles[categoryId] = [];
        const categories = rootCategory.categories;
        categories.forEach(category => {
            const filters = category.filters
            filters.forEach( filter => {
                if (filter._clazz === "uso.db.categorization.RoleFilter"){
                    const roleClazz = filter.value;
                    categorieIdRoles[categoryId].push(roleClazz);
                    roleToCategoryId[roleClazz] = categoryId;
                }
            })
        })
    } )

    console.log("categorieIdRoles", categorieIdRoles);
}




export function roleToMainCategoryId( roleName){
    if (categorieIdRoles === null){
        createMapping();
    }
    const categoryId = roleToCategoryId[roleName];
    if(!categoryId){
        console.log("no category id found for ", roleName);
    }
    return categoryId;
} 