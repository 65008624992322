import React from 'react';
//import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItem from '@material-ui/core/ListItem';
// import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

//import Slide from '@material-ui/core/Slide';

import { observer } from 'mobx-react'

import Preview from 'components/storyblocks/Preview';
import StoryBlockCommonForm from 'components/storyblocks/StoryBlockCommonForm';
import StoryBlockContentForm from 'components/storyblocks/StoryBlockContentForm';
import TabPanel from 'components/TabPanel';

import Transition from './DialogTransition';
import StoryBlocksTableVirtualized from 'components/storyblocks/StoryBlocksTableVirtualized';
import TagsFilter from 'components/search/TagsFilter';

import { useRootStore } from 'contexts';

const useStyles = makeStyles(theme => ({
  appBar: {
    //position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));


// function a11yProps(index) {
//   return {
//     id: `wrapped-tab-${index}`,
//     'aria-controls': `wrapped-tabpanel-${index}`,
//   };
// }




const  SelectStoryBlockDialog = observer(({storyBlocksStore, contentObjectStore, open, providedLocation, handleClose, handleCancel}) => {

  const classes = useStyles();

  const onSearchInputChange = (event) => {
    const value = event.target.value;
    if (value === ""){
      storyBlocksStore.setFilterPart({ "search.all": null });
    }else{
      storyBlocksStore.setFilterPart({ "search.all": value });
    }
  }

  const [selectedTab, setSelectedTab] = React.useState('searchStoryblock');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }

  const selectedStoryBlock = storyBlocksStore.selectedObject;
  console.log("selected storyblock ", selectedStoryBlock)

  const handleSelect = () => {
    handleClose(selectedStoryBlock);
  }

  const onSelectObject = (id) => {
    storyBlocksStore.selectObject(id);
    console.log("selected ", id)
  }

  const newStoryBlock = () => {
    const initValues = providedLocation ? {location: providedLocation} : {};
    contentObjectStore.newObject(initValues);
  }

  const storyblock = contentObjectStore.selectedObject;

  const saveStoryBlock = async() => {
     const saved = await contentObjectStore.saveAsync()
     if (saved){
        console.log("saved and selected id ", contentObjectStore.selectedObject.id);
        handleClose(contentObjectStore.selectedObject);
     }
  }

  const searchTerm = storyBlocksStore.selectObjectsState.filter["search.all"] ?  storyBlocksStore.selectObjectsState.filter["search.all"]: "";
  
  

  //const createdContentObject = storyblock;

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar  position='sticky'  className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
            <CloseIcon />
          </IconButton>
            {selectedStoryBlock && !contentObjectStore.selectedObject ? 
              <Button variant="contained" color="primary" onClick={handleSelect}>Selecteer deze</Button>:
               null 
            } 
            {contentObjectStore.selectedObject ?
            <Button variant="contained" style={{marginLeft: 8}}
                    color="primary"
                    onClick={() => saveStoryBlock()}
                  >
                   Save and select
                  </Button>: null
            }
          <Typography variant="h6" className={classes.title}>
            Select a Storyblock
          </Typography>
        </Toolbar>
      </AppBar>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="select search or upload">
          <Tab value="searchStoryblock" label="Search Storyblock" />
          <Tab value="createStoryBlock" label="Create Storyblock" />
      </Tabs>
      <TabPanel style={{height: 'calc(100% - 128px)'}} value={selectedTab} index="searchStoryblock">
          <div style={{
                  width: 'calc(100% + 64px)',
                  margin: -32,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row'
            }}>
                <div style={{
                  padding: 32,
                  flexGrow: 1,
                  maxWidth: '66.666667%',
                  flexBasis: '66.666667%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}>

                  <TextField label="Search" variant="outlined" value={searchTerm} onChange={(value => onSearchInputChange(value))} />
                  <TagsFilter 
                    store={storyBlocksStore} 
                    tagsStore={storyBlocksStore.tags} 
                    filterName="tag" />
        
                  <div style={{height: '100%',"width": '100%'}}>
                    <StoryBlocksTableVirtualized  storyBlocksStore={storyBlocksStore} onSelectObject={onSelectObject} />
                  </div>
                </div>

                <div style={{
                  padding: 32,
                  flexGrow: 1,
                  maxWidth: '33.333333%',
                  flexBasis: '33.333333%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}>

                 {selectedStoryBlock ? 
                    <div style={{overflowY: 'auto'}}>
                    <h1>{selectedStoryBlock.name}</h1>
                    
                    <Preview storyblock={selectedStoryBlock} />
                      
                    </div>
                    : null}
                </div>
            </div>

              
      </TabPanel>


      <TabPanel value={selectedTab} index="createStoryBlock">
          <Grid container spacing={8}>
                <Grid item xs={12}>
                  {contentObjectStore.selectedObject ? 
                    null:
                  <Button variant="contained"
                    color="primary"
                    onClick={() => newStoryBlock()}
                  >
                  New
                  </Button>
                }
                  
                </Grid>
                {storyblock ?
                    <Grid container>
                      <Grid item xs={3}>
                        <Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
                          <StoryBlockCommonForm instance={storyblock} store={contentObjectStore} />
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
                          <StoryBlockContentForm instance={storyblock} store={contentObjectStore} />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box bgcolor="background.paper" margin={1} padding={1}>
                          <Preview storyblock={storyblock} />
                        </Box>
                      </Grid>
                    </Grid>: null}
            </Grid>
      </TabPanel>
    </Dialog>
 )
});


const ProvidedSelectStoryBlockDialog = (props) => {

  const rootStore = useRootStore();

  const storyBlocksStore = rootStore.createStoryBlocksStore();
  storyBlocksStore.loadObjects();

  const contentObjectStore = rootStore.createContentObjectStore();
  
  return <SelectStoryBlockDialog storyBlocksStore={storyBlocksStore} contentObjectStore={contentObjectStore} {...props} />
}

export default ProvidedSelectStoryBlockDialog;