import { observable, makeObservable, action } from 'mobx';


import { moveItem } from '../utils';

import { isNotUndefined } from '../utils';


const getTouchedAndValidate = (options) => {
    const doSetTouched = options && isNotUndefined(options.touched) ? options.touched : true;
    const doValidate = options && isNotUndefined(options.validate)  ? options.validate : true;
    return { doSetTouched, doValidate };
}

export class Base {

    changed = false;
    touched = false;
    valid = true;
    errors = null;
    owner = null;  // owner is a field 
    internalId = null;     
    invalidChilds = [];

    constructor({ owner, internalId }) {
        this.owner = owner;
        this.internalId = internalId;

        makeObservable(this, {
            changed: observable, // self (including tree)
            touched: observable,
            valid: observable,
            errors: observable,
            setErrors: action,
            setTouched: action,
            setValid: action,  // self (including tree)
            setInternalState: action,
        });
    }

    setErrors = (errors) => {
        this.errors = errors;
    }

    setTouched = (touched) => {
        this.touched = touched;
    }

    setOwner = (owner) => {
        this.owner = owner;
    }

    setValid = (valid) => {
        this.valid = valid;
    }

    setChanged = (changed) => {
        this.changed = changed;
    }

    setInternalState({errors, valid, changed, touched}){
        this.changed = isNotUndefined(changed) ? changed : this.changed;
        this.touched =  isNotUndefined(touched) ? touched : this.touched;
        this.valid = isNotUndefined(valid) ? valid : this.valid;
        this.errors = isNotUndefined(errors) ? errors : this.errors;
        
    }


    _updateInvalidChildsState = (childInternalId, valid) => {
        const invalidChildIndex = this.invalidChilds.indexOf(childInternalId)
        
        if(valid && (invalidChildIndex >= 0)){
            this.invalidChilds.splice(invalidChildIndex, 1);
        }else if (!valid && invalidChildIndex < 0){
            this.invalidChilds.push(childInternalId);
        }
        const validChilds = this.invalidChilds.length <= 0;
        const selfValid = this.errors ? this.errors.length <= 0 : true;

        const completeValid = validChilds && selfValid;
        this.setValid(completeValid)
    }
}

export class BaseValue extends Base {


    initialValue = null;
    value = null;
    fieldDescriptor = null;


    constructor({ internalId, owner, initialData, fieldDescriptor, options }) {
        super({ internalId, owner })
        this.initialValue = initialData;
        this.fieldDescriptor = fieldDescriptor;

        makeObservable(this, {
            value: observable,
        });
    }


    notifyOwner = (stateChange) => {
        if (this.owner) {
            stateChange.internalId = this.internalId
            this.owner.notify(stateChange);
        }
    }

    notify = (message) => {
        throw new Error("not expected to receive notifications");
    }

    _validateValue = (value) => {
        throw new Error("Must be implemented by subclasses");
    }
    

    mergeErrors = (errors) => {
        this.setErrors(errors);
        // @todo how merge errors for
    }

    toJSON = () => {
        throw new Error("Must be implemented by subclasses");
    }
}


/*
  Value is used a a node in a ListOf or MapOf with primitive values
  
  For validation of a value the validateItem is called.
  Owner is a ListOf, MapOf of OneOf field

*/
export class Value extends BaseValue {

    label = "";
    required = null;

    constructor({internalId, owner, initialData, fieldDescriptor, options, field }) {
        super({ internalId, initialData, owner, fieldDescriptor, options });

        makeObservable(this, {
            setValue: action,
        });

        const value = this.convertFromData(initialData);
        const valueSetOptions = options ? options : { touched: false, dirty: true, validate: true, inConstructor:true };
        this.setValue(value, valueSetOptions);
        this.label = fieldDescriptor.label ? fieldDescriptor.label: "";
        this.required = fieldDescriptor.required;
    }

    _validateValue = (value) => {
        const errors = this.fieldDescriptor.validate(value);
        return errors;
    }

    convertFromData = (initialData) => {
        return initialData;
    }

    setValue = (value, options) => {
        this.value = value;

        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        let stateChange = {internalId: this.internalId, inConstructor: options ? options.inConstructor: undefined};

        if (doSetTouched) {
            this.setTouched(true);
            stateChange.touched = true;
        }
        const doCheckDirty = options ? options.dirty : true;
        if (doCheckDirty) {
            stateChange.changed =  this.initialValue !== value;
        }

        if (doValidate) {
            const valid = this.validate();  // validates 
            stateChange.valid = valid;
        }
        this.setInternalState(stateChange);
        this.notifyOwner(stateChange);
    }

    validate = () => {
        if (!this.fieldDescriptor.skipClientValidation) {
            const errors = this._validateValue(this.value);
            this.setErrors(errors);
            const valid =  errors ? false : true;
            this.setValid(valid);
            return valid;
        }
        return true;
    }

    toJSON = () => {
        return this.value ? this.value: null;
    }
}


export class PrimitiveField extends Value {
}

export class RelatedField extends BaseValue {

    modelFactory = null;
    label = "";
    required = null;
    containsPrimitiveValues = false;
    



    constructor({ internalId, owner, fieldDescriptor, modelFactory,  initialData, options }) {
        super({ internalId, owner, fieldDescriptor, initialData, options });
        this.modelFactory = modelFactory;
        this.label = fieldDescriptor.label ? fieldDescriptor.label: "";
        this.required = fieldDescriptor.required;
        this.containsPrimitiveValues = fieldDescriptor.containsPrimitiveValues;
    }


    /*
      receive notifications of a related instance
    */
    notify = (message) => {

        const { touched, valid, changed } = message;
        const propagatedMessage = {internalId: this.internalId, inConstructor: message ? message.inConstructor: undefined};

        if (isNotUndefined(valid)){
            propagatedMessage.valid = valid;
        }
        if (isNotUndefined(touched) && touched){
            this.setTouched(true);
        }

        if (isNotUndefined(changed)){
            propagatedMessage.changed = changed;
        }
        this.notifyOwner(propagatedMessage)

    }
}


export class OneOfField extends RelatedField {

    constructor({ internalId, owner, modelFactory, fieldDescriptor, initialData, options, path }) {
        super({internalId, owner, modelFactory, fieldDescriptor, initialData, options, path });

        makeObservable(this, {
            setValue: action,
        });
    
        const value = this.convertFromData(initialData);
        const valueSetOptions = options ? options : { touched: false, dirty: false, validate: true, inConstructor: true};
        this.setValue(value, valueSetOptions);
    }

    

    convertFromData = (initialData) => {
        if (initialData) {
            if (this.containsPrimitiveValues){
                return initialData;
            }else{
                const clazzName = initialData._clazz ? initialData._clazz : this.fieldDescriptor.relationClazz;
                return this.modelFactory.createInstance(clazzName, { initialData });
            }
        } else {
            return null;
        }
    }


    setValue = (value, options) => {
        
        if (this.containsPrimitiveValues) {
            if( value && (this.value === null)){
                // create a new value item
                this.value = new Value({initialData: value, fieldDescriptor: this.fieldDescriptor.itemFieldDescriptor});
            }else if (value){
                this.value.setValue(value)
            }else{
                this.value = null;
            }
        }else{
            this.value  = value;
        }
        
        if (this.value){
            this.value.owner = this;
        }
        this.internalId = this.fieldDescriptor.name;

        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        const propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor: undefined};

        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = true;            
        }
        const doCheckDirty = options ? options.dirty : true;
        if (doCheckDirty) {
            propagatedMessage.changed =  this.initialValue !== value;
        }

        if (doValidate) {
            const valid = this.validate();  // validates 
            this.setValid(valid);
            propagatedMessage.valid = this.valid;

        }
       this.notifyOwner(propagatedMessage);
    }

    validate = () => {
        let valid = true;      
        if (!this.fieldDescriptor.skipClientValidation) {

            // check first the instance values
            const validInstance = this.value ? this.value.validate() : true;
            valid = valid && validInstance;
            // checks if instance can be null, ....
            const errors = this.fieldDescriptor.validate(this.value);
            this.setErrors(errors);
            if (errors) {
                valid = false;
            }            
        }
        return valid;
    }


    toJSON = () => {
        // if field is primitive, map of primitive, or array of primitive return value
        // if field is OneOf, MapOf, ListOf classes 
        return this.value ? this.value.toJSON() : null;
    }

    getErrors(currentPath) {
        let fieldErrors = []
        if (this.errors) {
            fieldErrors.push({ path: currentPath, errors: this.errors });
        }
        /*
        if (this.value){
            const path = currentPath + "." + this.fieldDescriptor.name;
            const errors = value.getErrors(path);

        }
        */
        return fieldErrors;
    }

    mergeErrors = (validationErrors) => {
        if (this.value){
            this.value.mergeErrors(validationErrors);
        }else{
            console.error("mergeErrors: expected for this field to have a value ", this);
        }
    } 


    /*
      receive notifications of a child instance
    */
    notify = ({internalId, valid, touched, changed, inConstructor}) => {

        const propagatedMessage = {internalId: this.internalId, inConstructor};

        if (isNotUndefined(valid)){
            const selfValid = valid && this.errors === null;
            propagatedMessage.valid = selfValid;
        }
        if (isNotUndefined(touched)){
            propagatedMessage.touched = touched;
            this.setTouched(true);
        }
        if (isNotUndefined(changed)){
            propagatedMessage.changed = changed;
            //this.setTouched(true);
        }

        this.notifyOwner(propagatedMessage)
    }

}


export class ListOfField extends RelatedField {

    invalidInternalIds = [];
    childCounter = 0;  // used for generating internal ids;

    constructor({internalId, owner, modelFactory, fieldDescriptor, initialData, options }) {
        super({internalId, owner, modelFactory, fieldDescriptor, initialData, options });

        makeObservable(this, {
            setValue: action,
            set: action,
            remove: action,
            move: action,
            add: action,
        });
        
        const value = this.convertFromData(initialData);

        const valueSetOptions = options ? options : { touched: false, dirty: false, validate: true, inConstructor: true };
        this.setValue(value, valueSetOptions);

    }

    convertFromData = (initialData) => {
        if (initialData) {
            if (this.containsPrimitiveValues) {
                return initialData.map( (initialDataItem, indx) => new Value(
                    { 
                        initialData: initialDataItem, 
                        fieldDescriptor: this.fieldDescriptor.itemFieldDescriptor 
                    }))
            }

            return initialData.map( (initialDataItem, indx) => {
                const clazzName = initialDataItem._clazz ? initialDataItem._clazz : this.fieldDescriptor.relationClazz;
                return this.modelFactory.createInstance(clazzName, {internalId: indx.toString(), owner: this, initialData: initialDataItem });
            });
        } else {
            return null;
        }
    }

    setValue = (value, options) => {
        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        if (value){
            this.value = value;
            this.value.forEach( (initialDataItem, indx) => { 
                initialDataItem.internalId = this.childCounter.toString();;
                initialDataItem.owner = this;
                this.childCounter++;
            });
        

        }else{
            this.value = null;
        }

        let propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor: undefined};
        
        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = true;
        }
        if (doValidate){
            const valid = this.validate();
            propagatedMessage.valid = valid;
        }
        this.notifyOwner(propagatedMessage)
    }

    toJSON = () => {
        if (this.value) {
            if (this.containsPrimitiveValues) {
                return this.value.map(itemValue => itemValue.value);
            } else {
                return this.value.map( item => item.toJSON());
            }
        } else {
            return null;
        }
    }

    validateRelation = () =>  {
        const errors = this.fieldDescriptor.validate(this.value);
        this.setErrors(errors);
        return !errors;
            
    }


    validate = (options) => {
        let valid = true;
        

        if (!this.fieldDescriptor.skipClientValidation) {
            //
            valid = this.validateRelation()

            if (this.value) {
                this.value.forEach(itemValue => {
                    const validItem = itemValue ? itemValue.validate(options) : true;
                    this._updateInvalidChildsState(itemValue.internalId, validItem);
                    valid = valid && validItem;
                });
            }else{

            }
        }
        return valid;
    }

    notify = ({internalId, valid, touched, changed, inConstructor}) => {

        const propagatedMessage = {internalId: this.internalId, inConstructor};

        if (isNotUndefined(valid)){

            this._updateInvalidChildsState(internalId, valid);
            propagatedMessage.valid = this.valid;
        }
        if (isNotUndefined(touched) && touched){
            this.setTouched(true);
            propagatedMessage.touched = this.touched;
        }
        this.notifyOwner(propagatedMessage)

    }


    add = (item, options) => {

        const newItem = this.containsPrimitiveValues ? new Value({ initialData: item, options, fieldDescriptor: this.fieldDescriptor.itemFieldDescriptor }) :
            item;

        if(!this.value){
            this.value = [];
        }

        newItem.internalId = this.childCounter.toString();
        newItem.owner = this;
        this.childCounter++;

        this.value.push(newItem);
        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        const propagatedMessage = {internalId: this.internalId};

        if (doSetTouched) {
            if (this.containsPrimitiveValues){
                newItem.setTouched(true);
            }
            this.setTouched(true);
            propagatedMessage.touched = this.touched;   
        }

        if (doValidate && newItem) {
            const validItem = newItem.validate(options);
            this.validateRelation()
            this._updateInvalidChildsState(newItem.internalId, validItem);
            propagatedMessage.valid = this.valid; 
        }
        this.notifyOwner(propagatedMessage);
    }

    set = (index, value, options) => {
        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        const propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor : undefined };

        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = this.touched;
        }

        const newValue = this.containsPrimitiveValues ?
            new Value({ owner: this, value, options, fieldDescriptor: this.fieldDescriptor.itemFieldDescriptor }) : value;

        newValue.setOwner(this);
        newValue.internalId = this.initialCounter.toString();

        this.value[index] = newValue;
        if (doValidate) {
            const validItem = newValue.validate(options);
            this.validateRelation();
            this._updateInvalidChildsState(newValue.internalId, validItem);
            propagatedMessage.valid = this.valid;
        }
        this.notifyOwner(propagatedMessage);
    }

    remove = (indx, options) => {
        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        const propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor : undefined};

        
        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = this.touched;
        }
        const itemToRemoveInternalId = this.value[indx].internalId;
        this.value.splice(indx, 1);
            

        if (doValidate) {
            this.validateRelation();
            this._updateInvalidChildsState(itemToRemoveInternalId, true); // set item valid
            propagatedMessage.valid = this.valid;
        }

        this.notifyOwner(propagatedMessage);
    }

    move = (from, to, options) => {
        const { doSetTouched } = getTouchedAndValidate(options);

        const propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor : undefined};

        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = this.touched;
        }

        moveItem(this.value, from, to);
        this.notifyOwner(propagatedMessage);
    }

    getErrors(currentPath) {
        let collectedErrors = []
        if (this.errors) {
            collectedErrors.push({ path: currentPath, errors: this.errors });
        }
        if (this.value) {
            this.value.forEach((item, index) => {
                const elementPath = `${currentPath}[${index}]`;
                const elementErrors = item.getErrors(elementPath);
                collectedErrors.push({ path: elementPath, errors: elementErrors });
            });
        }
        return collectedErrors;
    }

    mergeErrors = (errorDict) => {
        if (this.value){
            
            const strIndexes = Object.keys(errorDict);

            strIndexes.forEach( strIndex => {
                const index = parseInt(strIndex);
                const instance = this.value[index];
                const errors = errorDict[strIndex].errors;
                instance.mergeErrors(errors);
            })
            
        }else{
            console.error("mergeErrors: expected for this field to have a value ", this);
        }
    } 
}


export class MapOfField extends RelatedField {

    childCounter = 0;

    constructor({internalId, owner, modelFactory, fieldDescriptor,  initialData, options }) {
        super({ internalId, owner, modelFactory, fieldDescriptor,  initialData, options });
        makeObservable(this, {
            setValue: action,
            set: action,
            remove: action,
        });
        const value = this.convertFromData(initialData);
        const valueSetOptions = options ? options : { touched: false, dirty: false, validate: true, inConstructor: true};
        this.setValue(value, valueSetOptions);
    }

    convertFromData = (initialData) => {
        if (initialData) {
            const value = {};
            const keys = Object.keys(initialData);
            keys.forEach(key => {
                const item = initialData[key];
                const clazzName = item._clazz ? item._clazz : this.fieldDescriptor.relationClazz;
                value[key] = this.containsPrimitiveValues ? 
                    new Value({ 
                        initialData: item, 
                        fieldDescriptor: this.fieldDescriptor.itemFieldDescriptor 
                    })
                    :this.modelFactory.createInstance(clazzName, { initialData: item });                
            });
            return value
        } else {
            return null;
        }
    }

    setValue = (value, options) => {
        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        if (value){
            this.value = value;

            const keys = Object.keys(value);
            keys.forEach(key => {
                const item = value[key];
                item.internalId = this.childCounter.toString();
                item.owner = this;
            });
            this.childCounter++;
        }else{
            this.value = null;
        }

        let propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor : undefined};
        
        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = true;
        }
        if (doValidate){
            const valid = this.validate();
            propagatedMessage.valid = valid;
        }
        this.notifyOwner(propagatedMessage)
    }

    toJSON = () => {
        if (this.value) {
            const jsonValue = {};
            const keys = Object.keys(this.value);
            keys.forEach(key => {
                const item = this.value[key];
                if (this.containsPrimitiveValues){
                    jsonValue[key] = item.value;
                }else{
                    jsonValue[key] = item ? item.toJSON() : null;
                }
            });
            return jsonValue;
        }
        return null;
    }

    validateRelation = () =>  {
        const errors = this.fieldDescriptor.validate(this.value);
        this.setErrors(errors);
        return !errors;
            
    }

    validate = () => {
        let valid = true;

        if (!this.fieldDescriptor.skipClientValidation) {

            const relationValid = this.validateRelation();
            valid = valid && relationValid;
            if (this.value) {
                const keys = Object.keys(this.value);
                keys.forEach(key => {
                    const item = this.value[key];
                    const validItem = item ? item.validate() : true;
                    this._updateInvalidChildsState(item.internalId, validItem);
                    valid = valid && validItem;
                });
            }else{
                this.setValid(valid);
            }
        }
        return valid;
    }

    set = (key, value, options) => {
        if (!this.value) {
            this.value = {};
        }

        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        let propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor : undefined};
        

        const newValue = this.containsPrimitiveValues ?
            new Value({ initialData: value, options, fieldDescriptor: this.fieldDescriptor.itemFieldDescriptor }) : value;

        newValue.owner = this;
        newValue.internalId = this.childCounter.toString();
        this.childCounter++;

        this.value[key] = newValue;

        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = this.touched;
        }

        if (doValidate) {
            const validItem = newValue.validate(options);
            this.validateRelation();
            this._updateInvalidChildsState(newValue.internalId, validItem);
            propagatedMessage.valid = this.valid;
        }
        this.notifyOwner(propagatedMessage)
    }

    remove = (key, options) => {
        //delete this.value[mapName][key];
        const { doSetTouched, doValidate } = getTouchedAndValidate(options);

        let propagatedMessage = {internalId: this.internalId, inConstructor: options ? options.inConstructor : undefined};

       
        
        if (doSetTouched) {
            this.setTouched(true);
            propagatedMessage.touched = true;
        }
        const itemToRemoveInternalId = this.value[key].internalId;

        delete this.value[key];

        if (doValidate) {
            this.validateRelation();
            this._updateInvalidChildsState(itemToRemoveInternalId, true); // set item valid
            propagatedMessage.valid = this.valid;
        }

        this.notifyOwner(propagatedMessage)
    }

    // checkDirty = (value) => {
    //     // @todo
    //     this.isDirty = this.initialValue !== value;
    // }


    notify = ({internalId, valid, touched, changed, inConstructor}) => {

        const propagatedMessage = {internalId: this.internalId, inConstructor};

        if (isNotUndefined(valid)){

            this._updateInvalidChildsState(internalId, valid);
            propagatedMessage.valid = this.valid;
        }
        if (isNotUndefined(touched) && touched){
            this.setTouched(true);
            propagatedMessage.touched = touched;
        }
        this.notifyOwner(propagatedMessage)
    }


    getErrors(currentPath) {
        let collectedErrors = []
        if (this.errors) {
            collectedErrors.push({ path: currentPath, errors: this.errors });
        }
        if (this.value) {
            const keys = Object.keys(this.value);
            keys.forEach(key => {
                const elementPath = `${currentPath}[${key}]`;
                const item = this.value[key];
                if (item) {
                    collectedErrors.push({ path: currentPath, errors: item.getErrors(elementPath) });
                }
            });

        }
        return collectedErrors;
    }

    mergePathError = (pathAsArray, errors) => {

    }
}



export class CharField extends PrimitiveField {
}

export class TextField extends PrimitiveField {
}


export class DateField extends PrimitiveField {
}

export class DateTimeField extends PrimitiveField {
}

export class IntegerField extends PrimitiveField {
}

export class BooleanField extends PrimitiveField {
}

export class DecimalField extends PrimitiveField {
}

export class FloatField extends PrimitiveField {
}


