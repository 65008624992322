import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';
import {InstancePropertyFormWidget} from 'kiini/Forms';
import { 
    ChildAudience,
    FamilyAudience, 
    AdultAudience, 
} from 'model/contentObjects';

import {contentRoleFormConfigurations} from './configuration';

const audienceRolesClazzNames = [
    'content_objects.roles.ChildAudience',
    'content_objects.roles.FamilyAudience',
    'content_objects.roles.AdultAudience',
]



const audienceRolesCreateOptions = {
    'content_objects.roles.ChildAudience': {
        label: "Child Audience", 
        creator: () => (new ChildAudience(null, {id: uuidv4(), childAgeGroups:[]}))
    },
    'content_objects.roles.FamilyAudience': {
        label: "Family Audience", 
        creator: () => (new FamilyAudience(null, {id: uuidv4()}))
    },
    'content_objects.roles.AdultAudience': {
        label: "Adult Audience", 
        creator: () => (new AdultAudience(null, {id: uuidv4(), adultAges:[]}))
    },
};

const AudienceRolesForm = ({instance}) => {
    return <InstancePropertyFormWidget 
        label="Audience"
        instance={instance}
        fieldName="contentRoles"
        Widget={ObjectListWithPanel}
        widgetProps={{
            creationOptions: audienceRolesCreateOptions, 
            itemFilterFuction: instance => audienceRolesClazzNames.some( name => name === instance.$classDescription.clazzName),
            oneInstancePerClassInList: true,
        }}
        formConfigurations={contentRoleFormConfigurations}
    />
}


export default AudienceRolesForm;

