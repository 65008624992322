import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { RouterContext, RouterView } from 'mobx-state-router';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs'

import { RootStoreContext } from './contexts';

import { initApp } from './init';
import { viewMap } from './viewMap';

import enLocale from "date-fns/locale/en-US";

const rootStore = initApp();
const { prefStore, routerStore } = rootStore;

const localeMap = {
  en: enLocale,
};

// App must be an observer to react to theme changes
 const App = observer(() => {

  const locale = "en";

  return <ThemeProvider theme={prefStore.theme}>
    <MuiPickersUtilsProvider utils={DayJsUtils} locale={localeMap[locale]}>
    <CssBaseline />
    <RootStoreContext.Provider value={rootStore}>
      <RouterContext.Provider value={routerStore}>
        <RouterView viewMap={viewMap} />
      </RouterContext.Provider>
    </RootStoreContext.Provider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
});

export default App;