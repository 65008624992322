
import { makeObservable, observable, action, runInAction} from "mobx";


import { spotObjectsFastEntryApi } from 'Api';

import {Spot, Name, Description, Location, Meta as UsoMeta, Source as UsoSource } from 'model/uso';


export default class SpotFastEntryStore {

    selectedObject = undefined; // contains the spot
    selectedObjectJson = undefined;
    selectedRoleClassNames = [];

    constructor() {
        //this.rootStore = rootStore;
        makeObservable(this, {
            selectedObject: observable.ref,  
            selectedObjectJson: observable.ref,
            selectedRoleClassNames: observable,  
            selectObject: action,
            setSelectedObject: action,
            newObject: action
        });
    }



    setSelectedObject = (instance) => {
        this.selectedObject = instance;
    }

    selectObject = (id) => {
        spotObjectsFastEntryApi.getObject(id).then(
            action("selected Object", data => {
                this.selectedRoleClassNames = data.roleClassNames;
                this.selectedObject = new Spot(null, data.spot);
            })
        );
    }

   

    newObject = (roleClassNames, latLng) => {
        this.selectedObject = new Spot(
            null,
            {
                "id": null,
            }
        ); 
        const nlName = new Name(null, {language: 'nl', value: ""});
        const nlDescription = new Description(null,{ language: 'nl', value: ""});
        const location = new Location(null, {lat: latLng.lat, lng: latLng.lng});
        this.selectedObject.addToArray('name', nlName, {touched: false});
        this.selectedObject.addToArray('description', nlDescription, {touched: false});
        this.selectedObject.setValue('location', location, {touched: false});
        this.selectedObject.setValue('meta', new UsoMeta(null, {}));
        this.selectedObject.setValue('source', new UsoSource(null, {}));
        this.selectedRoleClassNames = roleClassNames;
    }





    saveAsync = async() => {

        // fix, set description same as name 
        const name =  this.selectedObject.name[0].value;
        this.selectedObject.description[0].setValue('value', name, {touched: false});

        const isValid = this.selectedObject.validate({touched:true});
        if (isValid){
            try{
                const instanceJson = this.selectedObject.toJSON();
                const fastSpotEntryJson = {
                    spot: instanceJson,
                    roleClassNames: this.selectedRoleClassNames,
                };
                const savedFastSpotEntryJson = await spotObjectsFastEntryApi.save(fastSpotEntryJson);
                runInAction(() => {
                    this.selectedRoleClassNames = savedFastSpotEntryJson.roleClassNames;
                    this.selectedObjectJson = savedFastSpotEntryJson.spot; // needed because spots are used in json representation
                    this.selectedObject = new Spot(null, savedFastSpotEntryJson.spot);
                    console.log("saved instance id ", this.selectedObject.id);
                });
                return true;
            }catch(invalidObject){
                // merge errors
                return false;
            };
        }else{
            const errors = this.selectedObject.getErrors();
            console.log("invalid ", errors);
            return new Promise((resolve, reject) => {
                resolve(false);
            })
        }
    }

    delete = (id) => {
        spotObjectsFastEntryApi.delete(id).then(
            action("delete Object", id => {
                this.selectedObject = null;
            })    
        );
    }


    

    hasUnsavedChanges = () => {
        return this.selectedObject ? this.selectedObject.$instanceMeta.isTouched : false;
    }



    // save = (instance) => {
    
    //     const isValid = instance.validate({touched:true});
    //     if (isValid){
    //         const instanceJson = instance.toJSON();
    //         spotObjectsApi.save(instanceJson).then(
    //             action("selected Object", savedInstance => {
    //                 this.selectedObject = this.fromJsonToObject(savedInstance);
    //             })
    //         );
    //     }
    // }
}

