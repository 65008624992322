import * as React from "react";
import Box from '@material-ui/core/Box';
//import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import GroupPanel from '../GroupPanel';

// const useStyles = makeStyles(theme => ({
//     headingButton: {
//             padding: 4
        
//     }
// }));


export const FieldsRenderer = observer(({instance, store, fields, formConfigurations, path}) => {

    const fieldsWidgets = fields.map((field,index) => <field.widget 
        key={index}
        id={path + "." + field.fieldName} 
        fieldName={field.fieldName}
        label={field.label}
        path={path + "." + field.fieldName} 
        formConfigurations={formConfigurations}
        instance={instance}
        store={store}
        {...field.widgetProps}
        
        /> );
    
    return fieldsWidgets;
});

export const GroupRenderer = observer(({instance, store, group, formConfigurations, path}) => {
    return <Box>
        {group.label ? <GroupPanel label={group.label}/>: null}
        <FieldsRenderer 
            instance={instance} 
            store={store}
            fields={group.fields} 
            formConfigurations={formConfigurations} 
            path={path}
            
            />
    </Box>
});

export const GroupsRenderer =  ({instance, store, groups, formConfigurations, path}) => {

    return groups.map( (group,index) => <GroupRenderer 
        key={index} 
        instance={instance}
        store={store}
        group={group} 
        formConfigurations={formConfigurations}  
        path={path}
        />)
}

export const FormWidget = observer(({instance, formConfigurations, store}) => {
    return formConfigurations.createFormWidget({instance, path:"", store});
});

export const InstancePropertyFormWidget = observer(({instance, store, fieldName, label, Widget, widgetProps, formConfigurations}) => {
    return <Box>
        {label ? <GroupPanel label={label}/>: null}
        <Widget 
        instance={instance}
        store={store}
        formConfigurations={formConfigurations} 
        fieldName={fieldName}
        path={fieldName}
        {...widgetProps} />
        
    </Box>
});



export class FormConfigurations{

    configuration;

    constructor(configuration){
        this.configuration = configuration;
    }

    getLabel = (instance) => {
        const clazzName = instance.$classDescription.clazzName;
        if (this.configuration[clazzName]){
            return this.configuration[clazzName].label;
        }else{
            console.error("No configuration for class ",instance.$classDescription.clazzName);
        }
    }


    createFormWidget = ({instance, path, store}) => {
        
        const clazz = instance.$classDescription.clazzName;
       

        const clazzFormConfiguration = this.configuration[clazz];
    
        if(! clazzFormConfiguration){
            return <div>Programming error, no form configuration found for {clazz} </div>
        }
    
        if (clazzFormConfiguration.widget){
            return <clazzFormConfiguration.widget 
                instance={instance}
                store={store}
                formConfigurations={this} 
                label={clazzFormConfiguration.label}
                path={path}
                {...clazzFormConfiguration.widgetProps} />
        }else if( clazzFormConfiguration.groups){
            return <GroupsRenderer 
                groups={clazzFormConfiguration.groups}
                store={store}
                instance={instance} 
                formConfigurations={this} 
                path={path} 
                {...clazzFormConfiguration.widgetProps}
                />
        }else if(clazzFormConfiguration.fields){
            return <FieldsRenderer
                fields={clazzFormConfiguration.fields}
                instance={instance} 
                store={store}
                formConfigurations={this} 
                path={path} 
                {...clazzFormConfiguration.widgetProps}
                />
        }else{
            return <div>Programming error </div>
        } 
    }
}
