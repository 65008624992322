import React from 'react';

import Box from '@material-ui/core/Box'

import SelectedObjectDialog from 'components/dialogs/SelectedObjectEditDialog';
import RunnableObjectEditPanel from 'components/runnableObjects/RunnableObjectEditPanel';
import { observer } from 'mobx-react'

const RunnableObjectDialog = ({ store, open, handleClose }) => {

    const onSave = ()=>{
        store.saveWithUploadFiles();
    }

    return <SelectedObjectDialog 
        store={store} 
        open={open} 
        handleClose={handleClose} 
        enableSaveButton={store.enableSaveSelectedObject}
        onSave={onSave}>
        <Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
            <RunnableObjectEditPanel store={store} />
        </Box>
    </SelectedObjectDialog>
}


export default observer(RunnableObjectDialog);