import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';

import { inputLabelStyle, formControlStyle } from 'kiini/Style';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import TagsInput from 'components/TagsInput'

import { observer } from 'mobx-react';



const useStyles = makeStyles(theme => ({
  tagsList: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  tagsInput: {
    flex: 1,
  }
}));


const TagsWidget = observer(({ instance, store, fieldName, id, label, path, formConfigurations, ...rest }) => {
  const classes = useStyles()

  const formControlClasses = formControlStyle();
  const inputLabelClasses = inputLabelStyle();
  const tags = instance[fieldName] ? instance[fieldName]: [];

  const onTagsChange = (tags)=> {
    instance.setValue(fieldName, tags);      
  }

  if (store){
    store.tags.loadObjects()
  }else{
    console.info("No store provided");
  }
  const allTags = store && store.tags ? store.tags.values: [];

  return <FormControl fullWidth classes={formControlClasses}>
    <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>{label}</InputLabel>
    <div className={classes.tagsList}>
      <TagsInput value={tags} onChange={onTagsChange} allTags={allTags} />
    </div>
  </FormControl>
});

export default TagsWidget;
