import { Mark, markPasteRule, mergeAttributes } from '@tiptap/core'
import { find } from 'linkifyjs'
import { clickHandler } from './helpers/clickHandler'


export const ContentObjectLink = Mark.create({
  name: 'content-object-link',

  keepOnSplit: false,

  inclusive() {
    return this.options.autolink
  },

  addOptions() {
    return {
      openOnClick: false,
      linkOnPaste: true,
      autolink: true,
      HTMLAttributes: {
        target: '_blank',
        'data-content-object-type': "test",
        'data-content-object-id': "45af",
        rel: 'noopener noreferrer nofollow',
      },
    }
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
      'data-content-object-type': "test",
      'data-content-object-id': "45af",
    }
  },

  parseHTML() {
    return [
      { tag: 'a[data-content-object-type]' },
    ]
  },

//   parseHTML() {
//     return [
//   {
//     tag: 'a',
//     getAttrs(value){
//       console.log("parseHtml ", value.attributes);
//       const dataContentObjectType = value.attributes.getNamedItem('data-content-object-type')
//       if (dataContentObjectType){
//         console.log("dataContentObjectType ", dataContentObjectType.value);
//         return true;
//       }
//       return false;
//     },
//   }]
//   },


  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },

  addCommands() {
    return {
        setContentObjectLink: attributes => ({ chain }) => {
        return chain()
          .setMark(this.name, attributes)
          .setMeta('preventAutolink', true)
          .run()
      },

      toggleLink: attributes => ({ chain }) => {
        return chain()
          .toggleMark(this.name, attributes, { extendEmptyMarkRange: true })
          .setMeta('preventAutolink', true)
          .run()
      },

      unsetContentObjectLink: () => ({ chain }) => {
        return chain()
          .unsetMark(this.name, { extendEmptyMarkRange: true })
          .setMeta('preventAutolink', true)
          .run()
      },
    }
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: text => find(text)
          .filter(link => link.isLink)
          .map(link => ({
            text: link.value,
            index: link.start,
            data: link,
          })),
        type: this.type,
        getAttributes: match => ({
          href: match.data?.href,
        }),
      }),
    ]
  },

  addProseMirrorPlugins() {
    const plugins = []

    // if (this.options.autolink) {
    //   plugins.push(autolink({
    //     type: this.type,
    //   }))
    // }

    if (this.options.openOnClick) {
      plugins.push(clickHandler({
        type: this.type,
      }))
    }

    // if (this.options.linkOnPaste) {
    //   plugins.push(pasteHandler({
    //     editor: this.editor,
    //     type: this.type,
    //   }))
    // }

    return plugins
  },
})
