import * as React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { observer } from 'mobx-react';

import {inputLabelStyle, formControlStyle } from 'kiini/Style';
import Select from 'kiini/Select';

const options = [
    {label: "8 meter", value: 8},
    {label: "10 meter", value: 10},
    {label: "16 meter", value: 16},
    {label: "20 meter", value: 20},
    {label: "30 meter", value: 30},
    {label: "40 meter", value: 40},
    {label: "60 meter", value: 60},
]


const GeoFenceWidget = observer(({id, label, instance }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const geoFenceInstance = instance.geoFence; 

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        <Select
          id={id}
          label={label}
          fieldName="radius"
          instance={geoFenceInstance}
          selectOptions={options}
        />
        
    </FormControl>
});

export default GeoFenceWidget;