import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {InstancePropertyFormWidget} from 'kiini/Forms';

//import {FormConfigurations} from 'kiini/Forms';
//import TextField from 'kiini/TextField';
//import BooleanField from 'kiini/BooleanField';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';
//import SelectionField from 'kiini/SelectionField';

import { observer } from 'mobx-react';

import {contentRoleFormConfigurations} from "./configuration";

import { 
//    CopyRights, 
    ClosingsPeriods,
    OpenPeriods
} from 'model/contentObjects';


const contentRolesClazzNames = [
    'content_objects.roles.ClosingsPeriods',
    'content_objects.roles.OpenPeriods',
]; 


const rolesCreationOptions = {
    'content_objects.roles.ClosingsPeriods': {
        label: "Closings Periods", 
        creator: () => (new ClosingsPeriods(null,{id: uuidv4(), values:[]}))
    },    
    'content_objects.roles.OpenPeriods': {
        label: "Open Periods", 
        creator: () => (new OpenPeriods(null,{id: uuidv4(), values:[]}))
    },    
}


const ContentObjectRolesEditor = observer(({instance, store}) => {
    return <InstancePropertyFormWidget 
        instance={instance} 
        store={store}
        fieldName="contentRoles" 
        label="Additional Properties"
        Widget={ObjectListWithPanel}
        widgetProps={{
            creationOptions: rolesCreationOptions,
            itemFilterFuction: instance =>  contentRolesClazzNames.some( name => name === instance.$classDescription.clazzName),
                oneInstancePerClassInList: true,
        }}
        formConfigurations={contentRoleFormConfigurations}/>
});


export default ContentObjectRolesEditor;


