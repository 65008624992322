import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function LinkDialog({onClose, onCancel, value, open}) {
  
  const [linkValue, setLinkValue] = React.useState(value ? value.href: "");
  React.useEffect(() => { setLinkValue(value ? value.href: "")}, [value] )

  const onLinkTextChange = (value)=> {
    setLinkValue(value);
  }

  const handleClose = () => {
    onClose({href: linkValue})
  }

  const handleCancel = () => {
    onCancel();
  }


  return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter link</DialogTitle>
        <DialogContent>
          <TextField
            value={linkValue}
            autoFocus
            margin="dense"
            id="name"
            label="Link"
            type="text"
            onChange={(evt)=>onLinkTextChange(evt.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}