import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import MailIcon from '@material-ui/icons/Mail';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { observer } from 'mobx-react'

import { PublishingState } from 'stores/ExperienceEditorStore';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        width: theme.spacing(4),
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },

}));



const ReadyToPublishIndicator = observer(({ experienceEditorStore }) => {

    const classes = useStyles();

    const checks = experienceEditorStore.getChecks();
   
    const checksAsArray = Array.from(checks.values());

    const hasChecksForNoPublish = checksAsArray.some(check => check.state === PublishingState.NO_PUBLISHING);
    //const hasChecksForWarnings = checksAsArray.some(check => check.state === PublishingState.WARNING);
    //const hasChecksForAdvises = checksAsArray.some(check => check.state === PublishingState.ADVISE);

    const messages = checksAsArray.map((check,index) => <Typography key={index} >{check.message}</Typography>);
    const nrMessages = checksAsArray.length;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    return <div className={classes.root}>
       

        {hasChecksForNoPublish ? <Box 
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Badge color="error" badgeContent={nrMessages}>
                <MailIcon fontSize="small"  />
            </Badge>
            <Popover id="mouse-over-popover"  className={classes.popover}
            classes={{
            paper: classes.paper,
            }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography key="top" >Can't publish this trail (saving is possible):</Typography>  
        {messages}
      </Popover>
        </Box> : null}
    </div>

});

export default ReadyToPublishIndicator;