import * as React from "react";
import Box from '@material-ui/core/Box';
import IconButton  from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import {BiXCircle} from 'react-icons/bi';
import {IoIosAddCircleOutline } from 'react-icons/io';

import BaseInput from "kiini/BaseInput";


import {inputLabelStyle, formControlStyle } from '../Style';


const useStylesAddListPanel = makeStyles(theme => ({
    addPanel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }
}));


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        maxWidth: 360,
        background: 'linear-gradient(0deg,#ba54f5,#e14eca)',
        overflow: 'scroll'
    },
    mainItemText: {
        marginLeft: 8,
    },
    nested: {
    //    marginLeft: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    itemHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
        minHeight: 24
    },
    listItemIcon: {
        minWidth: 16
    },
    headingButton: {
            padding: 4
        
    }
}));



const ListItem = ({form, label, onRemove, id, key }) => {
    const classes = useStyles();
    return <div key={key} className={classes.nested}>
        <Box className={classes.itemHeader}>
            <div>
                {form}
            </div>
            <div>
            <IconButton classes={classes.headingButton} edge="end" aria-label="delete" onClick={() => onRemove(id)} >
                <BiXCircle size={16}/>
            </IconButton>    
        </div>
        </Box>    
    </div>
};

const InputList = observer(({instance, fieldName, path, formConfigurations }) => {

    const onRemove = (index) => {
        instance.removeFromArray(fieldName, index)
    }

    const onChange = (evt, index) => {
        instance.setArrayValue(fieldName, index, evt.target.value);
    }

    const onBlur = (evt, index) => {

    }

    const items = instance[fieldName];

    const getForm = (instance, index) => {

        const itemPath = `${path}[${index}]`;
        const id = itemPath;
        const value = instance[fieldName][index];

        const parentFieldMeta = instance.$fieldsMeta.get(fieldName);
        const fieldMeta = parentFieldMeta.elementsMeta[index];

        const isTouched = fieldMeta.isTouched;
        const errors = fieldMeta.errors;
        const showError = isTouched && errors;

        return <BaseInput id={id} error={showError} value={value} onChange={(evt) => onChange(evt, index)} onBlur={(evt) => onBlur(evt, index)}/>
    }

    return <div>
        {items.map((value, i) => (
            <ListItem
                key={i}
                index={i}
                form={getForm(instance, i)}
                id={i}
                onRemove={onRemove}
            />
        ))}
    </div>
});

const PrimitiveListField = ({instance, fieldName, id, label, path, formConfigurations, creationOptions, createItem }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const classes = useStylesAddListPanel();

    const handleAddClick = (evt) => {
        instance.addToArray(fieldName, null);  // add empty element, so a forms get rendered.
    }

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
        </InputLabel>
        <div>
            <InputList instance={instance} fieldName={fieldName} path={path} formConfigurations={formConfigurations} />
            <Box className={classes.addPanel}>
                <IconButton edge="end" aria-label="delete" onClick={(event) => handleAddClick(event)} >
                    <IoIosAddCircleOutline size={16}/>
                </IconButton>
            </Box>
        </div>
    </FormControl>
}

export default PrimitiveListField;