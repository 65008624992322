import * as React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { observer } from 'mobx-react';

import { inputLabelStyle, formControlStyle } from 'jimbo/ui/Style';

import ObjectSelector from 'jimbo/ui/ObjectSelector';
import ObjectSelectDialog from 'jimbo/ui/ObjectSelectDialog';



const ObjectSelectWidget = observer(({ id, label, instance, fieldName, objectSelectDialogProps, valueToLabel }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();


    const onValueChange = (value) => {
        if (value) {
            instance.setValue(fieldName,value);
        } else {
            instance.setValue(fieldName, null);
        }
    }

    //const field = instance.$fields.get(fieldName);
    // const isTouched = field.isTouched;
    // const hasErrors = field.errors;
    // const value = field.value ? field.value : null;

    const value = instance[fieldName] ? instance[fieldName]: "";

    const fieldMeta = instance.$fieldsMeta.get(fieldName);
    const isTouched = fieldMeta.isTouched;
    const hasErrors = fieldMeta.errors;
    
    const showError = isTouched && hasErrors;

    const displayLabel = valueToLabel(value);

    const isClearable = fieldMeta.fieldDescriptor.isNull; 
   
    console.log("isclearable ", isClearable)
    console.log({"fieldMeta":fieldMeta})


    return <FormControl error={showError} fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        <ObjectSelector
            ObjectSelectDialog={ObjectSelectDialog}
            objectSelectDialogProps={objectSelectDialogProps}
            value={displayLabel}
            onValueChange={onValueChange}
            clearable={isClearable}
        />
    </FormControl>
});



export default ObjectSelectWidget;
