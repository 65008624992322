import React from 'react';

import Button from '@material-ui/core/Button';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

import FixedToolbar from 'components/FixedToolbar'
import MainLayoutVh from 'layouts/MainVh';

import TextSearch from 'components/search/TextSearch';
import NotificationsTableVirtualized from './components/NotificationsTableVirtualized'


const NotificationsScreen = observer(() => {

  const routerStore = useRouterStore();
  const rootStore = useRootStore();
  const {notificationsStore} = rootStore;

  const newObject = () => {
    routerStore.goTo('newNotification');
  }
  
  const onSelectObject = id => {
    routerStore.goTo('notification', { params: { id } });
  }

  const toolbar = <FixedToolbar>
      <TextSearch store={notificationsStore} />
      <Button color="primary" variant="contained" onClick={() => newObject()}>Add a notification</Button>
    </FixedToolbar>

  return <MainLayoutVh toolbar={toolbar} >
        <NotificationsTableVirtualized  store={notificationsStore} onSelectObject={onSelectObject} />
    </MainLayoutVh>;
});

export default NotificationsScreen;
