import axios from 'axios';



const queryMapToQueryString = (queryObject) => {

    let queryArgs = [];

    const addQueryArgs = (value, key) => {
        if (Array.isArray(value)) {
            const notNullValues = value.filter(value => value != null); 
            queryArgs = queryArgs.concat(notNullValues.map(part => `${key}=${part}`));
        } else {
            if (value != null){
                queryArgs.push(`${key}=${value}`)
            }
        }
    }

    const queryMap = new Map(Object.entries(queryObject));

    if (queryMap.has("search.all")){
        const searchAllValue = queryMap.get("search.all");
        if (searchAllValue){
            queryMap.set("search.all", searchAllValue + "*")
        }else{
            queryMap.delete("search.all");
        }
    }

    queryMap.forEach(addQueryArgs)
    return queryArgs.join('&');
}






function contentObjectFilterToQuery(start, count, filter, sort) {
    let params = { start, count, ...filter };
    if (sort){
        params = {...params, ...sort}; 
    }
    const query = '?' + queryMapToQueryString(params);
    return query
}


export class ObjectNotFound extends Error {
    constructor(message,id) {
      // Needs to pass both `message` and `options` to install the "cause" property.
      super(message);
      this.id = id;
      this.name = "ObjectNotFound"
    }
}

export class ValidationErrorResponse extends Error {
    constructor(message,validatedObjectData, errors) {
        // Needs to pass both `message` and `options` to install the "cause" property.
        super(message);
        this.validatedObjectData = validatedObjectData;
        this.errors = errors;
        this.name = 'ValidationErrorResponse';
    }
}


class BaseApi{

    constructor({endPoint}) {
        this.endPoint = endPoint;
    }

    getObjectsEndpoint = () =>{
        return this.endPoint;
    }


    getObjects = async (start, count, filter, sort) => {
        const query = contentObjectFilterToQuery(start, count, filter, sort)
        const resourceUrl = this.getObjectsEndpoint() + query;        
        const res = await axios(resourceUrl);
        return res.data;
    }

    getObject = async (id) => {
        const resourceUrl = this.getObjectsEndpoint(); 
        const detailEndpoint = `${resourceUrl}${id}/`;
        try{
            let res = await axios.get(detailEndpoint);
            return res.data;
        }catch (error){
            if (error.response.status === 404) {
                throw new ObjectNotFound("Object not found", id)
            }
            throw error;
        }

    }

    delete = async (id) => {
        const resourceUrl = this.getObjectsEndpoint(); 
        const detailEndpoint = `${resourceUrl}${id}/`;
        await axios.delete(detailEndpoint);
        return id;
    }

    save = async (instance) => {
        if (instance.id){
            return this.put(instance);
        }else{
            return this.post(instance);
        }
    }

    put = async (instance) => {
        const resourceUrl = this.getObjectsEndpoint(); 
        const detailEndpoint = `${resourceUrl}${instance.id}/`;

        try{
            let res = await axios.put(detailEndpoint, instance);
            return res.data;
        }catch(error){
            if (error.response.status === 400){
                
                throw new ValidationErrorResponse(
                    "validation error",
                        error.response.data.validatedObject,
                        error.response.data.errors
                    )
            }
            throw error;
        }
    }

    post = async (instance) => {
        const resourceUrl = this.getObjectsEndpoint(); 
        let res = await axios.post(resourceUrl, instance);
        return res.data;
    }
}

export default BaseApi;