import * as React from "react";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
//import { observer } from 'mobx-react';

import BaseInput from 'kiini/BaseInput';
import { Chip } from "@material-ui/core";
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: 360,
    background: 'linear-gradient(0deg,#ba54f5,#e14eca)',
    overflow: 'scroll'
  },
  mainItemText: {
    marginLeft: 8,
  },
  nested: {
    //    marginLeft: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
    minHeight: 24
  },
  listItemIcon: {
    minWidth: 16
  },
  headingButton: {
    padding: 4

  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  }
}));


const Suggestions = ({ tags, container, inputValue, addTag }) => {

  const inputLength = inputValue.trim().length;

  if (inputLength > 0) {
    const tagSuggestions = tags.filter((tag) => {
      return tag.label.toLowerCase().slice(0, inputLength) === inputValue.toLowerCase();
    })

    if (tagSuggestions.length > 0) {

      console.log("tagsuggestions ", tagSuggestions);


      const tagSuggestionListItems = tagSuggestions.map((tag, indx) => (<MenuItem key={indx} onClick={() => addTag(tag.name)}>{tag.label}</MenuItem>));

      return <Popper style={{ zIndex: 3000 }} open={true} anchorEl={container} placement="bottom-start">
        <div style={{ backgroundColor: "white", color: "black"}}>
          <MenuList component="nav">
            {tagSuggestionListItems}
          </MenuList>
        </div>
      </Popper>
    }
  }
  return null;
}

const enterOrTab = [9, 13]


const TagsInput = ({ value, onChange, allTags }) => {
  const classes = useStyles();
  // const [tags, setTags] = React.useState([]);

  const [inputValue, setInputValue] = React.useState("")

  const onValueChange = (evt) => {
    console.log("value changed ", evt.target.value)
    setInputValue(evt.target.value);
  }

  const onKeyDown = (evt) => {
    //console.log("keypress ", evt);
    if (evt.keyCode === 9 && inputValue.length > 0 ){
        // evt.preventDefault();
        //susref.current.focus(); susref.current is null
    } 

  }

  const onKeyPress = (evt) => {
    console.log("keypress ", evt.charCode)
    if (enterOrTab.indexOf(evt.charCode) >= 0) {
      const newTag = inputValue.trim();

      if (newTag.length > 0) {
        const currentTags = [...value];
        currentTags.push(newTag)
        //setTags(currentTags);
        onChange(currentTags);
        setInputValue("");
        container.current.firstChild.focus();
      }
    }
  }

  const handleDelete = (tag) => {
    const currentTags = [...value];
    const indexToDelete = currentTags.indexOf(tag);
    currentTags.splice(indexToDelete, 1);
    //setTags(currentTags)
    onChange(currentTags);
    container.current.firstChild.focus();
  }

  const addTag = (tag) => {
    const currentTags = [...value];
    currentTags.push(tag)
    //setTags(currentTags);
    onChange(currentTags);
    setInputValue("");
    container.current.firstChild.focus();
  }

  const tagChips = value ? value.map( (tag, indx) => <Chip key={indx} size="small" label={tag} onDelete={() => handleDelete(tag)} />): null;

  const container = React.useRef(null);
  
  return <React.Fragment>
    <Box className={classes.tags}>
      {tagChips}
    </Box>
    <BaseInput ref={container} value={inputValue} onChange={onValueChange} onKeyDown={onKeyDown} onKeyPress={onKeyPress} />
    <Suggestions 
      //ref={susref} 
      inputValue={inputValue} 
      tags={allTags} 
      container={container.current} 
      addTag={addTag} 
      />
  </React.Fragment>
}

export default TagsInput;