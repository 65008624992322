import * as React from 'react';

import { FormWidget, FormConfigurations } from 'kiini/Forms';
import TextField from 'kiini/TextField';
import LocaleField from 'kiini/LocaleField';
import LocationField from 'kiini/LocationField';
import BooleanField from 'kiini/BooleanField';
import OptionalOneOfField from 'kiini/OptionalOneOfField';
import SelectionChoicesField from 'kiini/SelectionChoicesField';

import ContentObjectRolesEditor from 'components/contentRoles/ContentObjectRolesEditor';

import TagsWidget from 'components/contentObjects/TagsWidget';

import { Copyrights, copyrightsClassDescription } from 'model/contentObjects';


const commonStoryBlockConfiguration = {
    groups: [
        {
            label: "Common",
            fields: [
                { fieldName: "name", label: "Name", widget: TextField },
                //{fieldName: "description", label: "Description", widget: TextField, widgetProps: { multiline: true, rows: 5 }},
                { fieldName: "locale", label: "Locale", widget: LocaleField },
                { fieldName: "isInLibrary", label: "In library", widget: BooleanField },
                { fieldName: "location", label: "Location", widget: LocationField },
                {
                    fieldName: "copyrights",
                    label: "Copyrights",
                    widget: OptionalOneOfField,
                    widgetProps: {
                        createItem: () => (new Copyrights(null, {}))
                    }
                }
            ]
        },
        {
            label: "Tags",
            fields: [
                {
                    fieldName: "tags",
                    label: "Tags",
                    widget: TagsWidget,
                    widgetProps: {
                        getTags: ({rootStore}) => (["test","test2"])
                    }
                },
            ]
        },
    ]
}

const commonStoryBlockFormConfigurationsData = {
    "content_objects.model.CompoundContentObject": commonStoryBlockConfiguration,
    "content_objects.model.Copyrights": {
        fields: [
            { fieldName: "attribution", label: "Attribution", widget: TextField },
            {
                fieldName: "licenceType",
                label: "Type",
                widget: SelectionChoicesField,
                widgetProps: {
                    clazzDescription: copyrightsClassDescription,
                }
            }
        ]
    }
}


const commonStoryBlockFormConfigurations = new FormConfigurations(commonStoryBlockFormConfigurationsData);


const StoryBlockCommonForm = ({ instance, store }) => {
    return <React.Fragment>
        <FormWidget instance={instance} store={store} formConfigurations={commonStoryBlockFormConfigurations} />
        <ContentObjectRolesEditor instance={instance} store={store}/>
    </React.Fragment>
}


export default StoryBlockCommonForm;