import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import DropzoneComponent from 'react-dropzone-component';
import './dropzone.css';

import { observer } from 'mobx-react'

import {JIMBOGO_SERVICE_API_HOST} from "Api"

var componentConfig = {
    iconFiletypes: ['.gpx', '.kml'],
    showFiletypeIcon: true,
    postUrl: `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/gpx-to-route/`
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        margin: 8,
        padding: 8

    }
});

class UploadGpxPanel extends Component {

    constructor(props) {
        super(props);
        this.dropZone = null;
        this.state = { filesToUpload: [], newUploadedDocuments: [] }; 
    }

    _afterUpload = (routeJson) => {
        if (this.props.onReceiveRouteJson){
            this.props.onReceiveRouteJson(routeJson);
        }
        const quededFiles = this.dropZone.getQueuedFiles();
        if (quededFiles.length > 0) {
            this.dropZone.processQueue();
        }
    }

    render() {
        const initDropZone = (dropZone) => {
            this.dropZone = dropZone;
        }
        const djsConfig = {
            //            addRemoveLinks: false,
            autoProcessQueue: false,
            addRemoveLinks: true,
            dictDefaultMessage: "Drop gpx files here to upload or click here to select files, after selection press the upload button"
        };
        const addedFile = (file) => {
            console.log(file);

            let filesToUpload = this.state.filesToUpload;
            filesToUpload.push(file);
            console.log("FILES ", filesToUpload);
            // const filesToUpload = this.dropZone.getQueuedFiles(); NOTE THIS
            console.log("IN QUEUED ", this.dropZone.getQueuedFiles());
            this.setState({ filesToUpload });
        }
        const processing = (file) => {
            console.log("processing ", file);
            //console.log(args);
            console.log("processing ", this.dropZone.getQueuedFiles());
        }
        const removedFile = (file) => {
            const filesToUpload = this.dropZone.getQueuedFiles();
            this.setState({ filesToUpload });
        }
        const onSuccess = (file, response) => {
            this._afterUpload(response)
            this.dropZone.removeFile(file);
        }
        const onSending = (file, xhr, formData) => {
            //set();
            const token = this.props.authStore.access;
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }
        const eventHandlers = {
            success: onSuccess,
            init: initDropZone,
            addedfile: addedFile,
            removedfile: removedFile,
            processing: processing,
            sending: onSending

        }
        const uploadFileQueue = () => {
            console.log("upload it");
            this.dropZone.processQueue();
        }
        const uploadButtonDisabled = this.state.filesToUpload.length === 0;
        return (
            <Paper className={this.props.classes.paper}>
                <DropzoneComponent eventHandlers={eventHandlers} config={componentConfig} djsConfig={djsConfig} />
                <Button
                    disabled={uploadButtonDisabled}
                    onClick={() => uploadFileQueue()}
                    variant="contained"
                    color="primary">
                    Upload
                </Button>
            </Paper>
        )
    }
}

const StyledUploadGpxPanel = withStyles(styles)(UploadGpxPanel);
export default observer(StyledUploadGpxPanel)


/*
                            <Button onClick={() => getQueuedFiles()} variant="contained" color="primary">
                                Check
                            </Button>
                            <Button onClick={() => getRejectedFiles()} variant="contained" color="primary">
                                Check getRejectedFiles
                            </Button>
                            <Button onClick={() => getAcceptedFiles()} variant="contained" color="primary">
                                Check getAcceptedFiles
                            </Button>
*/
        // const getQueuedFiles = () => {
        //     console.log("queded files ", this.dropZone.getQueuedFiles());
        // }

        // const getRejectedFiles = () => {
        //     console.log("rejected files ", this.dropZone.getRejectedFiles());
        // }


        // const getAcceptedFiles = () => {
        //     console.log("accepted files ", this.dropZone.getAcceptedFiles());
        // }
