import L from 'leaflet'
import {lineString} from '@turf/helpers'; 
import length from '@turf/length'; 

export function toMapBounds(bounds) {
    return new L.LatLngBounds(new L.LatLng(bounds.sw.lat, bounds.sw.lng), new L.LatLng(bounds.ne.lat, bounds.ne.lng));
}
  
export function boundToFilterPart(latLngBounds) {
    const sw = latLngBounds.getSouthWest();
    const ne = latLngBounds.getNorthEast();
  
    const bboxSwLat = sw.lat;
    const bboxSwLng = sw.lng;
    const bboxNeLat = ne.lat;
    const bboxNeLng = ne.lng;
    return {
      bboxSwLat,
      bboxSwLng,
      bboxNeLat,
      bboxNeLng
    }
  }
  
export  function latLongDoubletListToPosition(latLngPointsList) {
  const positions = []
  for (let i = 0; i < latLngPointsList.length; i = i + 2) {
    positions.push([latLngPointsList[i], latLngPointsList[i + 1]])
  }
  return positions;
}
  

export function *makeLatLngIterator(points) {
  for(let i = 0; i < points.length; i = i + 2){
     yield {lat: points[i], lng: points[i + 1]}
  }
}

export function *makeLatLngAsArrayIterator(points) {
  for(let i = 0; i < points.length; i = i + 2){
     yield [points[i], points[i + 1]]
  }
}




export function updateBounds(bounds, {lat, lng}){
  if(!bounds){
    return {
          ne: {
              "_clazz": "content_objects.model.Location",
              lat,
              lng
          },
          sw: {
              "_clazz": "content_objects.model.Location",
              lat,
              lng
          },
          "_clazz": "content_objects.model.MapBounds"
      }
  }else{
    bounds.ne.lat = bounds.ne.lat > lat ? bounds.ne.lat: lat;
    bounds.ne.lng = bounds.ne.lng > lng ? bounds.ne.lng: lng;  
    bounds.sw.lat = bounds.sw.lat < lat ? bounds.sw.lat: lat; 
    bounds.sw.lng = bounds.sw.lng < lng ? bounds.sw.lng: lng;
    return bounds
  }
}

export function maxBounds(boundsA, boundsB){
  if (!boundsB){
    return boundsA;
  }else{
    return {
      ne: {
          "_clazz": "content_objects.model.Location",
          lat: boundsA.ne.lat > boundsB.ne.lat ? boundsA.ne.lat: boundsB.ne.lat,
          lng: boundsA.ne.lng > boundsB.ne.lng ? boundsA.ne.lng: boundsB.ne.lng
      },
      sw: {
          "_clazz": "content_objects.model.Location",
          lat: boundsA.sw.lat < boundsB.sw.lat ? boundsA.sw.lat: boundsB.sw.lat,
          lng: boundsA.sw.lng < boundsB.sw.lng ? boundsA.sw.lng: boundsB.sw.lng
      },
      "_clazz": "content_objects.model.MapBounds"
  }
  }
}


export function getBoundingBox( interLeavedLatLngArray ){

  let bounds = null;
  const latLngIterator = makeLatLngIterator(interLeavedLatLngArray);
  for (const latLng of latLngIterator) {
    bounds = updateBounds(bounds, latLng);
  }
  return bounds;

}

export function getLineString(interLeavedLatLngArray){

  let lineString = [];
  const latLngAsArrayIterator = makeLatLngAsArrayIterator(interLeavedLatLngArray);
  
  for (const latLngArray of latLngAsArrayIterator) {
    lineString.push(latLngArray);
  }
  return lineString;
}


export function getLenghtInMeters(interLeavedLatLngArray){
  const asLineStringArray = getLineString(interLeavedLatLngArray);
  const line = lineString(asLineStringArray);
  const lengthKm = length(line);
  return Math.trunc(lengthKm * 1000);
}