import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MainLayout from 'layouts/Main';
import FixedToolbar from 'components/FixedToolbar'
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react'
import { useRootStore } from 'contexts';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));


const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const SettingsScreen = observer(() => {
  const classes = useStyles();

  //const [files] = useState(mockData);
  const rootStore = useRootStore();
  const { prefStore } = rootStore;

  const isDarkTheme = prefStore.paletteType === 'dark' 

  const handleChange = (evt)=> {
    prefStore.toggleTheme()
  }

  const toolbar = <FixedToolbar>
  </FixedToolbar>

  return (
    <MainLayout toolbar={toolbar}>
      <div className={classes.root}>
        <div className={classes.content}>
          <Box bgcolor="background.paper" margin={2} padding={2}>
            <Typography variant="h4" gutterBottom>Settings</Typography>
            <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Light Theme</Grid>
          <Grid item>
            <AntSwitch checked={isDarkTheme} onChange={handleChange} name="checkedC" />
          </Grid>
          <Grid item>Dark Theme</Grid>
        </Grid>
          </Box>
        </div>
      </div>
    </MainLayout>
  );
});

export default SettingsScreen;