import React from 'react';

import objectTypeRegistry from './ExperienceObjectTypes' 
import EditStoryBlockDialog from 'components/dialogs/EditStoryBlockDialog';
import EditFileAssetDialog from 'components/dialogs/EditFileAssetDialog';
import SpotAmentiesDialog from 'components/dialogs/SpotAmentiesDialog';

import {observer} from 'mobx-react'

import { ADD_OBJECT_DIALOG, ADD_AMENTITY_SPOT_DIALOG, EDIT_STORYBLOCK_DIALOG, EDIT_FILEASSET_DIALOG } from 'stores/ExperienceEditorStore';

const ExperienceDialogSelector = observer(({experienceEditorStore, authStore}) => {
    
   
    const experienceEditorStateValue = experienceEditorStore.experienceEditorState;
    const contentObjectStore = experienceEditorStore.contentObjectStore;

    const handleCloseAddObjectDialog = (selectedObject) => {
        experienceEditorStore.addContentObject(selectedObject);
    }

    const handleCloseAddSpotDialog = () => {
        experienceEditorStore.addNewSpot();
    }

    const handleCancel = () => {
        experienceEditorStore.closeDialog();
    }


    const handleCloseEditContentObjectDialog = (selectedObject) => {
        experienceEditorStore.updateContentObjectFromDialog(selectedObject);
    }

    const handleCopyStoryBlock = () => {
        experienceEditorStore.changeToCopiedContentObject();
    }

   
    
    const showDialog = experienceEditorStateValue.showDialog;

   


    const providedLocation = experienceEditorStateValue.latLatForNewLocationObject ? {
        lat: experienceEditorStateValue.latLatForNewLocationObject.lat,
        lng: experienceEditorStateValue.latLatForNewLocationObject.lng,
        _clazz: "content_objects.model.Location"
    }: null;
    
    if (showDialog){

        if (showDialog === ADD_OBJECT_DIALOG){
    
            const experienceObjectsTypeNameToAdd = experienceEditorStateValue.experienceObjectsTypeNameToAdd;
            const DialogClazz = objectTypeRegistry.getDialogClazz(experienceObjectsTypeNameToAdd);
            return  <DialogClazz open={true} 
                        handleCancel={handleCancel}
                        handleClose={handleCloseAddObjectDialog}  
                        providedLocation={providedLocation}
                        objectTypeToAdd={experienceObjectsTypeNameToAdd} 
                        authStore={authStore}
                        experienceEditorStore={experienceEditorStore}/> 
        }else if(showDialog === ADD_AMENTITY_SPOT_DIALOG){
            return  <SpotAmentiesDialog 
                        open={true}
                        handleCancel={handleCancel}
                        handleClose={handleCloseAddSpotDialog}  
                        experienceEditorStore={experienceEditorStore}/> 
        
        }else if(showDialog === EDIT_STORYBLOCK_DIALOG){
            return <EditStoryBlockDialog 
                    storyBlockStore={contentObjectStore}
                    handleCancel={handleCancel}
                    handleCopyStoryBlock={handleCopyStoryBlock}  
                    handleClose={handleCloseEditContentObjectDialog}  
                />
        }else if (showDialog === EDIT_FILEASSET_DIALOG){

            // const fileAssetsStore = rootStore.dialogSelectionFileAssetsStore;
            // fileAssetsStore.setSelectedObject(contentObjectStore.selectedObject);

            return <EditFileAssetDialog  
                store={contentObjectStore}
                handleCancel={handleCancel}
                handleClose={handleCloseEditContentObjectDialog}      
                />
        }
    }else{
        return null;
    }
});

export default ExperienceDialogSelector;