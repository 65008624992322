import { DEFAULT_FIELD_VALUES, ModelFactory } from "jimbo/model/base"; 


export const notificationPackageClassFactory = new ModelFactory();

notificationPackageClassFactory.addClassDescriptionsData([
    {
        clazzName: "Notification",
        verboseName: "None",

        fields: [
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "id",
                "auto_created": true,
                "type": "persistent.fields.CharField",
                "init_value": "null",
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "title",
                "max_length": 512,
                "type": "persistent.fields.CharField",
            }, {
                ...DEFAULT_FIELD_VALUES,
                "name": "text",
                "max_length": 2512,
                "type": "persistent.fields.TextField",
            }, {
                ...DEFAULT_FIELD_VALUES,
                "name": "notificationType",
                "choices": [["urgent", "Red"], ["warning", "Orange"], ["informative", "Green"],],
                "max_length": 48,
                "type": "persistent.fields.CharField",
                "init_value": "urgent",

            }, {
                ...DEFAULT_FIELD_VALUES,
                "name": "publishStartDateTime",
                "type": "persistent.fields.DateTimeField",
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "publishEndDateTime",
                "type": "persistent.fields.DateTimeField",
                "blank": true,
                "null": true,
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "publishedContentObject",
                "type": "persistent.fields.OneOf",
                "relation_clazz": "PublishedContentObjectProxy",
                "init_value": "null",
                "null": true,
            },
        ]
    },
    {
        clazzName: "PublishedContentObjectProxy",
        verboseName: "PublishedContent",
        fields: [
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "id",
                "auto_created": false,
                "type": "persistent.fields.CharField",
                "init_value": "null",
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "name",
                "max_length": 512,
                "type": "persistent.fields.CharField",
            }
        ],

    }
]);
