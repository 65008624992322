import { createMuiTheme } from '@material-ui/core';

//import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';


import Poppins from '../fonts/Poppins-Regular.ttf';
import PoppinsExtraLight from '../fonts/Poppins-ExtraLight.ttf';
import PoppinsLight from '../fonts/Poppins-Light.ttf';
import PoppinsSemiBold from '../fonts/Poppins-SemiBold.ttf';

// import palette from './palette';
// import typography from './typography';
//import overrides from './overrides';

// const theme = createMuiTheme({
//   palette,
//   typography,
//   overrides,
//   zIndex: {
//     appBar: 1200,
//     drawer: 1100
//   },
//   storytrails: {
//     paperStyle : {
//       margin: 8,
//       padding: 8
//     },
//     buttonStyle: {
//       marginTop: 12,
//       marginBottom: 12,
//     }
//   }
// });

// export default theme;



const poppins = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Poppins'),
    url(${Poppins}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const poppinsLight = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Poppins-Light'),
    url(${PoppinsLight}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const poppinsExtraLight = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    local('Poppins-ExtraLight'),
    url(${PoppinsExtraLight}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const poppinsSemiBold = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('Poppins-ExtraLight'),
    url(${PoppinsSemiBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
 
const typography = {
  fontSize: 14,
  fontFamily: 'Poppins, sans-serif',
  h1: {
    fontSize: 33,
    fontWeight: 400,
    fontFamily: ['"Poppins"','sans-serif'].join(','),
    lineHeight: 1.05
  },
  h2: {
    fontSize: 27,
    fontWeight: 400,
    fontFamily: ['"Poppins"','sans-serif'].join(','),
    lineHeight: 1.2
  },
  h3: {
    fontSize: 23,
    fontWeight: 400,
    fontFamily: ['"Poppins"','sans-serif'].join(','),
    lineHeight: '1.4em'
  },
  h4: {
    fontSize: 17,
    fontWeight: 400,
    fontFamily: ['"Poppins"','sans-serif'].join(','),
    lineHeight: '1.45em'
  },
  h5: {
    fontSize: 13,
    fontWeight: 400,
    fontFamily: ['"Poppins"','sans-serif'].join(','),
    lineHeight: '1.4em'
  },
  h6: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: ['"Poppins"','sans-serif'].join(','),
    lineHeight: '1.2'
  },
  body1: {
    fontSize: "0.8rem",
    fontWeight: 300,
  }
}

const secondaryColorMain =  '#3358f4';
const focusedBorderColor = '#1d8cf8';

const linesColorDark = '#2b3553';
const linesColorLight = 'rgba(29,37,59,.5)'
//const textPrimaryColor =   'hsla(0,0%,100%,.8)';
const textPrimaryColorDark =   'hsla(0,0%,100%,.8)';
const textPrimaryColorLight =  'rgba(0, 0, 0, 0.87)';//  'rgba(34,42,66,.7)';

const backGroundPaperColorDark =  '#27293d';
const backGroundPaperColorLight =  '#ffffff';

const labelColorLight = "#344675";
const labelColorDark = "hsla(0,0%,100%,.6)"; 

const actionActiveLight = textPrimaryColorLight;
const actionActiveDark = textPrimaryColorDark;

export const createTheme = ({paletteType}) => createMuiTheme({
  palette: {
    type: paletteType,
    primary: {
      main: purple[500],
    },
    secondary: {
      main: green[500],
    },
    background: {
        default: paletteType === 'light' ? '#f5f6fa' : '#1e1e2f',
        paper:  paletteType === 'light' ? backGroundPaperColorLight: backGroundPaperColorDark,
    },
    text: {
      primary: paletteType === 'light' ? textPrimaryColorLight: textPrimaryColorDark,
    },
    divider: paletteType === 'light' ? linesColorLight: linesColorDark,
    inputsBorderColor: paletteType === 'light' ? linesColorLight: linesColorDark,
    focusedBorderColor,
    menu: {
      default: textPrimaryColorDark,
    },
    label: paletteType === 'light' ? labelColorLight: labelColorDark,
  },
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [poppins, poppinsLight, poppinsExtraLight, poppinsSemiBold],
      },
    },
    MuiInputBase: {
      root: {
        ...typography.body1
      }
    },
    MuiTableCell: {
      root: {
        ...typography.body1
      }
    },
    MuiOutlinedInput: {
      input: {
          padding: '14.5px 12px'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 10
      }
    },
    MuiToggleButton: {
      root: {
        border: `1px solid ${paletteType === 'light' ? actionActiveLight : actionActiveDark}`, //${alpha(theme.palette.action.active, 0.12)}
        '&$selected': {
          backgroundColor: secondaryColorMain,
        },
        textTransform: 'unset',
        color: paletteType === 'light' ? actionActiveLight : actionActiveDark,
        
      },
      sizeSmall: {
        paddingTop: 3,
        paddingBottom: 3
      }
    },
  },
  creator: {
    sideBarMenuBackGround: 'linear-gradient(0deg,#3358f4,#1d8cf8)',
  },
  storytrails: {
    paperStyle : {
      margin: 8,
      padding: 8
    },
  }
});

