import React from 'react';

import { makeStyles } from '@material-ui/styles';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { observer } from 'mobx-react'


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
  
}));

const SearchFilterSelect = observer(({ store, label, filterName, filterValues }) => {

    
  const fileAssetTypeFilterValue = store.selectObjectsState.filter[[filterName]] ? store.selectObjectsState.filter[[filterName]] : "";

  const onFileAssetFilterChange = (event) => {
    const value = event.target.value;
    console.log("filtering on ", filterName, " ", value);
    if (value === "") {
      store.setFilterPart({ [filterName]: null });
    } else {
      store.setFilterPart({ [filterName]: value });
    }
  }

  const classes = useStyles();

  const menuItems = filterValues.map( value_name => <MenuItem value={value_name[0]}>{value_name[1]}</MenuItem>)

  return <FormControl variant="outlined" className={classes.formControl}>
    <InputLabel className={classes.inputLabel} id="demo-simple-select-filled-label">{label}</InputLabel>
    <Select
      id="demo-simple-select-filled"
      className={classes.select}
      value={fileAssetTypeFilterValue}
      onChange={onFileAssetFilterChange}
    >
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      {menuItems}
    </Select>
  </FormControl>
});

export default SearchFilterSelect;