import * as React from "react";

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import MainLayout from '../layouts/Main';


const  NotfoundScreen = ()=> {

    return <MainLayout>
            <Container maxWidth="sm">
                <Box
                    border={1}
                    color="primary.main"
                    bgcolor="background.paper"
                    fontFamily="h6.fontFamily"
                    fontSize={{ xs: 'h6.fontSize', sm: 'h4.fontSize', md: 'h3.fontSize' }}
                    p={{ xs: 2, sm: 3, md: 4 }}
                >
                   Not found
        </Box>
                
            </Container>
        </MainLayout>
}

export default NotfoundScreen;