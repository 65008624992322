import React from 'react';


import TablePagination from '@material-ui/core/TablePagination';

import { observer } from 'mobx-react'



const StoreTablePagination = observer(({store})=> {

  const rowsPerPage = store.selectObjectsState.count;
  const meta  = store.selectObjectsState.meta;
  const count = meta ? meta.numFound: 0;
  const page = meta ? Math.floor( meta.start/meta.count): 0;
  
  const handlePageChange = (event, page) => {
    store.fetchPage(page)
  };


  return <TablePagination
    component="div"
    count={count}
    onChangePage={handlePageChange}
    //onChangeRowsPerPage={handleRowsPerPageChange}
    page={page}
    rowsPerPage={rowsPerPage}
    rowsPerPageOptions={[]}
  />

});

export default StoreTablePagination;