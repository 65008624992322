
import * as React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import {inputLabelStyle, formControlStyle } from 'kiini/Style';



const useStyles = makeStyles(theme => ({
    labelContainer: {
       marginBottom: 4,
       color: theme.palette.text.primary,
       flexGrow: 1
    },
    
}));

const DefaultTagRoleWidget = observer(({id, label, instance }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();
    const classes = useStyles();
 
    const value = instance ?  instance.$classDescription.verboseName: "";

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
        </InputLabel>
        <div className={classes.labelContainer}>
            <Typography variant="subtitle2" >{value}</Typography>
        </div>
    </FormControl>
});

export default DefaultTagRoleWidget;