import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MainLayout from '../layouts/Main';
import FixedToolbar from 'components/FixedToolbar'

import Box from '@material-ui/core/Box';
//import Switch from '@material-ui/core/Switch';
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExperienceCard from 'components/experiences/ExperienceCard';

import BoxHeader from 'components/BoxHeader';
import TimeLine from 'components/analytics/TimeLine'

import { observer } from 'mobx-react'

import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));



const DashboardScreen = observer(() => {
  const classes = useStyles();

  const rootStore = useRootStore();
  const routerStore = useRouterStore();
  const { homeStore } = rootStore;

  const showAnalytics = rootStore.authStore.showAnalytics;

  const onExperienceSelect = (id) => {
    routerStore.goTo('experience', { params: { id } });
  }

  const toolbar = <FixedToolbar>
  </FixedToolbar>

  const experiences = homeStore.experiences;
  const lastUpdateTrails = experiences.map(resultObject => <Grid item xs={2}><ExperienceCard onClick={onExperienceSelect} resultObject={resultObject}></ExperienceCard> </Grid>)

  const analyticsDownloadTimeseries = homeStore.analyticsDownloadTimeseries;
  const analyticsSessionTimeseries = homeStore.analyticsSessionTimeseries;

  

  return (
    <MainLayout toolbar={toolbar}>
      <div className={classes.root}>
        <div className={classes.content}>
          <Box bgcolor="background.paper" margin={2} padding={2}>
            <BoxHeader
              header="Last updates"
              subHeader="Storytrails"
            />

            <Grid component="label" container alignItems="center" spacing={1}>
              {lastUpdateTrails}
            </Grid>
          </Box>
          { showAnalytics ? <React.Fragment>
          <Box
            bgcolor="background.paper"
            margin={2}
            padding={2}
          >
            <BoxHeader
              header="Downloads"
              subHeader="Analytics"
            />
            <TimeLine  label="Trail downloads"  timeseries={analyticsDownloadTimeseries} />
          </Box>
          <Box
            bgcolor="background.paper"
            margin={2}
            padding={2}
          >
            <BoxHeader
              header="App Sessions"
              subHeader="Analytics"
            />
            <TimeLine label="App Sessions" timeseries={analyticsSessionTimeseries} />
          </Box></React.Fragment>: null}

        </div>
      </div>
    </MainLayout>
  );
});

export default DashboardScreen;
