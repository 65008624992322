import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
//import Button from '@material-ui/core/Button';

//import IconButton from '@material-ui/core/IconButton';
//import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import HelpOutline from '@material-ui/icons/HelpOutline'
import ImageIcon from '@material-ui/icons/Image';
import MapIcon from '@material-ui/icons/Map'
import TrendingUpIcon from  '@material-ui/icons/TrendingUp';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CollectionsIcon from '@material-ui/icons/Collections';
import RateReviewIcon from '@material-ui/icons/RateReview';

import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

const pages = [
    {
        title: 'Dashboard',
        routeName: "dashboard",
        icon: <DashboardIcon />
    },
    {
        title: 'Storytrails',
        routeName: "experiences",
        icon: <MapIcon />
    },
    {
        title: 'Storyblocks',
        routeName: 'storyBlocks',
        icon: <FeaturedPlayListIcon />
    },
    {
        title: 'Media',
        routeName: 'fileAssets',
        icon: <ImageIcon />
    },
    {
        title: 'App Objects',
        routeName: 'runnableObjects',
        icon: <CollectionsIcon />
    },    
    {
        title: 'Notifications',
        routeName: 'notifications',
        icon: <NotificationsIcon />
    },
    {
        title: 'Ratings',
        routeName: 'ratings',
        icon: <RateReviewIcon />
    },
    {
        title: 'Analytics',
        routeName: 'analytics',
        icon: <TrendingUpIcon />
    },
];

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerPaper: {
        width: drawerWidth, 
        background: 'unset',
        borderRight: 'unset'
    },
    toolbarSpacer: {
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    menuWrapper: {
        background: theme.creator.sideBarMenuBackGround,
        height: "100%",
        borderRadius: 4,
        margin: theme.spacing(2),
        color: theme.palette.menu.default,
        
    },
    icon: {
        minWidth: 'unset',
        marginLeft: 4,
        marginRight: 4,
        color: theme.palette.menu.default,
        // width: 24,
        // height: 24,
        // display: 'flex',
        // alignItems: 'center',
        // marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    }

}));


const hasRights = (showAnalytics, page) => {
    if (page.routeName === 'analytics'){
        return showAnalytics
    }  
    return true;
}


const SideBar = (props) => {
    const classes = useStyles();
    //const theme = useTheme();

    const routerStore = useRouterStore();
    const rootStore = useRootStore();
    const showAnalytics = rootStore.authStore.showAnalytics;


    const navigateToPage = (name)=>{
        routerStore.goTo(name);
    }

    const openDocumentation = ()=> {
        window.open("https://creatordocs.storytrails.eu/docs/intro", "creator-docs-window");
    }

    return <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }} >

        <div className={classes.toolbarSpacer}>
        </div>
       
        <div className={classes.menuWrapper} >
        <List>
            {pages.filter(page => hasRights(showAnalytics, page)).map((page) => (
                <ListItem
                    button
                    onClick={() => navigateToPage(page.routeName)}
                    className={classes.item}
                    disableGutters
                    key={page.routeName}
                >
                    <ListItemIcon className={classes.icon} >{page.icon}</ListItemIcon>
                    <ListItemText primary={<Typography variant="caption">{page.title}</Typography>}/>    
                </ListItem>
            ))}
            <ListItem
                    button
                    onClick={() => openDocumentation()}
                    className={classes.item}
                    disableGutters
                    key="documentation-link"
                >
                    <ListItemIcon className={classes.icon} ><HelpOutline/></ListItemIcon>
                    <ListItemText primary={<Typography variant="caption">Documentation</Typography>}/>    
                </ListItem>
        </List>
        </div>
    </Drawer>
}

export default SideBar;