import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton  from '@material-ui/core/IconButton';


import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'

import FixedToolbar from 'components/FixedToolbar'
import MainContentLayout from 'layouts/MainContentLayout';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

import {getStoryPointUrl} from 'model/content-object-utils';

import ActionButtons from 'kiini/ActionButtons'

import Preview from 'components/storyblocks/Preview';
import StoryBlockCommonForm from 'components/storyblocks/StoryBlockCommonForm';
import StoryBlockContentForm from 'components/storyblocks/StoryBlockContentForm';


import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
	createStyles({
		offset: theme.mixins.toolbar,
		backToOverview: {
			backgroundColor: theme.palette.primary.light
		}
	})
);


const StoryPointLink = observer(({storyBlock, sharingHost}) => {

	const url =  getStoryPointUrl(storyBlock, sharingHost); 

	const copyStoryPointLink = () =>{
		navigator.clipboard.writeText(url);
	}

	return <Box margin= {1}>
		<Typography onClick={() => copyStoryPointLink()} >{url}</Typography>
		<Box margin= {1}>
			<IconButton onClick={() => copyStoryPointLink()} aria-label="copy"  size="small">
          		<FileCopyIcon fontSize="inherit" />
       		</IconButton>
		</Box>
	</Box>
});

const StoryBlockScreen = observer((props) => {

	const classes = useStyles();
    const routerStore = useRouterStore();
    const rootStore = useRootStore();
    const { storyBlockStore, authStore } = rootStore;
	
	const storyblock = storyBlockStore.selectedObject;
	const sharingHost = authStore.sharingHost;

	console.log("storyblock sreen ", storyblock);

    const onBackToOverview = () => {
		routerStore.goTo("storyBlocks")
	}

    const backButton =  <Button
		onClick={() => onBackToOverview() }
		size="small"
		variant="text">
		<ArrowBackIcon />
			Back to Overview
		</Button>

	const toolbar = <FixedToolbar back={backButton} >
		<div>
			{storyblock ? <ActionButtons store={storyBlockStore} instance={storyblock} />: null}
		</div>
	</FixedToolbar>


	return (
		<MainContentLayout toolbar={toolbar}>
			{storyblock ?
			<Grid container>
				<Grid item xs={3}>
					<Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
						<StoryBlockCommonForm instance={storyblock} store={storyBlockStore} />
					</Box>
					{storyblock.id ?
					<Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
						 <StoryPointLink storyBlock={storyblock} store={storyBlockStore} sharingHost={sharingHost}/>
					</Box>
					 : null}
				</Grid>
				<Grid item xs={5}>
					<Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
						<StoryBlockContentForm instance={storyblock} store={storyBlockStore} />
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box bgcolor="background.paper" margin={1} padding={1}>
						<Preview storyblock={storyblock} />
					</Box>
				</Grid>
			</Grid>: null}

			<div className={classes.offset} />
		</MainContentLayout>)
});

export default StoryBlockScreen;

