import * as React from "react";

import { observer } from 'mobx-react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {inputLabelStyle, formControlStyle } from '../Style';



const MultipleChoicesField = ({instance, fieldName, id, label, path}) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const values = instance[fieldName] ? instance[fieldName]: [];
    
    const handleChange = (event) => {
        const value = event.target.name; 
        if (event.target.checked ){
            instance.addToArray(fieldName, value);
        }else{
            const index = values.indexOf(value);
            if (index >= 0){    
                instance.removeFromArray(fieldName, index);
            }
        }
    }

    const choices = instance.$classDescription.getFieldChoices(fieldName);

    const formControlLabels = choices.map( choice => <FormControlLabel
        key={choice.value}
        control={<Checkbox checked={values.indexOf(choice.value) >= 0} onChange={handleChange} name={choice.value} />}
        label={choice.label}
      />)

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        <div>
        <FormGroup>
          {formControlLabels}
        </FormGroup>
        </div>
    </FormControl>
}
export default observer(MultipleChoicesField);