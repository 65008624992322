
import { makeObservable, observable, action, runInAction } from "mobx";


export const OBJECTS_LIST_REPRESENTED_CONTENT = "OBJECTS_LIST_REPRESENTED_CONTENT_BASED";
export const OBJECTS_LIST_SAME_STRUCTURE = "OBJECTS_SAME_STRUCTURE";


export default class AbstractBaseStore {

    selectObjectsState = {
        objects: [],
        filter: {},
        sort: null,           // selected object type to add
        start: 0,
        count: 100, 
        hasMore: false,
        loaded: false,
        //pageRequested: -1  
    };

    selectedObject = null;
    showAdvancedSearch = false;


    constructor({rootStore, api, listType}) {
        this.rootStore = rootStore;
        this.api = api;
        this.listType = listType ? listType : OBJECTS_LIST_SAME_STRUCTURE;
        makeObservable(this, {
            selectObjectsState: observable,
            selectedObject: observable,
            loadObjects: action,
            selectObject: action,
            clearSelectObject: action,
            next: action,
            previous: action,
            fetchPage: action,
            setFilterPart: action,
            setSort: action,
            showAdvancedSearch: observable,
            toggleAdvancedSearch: action,
            newObject: action,
            saveSelectedObject: action, // not sure if this is needed, because we programmatically create an action
            
        });
        
    }

    loadObjects = (initialProps = {}) => {
        const {initialFilter = null} = initialProps;
        if (initialFilter){
            this.selectObjectsState.filter = {...this.selectObjectsState.filter, ...initialFilter};
        }
        this.api.getObjects(
            this.selectObjectsState.start, 
            this.selectObjectsState.count, 
            this.selectObjectsState.filter, 
            this.selectObjectsState.sort).then(
            action("loadobjects", results => {
                this.selectObjectsState.objects = results.objects;
                this.selectObjectsState.meta = results.meta;
                this.selectObjectsState.loaded = true;
                this.selectObjectsState.hasMore = results.meta.numFound > this.selectObjectsState.start + this.selectObjectsState.count
            })
            
        );
        this.afterLoadObjects();
    }

    afterLoadObjects = () => {  // rename to onLoadObjects
        // abstract template method for extention
    }

    _selectObjectAction = (objectData) => {
        this.selectedObject = this._transformJsonToObject(objectData);
    }

    _afterSelectObject = () => {
        // template method
    }

    selectObject = (id) => {
        this.api.getObject(id).then(
            action("selected Object", objectData => {
                this._selectObjectAction(objectData);
                this._afterSelectObject();
            })
        );
    }

    selectObjectAsync = async(id) => {
        const objectData = await this.api.getObject(id);
        runInAction(()=> {
            this._selectObjectAction(objectData);
            this._afterSelectObject();
        })  
        return this.selectedObject;  
    }

    _afterUnselectObject = () => {
        // template method
    }

    clearSelectObject = () => {
        this.selectedObject = null;
        this._afterUnselectObject();
    }

    fetchPage = (page) => {
        
        if (this.hasMore()){
            this.selectObjectsState.start = page * this.selectObjectsState.count ;
            this.loadObjects();
        }
    }

    next = () => {
        this.selectObjectsState.start = this.selectObjectsState.start + this.selectObjectsState.count;
        this.loadObjects();    
    }

    previous = () => {
        this.selectObjectsState.start = this.selectObjectsState.start - this.selectObjectsState.count;
        this.loadObjects();    
    }

    hasMore = () => {
        return  this.selectObjectsState.meta ? this.selectObjectsState.objects.length < this.selectObjectsState.meta.numFound : true;
    }

    setFilterPart = (filterpart) => {
        this.selectObjectsState.filter = {...this.selectObjectsState.filter, ...filterpart};
        this.selectObjectsState.start = 0;
        this.loadObjects();
    }


    setSort = (column) => {
        this.selectObjectsState.sort = {"sort": column}
        this.selectObjectsState.start = 0;
        this.loadObjects();
    }

    _deleteAction = (id) => {
        if (this.selectedObject && this.selectedObject.id === id){
            this.selectedObjected = null;
        }
        this.loadObjects();
    }

    _afterDelete = (id) => {
        // template method
    }

    _postDeleteAction = (id) => {
        // this runs in the action
    }

    delete = (id) => {
        this.api.delete(id).then(
            action("delete Object", id => {
                this._deleteAction(id);
                this._postDeleteAction(id);
            })    
        );
        this._afterDelete(id);
    }

    deleteSelectedObject = () => {
        const id = this.selectedObject.id;
        this.delete(id);
    }

    _createNewObject = (initValues) => {
        // template hook method, should be overriden in subclass.
        return null;
    }

    newObject = (initValues) => {
        this.selectedObject = this._createNewObject(initValues)
    }

    _processSavedObjectJson = (savedSelecteObjectJson) => {
        // template method
    }


    saveSelectedObject = () => {
        const selectedObjectJson = this.selectedObject.toJSON();

        this.api.save(selectedObjectJson).then(
            savedSelecteObjectJson => {
                this._processSavedObjectJson(savedSelecteObjectJson)
            }
        );
    }


    _transformJsonToObject = (objectAsJson) => {
        return objectAsJson;
    }

    _processActionSavedAsyncObject = (savedObject) => {
        runInAction(() => {
            this.selectedObject = savedObject;
            this._processSavedAsyncObject(savedObject);
        });
    }

    _processSavedAsyncObject = (savedObject) => {
        // template hook method
    }
    

    saveSelectedObjectAsync = async() => {
        const selectedObjectJson = this.selectedObject.toJSON();
        const savedSelecteObjectJson = await this.api.save(selectedObjectJson);
        const savedObject = this._transformJsonToObject(savedSelecteObjectJson);
        this._processActionSavedAsyncObject(savedObject);
        return savedObject;
    } 

    /*
      Retrieve a instance from the (search) index. For example saving an object, retrieve the
      represented object from the search index, to synchronize the object list.
    */
    _refreshFromIndex = async(id) => {
        const result = this.api.getIndexedObjects([id])
        return result;
    }

    /*
      Merge a reloaded  object synchronizing this.selectObjectsState.objects  with  one or more objectsToMerge.
    */
    _mergeChangedObjects = (objectsToMerge) => {
        objectsToMerge.objects.forEach( newObjectResult => {
            const objectIndex = this.selectObjectsState.objects.findIndex( 
                (objectResult => objectResult.instance.id === newObjectResult.instance.id)
            );
            if (objectIndex > -1){
                this.selectObjectsState.objects[objectIndex] = newObjectResult;
            }
        });
    }

    /*
      Deleting a object in this.selectObjectsState.objects, if it exists there. Use it to sync the list
      after deleting an object.
    */
    _removeDeletedObject = (id) => {
        const objectIndex = this.selectObjectsState.objects.findIndex( 
            (objectResult => objectResult.instance.id === id)
        );
        if (objectIndex > -1) {
            this.selectObjectsState.objects.splice(objectIndex, 1);
        }
    }

    toggleAdvancedSearch = () => {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

}