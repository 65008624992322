import React from 'react';

import Button from '@material-ui/core/Button';

import { observer } from 'mobx-react'

import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

//import ExperiencesTable from 'components/experiences/ExperiencesTable';
import ExperiencesTableVirtualized from 'components/experiences/ExperiencesTableVirtualized'
import FixedToolbar from 'components/FixedToolbar'
import MainLayoutVh from 'layouts/MainVh';

import TextSearch from 'components/search/TextSearch';
import ExperienceQRDialog from 'components/dialogs/ExperienceQRDialog';

const ExperiencesScreen = observer(({ store }) => {
  const rootStore = useRootStore();
  const routerStore = useRouterStore();
  const { experiencesStore, authStore } = rootStore;

  const sharingHost = authStore.sharingHost;

  const [qrDialogOpen, setQRDialogOpen] = React.useState(false);


  const handleCloseQRDialog = () => {
    setQRDialogOpen(false);
  };


  const newExperience = () => {
    routerStore.goTo('newExperience');
  }

  const onSelectObject = id => {
    routerStore.goTo('experience', { params: { id } });
  }


  const onCreateDraft = (id) => {
    experiencesStore.createDraft(id);
  }

  const onRepackage = (id) => {
    experiencesStore.repackage(id);
  }

  const onPublish = (id) => {
    experiencesStore.publish(id);
  }

  const onRemovePublished = (id) => {
    experiencesStore.removePublished(id);
  }

  const onCopy = async (id) => {
    const copiedObject = await experiencesStore.copy(id);
    routerStore.goTo('experience', { params: { id: copiedObject.id } });
  }

  const onDelete = (id) => {
    experiencesStore.delete(id);
  }

  const onSort = (column)=> {
    experiencesStore.setSort(column);
  }

  const onShowQR = ({ contentObjectId, name, organisationId, isPublished }) => {
    experiencesStore.setSelectedQrCodeData({ contentObjectId, name, organisationId, isPublished });
    setQRDialogOpen(true);
  }

  const toolbar = <FixedToolbar>
    <TextSearch store={experiencesStore} />
    <Button color="primary" variant="contained" onClick={() => newExperience()}>
      Add a Storytrail
    </Button>
  </FixedToolbar>

  const qrCodeData = experiencesStore.qrCodeData;

  return (
    <MainLayoutVh toolbar={toolbar}>
      {qrDialogOpen ? <ExperienceQRDialog open={true} handleClose={handleCloseQRDialog} sharingHost={sharingHost} {...qrCodeData} /> : null}
      <ExperiencesTableVirtualized
        store={experiencesStore}
        onSort={(column) => onSort(column)}
        onSelectObject={(id) => onSelectObject(id)}
        onCreateDraft={onCreateDraft}
        onRepackage={onRepackage}
        onPublish={onPublish}
        onCopy={onCopy}
        onDelete={onDelete}
        onRemovePublished={onRemovePublished}
        onShowQR={onShowQR}
      />
    </MainLayoutVh>
  );
});



export default ExperiencesScreen;
