import React from 'react';


import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

import FixedToolbar from 'components/FixedToolbar'
import MainLayoutVh from 'layouts/MainVh';

import TextSearch from 'components/search/TextSearch';
import StoryBlocksTableVirtualized from 'components/storyblocks/StoryBlocksTableVirtualized'

import AdvancedSearchButton from 'components/search/AdvancedSearchButton';
import TagsFilter from 'components/search/TagsFilter';



const StoryBlocksScreen = observer(() => {

  const routerStore = useRouterStore();
  const rootStore = useRootStore();
  const {storyBlocksStore} = rootStore;

  const newStoryBlock = () => {
    routerStore.goTo('newStoryBlock');
  }
  
  const onSelectObject = id => {
    routerStore.goTo('storyBlock', { params: { id } });
  }

  const onCopy = async (id) => {
    console.log("onCoyp id ", id)
    const copiedObject = await storyBlocksStore.copy(id);
    routerStore.goTo('storyBlock', { params: { id: copiedObject.id } });
  }

  const toolbar = <FixedToolbar>
      <TextSearch store={storyBlocksStore} />
      <AdvancedSearchButton store={storyBlocksStore} />
      <Button color="primary" variant="contained" onClick={() => newStoryBlock()}>Add a storyblock</Button>
    </FixedToolbar>

  return <MainLayoutVh toolbar={toolbar} >
         {storyBlocksStore.showAdvancedSearch ? 
        <Box>
          <TagsFilter store={storyBlocksStore}  tagsStore={storyBlocksStore.tags} filterName="tag"/>
          <TagsFilter 
            tagsStore={storyBlocksStore.areaTags}
            store={storyBlocksStore} 
            filterName="areas"/>
        </Box>: null}
        <StoryBlocksTableVirtualized  
          storyBlocksStore={storyBlocksStore} 
          onSelectObject={onSelectObject} 
          onCopy={onCopy}
          />
    </MainLayoutVh>;
});

export default StoryBlocksScreen;
