import React from "react";
import { observer } from 'mobx-react'

import {
    Polyline,
    Marker,
    Tooltip,
    Circle
} from "react-leaflet";


import { LatLng } from 'leaflet'
import { Icon } from 'leaflet'

import instructionMarkerImageUrl from 'icons/instruction.png';
import triggerMarkerImageUrl from 'icons/trigger.png'
import startSpotMarkerImageUrl from 'icons/start-spot.png'
import focusedUrl from 'icons/focus.png'

import {Location} from 'model/contentObjects';
import {getMapIconImageForSpotObject, getMapIconImageForContentObject, getMapIconImageForContentObjectOnClazzName} from 'utils/IconDefinitions';


const LinkedContentObjectMarker = ({ contentObjectLink, focusedContentObjectLinkId, onClick, location, spotOrContentObject }) => {

     const iconUrl = contentObjectLink.$classDescription.clazzName === "content_objects.model.SpotContentObjectLink" ?
        getMapIconImageForSpotObject({spot:spotOrContentObject, selected: true}):
        getMapIconImageForContentObject({contentObject:spotOrContentObject, selected: true});




    const iconProps = {
        iconAnchor: [16, 38],
        popupAnchor: [0, 0],
        iconSize: [32, 38],
        iconUrl,
        
    }

    const focussed = focusedContentObjectLinkId === contentObjectLink.id;

    if (focussed){
        iconProps['shadowUrl'] = focusedUrl;
        iconProps['shadowSize'] = [42, 49];
        iconProps['shadowAnchor'] = [21, 44];
    }

    const icon = new Icon(iconProps);

    const handleClick = () => {
        onClick(contentObjectLink.id);
    }

    if (location){
        return <Marker
            zIndexOffset={1000}
            position={location}
            draggable={false}
            icon={icon}
            onClick={() => handleClick()}
        />
    }else{
        console.warn("No location for marker " + contentObjectLink)
        return null;
    }

}

export const LinkedContentObjectMarkers = ({ contentObjectLinksWithLocation, focusedContentObjectLinkId, onClick }) => {

    return contentObjectLinksWithLocation.map((contentObjectLinkWithLocation, index) =>
        
        <LinkedContentObjectMarker
            onClick={onClick}
            key={index}
            focusedContentObjectLinkId={focusedContentObjectLinkId}
            location={contentObjectLinkWithLocation.location}
            spotOrContentObject={contentObjectLinkWithLocation.spotOrContentObject}
            contentObjectLink={contentObjectLinkWithLocation.contentObjectLink}
        />);
}

const DragableMarker = ({ instance, onClick, markerImageUrl }) => {


    const onDragEnd = (dragEndEvent) => {
        const newLocation = dragEndEvent.target.getLatLng();
        instance.setValue("location", new Location(null,newLocation));
    }


    const iconProps = {
        iconAnchor: [16, 38],
        popupAnchor: [0, 0],
        iconSize: [32, 38],
        iconUrl: markerImageUrl
    }
    const icon = new Icon(iconProps);

    const handleClick = () => {
        onClick(instance.id);
    }

    return <Marker
        position={instance.location}
        draggable={true}
        icon={icon}
        onDragend={onDragEnd}
        onClick={handleClick}
    />
}


export const PointMarkers = observer(({ points, onClick }) => {

    return points.map((point) =>
        <DragableMarker
            markerImageUrl={startSpotMarkerImageUrl}
            onClick={onClick}
            key={point.id}
            instance={point}
        />);
});



const InstructionMarker = ({ instruction, onClick }) => {


    const onDragEnd = (dragEndEvent) => {
        const newLocation = dragEndEvent.target.getLatLng();
        instruction.setValue("location", new Location(null, newLocation));
    }


    const iconProps = {
        iconAnchor: [16, 38],
        popupAnchor: [0, 0],
        iconSize: [32, 38],
        iconUrl: instructionMarkerImageUrl
    }
    const icon = new Icon(iconProps);

    const handleClick = () => {
        onClick(instruction.id);
    }

    return <Marker
        position={instruction.location}
        draggable={true}
        icon={icon}
        onDragend={onDragEnd}
        onClick={() => handleClick()}
    />
}


export const InstructionMarkers = observer(({ instructions, onClick }) => {

    return instructions.map((instruction) =>
        <InstructionMarker
            onClick={onClick}
            key={instruction.id}
            instruction={instruction}
        />);
});



const GeoFenceMarker = ({ geoFence, objectId, onClick }) => {

    const onDragEnd = (dragEndEvent) => {
        const newLocation = dragEndEvent.target.getLatLng();
        geoFence.setValue("location",new Location(null, newLocation));
    }

    const handleClick = () => {
        onClick(objectId);
    }

    const iconProps = {
        iconAnchor: [16, 16],
        iconSize: [32, 32],
        iconUrl: triggerMarkerImageUrl
    }
    const icon = new Icon(iconProps);

    return <Marker
        position={geoFence.location}
        draggable={true}
        onDragend={onDragEnd}
        onClick={() => handleClick()}
        icon={icon}
    >
    </Marker>
}

const GeoFenceCircle = observer(({ geoFence }) => {

    const onDragEnd = (dragEndEvent) => {
        const newLocation = dragEndEvent.target.getLatLng();
        geoFence.setValue("location",new Location(null, newLocation));
    }

    const circleCenter = [geoFence.location.lat, geoFence.location.lng];

    return <Circle
        center={circleCenter}
        draggable={true}
        onDragend={onDragEnd}
        radius={geoFence.radius}
        //icon={icon}
    >
    </Circle>
});

export const BoundMarkers = observer(({bounds}) => {
    const northEast = [bounds.ne.lat, bounds.ne.lng];
    const southWest = [bounds.sw.lat, bounds.sw.lng];
    return  <>
        <Marker position={northEast}></Marker>
        <Marker position={southWest}></Marker>
        </>
}); 


export const GeoTriggerMarkers = ({ geoTriggers, onClick }) => {

    return geoTriggers.map((geoTrigger) =>
        <GeoFenceMarker
            key={geoTrigger.id}
            geoFence={geoTrigger.geoFence}
            objectId={geoTrigger.contentObjectLinkId}
            onClick={onClick}
        />);
}

export const GeoTriggerCircles = ({ geoTriggers }) => {

    return geoTriggers.map((geoTrigger) =>
        <GeoFenceCircle
            key={geoTrigger.id}
            geoFence={geoTrigger.geoFence}

        />);
}


// const InstructionTriggerMarker = ({ instruction }) => {

//     const onDragEnd = (dragEndEvent) => {
//         const newLocation = dragEndEvent.target.getLatLng();
//         instruction.geoFence.location = newLocation;
//     }

//     const iconProps = {
//         iconAnchor: [16, 16],
//         iconSize: [32, 32],
//         iconUrl: triggerMarkerImageUrl
//     }
//     const icon = new Icon(iconProps);

//     return <Marker
//         position={instruction.geoFence.location}
//         draggable={true}
//         onDragend={onDragEnd}
//         icon={icon}
//     >
//     </Marker>
// }

export const InstructionsTriggerMarkers = observer(({ instructions, onClick }) => {

    return instructions.map((instruction) =>
        <GeoFenceMarker
            key={instruction.id}
            geoFence={instruction.geoFence}
            objectId={instruction.id}
            onClick={onClick}
        />);
});

export const InstructionsTriggerCircles = observer(({ instructions }) => {

    return instructions.map((instruction) =>
        <GeoFenceCircle
            key={instruction.id}
            geoFence={instruction.geoFence}
        />);
});



const createIcon = ({ iconUrl}) => {

    let iconProps = {
        iconAnchor: [16, 37],
        popupAnchor: [16, 18],
        iconSize: [32, 38],
        iconUrl
    }

    return new Icon(iconProps);
}


const LibraryObjectMarker = ({position, onClick, name, iconUrl}) => {
    if (position) {
        const icon = createIcon({ iconUrl})
        return <Marker
            position={position}
            //draggable={isDraggable}
            //onDragend={(dragEndEvent) => this.updatePosition(dragEndEvent, routeObject, index)}
            onClick={onClick}
            icon={icon} >
            <Tooltip>
                <div>{name}</div>
            </Tooltip>

        </Marker>
    } else {
        return null;
    }
}


export const LibraryObjectMarkers = ({libraryObjects, onClick, iconUrl}) => {

    return libraryObjects ? libraryObjects.objects.map(libraryObject => {
        const instance = libraryObject.instance;
        const iconUrl = getMapIconImageForContentObjectOnClazzName(instance.representedClazz);
        return <LibraryObjectMarker
            key={instance.id}
            name={instance.name}
            position={instance.location}
            iconUrl={iconUrl}
            onClick={() => onClick ? onClick(instance) : null}
            
        />
    }) : null;
}


const SpotMarker = ({position, onClick, name, icon}) => {
    if (position) {
        return <Marker
            position={position}
            //draggable={isDraggable}
            //onDragend={(dragEndEvent) => this.updatePosition(dragEndEvent, routeObject, index)}
            onClick={onClick}
            icon={icon} >
            <Tooltip>
                <div>{name}</div>
            </Tooltip>
        </Marker>
    } else {
        return null;
    }
}

const createSpotIcon = ({ spot, isSelected }) => {

    const iconUrl = getMapIconImageForSpotObject({spot, selected: false});

    let iconProps = {
        iconAnchor: [16, 37],
        popupAnchor: [16, 18],
        iconSize: [32, 37],
        iconUrl
    }
    return new Icon(iconProps);
}


export const SpotMarkers = ({spots, onClick}) => {
    return spots ? spots.map( spotResult => {
        const spot =  spotResult.spot;
        const icon = createSpotIcon({spot});
        return <SpotMarker
            key={spot.id}
            icon ={icon} 
            name={spot.name[0].value}
            position={spot.location}
            onClick={() => onClick ? onClick(spot) : null}
        />
    }): null;
}

const Link = ({fromLocation, toLocation, color}) => {
   
    let positions = [new LatLng(fromLocation.lat, fromLocation.lng), new LatLng(toLocation.lat, toLocation.lng)];
    return <Polyline color={color} positions={positions} />
}

export const GeoTriggerLinks = ({geoTriggerLinks}) => {
    return geoTriggerLinks ?  geoTriggerLinks.map((geoTriggerLink, index) => {
        return <Link 
            key={index}
            color={"black"}
            fromLocation={geoTriggerLink.fromLocation}
            toLocation={geoTriggerLink.toLocation}
        />
    }): null; 
}


export const InstructionLinks = observer(({instructions}) => {
    return instructions ?  instructions.map((instruction) => {

        return <Link 
            key={instruction.id}
            color={"black"}
            fromLocation={instruction.location}
            toLocation={instruction.geoFence.location}
        />
    }): null; 
});
