import * as React from "react";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { SketchPicker } from 'react-color';

import { inputLabelStyle, formControlStyle } from 'kiini/Style';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {BiXCircle} from 'react-icons/bi'


import { observer } from 'mobx-react';

import { LineStyle } from 'model/contentObjects'

const useStyles = makeStyles(theme => ({
  colorSetter: {
    marginTop: theme.spacing(1),
  },
  toolbar: {
    height: theme.spacing(4),
  }
}));

const useColorButtonStyles = makeStyles(theme => ({
  root:{
    paddingTop: 13,
  },
  contained: {
    backgroundColor: (props) => props.selectedHexColor
  }
}));


// https://www.magicpattern.design/tools/css-backgrounds
const useColorButtonStylesNoColorSelected = makeStyles(theme => ({
  root:{
    paddingTop: 13,
  },
  contained: {
    backgroundColor: '#e5e5f7',
    opacity: 0.3,
    background: 'repeating-linear-gradient( 45deg, #444cf7, #444cf7 5px, #e5e5f7 5px, #e5e5f7 25px )'
  }
}));




const ColorChooser = ({ onClick, selectedHexColor }) => {

  const selectedColorclasses = useColorButtonStyles({ selectedHexColor });
  const unselectedColorClases = useColorButtonStylesNoColorSelected();
  return <Button classes={selectedHexColor ? selectedColorclasses : unselectedColorClases} variant="contained" onClick={onClick}>
  </Button>
}


const ColorPicker = ({ id, handleColorChangeComplete, hexColor }) => {
  const classes = useStyles()

  const defaultHexColor = "#c56f30";
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeCompleteIntern = (color, event) => {
    if (handleColorChangeComplete) {
      handleColorChangeComplete(color.hex);
    }
  };

  const open = Boolean(anchorEl);

  return <React.Fragment>
    <ColorChooser onClick={handleClick} selectedHexColor={hexColor} ></ColorChooser>
    <Popover id={id} open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className={classes.toolbar}>
        <IconButton size="small" edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Box>
      <SketchPicker color={hexColor ? { hex: hexColor } : { hex: defaultHexColor }} onChangeComplete={handleChangeCompleteIntern} />
    </Popover>
  </React.Fragment>

}

const createLineStyle = (color) => {
  return new LineStyle(null, { color });
}


const LineStyleWidget = observer(({ instance, fieldName, id, label, path, formConfigurations }) => {
  const classes = useStyles()


  const formControlClasses = formControlStyle();
  const inputLabelClasses = inputLabelStyle();
  const value = instance[fieldName];

  const hexColor = value ? value.color : null;

  const handleColorChangeComplete = (hexColor) => {
    if (typeof value == 'undefined' || value == null) {
      const lineStyle = createLineStyle(hexColor);
      instance.setValue(fieldName, lineStyle);
    } else {
      value.setValue('color', hexColor);      
    }
  }

  const handleRemoveLineStyle = (evt) => {
    instance.setValue(fieldName, null);
  }

  return <FormControl fullWidth classes={formControlClasses}>
    <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>Color</InputLabel>
    <div className={classes.colorSetter}>
      <ColorPicker hexColor={hexColor} handleColorChangeComplete={handleColorChangeComplete} />
      {hexColor ? <IconButton size="small" edge="start" color="inherit" onClick={handleRemoveLineStyle} aria-label="close">
          <BiXCircle size={14}/>
        </IconButton>: null}
    </div>
  </FormControl>
});




export default LineStyleWidget;