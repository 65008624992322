import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '../Select';

import { observer } from "mobx-react";


import { inputLabelStyle, formControlStyle } from '../Style';



const SelectionField = observer(({ id, label, instance, store, fieldName, selectOptions, ...rest }) => {

  const formControlClasses = formControlStyle();
  const inputLabelClasses = inputLabelStyle();


  const field = instance.$fields.get(fieldName);
  if (field) {
    const isTouched = field.isTouched;
    const hasErrors = field.errors;
    
    const showError = isTouched && hasErrors;


    return <FormControl  error={showError}  fullWidth classes={formControlClasses}>
      <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id}
        label={label}
        fieldName={fieldName}
        instance={instance}
        selectOptions={selectOptions}
      />

    </FormControl>
  } else {
    return <div>Error: Field {fieldName} to found</div>
  }
});

export default SelectionField;