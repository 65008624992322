import { makeObservable, observable, action } from "mobx";
import { normalizedErrorToErrorDict} from '../utils';

export const SELECTED_OBJECT_STATE_UN_INITIALIZED = 'SELECTED_OBJECT_STATE_UN_INITIALIZED';
export const SELECTED_OBJECT_STATE_REQUESTING = 'SELECTED_OBJECT_STATE_REQUESTING';
export const SELECTED_OBJECT_STATE_INITIALIZED = 'SELECTED_OBJECT_STATE_INITIALIZED';
export const SELECTED_OBJECT_STATE_SAVING = 'SELECTED_OBJECT_STATE_SAVING';


class BaseStore{

    selectObjectsState = {
        objects: [],
        filter: {},
        sort: null,           // selected object type to add
        selectedElement: null,
        start: 0,
        count: 100, 
        hasMore: false,
        loaded: false
    };

    selectedObject = null;
    defaultFilter = {};

    objectState = {
        current: SELECTED_OBJECT_STATE_UN_INITIALIZED,
        error: null,
    }

    constructor({rootStore, baseApi}){
        this.baseApi = baseApi;
        this.rootStore = rootStore;
        this.intialized = false;
        makeObservable(this, {
            selectObjectsState: observable,
            selectedObject: observable,
            objectState: observable,
            loadObjects: action,
            next: action,
            previous: action,
            fetchPage: action,
            setFilterPart: action,
            selectObject: action,
            setSort: action,
            save: action,
            delete: action,
            newObject: action,
            clear: action,
        });
    }

    initialize = () => {
       if (! this.initialized){
        this.initialized = true;
        this.loadObjects();
       }
    }

    getFilter = () => {
        return {...this.defaultFilter, ...this.selectObjectsState.filter};
    }

    getSort = () => {
        return this.selectObjectsState.sort;
    }

    loadObjects = () => {
       
        const filter = this.getFilter();
        const sort = this.getSort();

        this.baseApi.getObjects(
            this.selectObjectsState.start, 
            this.selectObjectsState.count, 
            filter, 
            sort).then(
            action("loadobjects", results => {
                this.selectObjectsState.objects = results.objects;
                this.selectObjectsState.meta = results.meta;
            })
        );
    }

    fromJsonToObject = (data) => {
        // can be overridden in subclass
        return data;
    }

    doSelectObject = async(id)=> {
        const selectedObjectData =  await this.baseApi.getObject(id);
        return this.fromJsonToObject(selectedObjectData);
    }
    
    selectObject = (id) => {
        this.objectState = {current: SELECTED_OBJECT_STATE_REQUESTING, error: null};
        this.baseApi.getObject(id).then(
            action("selected Object", selectedObjectData => {
                const obj = this.fromJsonToObject(selectedObjectData);
                this.selectedObject = obj;
                this.objectState = {current: SELECTED_OBJECT_STATE_INITIALIZED, error: null};
            })
        ).catch( action("error getting object", error => {

            if (error.name ==="ObjectNotFound"){
                this.objectState.current = SELECTED_OBJECT_STATE_UN_INITIALIZED; 
                this.objectState.error = {
                    code: 404,
                    message: "object not found"
                }
            }
        } ));
    }

    fetchPage = (page) => {
        
        if (this.hasMore()){
            this.selectObjectsState.start = page * this.selectObjectsState.count ;
            this.loadObjects();
        }
    }

    next = () => {
        this.selectObjectsState.start = this.selectObjectsState.start + this.selectObjectsState.count;
        this.loadObjects();    
    }

    previous = () => {
        this.selectObjectsState.start = this.selectObjectsState.start - this.selectObjectsState.count;
        this.loadObjects();    
    }

    hasMore = () => {
        return  this.selectObjectsState.meta ? this.selectObjectsState.objects.length < this.selectObjectsState.meta.numFound : true;
    }

    setFilterPart = (filterpart) => {
        this.selectObjectsState.filter = {...this.selectObjectsState.filter, ...filterpart};
        this.selectObjectsState.start = 0;
        this.loadObjects();
    }


    setSort = (column) => {
        this.selectObjectsState.sort = {"sort": column}
        this.selectObjectsState.start = 0;
        this.loadObjects();
    }

    delete = (id) => {
        this.baseApi.delete(id).then(
            action("delete Object", id => {
                this.selectedObject = null;
                this.loadObjects();                 
            })    
        );
    }

    doCreateNewObject =  ({initValues}) => {
        // must be implemented by subclass
    }

    newObject = ({initValues}) => {
        
        this.selectedObject = this.doCreateNewObject({initValues});
        this.objectState = {current: SELECTED_OBJECT_STATE_INITIALIZED, error: null};
    }


    afterSave = () => {
        // themplate function called after saving object
    }

    save = () => {
        const instance = this.selectedObject;
        this.objectState = {current: SELECTED_OBJECT_STATE_SAVING, error: null};
        const isValid = instance.valid;
        if (isValid){
            const instanceJson = instance.toJSON();
            this.baseApi.save(instanceJson).then(
                action("saved object", savedInstance => {
                    this.selectedObject = this.fromJsonToObject(savedInstance);
                    this.afterSave()
                    this.objectState.current = SELECTED_OBJECT_STATE_INITIALIZED;
                })
            ).catch( 
                action("saved object error", error => {
                    debugger;
                    if (error.name === "ValidationErrorResponse"){
                        const normalizedErrors = error.errors;
                        const errorDict = normalizedErrorToErrorDict(normalizedErrors)
                        this.selectedObject.mergeErrors(errorDict);
                    }else{
                        throw error;
                    }
                })
            );
        }
    }

    hasUnsavedChanges = () => {
        if (this.selectedObject){
            return this.selectedObject.touched;
        };
        return false;
    }
      
    clear = () => {
        this.selectedObject = null;
        this.objectState.current = SELECTED_OBJECT_STATE_UN_INITIALIZED
    }

}

export default BaseStore;