import { makeObservable, observable, action, runInAction, computed } from "mobx";

import { experiencesApi, libraryObjectsApi, fileAssetsApi, spotsApi, contentObjectsApi } from 'Api';
import { v4 as uuidv4 } from 'uuid';

//import { isUndefined } from "lodash";

import { toMapBounds, boundToFilterPart, getBoundingBox, maxBounds, getLenghtInMeters } from 'utils/MapUtils';
import { getContentObjectLink, isAmenitySpotTypeRoleName } from 'model/content-object-utils';
import { 
    OBJECT_TYPE_INSTRUCTION, 
    OBJECT_TYPE_START_SPOT, 
    CONTENT_OBJECTS_LIST, 
    ROUTE_PART_lIST, 
    INSTRUCTION_LIST, 
    POINTS_LIST,
} from 'model/const';

import SpotFastEntryStore from 'stores/SpotFastEntryStore';


import { 
    EditableExperience, 
    Route, 
    MapBounds, 
    SpotContentObjectLink, 
    ContentObjectLink,
    GeoTrigger, 
    Instruction,
    Point,
//    TrailLengths,
    contentObjectFromJson
} from 'model/contentObjects';




//configure({ enforceActions: "never" });

export const createEditableExperience = (locale) => {
    return new EditableExperience(null, {
    locale,
    experienceInstructions: {
        id: uuidv4(),
        instructions: []
    },
    // source: {
    //     id: uuidv4(),
    //     name: "Jimbo"
    // },
    // meta: {
    //     creationTime: "2021-04-01 08:37:50",
    //     modifier: "13246",
    //     modificationTime: "2021-07-09 07:09:20",
    //     version: 1,
    //     owner: "1",
    //     creator: "1"
    // },
    isLocalisationBase: true, 
    experienceMap: {
        id: uuidv4(),

        bounds: {
            ne: {
                lng: 6.5631562,
                lat: 53.2239732
            },
            sw: {
                lng: 6.5612572,
                lat: 53.2225215
            },
        },
        mapLayers: [{
            "_clazz": "content_objects.model.MapLayerAlias",
            id: uuidv4(),
            name: "default"
        }],
        mapConfig: null,
        "_clazz": "content_objects.model.EditableExperienceMap"
    },

    })
}

export const createTriggerAndContentObjectLink = (contentObject, overriddenLocation) => {

    const newContentObjectLinkId = uuidv4();
    const contentObjectLink = new ContentObjectLink(null, {
        id: newContentObjectLinkId,
        contentObjectId: contentObject.id,
        overriddenLocation: overriddenLocation ? overriddenLocation : null
    });

    const location = overriddenLocation ? overriddenLocation : contentObject.location

    const geoTrigger =  new GeoTrigger(null, {
        id: uuidv4(),
        contentObjectLinkId: newContentObjectLinkId,
        geoFence: {
            "_clazz": "content_objects.model.GeoFenceCircle",
            id: uuidv4(),
            location: { ...location },
            radius: 30,
        }

    });
    return { geoTrigger, contentObjectLink };

}


export const createStartSpot = (latLng) => {
    return new Point(null,{
        id: uuidv4(),
        description: "",
        location: {
            "_clazz": "content_objects.model.Location",
            ...latLng
        },
        type: "mainStartPoint",
        rendition: {
            base: {
                id: "1f1cad79-38c7-4b2e-b1ba-0fb91dc77def",
                name: "original",
                contentObjectId: null,
                fileId: null,
                url: "https://api.jimbogo.com/static/content_objects/img/icons/start-spot.png",
                size: {
                    "_clazz": "content_objects.model.MediaSize",
                    width: 40,
                    height: 40
                },
                duration: null,
                type: "image"
            },
            defaultImage: {
                "_clazz": "content_objects.model.RenditionVariant",
                id: "1f1cad79-38c7-4b2e-b1ba-0fb91dc77def",
                name: "original",
                contentObjectId: null,
                fileId: null,
                url: "https://api.jimbogo.com/static/content_objects/img/icons/start-spot.png",
                size: {
                    "_clazz": "content_objects.model.MediaSize",
                    "width": 40,
                    "height": 40
                },
                duration: null,
                type: "image"
            },
            variants: {}
        }
    });
}

export const createInstruction = (latLng) => {
    return new Instruction( null,{
        id: uuidv4(),
        title: "Route-instructie",
        location: { ...latLng },
        geoFence: {
            id: uuidv4(),
            location: { ...latLng },
            radius: 30,
            "_clazz": "content_objects.model.GeoFenceCircle",
        },
        applicablePeriod: null
    });
};

export const createSpotContentObjectLink = (spot) => {


    const newContentObjectLinkId = uuidv4();
    const contentObjectLink = new SpotContentObjectLink(null, 
        {
            id:newContentObjectLinkId, 
            contentObjectId: spot.id,
            overriddenLocation: null,
        }
    );
    
    const geoTrigger = new GeoTrigger(null, {
        id: uuidv4(),
        contentObjectLinkId: newContentObjectLinkId,
        geoFence: {
            id: uuidv4(),
            location: { ...spot.location },
            radius: 30,
            "_clazz": "content_objects.model.GeoFenceCircle",
        }
    });
    return { geoTrigger, contentObjectLink };
}

export const createSpotContentObjectLinkNoTrigger = (spot) => {


    const newContentObjectLinkId = uuidv4();
    return new SpotContentObjectLink(null, 
        {
            id:newContentObjectLinkId, 
            contentObjectId: spot.id,
            overriddenLocation: null,
        }
    );
    
}

export const createGeoTriggerForContentObjectLink = (contentObjectLinkId, location) => {
    const geoTrigger = new GeoTrigger(null, {
        id: uuidv4(),
        contentObjectLinkId: contentObjectLinkId,
        geoFence: {
            id: uuidv4(),
            location: { ...location},
            radius: 30,
            "_clazz": "content_objects.model.GeoFenceCircle",
        }
    });
    return geoTrigger
}


// function validationErrorToMap(errorMap, errInner, translator){
	
// 	errorMap.clear()

//     for(let i=0; i < errInner.length; i++){

//         const { path, message } = errInner[i];
//         const el = translator ? translator(errInner[i]) : message;
//         if (errorMap.has(path)){
//             const errors = errorMap.get(path);
//             errors.push(el);
//         }else{
//             errorMap.set(path,[el])
//         }
//     }	
// }

function experienceFromJson(editableExperienceJson){
    return new EditableExperience(null, editableExperienceJson);
}


export const ADD_OBJECT_DIALOG = "ADD_OBJECT_DIALOG";
export const EDIT_STORYBLOCK_DIALOG = "EDIT_STORYBLOCK_DIALOG";
export const EDIT_FILEASSET_DIALOG = "EDIT_FILEASSET_DIALOG";
export const ADD_AMENTITY_SPOT_DIALOG = "ADD_AMENTITY_SPOT_DIALOG";


const WAY_OF_MOVEMENT_ROLE_CLAZZES = [
    'content_objects.roles.WalkingTrail',
    'content_objects.roles.CyclingTrail',
    'content_objects.roles.BoatingTrail',
    'content_objects.roles.CarTrail'
]

const checkHasWayOfMovementRoles = (contentRoles) => {
    return contentRoles.some(contentRole => WAY_OF_MOVEMENT_ROLE_CLAZZES.indexOf(contentRole.$classDescription.clazzName) >= 0)
}

const checkHasOneMainStartSpot = (points) => {
    return points && points.some(point => ((point.type === 'mainStartPoint')|| (point.type === 'secondaryStartPoint')));
}

// @todo remove in future (point.type === 'secondaryStartPoint')

const checkHasTracks = (route) => {
    return route && route.routeParts  && route.routeParts.length > 0;
}

const checkHasOneImage = (renditions) => {
    console.log("check renditions ", renditions.length)
    return renditions && renditions.length > 0 && renditions[0].defaultImage.url != null;
}

export class PublishingState{
    static WARNING = new PublishingState('WARNING');
    static ADVISE = new PublishingState('ADVISE');
    static NO_PUBLISHING = new PublishingState('NO_PUBLISHING');
 
    constructor(name) {
        this.name = name;
    }

    toString() {
        return `PublishingState.${this.name}`;
    }
}

class Check{
    
    constructor(message, state){
        this.message = message;
        this.state = state;
    }
}


export class ExperienceEditorStore {

    spotFastEntryStore = new SpotFastEntryStore();

    experienceEditorState = {
        requestedExperienceId: null,
        loadedExperienceId: null,
        objectTypeToAdd: null,           // selected object type to add
        waitForPositionOnMap: false,     // wait for click on map for the location of a new object to add
        editContext: null,
        latLatForNewLocationObject: null,
        libraryObjectsShow: false,  // show libray or show add object bar
        libraryObjectsFilter: {},
        libraryObjects: null,

        fileAssetsShow: false,
        fileAssetsFilter: {},
        fileAssets: null,

        spotsShow: false,
        spotsFilter: {},
        spots: [],

        uploadRouteTrackDialogOpen: false,
        mapBoundsFilter: null,
        showDialog: null,      // show dialog ADD_OBJECT_DIALOG,   //for a new object to add, after a location is selected
        experienceObjectsTypeNameToAdd: null,
        selectedElement: null,
    };

    experience = null;
    spotsMappedOnId = null;
    contentObjectsMappedOnId = null;
    //selectedContentObject = null;
    
    contentObjectStore = null;
    selectedContentObjectLink = null;
    
    constructor({rootStore}) {
       this.rootStore = rootStore;
        //this.experience = experience;
        makeObservable(this, {
            
            experience: observable.ref,
            //selectedContentObject: observable.ref,
            contentObjectStore: observable.ref,
            selectedContentObjectLink: observable.ref,
            experienceEditorState: observable,
            loadExperience: action,
            newObject: action,
            removeContentObjectLink: action,
            showLibraryObjectOnMap: action,
            setMapBoundsFilter: action,
            showDialogForAddingExperienceObject: action,
            startAddingLocationObjectWithDialog: action,
            stopAddingLocationObjectWithDialog: action,
            closeDialog: action,
            addContentObject: action,
            addSpot: action,
            setLocationObjectToAdd: action,
            addRoutePart: action,
            removeFromList: action,
            moveInList: action,
            saveExperience: action,
            selectElement: action,
            editSelectedContentObject: action,
            changeToCopiedContentObject: action,
            updateContentObjectFromDialog: action,
            removeTrigger: action,
            addGeoTrigger: action,
            addNewSpot: action,
            deleteSpot: action
        });

        this.errorMap = observable(new Map());
        //autorun(() => console.log("AUTORUN"));
    }


    loadExperience = (experienceId) => {
        //experienceEditorGlobalState.set(initialEditorState);
        this.experienceEditorState.requestedExperienceId = experienceId;
        experiencesApi.get(experienceId).then(
            action("loadedExperience", ({experience,spotsMappedOnId,contentObjectsMappedOnId}) => {
                // this.experience = experienceFromJson(experience);
                // this.spotsMappedOnId = spotsMappedOnId;
                // this.contentObjectsMappedOnId = contentObjectsMappedOnId;
                // const bounds = toMapBounds(this.experience.experienceMap.bounds);
                // this.experienceEditorState.mapBoundsFilter = boundToFilterPart(bounds);
                // this.experienceEditorState.loadedExperienceId = experience.id;
                this._afterLoad(experience, spotsMappedOnId, contentObjectsMappedOnId);
            })
        );
    
    }

    _afterLoad = (experience, spotsMappedOnId, contentObjectsMappedOnId) => {
        this.experience = experienceFromJson(experience);
        this.spotsMappedOnId = spotsMappedOnId;

        
        contentObjectsMappedOnId.forEach((contentObjectJson, contentId, remapedContentObjectsMappedOnId) =>{
            const contentObject = contentObjectFromJson(null, contentObjectJson)
            remapedContentObjectsMappedOnId.set(contentId, contentObject);
        }); 

        this.contentObjectsMappedOnId = contentObjectsMappedOnId;


        const unresolvedContentObjectLinkIds = [];

        // filter out not existing contentobjects
        const resolvedContentObjectLinks = this.experience.contentObjectLinks.map( contentObjectLink => {
            const spotOrContentObject = this.getObjectForContentObjectLink(contentObjectLink);
            if (spotOrContentObject){
                return contentObjectLink
            }else{
                console.log("unresolved content object link");
                unresolvedContentObjectLinkIds.push(contentObjectLink.id)
                return null;
            }
        }).filter(contentObjectLink => contentObjectLink !== null);

        this.experience.contentObjectLinks = resolvedContentObjectLinks;

        // filter out geotriggers for not existing contentobjects
        const resolvedGeoTriggers = this.experience.geoTriggers.map( geoTrigger => {
            const isUnresolved = unresolvedContentObjectLinkIds.indexOf( geoTrigger.contentObjectLinkId) >= 0;
            if (isUnresolved){
                console.log("unresolved geotrigger for content object link");
                return null;
            }
            return geoTrigger;
        }).filter(geoTrigger => geoTrigger !== null);
        
        this.experience.geoTriggers = resolvedGeoTriggers;

        const bounds = toMapBounds(this.experience.experienceMap.bounds);
        this.experienceEditorState.mapBoundsFilter = boundToFilterPart(bounds);
        this.experienceEditorState.loadedExperienceId = experience.id;

        computed(this.getChecks);
    }
    






    getChecks = () => {
        const checks = new Map();
        const contentRoles = this.experience.contentRoles;
        const hasWayOfMovementRoles = checkHasWayOfMovementRoles(contentRoles);
        if (!hasWayOfMovementRoles){
            checks.set("WAY_OF_MOVEMENT", new Check("Supply at least type trail, like walk, bike, ...", PublishingState.NO_PUBLISHING));
        }

        const hasOneStartSpot = checkHasOneMainStartSpot(this.experience.points);
        if (!hasOneStartSpot){
            checks.set("POINTS", new Check("Supply at least one main start point.", PublishingState.NO_PUBLISHING));
        }

        const hasTracks = checkHasTracks(this.experience.route);
        if (!hasTracks){
            checks.set("ROUTE", new Check("Supply at least one track.", PublishingState.NO_PUBLISHING));
        }

        const hasOneImage = checkHasOneImage(this.experience.renditions);
        if (!hasOneImage){
            checks.set("RENDITIONS", new Check("Supply at least one image.", PublishingState.NO_PUBLISHING));
        }

        return checks;
    }

    getObjectForContentObjectLink = (contentObjectLink) =>{
        const spotOrContentObject = contentObjectLink.$classDescription.clazzName === "content_objects.model.SpotContentObjectLink" ?
            this.spotsMappedOnId.get(contentObjectLink.contentObjectId):
            this.contentObjectsMappedOnId.get(contentObjectLink.contentObjectId)
        if (!spotOrContentObject){
            console.error("Not found spot or contentobject with id: ", contentObjectLink.contentObjectId);
            return null;
        }
        return spotOrContentObject;
    }

    getContentNameForContentObjectLink = (contentObjectLink) => {
        const spotOrContentObject = this.getObjectForContentObjectLink(contentObjectLink);
        if (spotOrContentObject){
            return contentObjectLink.$classDescription.clazzName === "content_objects.model.SpotContentObjectLink" ? 
                spotOrContentObject.name[0]['value'] :
                spotOrContentObject.name;
        }else{
            return "Not found object";
        }
    }

    getLocation = (contentObjectId) => {
        console.log("map ",  this.contentObjectsMappedOnId )
        const spotOrContentObject = this.contentObjectsMappedOnId.get(contentObjectId);
        if (spotOrContentObject){
            return spotOrContentObject.location;
        }
        console.error("Not found spot or contentobject with id: ", contentObjectId);
        return null;
    }

    newObject = () => {
        this.experience = createEditableExperience('nl');
        computed(this.getChecks);
    }

    saveExperience = () => {
        if(!this.experience.extendedDescription){
            this.experience.extendedDescription = null;//@todo otherwise validation fails, must be resolved
        }

        // repair lenght routeparts
        if (this.experience.route){
            for(let i=0; i < this.experience.route.routeParts.length; i++){
                if (!this.experience.route.routeParts[i].length){
                    const lenght = getLenghtInMeters(this.experience.route.routeParts[i].points);
                    this.experience.route.routeParts[i].length = lenght;
                }
            }
            
        }

        const isValid = this.experience.validate({touched:true});
        console.log("isValid ", isValid)        
        
        if(isValid){
            experiencesApi.save(this.experience).then(
                action("saved experience",  ({experience,spotsMappedOnId,contentObjectsMappedOnId}) => {
                    this._afterLoad(experience, spotsMappedOnId, contentObjectsMappedOnId);
                })
            ).catch(
                action("error saving experience", (validationErrorObject) => {
                    
                    // this.experience = validationErrorObject.validatedObject;
                    // TODO HOW TO MERGE THE ERRORS ???? validationErrorObject.errorMessages
                })
            );
        }else{
            const errors = this.experience.getErrors();
            console.log("Errors: ", errors);
        }
    }


     saveExperienceAsync = async() => {
        if(!this.experience.extendedDescription){
            this.experience.extendedDescription = null;//@todo otherwise validation fails, must be resolved
        }

        // repair length routeparts
        if (this.experience.route){
            for(let i=0; i < this.experience.route.routeParts.length; i++){
                if (!this.experience.route.routeParts[i].length){
                    const lenght = getLenghtInMeters(this.experience.route.routeParts[i].points);
                    this.experience.route.routeParts[i].length = lenght;
                }
            }
        }

        const isValid = this.experience.validate({touched: true});
        console.log("isValid ", isValid)        
        
        if(isValid){
            try{
                const {experience,spotsMappedOnId,contentObjectsMappedOnId} = await experiencesApi.save(this.experience);
                runInAction(()=>{
                    console.log("saved experience Json ", experience);
                    this._afterLoad(experience, spotsMappedOnId, contentObjectsMappedOnId);
                })
                return true;
            }catch(validationErrorObject){
                runInAction(()=>{
                    // this.experience = validationErrorObject.validatedObject;
                    // TODO HOW TO MERGE THE ERRORS ???? validationErrorObject.errorMessages
                    
                })
                return false;
            }
        }else{
            const errors = this.experience.getErrors();
            console.log("Errors: ", errors);
            return new Promise((resolve, reject) => {
                resolve(false);
            })
        }
    }

    removeContentObjectLink = (contentObjectLinkId) => {
        console.log("removing content object link with id ", contentObjectLinkId);
        const indx = this.experience.contentObjectLinks.findIndex(contentObjectLink => (contentObjectLink.id === contentObjectLinkId))
        const indxGeoTrigger = this.experience.geoTriggers.findIndex(geoTrigger => (geoTrigger.contentObjectLinkId === contentObjectLinkId))

        if (this.experienceEditorState.selectedElement &&
            this.experienceEditorState.selectedElement.id === contentObjectLinkId &&
            this.experienceEditorState.selectedElement.elementSet === CONTENT_OBJECTS_LIST
        ) {
            this.experienceEditorState.selectedElement = null;
        }

        this.experience.removeFromArray("contentObjectLinks",indx)
        if (indxGeoTrigger >= 0) {
            this.experience.removeFromArray("geoTriggers",indxGeoTrigger);
        }
    }

    getMapObjects = async (mapBoundsFilter) => {


        const filter = mapBoundsFilter;
        const sort = "asc";

        const getStoryBlocks = async () => {
            if (this.experienceEditorState.libraryObjectsShow) {
                const libraryObjects = await libraryObjectsApi.getObjects(0, 500, filter, sort);
                return libraryObjects;
            } else {
                return new Promise((resolve, reject) => resolve(null));
            }
        }

        const getSpots = async () => {
            if (this.experienceEditorState.spotsShow) {
                const data = await spotsApi.getObjects(0, 500, filter, sort);
                return data.spots;
            } else {
                return new Promise((resolve, reject) => resolve(null));
            }
        }


        const getFileAssets = async () => {
            if (this.experienceEditorState.fileAssetsShow) {
                const fileAssets = await fileAssetsApi.getObjects(0, 500, filter, sort);
                return fileAssets;
            } else {
                return new Promise((resolve, reject) => resolve(null));
            }
        }


        let [libraryObjects, fileAssets, spots] = await Promise.all([
            await getStoryBlocks(),
            await getFileAssets(),
            await getSpots(),
        ])
        return { libraryObjects, fileAssets, spots }
    }


    setMapBoundsFilter = async (mapBoundsFilter) => {
        console.log("set map bounds filter", mapBoundsFilter);

        const { libraryObjects, fileAssets, spots } = await this.getMapObjects(mapBoundsFilter);

        runInAction(()=> {
            this.experienceEditorState.libraryObjects = libraryObjects;
            this.experienceEditorState.spots = spots;
            this.experienceEditorState.fileAssets = fileAssets;
            this.experienceEditorState.mapBoundsFilter = mapBoundsFilter;
        });

    }

    showLibraryObjectOnMap = async (libraryObjectsShow) => {
        const load = this.experienceEditorState.libraryObjects === null;
        console.log("show library objects ", this.experienceEditorState.libraryObjects, " load ", load);
        this.experienceEditorState.libraryObjectsShow = libraryObjectsShow;
        if (libraryObjectsShow && load) {
            const sort = "asc";
            const filter = this.experienceEditorState.mapBoundsFilter;
            const libraryObjects = await libraryObjectsApi.getObjects(0, 500, filter, sort);
            this.experienceEditorState.libraryObjects = libraryObjects;
        }
    }

    showMediaAssetsOnMap = async (fileAssetsShow) => {
        const load = this.experienceEditorState.fileAssets === null;
        console.log("show file assets ", this.experienceEditorState.fileAssets, " load ", load);
        this.experienceEditorState.fileAssetsShow = fileAssetsShow;
        if (fileAssetsShow && load) {
            const sort = "asc";
            const filter = this.experienceEditorState.mapBoundsFilter;
            const fileAssets = await fileAssetsApi.getObjects(0, 500, filter, sort);
            this.experienceEditorState.fileAssets = fileAssets;
        }
    }

    showSpotsOnMap = async (spotsShow, options) => {
        const forceReload = options && options.forceReload ? true : false
        const load = this.experienceEditorState.spotsShow !== spotsShow || forceReload;
        console.log("show spots ", this.experienceEditorState.spots, " load ", load);
        this.experienceEditorState.spotsShow = spotsShow;
        if (this.experienceEditorState.spotsShow && load) {
            const sort = "asc";
            const filter = this.experienceEditorState.mapBoundsFilter;
            const data = await spotsApi.getObjects(0, 500, filter, sort);
            this.experienceEditorState.spots = data.spots;
        }
    }

    removeFromList = (listName, id) => {
        let indxToRemove = -1;
        switch (listName) {
            case INSTRUCTION_LIST:
                indxToRemove = this.experience.experienceInstructions.instructions.findIndex(instance => (instance.id === id));
                this.experience.experienceInstructions.removeFromArray("instructions",indxToRemove);
                break;
            case POINTS_LIST:
                indxToRemove = this.experience.points.findIndex(instance => (instance.id === id));
                this.experience.removeFromArray("points",indxToRemove);
                break;
            case CONTENT_OBJECTS_LIST:
                this.removeContentObjectLink(id);
                break;
            case ROUTE_PART_lIST:
                indxToRemove = this.experience.route.routeParts.findIndex(instance => (instance.id === id));
                this.experience.route.removeFromArray("routeParts",indxToRemove);
                break
            default:
                console.error("unknown listname");
                throw new Error("unknown listname ", listName);
        }

        if (this.experienceEditorState.selectedElement &&
            this.experienceEditorState.selectedElement.id === id &&
            this.experienceEditorState.selectedElement.elementSet === listName
        ) {
            this.experienceEditorState.selectedElement = null;
            this.contentObjectStore = null;                       

        }

    }

    moveInList = (listName, from, to) => {
        switch (listName) {
            case INSTRUCTION_LIST:
                this.experience.experienceInstructions.moveItemInArray("instructions",from, to);
                break;
            case POINTS_LIST:
                this.experience.moveItemInArray("points", from, to);
                break;
            case CONTENT_OBJECTS_LIST:
                this.experience.moveItemInArray("contentObjectLinks", from, to);
                break;
            case ROUTE_PART_lIST:
                this.experience.route.moveItemInArray("routeParts", from, to);
                break
            default:
                console.error("unknown listname")
        }

    }

    showDialogForAddingExperienceObject = (experienceObjectsTypeNameToAdd) => {
        console.log("showDialogForAddingExperienceObject for ", experienceObjectsTypeNameToAdd);
        this.experienceEditorState.experienceObjectsTypeNameToAdd = experienceObjectsTypeNameToAdd;
        this.experienceEditorState.showDialog = ADD_OBJECT_DIALOG;

    }

    startAddingLocationObjectWithDialog = (locationObjectsTypeNameToAdd) => {
        this.experienceEditorState.experienceObjectsTypeNameToAdd = locationObjectsTypeNameToAdd;
        this.experienceEditorState.waitForPositionOnMap = true;
        this.experienceEditorState.selectedElement = null;
    }

    stopAddingLocationObjectWithDialog = () => {
        this.experienceEditorState.waitForPositionOnMap = false;
        this.experienceEditorState.selectedElement = null;
        this.experienceEditorState.latLatForNewLocationObject = null;
        this.experienceEditorState.showDialog = null;
        this.experienceEditorState.experienceObjectsTypeNameToAdd = null;
    }

    closeDialog = () => {
        this.experienceEditorState.showDialog = null;
    }

    _addContentObject = (contentObject, overriddenLatLng) => {
        this.contentObjectsMappedOnId.set(contentObject.id, contentObject);
        const { geoTrigger, contentObjectLink } = createTriggerAndContentObjectLink(contentObject, overriddenLatLng);
        
        this.experience.addToArray("contentObjectLinks",contentObjectLink);
        this.experience.addToArray("geoTriggers",geoTrigger);
        
        this.experienceEditorState.waitForPositionOnMap = false;
        this.experienceEditorState.latLatForNewLocationObject = null;
        this.experienceEditorState.showDialog = null;
        this.experienceEditorState.experienceObjectsTypeNameToAdd = null;
        this.selectElement({ id: contentObjectLink.id, elementSet: CONTENT_OBJECTS_LIST });
    }

    updateContentObjectFromDialog = (changedContentObject) => {
        this.experienceEditorState.showDialog = null;
        this.contentObjectsMappedOnId.set(changedContentObject.id,changedContentObject);
        debugger;
        //experience.contentObjectLinks.map

    }

    addContentObject = (libraryObject) => {

        const useOverriddenLocation = (selectedLatLng, libraryObject) => {
            const sameLocation = 
                libraryObject.location && 
                libraryObject.location.lat === selectedLatLng.lat &&
                libraryObject.location.lng === selectedLatLng.lng ? true: false;
            return sameLocation ? null: {
                lat: this.experienceEditorState.latLatForNewLocationObject.lat,
                lng: this.experienceEditorState.latLatForNewLocationObject.lng
            }    
        }


        const overriddenLatLng = this.experienceEditorState.latLatForNewLocationObject ? 
            useOverriddenLocation(this.experienceEditorState.latLatForNewLocationObject, libraryObject)
            : null;
        console.log("add contentobject overriddenLatLng ", overriddenLatLng);

        const contentObjectLinks = this.experience.contentObjectLinks;

        const indx = contentObjectLinks.findIndex(item => (item.contentObjectId === libraryObject.id))
        //console.log("add contentobject indx ", indx, "libraryObject ", libraryObject);
        // maybe check
        if (indx < 0 || overriddenLatLng) {

            console.log("loading libaryObject ", libraryObject);

            // @todo check if we can add it immediate
            contentObjectsApi.get(libraryObject.id).then(
                action("loading content object to add",  (contentObjectJson) => {
                    const contentObject = contentObjectFromJson(null, contentObjectJson); 
                    this._addContentObject(contentObject, overriddenLatLng);                      
                })
            )
            
        }
    }


    addSpot = (spot, options) => {
        const contentObjectLinks = this.experience.contentObjectLinks;
        const indx = contentObjectLinks.findIndex(item => (item.contentObjectId === spot.id))
        if (indx < 0) {
            
            this.spotsMappedOnId.set(spot.id, spot);

            const withTrigger = options ? options.withTrigger : false;

            if (withTrigger){

                const { geoTrigger, contentObjectLink } = createSpotContentObjectLink(spot);
                this.experience.addToArray("contentObjectLinks",contentObjectLink);
                this.experience.addToArray("geoTriggers",geoTrigger);
                this.experienceEditorState.selectedElement = { id: contentObjectLink.id, elementSet: CONTENT_OBJECTS_LIST };
            }else{
                const contentObjectLink = createSpotContentObjectLinkNoTrigger(spot);
                this.experience.addToArray("contentObjectLinks",contentObjectLink);
                this.experienceEditorState.selectedElement = { id: contentObjectLink.id, elementSet: CONTENT_OBJECTS_LIST };
            }
            
            this.experienceEditorState.waitForPositionOnMap = false;
            this.experienceEditorState.latLatForNewLocationObject = null;
            this.experienceEditorState.showDialog = null;
            this.experienceEditorState.experienceObjectsTypeNameToAdd = null;
            
        }
    }

    addNewSpot = async() => {

        const saved = await this.spotFastEntryStore.saveAsync();
        if (saved){

            runInAction(() => {
                const spot = this.spotFastEntryStore.selectedObjectJson;
                this.addSpot(spot, {withTrigger: false});
                this.showSpotsOnMap(true,{forceReload: true});// trigger reload
            })

        }
    }

    _refreshMap = () => {
        runInAction(() => {
            this.showSpotsOnMap(true,{forceReload: true}); // trigger reload
        })
    }

    deleteSpot = async(contentObjectLinkId) => {
        // remove link
        const contentObjectLink = this.experience.contentObjectLinks.find(contentObjectLink => (contentObjectLink.id === contentObjectLinkId))
        
        const spotId = contentObjectLink.contentObjectId;

        this.removeContentObjectLink(contentObjectLinkId); // this also removes geotrigger
        await this.spotFastEntryStore.delete(spotId);
        setTimeout(() => this._refreshMap(), 1000);  // DIRTY HACK TO RESOLVE PROBLEM SOLR INDEX CACHE
    }



    setLocationObjectToAdd = (latLng) => {

        
        if (this.experienceEditorState.experienceObjectsTypeNameToAdd === OBJECT_TYPE_INSTRUCTION) {
            
            const instruction = createInstruction({ lat: latLng.lat, lng: latLng.lng });
            this.experience.experienceInstructions.addToArray("instructions",instruction);

            this.experienceEditorState.selectedElement = { id: instruction.id, elementSet: INSTRUCTION_LIST };
            this.experienceEditorState.experienceObjectsTypeNameToAdd = null;
            this.experienceEditorState.waitForPositionOnMap = false;
        } else if (this.experienceEditorState.experienceObjectsTypeNameToAdd === OBJECT_TYPE_START_SPOT) {
            const startSpot = createStartSpot({ lat: latLng.lat, lng: latLng.lng });
            
            this.experience.addToArray("points",startSpot);

            this.experienceEditorState.selectedElement = { id: startSpot.id, elementSet: POINTS_LIST };
            this.experienceEditorState.experienceObjectsTypeNameToAdd = null;
            this.experienceEditorState.waitForPositionOnMap = false;
        } else if ( isAmenitySpotTypeRoleName(this.experienceEditorState.experienceObjectsTypeNameToAdd)) {
            this.experienceEditorState.latLatForNewLocationObject = latLng;
            this.spotFastEntryStore.newObject([this.experienceEditorState.experienceObjectsTypeNameToAdd], latLng);
            this.experienceEditorState.showDialog = ADD_AMENTITY_SPOT_DIALOG;
        } else {
            this.experienceEditorState.latLatForNewLocationObject = latLng;
            this.experienceEditorState.showDialog = ADD_OBJECT_DIALOG;
        }
    }

    addRoutePart = (routePart) => {
        if (!this.experience.route) {
            const newRoute = new Route(null, {id: uuidv4(),routeParts: []});
            this.experience.setValue("route", newRoute);
            //const length = getLenghtInMeters(routePart.points);
            //this.experience.addToArray("contentRoles",new TrailLengths(null, {id: uuidv4(), lengths:[routePart.length]}));
            
        }
        this.experience.route.addToArray("routeParts",routePart);
        if (this.experience.points.length === 0){
            const startSpot = createStartSpot({lat: routePart.points[0], lng: routePart.points[1]});
            startSpot.description = "Start";
            this.experience.addToArray("points",startSpot);
        }

        let boundingBox = null;
        let maxBoundingBox = null;
        for(let i=0; i < this.experience.route.routeParts.length; i++){
            boundingBox = getBoundingBox(this.experience.route.routeParts[i].points);
            maxBoundingBox = maxBounds(boundingBox, maxBoundingBox);
        }
        this.experience.experienceMap.setValue("bounds", new MapBounds(null, maxBoundingBox));
    }

    removeRoutePart = (routePartId) => {
        const indx = this.experience.route.routeParts.findIndex(item => (item.id === routePartId))

        if (indx >= 0) {
            this.experience.route.removeFromArray("routeParts",indx);
        }
    }

    selectElement = ({ elementSet, id }) => {
        this.experienceEditorState.selectedElement = { elementSet, id };
        if (elementSet === CONTENT_OBJECTS_LIST){
            const contentObjectLink = getContentObjectLink(this.experience, id)
            const clazzName = contentObjectLink.$classDescription.clazzName;
            if (clazzName === "content_objects.model.ContentObjectLink"){
                const contentObjectId = contentObjectLink.contentObjectId;
                contentObjectsApi.get(contentObjectId).then(
                    action("retrieved selected content object",  (contentObjectJson) => {
                        const contentObject = contentObjectFromJson(null, contentObjectJson);   
                        const contentObjectStore = this.rootStore.createContentObjectStore();
                        contentObjectStore.setSelectedObject(contentObject);
                        this.contentObjectStore = contentObjectStore;
                        this.selectedContentObjectLink = contentObjectLink;
                    })
                )
            }else{
                this.contentObjectStore = null;
                this.selectedContentObjectLink = null;

            }
        }else{
            this.contentObjectStore = null;
            this.selectedContentObjectLink = null;
        }
    }

    editSelectedContentObject = (editDialogType) => {
        this.experienceEditorState.showDialog = editDialogType;
    }

    changeToCopiedContentObject = () => {
        this.experienceEditorState.showDialog = null;
        const newIdContentObject = this.contentObjectStore.selectedObject.id;
        this.selectedContentObjectLink.setValue('contentObjectId',newIdContentObject);
        this.contentObjectsMappedOnId.set(newIdContentObject, this.contentObjectStore.selectedObject);
    }

    removeTrigger = (geoTriggerId) => {
        const indx = this.experience.geoTriggers.findIndex(geoTrigger => (geoTrigger.id === geoTriggerId));
        if(indx >= 0){
            return this.experience.removeFromArray("geoTriggers",indx);
        }
    }

    addGeoTrigger = (contentObjectLink, location) => {
        const geoTrigger = createGeoTriggerForContentObjectLink(contentObjectLink, location);
        this.experience.addToArray("geoTriggers",geoTrigger);
    }
}


