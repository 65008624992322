import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { observer } from "mobx-react";


import {inputLabelStyle, formControlStyle } from '../Style';

const BooleanField = ({id, label, instance, store, fieldName }) => {
    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const onChange = (evt) => {
        const value = evt.target.checked;
        instance.setValue(fieldName, value );
    } 

    // const onBlur = (evt) => {
    //     console.log("blurred ", fieldName )
    // }

    const checked = instance[fieldName] ? instance[fieldName]: false;
    
    return <FormControl fullWidth classes={formControlClasses}>
            <InputLabel classes={inputLabelClasses} htmlFor={id}>
                {label}
            </InputLabel>
            <Checkbox checked={checked} onChange={onChange} />
    </FormControl>
}


export default observer(BooleanField);