import React from 'react';

import moment from 'moment';
import { observer } from 'mobx-react'

import ObjectsVirtualizedTable, {createDefaultCellRenderer} from 'components/ObjectsVirtualizedTable';



const NotificationsTableVirtualized = observer(({ store, onSort, onSelectObject, className, ...rest }) => {

    const results = store.selectObjectsState.objects;
    
    const getRowData = ({ index }) => {
        const obj = results[index];
        const title = obj.title;
        const id = obj.id;
        const publishStartDateTime = moment(obj.publishStartDateTime).format('DD/MM/YYYY HH:mm');
        const publishEndDateTime = obj.publishEndDateTime ? moment(obj.publishEndDateTime).format('DD/MM/YYYY HH:mm') : "";
        const contentObjectName = obj.publishedContentObject ? obj.publishedContentObject.name : ""
        return { id, title, contentObjectName, publishStartDateTime, publishEndDateTime, obj }
    }

    const sort = (column) => {
        if (onSort) {
            onSort(column)
        }
    }

    const columns = [
        {
            width: 400,
            label: 'Title',
            dataKey: 'title',
            sort: () => sort('name')
        },
        {
            width: 400,
            label: 'Name content',
            dataKey: 'contentObjectName',
            // sort: () => sort('name') 
        },
        {
            width: 200,
            label: 'Publish start date',
            dataKey: 'publishStartDateTime',
        },
        {
            width: 200,
            label: 'Publish end date',
            dataKey: 'publishEndDateTime',
        },
        {
            width: '100%',
            label: 'Actions',
            dataKey: 'id',
        },
    ]
    
    const onDelete = (objectId) => {
        if (window.confirm("Do you really want to delete your this notification?")) {
            store.delete(objectId);
        }
    }

    const onSelectRow = (rowData)=> {
        onSelectObject(rowData.obj.id);
    }

    const cellRenderer = createDefaultCellRenderer({ columns, actionColumnIndex: 4, onDelete, onSelectRow});


    return  <ObjectsVirtualizedTable 
        store={store} 
        columns={columns} 
        getRowData={getRowData}
        cellRenderer={cellRenderer}
    />

    
});

export default NotificationsTableVirtualized;