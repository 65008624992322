import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';

//import AppBar from './components/AppBar';
import SideBar from 'components/SideBar'


// function Alert(props) {
// 	return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    // toolbar: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'flex-end',
    //     padding: theme.spacing(0, 1),
    //     ...theme.mixins.toolbar,
    // },
    content: {
        flexGrow: 1,
    },
    contentMain: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
    }
}));




const MainAppLayout = (props) => {
    const { children, toolbar } = props;
    
    const classes = useStyles();

    
    // const snackBarOpen = editorMessage ? true: false;
    // const snackBarMessage = editorMessage ? editorMessage.message: "";
    // const snackBarSeverity = editorMessage ? editorMessage.severity: "warning";
    
    // const [open, setOpen] = React.useState(false);

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    // const handleSnackBarClose = () => {
    //     dispatch(removeEditorMessage())
    // }

    return (
        <div className={classes.root}>
            <SideBar/>
            <main className={classes.content}>
                {toolbar ? toolbar: null}
                <div className={classes.contentMain}>
                    {children}
                </div>
            </main>
        </div>
    );
}

export default MainAppLayout;


// Use of a global message
// <main className={classes.content}>
// <div className={classes.toolbar} />
// {editorMessage ? 
// <Snackbar
//     anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//     open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackBarClose}>
//     <Alert onClose={handleSnackBarClose} severity={snackBarSeverity}>
//         {snackBarMessage}
//     </Alert>
// </Snackbar>: null}
// {children}
// </main>