import React from 'react'

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/RemoveCircle';
import EditIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import DeleteForEverIcon from '@material-ui/icons/DeleteForever'

import { makeStyles } from '@material-ui/styles';


import { 
    CONTENT_OBJECTS_LIST, 
    ROUTE_PART_lIST, 
    INSTRUCTION_LIST, 
    ROUTE_GENERAL_TYPE, 
    ROUTE_LENGHT_DURATIONS_TYPE,
    ROUTE_AUDIENCE_TYPE,
    ROUTE_THEMES_TYPE,
    ROUTE_PROPERTIES_TYPE, 
    POINTS_LIST,
    EDITABLE_EXPERIENCE_MAP_TYPE 
} from 'model/const';


import { observer } from 'mobx-react'

import ExperienceGeneralForm from './ExperienceGeneralForm';
import ExperiencePointForm from './ExperiencePointForm';
import ExperienceTrackForm from './ExperienceTrackForm';
import InstructionForm from './InstructionForm';
import GeoTriggerForm from './GeoTriggerForm';
import EditableExperienceMapForm from './EditableExperienceMapForm';
import ExperienceRolesForm from 'components/contentRoles/ExperienceRolesForm';
import AudienceRolesForm from 'components/contentRoles/AudienceRolesForm';
import ThemeRolesForm from 'components/contentRoles/ThemeRolesForm';
import LenghtDurationRolesForm from 'components/contentRoles/LengthDurationRolesForm';

import Preview from 'components/storyblocks/Preview';
import GroupPanel from 'kiini/GroupPanel';

import { EDIT_STORYBLOCK_DIALOG, EDIT_FILEASSET_DIALOG } from 'stores/ExperienceEditorStore';
import { getContentObjectLink, getGeoTrigger, isSpotContentObjectLink, hasOneRoleAmenitySpotType} from 'model/content-object-utils';
// isAmenitySpotType,


import { Player } from 'video-react';
import AudioPlayer from 'react-h5-audio-player';
import 'video-react/dist/video-react.css';
import 'react-h5-audio-player/lib/styles.css';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 4,
        marginRight: 4,
        justifyContent: 'flexStart',
        //overflowY: 'scroll',
        //scrollPaddingTop: '24px',
        //marginBottom: 24
    }
}));


const DeleteAmenityButton = ({ id, experienceEditorStore }) => {
    const remove = () => {
        experienceEditorStore.deleteSpot(id)
    }
    return <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={remove}
            >
                <DeleteForEverIcon />
            </IconButton>
}


const RemoveButton = ({ id, listName, experienceEditorStore }) => {
    const remove = () => {
        experienceEditorStore.removeFromList(listName, id)
    }
    return <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={remove}
            >
                <DeleteIcon />
            </IconButton>
}


const EditButton = ({experienceEditorStore, editDialogType}) => {
    const edit = () => {
        experienceEditorStore.editSelectedContentObject(editDialogType)
    }
    return <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={edit}
            >
                <EditIcon />
            </IconButton>
}


const CompoundContentObjectPreview = ({contentObject}) => {
    return <Preview storyblock={contentObject} />
}

const ImagePreview = ({contentObject}) => {
    // if a image isn't selected yet the defaultImage is empty
    const rendition = contentObject.renditions[0]
    const imageUrl =  rendition.defaultImage ? rendition.defaultImage.url : null;
    return <img alt="" style={{ width: "100%" }} src={imageUrl} />     
}

const AudioPreview = ({contentObject}) => {
    console.log("audio element renderer")
    const rendition = contentObject.renditions[0]
    const src = rendition.base.url;
    return <AudioPlayer src={src} />
}


const VideoPreview = ({contentObject}) => {
    const rendition = contentObject.renditions[0]
    const imageUrl =  rendition.defaultImage.url;
    const src = rendition.base.url;
    if (src){
        return <Player
            poster={imageUrl}
            src={src} />
    }else{
        return <div>No video choosen yet</div>
    }
}



const contentObjectPreviewMapping = {
    "content_objects.model.CompoundContentObject": CompoundContentObjectPreview,
    "content_objects.model.Image": ImagePreview,
    "content_objects.model.Audio": AudioPreview,
    "content_objects.model.Video": VideoPreview,
}

const ContentObjectPreview = ({contentObject}) => {

    
    const clazzName = contentObject.$classDescription.clazzName;

    const PreviewClazz = contentObjectPreviewMapping[clazzName];
    if (PreviewClazz){
        return  <PreviewClazz contentObject={contentObject} />
    }else{
        return <div>No preview yet</div>
    }

}

const AddGeoTrigggerForm = observer(({contentObjectLinkId, location, experienceEditorStore}) => {
    const handleClick = (evt) => {
        if (location){
            experienceEditorStore.addGeoTrigger(contentObjectLinkId, location);
        }
    }
    return <Box>
        <GroupPanel label="Add geotrigger"/>
        <Button onClick={handleClick}>Add Trigger</Button>
    </Box>

});

const ContentObjectLinkEditor = observer(({ id, experienceEditorStore }) => {
    // const classes = useStyles();

    const contentObjectLink = getContentObjectLink(experienceEditorStore.experience, id);
    const name = experienceEditorStore.getContentNameForContentObjectLink(contentObjectLink);
    const geoTrigger = getGeoTrigger(experienceEditorStore.experience, contentObjectLink.id);
    const spotOrContentObject = experienceEditorStore.getObjectForContentObjectLink(contentObjectLink);
    const location = spotOrContentObject ? spotOrContentObject.location: null;

    const itsSpotContentObjectLink = isSpotContentObjectLink(contentObjectLink);

    const isAmenitySpot = itsSpotContentObjectLink && hasOneRoleAmenitySpotType(spotOrContentObject.roles); 

    const selectedContentObject = experienceEditorStore.contentObjectStore ? experienceEditorStore.contentObjectStore.selectedObject:null;

    const showEdit = selectedContentObject ? true: false; 

    const editDialogType = selectedContentObject && selectedContentObject.$classDescription.clazzName === "content_objects.model.CompoundContentObject" ? 
        EDIT_STORYBLOCK_DIALOG: EDIT_FILEASSET_DIALOG;

    return <React.Fragment>
                <Typography variant="button" display="inline">
                    {name}
                </Typography>
                <RemoveButton id={id} listName={CONTENT_OBJECTS_LIST} experienceEditorStore={experienceEditorStore} />
                {isAmenitySpot ? <DeleteAmenityButton id={id} experienceEditorStore={experienceEditorStore} />: null}
                {showEdit ?  <EditButton  editDialogType={editDialogType} experienceEditorStore={experienceEditorStore} /> : null}
                {geoTrigger ? 
                    <GeoTriggerForm instance={geoTrigger} experienceEditorStore={experienceEditorStore} /> : 
                    <AddGeoTrigggerForm contentObjectLinkId={contentObjectLink.id} location={location} experienceEditorStore={experienceEditorStore}/>
                    }
                {selectedContentObject ? 
                    <Box marginTop={4}>
                    <GroupPanel label="Preview"/>
                    <Box marginTop={1} marginRight={1} marginLeft={1} marginBottom={1}>
                        <ContentObjectPreview contentObject={selectedContentObject} />
                    </Box>
                </Box> : null}
        </React.Fragment>
});

const InstructionEditor = observer(({ id, experienceEditorStore }) => {
    const instructionIndx = experienceEditorStore.experience.experienceInstructions.instructions.findIndex(instruction => instruction.id === id);
    const instruction = experienceEditorStore.experience.experienceInstructions.instructions[instructionIndx];

    return <React.Fragment>
        <RemoveButton id={id} listName={INSTRUCTION_LIST} experienceEditorStore={experienceEditorStore} />
        <InstructionForm instance={instruction} />
    </React.Fragment>
});

const TrackEditor = observer(({ id, experienceEditorStore }) => {
    const indx = experienceEditorStore.experience.route.routeParts.findIndex(routePart => routePart.id === id);
    const routePart = experienceEditorStore.experience.route.routeParts[indx];

    return  <React.Fragment>
        <RemoveButton id={id} listName={ROUTE_PART_lIST} experienceEditorStore={experienceEditorStore} />
        <ExperienceTrackForm instance={routePart} />
    </React.Fragment>

});

const PointEditor = observer(({ id, experienceEditorStore }) => {

    const indx = experienceEditorStore.experience.points.findIndex(point => point.id === id);
    const point = experienceEditorStore.experience.points[indx];

    return <React.Fragment>
        <RemoveButton id={id} listName={POINTS_LIST} experienceEditorStore={experienceEditorStore} />
        <ExperiencePointForm instance={point}  />
    </React.Fragment>

});


const ExperienceGeneralEditor = observer(({ id, experienceEditorStore }) => {
    return <ExperienceGeneralForm instance={experienceEditorStore.experience} />
});

const AudienceRolesEditor = observer(({ id, experienceEditorStore }) => {
    return <AudienceRolesForm instance={experienceEditorStore.experience} />
});

const ThemeRolesEditor = observer(({ id, experienceEditorStore }) => {
    return <ThemeRolesForm instance={experienceEditorStore.experience} />
});

const ExperienceRolesEditor = observer(({ id, experienceEditorStore }) => {
    return <ExperienceRolesForm instance={experienceEditorStore.experience} />
});

const LengthDurationsEditor = observer(({ id, experienceEditorStore }) => {
    return <LenghtDurationRolesForm instance={experienceEditorStore.experience} />
});


const EditableExperienceMapEditor = observer(({ id, experienceEditorStore })=> {
    return <EditableExperienceMapForm  instance={experienceEditorStore.experience.experienceMap} />
});

const TODO = ({ id, experienceEditorStore }) => {
    const classes = useStyles();
    return <div className={classes.root}>
        Unknown set
    </div>
}

const editorTypes = {
    [CONTENT_OBJECTS_LIST]: ContentObjectLinkEditor,
    [ROUTE_PART_lIST]: TrackEditor,
    [INSTRUCTION_LIST]: InstructionEditor,
    [POINTS_LIST]: PointEditor,
    [ROUTE_GENERAL_TYPE]: ExperienceGeneralEditor,
    [ROUTE_LENGHT_DURATIONS_TYPE]: LengthDurationsEditor,
    [ROUTE_AUDIENCE_TYPE]: AudienceRolesEditor,
    [ROUTE_THEMES_TYPE]:  ThemeRolesEditor,
    [ROUTE_PROPERTIES_TYPE]: ExperienceRolesEditor,
    [EDITABLE_EXPERIENCE_MAP_TYPE]: EditableExperienceMapEditor,
}

const getEditor = (selectedElement) => {
    const elementSet = selectedElement.elementSet;
    const Editor = editorTypes[elementSet];
    return Editor ? Editor : TODO;
}

const ObjectEditPanel = observer(({ experienceEditorStore }) => {
    const classes = useStyles();

    const experienceEditorState = experienceEditorStore.experienceEditorState;
    const Editor = experienceEditorState.selectedElement ? getEditor(experienceEditorState.selectedElement): null;

    return (
        <div className={classes.root}>
            {experienceEditorState.selectedElement ? <Editor id={experienceEditorState.selectedElement.id}
                experienceEditorStore={experienceEditorStore} />: null}
        </div>
    )
    
});

export default ObjectEditPanel;