import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import Button from '@material-ui/core/Button';



import DialogTransition from './DialogTransition';


import { observer } from 'mobx-react'


import TextField from 'kiini/TextField';

import { 
    OBJECT_TYPE_PARKING_SPOT,
    OBJECT_TYPE_BUS_STOP,
    OBJECT_TYPE_RAILWAY_STATION,
 
} from 'model/const';

const ROLE_CLAZZ_TO_LABEL = {
    [OBJECT_TYPE_PARKING_SPOT]: "Parking place",
    [OBJECT_TYPE_BUS_STOP]:  "Busstop",
    [OBJECT_TYPE_RAILWAY_STATION]: "Railway station",
}

const SpotAmentiesDialog = observer(({ open, handleClose, handleCancel, experienceEditorStore }) => {


    const selectedRoleClazz = experienceEditorStore.spotFastEntryStore.selectedRoleClassNames[0];
    const label= ROLE_CLAZZ_TO_LABEL[selectedRoleClazz];


    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={DialogTransition}>
            <DialogTitle id="form-dialog-title">Add a {label}</DialogTitle>
            <DialogContent>
                <TextField 
                    label="Name" 
                    instance={experienceEditorStore.spotFastEntryStore.selectedObject.name[0]}
                    fieldName="value" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClose} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
});


export default SpotAmentiesDialog;

