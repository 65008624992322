import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import TabPanel from 'components/TabPanel';

import UploadTrackPanel from 'components/fileAssets/UploadTrackPanel';
import UploadShapePanel from 'components/fileAssets/UploadShapePanel';

import Slide from '@material-ui/core/Slide';



const useStyles = makeStyles(theme => ({
  appBar: {
    //position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});




export default function UploadTracksDialog(props) {

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = React.useState('one-file');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }

  return (
    <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleCancel} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Upload track(s)
          </Typography>
        </Toolbar>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="select search or upload">
          <Tab value="one-file" label="Gpx, Kml" />
          <Tab value="shape" label="Shape" />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index="one-file">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <UploadTrackPanel authStore={props.authStore} onReceiveRouteJson={props.onReceiveRouteJson} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTab} index="shape">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <UploadShapePanel authStore={props.authStore} onReceiveRouteJson={props.onReceiveRouteJson} />
          </Grid>
        </Grid>
      </TabPanel>
    </Dialog>
  );
}