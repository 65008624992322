//import React from 'react';
import {
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';


const BaseInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        //marginTop: theme.spacing(3),
      },
      //width: "100%",
      "&.Mui-error input": {
        borderColor: theme.palette.error.main,
      },
      "&.Mui-error textarea": {
        borderColor: theme.palette.error.main,
      },
    },
    input: {
      borderRadius: 8,
      position: 'relative',
      color: theme.palette.text.primary,
      backgroundColor: 'transparant',
      border: '1px solid',
      borderColor: theme.palette.inputsBorderColor,
      fontSize: 12,
      //width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      // fontFamily: [
      //   '-apple-system',
      //   'BlinkMacSystemFont',
      //   '"Segoe UI"',
      //   'Roboto',
      //   '"Helvetica Neue"',
      //   'Arial',
      //   'sans-serif',
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"',
      // ].join(','),
      '&:focus': {
        //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.focusedBorderColor,
      },
    },
  }),
)(InputBase);


export default BaseInput; 
