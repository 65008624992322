import * as React from "react";

import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from '@material-ui/core/styles';


import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoIosRemoveCircleOutline } from "react-icons/io";

import { observer } from "mobx-react";

import GroupPanel from 'kiini/GroupPanel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
    addPanel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}));


const OptionalOneOfField = observer(({ instance, store, fieldName, label, path, formConfigurations, creationOptions, createItem }) => {

    const classes = useStyles()
    const value = instance[fieldName];

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        if (creationOptions) {
            // show menu for choice 
            setAnchorEl(event.currentTarget);
        } else if (createItem) {
            const newItem = createItem();
            instance.setValue(fieldName, newItem);
        }
    };

    const handleDeleteClick = (event) => {
        instance.setValue(fieldName, null);
    }

    const select = (selection) => {
        setAnchorEl(null);
        const newItem = creationOptions[selection].creator();
        instance.addToArray(fieldName, newItem);
    };


    const menuItems = creationOptions ? Object.keys(creationOptions).map(
        (creationOptionClazzName, index) => {
            return (<MenuItem key={index} onClick={() => select(creationOptionClazzName)}>{creationOptions[creationOptionClazzName].label}</MenuItem>)
        }
    ) : null;

    const form = value ? formConfigurations.createFormWidget({instance: value, store, path: `${path}.${fieldName}`}) : null;

    const handleClose = () => {
        setAnchorEl(null);
    }


    return <React.Fragment>
        {label ? <GroupPanel label={label} /> : null}
        {form}
        {!value ? <Box className={classes.addPanel}>
            <IconButton edge="end" aria-label="add" onClick={(event) => handleClick(event)} >
                <IoIosAddCircleOutline size={16} />
            </IconButton>
        </Box> : null}
        {!value && creationOptions ? <Menu id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {menuItems}
        </Menu> : null}
        {value ? <Box className={classes.addPanel}>
                <IconButton edge="end" aria-label="delete" onClick={(event) => handleDeleteClick(event)} >
                    <IoIosRemoveCircleOutline size={16}/>
                </IconButton>
            </Box>: null}
    </React.Fragment>
});

export default OptionalOneOfField;