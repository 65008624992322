import React from 'react';

import Button from '@material-ui/core/Button';


import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import FixedToolbar from 'components/FixedToolbar'
import MainContentLayout from 'layouts/MainContentLayout';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

import ActionButtons from 'kiini/ActionButtons'

import RunnableObjectEditPanel from 'components/runnableObjects/RunnableObjectEditPanel';

const useStyles = makeStyles((theme) =>
	createStyles({
		offset: theme.mixins.toolbar,
		backToOverview: {
			backgroundColor: theme.palette.primary.light
		}
	})
);


const BaseObjectScreen = observer(() => {

	const classes = useStyles();
	const routerStore = useRouterStore();
	const rootStore = useRootStore();

	const store = rootStore.runnableObjectsStore;

	const selectedObject = store.selectedObject;

	const onBackToOverview = () => {
		routerStore.goTo("runnableObjects")
	}

	const onSaveInstance = () => {
		store.saveWithUploadFiles();
	}

	const backButton = <Button
		onClick={() => onBackToOverview()}
		size="small"
		variant="text">
		<ArrowBackIcon />
		Back to Overview
	</Button>

	const toolbar = <FixedToolbar back={backButton} >
		<div>
			{selectedObject ? <ActionButtons store={store} instance={selectedObject} onSave={onSaveInstance} enableSaveButton={store.enableSaveSelectedObject} /> : null}
		</div>
	</FixedToolbar>

	return <MainContentLayout toolbar={toolbar}>
		{selectedObject ? <RunnableObjectEditPanel store={store} /> : null}
		<div className={classes.offset} />
	</MainContentLayout>
});

export default BaseObjectScreen;

