import * as React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import SelectFileContentDialog from 'components/dialogs/SelectFileContentDialog';
import {inputLabelStyle, formControlStyle } from 'kiini/Style';

import { Rendition } from 'model/contentObjects';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
});


const FileAssetElementWidget = observer(({id, label, instance, store, assetType, selectLabel  }) => {

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    }

    const handleClose = (selectedFileAsset) => {
        console.log("Dialog selected ", selectedFileAsset);
        setOpen(false);
        const rendition = new Rendition(null, selectedFileAsset.renditions[0])
        instance.setValues(
            {
                contentObjectId: selectedFileAsset.id,
                rendition
            }
        );
    };

    const makeEmpty = () => {

        const rendition = new Rendition(null, {
            base: {url: null,size: { width: null, height: null },type: "image"},
            defaultImage: {url: null,size: { width: null, height: null },type: "image"},
        });

        instance.setValues({
            contentObjectId: null,
            rendition
        });
    }

    const imageUrl = instance ?  instance.rendition.defaultImage.url: null;

    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
            {label}
        </InputLabel>
        {open ?
            <SelectFileContentDialog assetType={assetType} typeLabel={selectLabel} open={open} handleCancel={handleCancel} handleClose={handleClose}></SelectFileContentDialog>
        :null
        }
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image={imageUrl}
                />
            </CardActionArea>
            <CardActions>
                <Button variant="contained" onClick={() => handleClickOpen()}>{selectLabel}</Button>
                <Button variant="contained" onClick={() => makeEmpty()} color="primary">
                    Reset
                </Button>
            </CardActions>
        </Card>
    </FormControl>
});

export default FileAssetElementWidget;