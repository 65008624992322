import * as React from 'react';

import {FormWidget, FormConfigurations} from 'kiini/Forms';
import TextField from 'kiini/TextField';
import SelectionField from 'kiini/SelectionField';


const pointTypeOptions = [
    {label: "main start point", value: "mainStartPoint"},
    {label: "secondary start point", value: "secondaryStartPoint"},
    {label: "transfer point", value: "transferPoint"},
    {label: "end point", value: "endPoint"},
    {label: "meeting point", value: "meetingPoint"},
]


const experiencePointFormConfigurationData = {
       "content_objects.model.Point": {
        groups:  [
            {
                label: "Point",
                fields: [
                    {fieldName: "description", label: "Description", widget: TextField, widgetProps: { multiline: true, rows: 3}},
                    {fieldName: "type", label: "Type", widget: SelectionField, widgetProps:{selectOptions: pointTypeOptions}}
                ]   
            }
        ]
    }
}

const experiencePointFormConfiguration = new FormConfigurations(experiencePointFormConfigurationData);

const ExperiencePointForm = ({instance, store}) => {
    return <FormWidget instance={instance} formConfigurations={experiencePointFormConfiguration} store={store}/>
}


export default ExperiencePointForm;