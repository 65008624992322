import React from "react";
import { useRef } from 'react';

import {
  Map, TileLayer,
  //Marker, 
  //Popup, 
  Polyline
} from "react-leaflet";



import {observer} from 'mobx-react'

import {
  InstructionMarkers, LinkedContentObjectMarkers, GeoTriggerMarkers, GeoTriggerCircles, GeoTriggerLinks, InstructionsTriggerMarkers, 
  InstructionsTriggerCircles, LibraryObjectMarkers, InstructionLinks, SpotMarkers, PointMarkers, BoundMarkers} from './MapMarkers';

import {toMapBounds, boundToFilterPart, latLongDoubletListToPosition  } from 'utils/MapUtils';

import {CONTENT_OBJECTS_LIST, ROUTE_PART_lIST, INSTRUCTION_LIST, POINTS_LIST } from 'model/const';

import spotIconUrl from 'icons/spot.png'



function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

const ExperienceMap = observer(({experienceEditorStore}) => {

  console.log("rendering experience map");

  const showLibrayObjects = experienceEditorStore.experienceEditorState.libraryObjectsShow;
  const fileAssetsShow  = experienceEditorStore.experienceEditorState.fileAssetsShow;
  const spotsShow  = experienceEditorStore.experienceEditorState.spotsShow;

  const experience = experienceEditorStore.experience;
  const experienceEditorState = experienceEditorStore.experienceEditorState;

  const route = experience.route;
  const libraryObjects = experienceEditorState.libraryObjects;
  const fileAssets = experienceEditorState.fileAssets;
  const spots = experienceEditorState.spots;

  const bounds = toMapBounds(experience.experienceMap.bounds);
  
  const mapRef = useRef(null);

  // collect the location of the contentObjectLinks
  const contentObjectLinksWithLocation = experience.contentObjectLinks.map( contentObjectLink => {
    
    const spotOrContentObject =  experienceEditorStore.getObjectForContentObjectLink(contentObjectLink);
   
    const location = contentObjectLink.overriddenLocation
      ? contentObjectLink.overriddenLocation
      : spotOrContentObject.location;
    return {contentObjectLink, location, spotOrContentObject};

  });

  const createGeoTriggerLinkLocations = (geoTriggers, contentObjectLinksWithLocation) => {

    return geoTriggers.map( geoTrigger => {

      const indx = contentObjectLinksWithLocation.findIndex(contentObjectLinkWithLocation => contentObjectLinkWithLocation.contentObjectLink.id === geoTrigger.contentObjectLinkId);
      if (indx > -1){
        const location = contentObjectLinksWithLocation[indx].location;
        if (!location){
          //console.log("no location for ", correspondingContentObjectLink.cachedContentObject.name)
          return {fromLocation: geoTrigger.geoFence.location, toLocation: geoTrigger.geoFence.location};
        }else{
          return {fromLocation: geoTrigger.geoFence.location, toLocation: location}
        }
      }else{
          //debugger;
          return null;
      } 
    });  
  
  }


  const selectedRoutePartId = 
    experienceEditorState.selectedElement && experienceEditorState.selectedElement.elementSet  ===  ROUTE_PART_lIST ? 
      experienceEditorState.selectedElement.id : null;


  const focusedContentObjectLinkId = experienceEditorState.selectedElement && experienceEditorState.selectedElement.elementSet  ===  CONTENT_OBJECTS_LIST ? 
    experienceEditorState.selectedElement.id : null;



  const routePolylines =
  route && route.routeParts ?
    route.routeParts.map((routePart, indx) => {
        let positions = latLongDoubletListToPosition(routePart.points);
        const dashArray = routePart.usage === 'mainRoute' ? null : [5, 5] ;
        const weigth = routePart.id === selectedRoutePartId ? 6: 3;

        const color = routePart.lineStyle ? routePart.lineStyle.color : "#FF0000";

        return <Polyline key={indx} color={color} weight={weigth} dashArray={dashArray} positions={positions} />
      }) : null;

  const geoTriggers = experience.geoTriggers;
  const geoTriggerLinks = createGeoTriggerLinkLocations(geoTriggers, contentObjectLinksWithLocation)

  const [viewport, setViewport] = React.useState(null)

  const onViewportChanged = (viewport) => {
    // The viewport got changed by the user, keep track in state
    setViewport(viewport);
    if (mapRef && mapRef.current) {

      const bounds = mapRef.current.leafletElement.getBounds();
      const mapBoundsFilter = boundToFilterPart(bounds);
      debounce(
        experienceEditorStore.setMapBoundsFilter(mapBoundsFilter),
        500
      );
      //experienceEditorActions.setLibrayObjectsFilterPart(boundsFilter);
    }
  }

  const handleMapClick = (e) => {
    /* add here check if we are in the add modus, is there type selected to add in the toolbar */
    if (experienceEditorState.waitForPositionOnMap){
      experienceEditorStore.setLocationObjectToAdd(e.latlng);
    }
  }
  
  const  handleClickLibraryObject = (libraryObject) => {
      console.log("add content object ", libraryObject);
      experienceEditorStore.addContentObject(libraryObject);
  }

  const handleOnClickLinkedObject = (contentObjectLinkId) => {
      console.log("select link with id", contentObjectLinkId);
      experienceEditorStore.selectElement({elementSet: CONTENT_OBJECTS_LIST, id:contentObjectLinkId});
  }

  const handleClickPoint = (id) => {
    experienceEditorStore.selectElement({elementSet: POINTS_LIST, id});
  }

  const handleClickSpot = (spot) => {
     experienceEditorStore.addSpot(spot);
  }

  const style = experienceEditorState.waitForPositionOnMap === true ? {cursor: 'crosshair'} : {};

  return <Map 
    bounds={bounds} 
    zoom={12} 
    gestureHandling={true} 
    onViewportChanged={onViewportChanged}
    viewport={viewport}
    onClick={handleMapClick} 
    ref={mapRef}
    style={style}
  >
  
    {spotsShow ? <SpotMarkers 
      onClick={(spot => handleClickSpot(spot))}  
      spots={spots} />: null}  

    <GeoTriggerCircles geoTriggers={geoTriggers} />
    <InstructionsTriggerCircles instructions={experience.experienceInstructions.instructions} /> 
    <GeoTriggerMarkers 
      geoTriggers={geoTriggers} 
      onClick={(contentObjectLinkId => handleOnClickLinkedObject(contentObjectLinkId))}  
      />
    <GeoTriggerLinks geoTriggerLinks={geoTriggerLinks} />
    <LinkedContentObjectMarkers 
      focusedContentObjectLinkId={focusedContentObjectLinkId}
      contentObjectLinksWithLocation={contentObjectLinksWithLocation}
      onClick={(contentObjectLinkId => handleOnClickLinkedObject(contentObjectLinkId))}  
      />

     <InstructionsTriggerMarkers 
      instructions={experience.experienceInstructions.instructions} 
      onClick={(id) => experienceEditorStore.selectElement({elementSet: INSTRUCTION_LIST, id})}
      />  
     <InstructionLinks instructions={experience.experienceInstructions.instructions} />  
    <InstructionMarkers
       instructions={experience.experienceInstructions.instructions}
       onClick={(id) => experienceEditorStore.selectElement({elementSet: INSTRUCTION_LIST, id})}
    />
    {showLibrayObjects ? <LibraryObjectMarkers 
      onClick={(libraryObject => handleClickLibraryObject(libraryObject))}  
      iconUrl={spotIconUrl}
      libraryObjects={libraryObjects} />: null}

    {fileAssetsShow ? <LibraryObjectMarkers 
      onClick={(fileAsset => handleClickLibraryObject(fileAsset))}  
      iconUrl={spotIconUrl}
      libraryObjects={fileAssets} />: null}  



    
    <PointMarkers points={experience.points}  onClick={handleClickPoint} />  

   
    {routePolylines}

    <TileLayer
     
      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      //url="https://maps.heigit.org/openmapsurfer/tiles/roads/webmercator/{z}/{x}/{y}.png"
      url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
    />
  </Map>
});

//  <BoundMarkers bounds={experience.experienceMap.bounds} />  


export default ExperienceMap;
