import React from 'react';

import moment from 'moment';

import { observer } from 'mobx-react'

import ObjectsVirtualizedTable, {createDefaultCellRenderer} from 'components/ObjectsVirtualizedTable';


const RunnableObjectsVirtualizedTable = observer(({ store, onSort, onSelectObject, className, ...rest }) => {

    const results = store.selectObjectsState.objects;

    const getRowData = ({ index }) => {
        const obj = results[index].instance;
        const name = obj.name;
        const id = obj.id;
        const modificationTime = moment(obj.meta.modificationTime).format('DD/MM/YYYY HH:mm');
        return { id, name, modificationTime, obj }
    }

    const nameSort = onSort ? () => onSort('name') : null;

    const columns = [
        {
            width: 400,
            label: 'Name',
            dataKey: 'name',
            sort: nameSort
        },
        {
            width: 200,
            label: 'Last updated',
            dataKey: 'modificationTime',
        },
        {
            width: 100,
            label: 'Actions',
            dataKey: 'id',
        },
    ]

    const onDelete = (objectId) => {
        if (window.confirm("Do you really want to delete your this App object?")) {
            store.delete(objectId);
        }
    }

    const onSelectRow = (rowData)=> {
        onSelectObject(rowData.obj.id);
    }

    const cellRenderer = createDefaultCellRenderer({ columns, actionColumnIndex: 2, onDelete, onSelectRow});

    return <ObjectsVirtualizedTable
        store={store}
        onSort={onSort}
        getRowData={getRowData}
        columns={columns}
        cellRenderer={cellRenderer}
    />
});

export default RunnableObjectsVirtualizedTable;