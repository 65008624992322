import React from 'react';

import { observer } from "mobx-react";

import SelectionField from 'kiini/SelectionField';

const getField = (clazzDescription, fieldName ) => {
  return clazzDescription.fields.find( field => field.name === fieldName)
} 

const getFieldChoiceOptions = (clazzDescription, fieldName) => {
   const field = getField(clazzDescription, fieldName);
   if (field && field.choices){
      return field.choices.map( choice => ({label: choice[1], value: choice[0]}));
   }
   throw Error(`field ${fieldName}not found`)
}



const SelectionChoicesField = observer(({id, label, instance, fieldName, clazzDescription,  ...rest }) =>{

    const clazzDescriptionToUse = clazzDescription ? clazzDescription : instance.$classDescription

    const selectOptions = getFieldChoiceOptions(clazzDescriptionToUse, fieldName);

    return <SelectionField 
      id={id}
      label={label}
      instance={instance}
      fieldName={fieldName}
      selectOptions={selectOptions}
      {...rest}
    />
});

export default SelectionChoicesField;