import React from 'react';

import IconButton from '@material-ui/core/IconButton';

//import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { useRootStore } from 'contexts';
//import { ClassDescription } from 'model/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
	createStyles({

		root: {
			display: 'flex',
            
		},
        account: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        accountEntry: {
            display: 'flex',
        }	
    })
);

const AccountMenu = (props) => {

	const classes = useStyles();
    const rootStore = useRootStore();
    const { authStore, routerStore } = rootStore;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const user = authStore.user;
    const organisation = authStore.selectedOrganisation;

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen  = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
      setAnchorEl(null);
      authStore.signOut();
    }

    const handleProfileMenuClose   = (event) => {
      setAnchorEl(null);
    };

	const handleSettings = () => {
		setAnchorEl(null);
		routerStore.goTo("settings");
	}
  
    return <div className={classes.root}>
                <div className={classes.account}>
                {organisation?<div className={classes.accountEntry}>{organisation.name.value}</div>: null}
                {user? <div className={classes.accountEntry}>{user.userName}</div>: null}
                </div>
				<IconButton
					edge="end"
					aria-label="account of current user"
					aria-haspopup="true"
					onClick={handleProfileMenuOpen}
					color="inherit"
					>
					<AccountCircle />
				</IconButton>
                    <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleProfileMenuClose}
            >
                <MenuItem onClick={handleSettings}>Settings</MenuItem>
                <Divider />		
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
			</div>
	
}

export default AccountMenu;