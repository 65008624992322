import { action, computed, makeObservable, observable } from 'mobx';
import { createRouterState } from 'mobx-state-router';
import { RootStore } from './RootStore';

import axios from 'axios';

import {Storage} from '../utils/Storage';
import { LsKeys } from './LsKeys';

import { authenticationApi, JWT_REFRESH_TOKEN_URL } from '../Api';

import {getRoleFromJsonObject} from 'model/content-object-utils';
import {ORGANISATION_ROLE_EXPERIENCE_SITE_SHARE_ROLE} from 'model/const'

const defaultState = createRouterState('dashboard');
const signin = createRouterState('signin');

const DEFAULT_SHARING_HOST = "https://storytrails.eu"


const getSharingHost = (organisation) => {
	const sharingRole = organisation ? getRoleFromJsonObject(organisation.roles, ORGANISATION_ROLE_EXPERIENCE_SITE_SHARE_ROLE) : undefined;
	return sharingRole ? sharingRole.sharingHost : DEFAULT_SHARING_HOST ;
}


export class AuthStore {
    rootStore;
    user = undefined;
    access=undefined;
    refresh=undefined;
    organisations = undefined;
    selectedOrganisation = undefined;
    sharingHost = DEFAULT_SHARING_HOST;

    // Where should we redirect after sign in
    signInRedirect = defaultState;
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this, {
            user: observable.ref,
            organisations: observable.ref,
            signInRedirect: observable.ref,
            selectedOrganisation: observable.ref,
            sharingHost: observable.ref,
            showAnalytics: computed,

            setUser: action,
            clearUser: action,
            setSignInRedirect: action,
            init: action
        });
        
    }


    init = () => {
        this.access = Storage.get(LsKeys.access, null);
        this.refresh = Storage.get(LsKeys.refresh, null);
        this.user = Storage.get(LsKeys.user, null);
        this.organisations = Storage.get(LsKeys.organisations, null);
        this.selectedOrganisation = this.organisations ? this.organisations[0] : null;
        this.sharingHost = getSharingHost(this.selectedOrganisation);
        authenticationApi.setOrganisation(this.selectedOrganisation); // @todo think about another mechanism

        // set up interceptors for adding accessToken
        axios.interceptors.request.use(req => {
            const accessToken = this.access;
            req.headers.Authorization = `Bearer ${accessToken}`;
            return req;
        });

        // set up interceptor for refreshing access token
        axios.interceptors.response.use(res => res, err => {

            // Return any error which is not due to authentication back to the calling service
            if (!err.response || err.response.status !== 401) {
                return new Promise((resolve, reject) => {
                    reject(err);
                });
            }
            
            if (err.config && err.config.url === JWT_REFRESH_TOKEN_URL) {

                console.log("401 on refresh token signing out");
                this.signOut();
                return new Promise((resolve, reject) => {
                  reject(err);
                });
            }

            if (!this.refresh) {
                console.log("no refresh token signing out");
                this.signOut();
                
                return new Promise((resolve, reject) => {
                  reject(err);
                });
            }

            if (err.config) {
                    console.log("refresh token ", this.refresh);
                    return authenticationApi.getRefreshToken(this.refresh)
                    .then(response => {
                        console.log("getting new access token, now requesting ", err.config.url);
                        this.access = response.data.access;
                        //err.config.headers.Authorization = 'Bearer ' + response.data;
                        return axios(err.config);
                    })
                    .catch(error => {
                        console.log('Refresh login error: ');
                        this.signOut();
                        //throw error;
                        // return new Promise((resolve, reject) => {
                        //     reject(err);
                        // });
                        return new Promise((resolve, reject) => {
                                resolve(null);
                            });
                    });
            }           
        });
    }

    sh

    setAccess = (access, refresh)=> {
        this.access = access;
        this.refresh = refresh;
        Storage.set(LsKeys.access, this.access);
        Storage.set(LsKeys.refresh, this.refresh);
    }


    setUser = (user, organisations, options) => {
        this.user = user;
        this.organisations = organisations;
        this.selectedOrganisation = this.organisations ? this.organisations[0] : null;
        this.sharingHost = getSharingHost(this.selectedOrganisation);
        authenticationApi.setOrganisation(this.selectedOrganisation); // @todo think about another mechanism
        
        Storage.set(LsKeys.user, this.user);
        Storage.set(LsKeys.organisations, this.organisations); 
        
        const redirect = options && options.redirect ? options.redirect: true;
        if(redirect){ 
            this.rootStore.routerStore.goToState(this.signInRedirect);
        }
    };

    clearUser = () => {
        this.user = undefined;
        this.access = undefined;
        this.refresh = undefined;
        this.organisations = undefined;
        Storage.remove(LsKeys.access);
        Storage.remove(LsKeys.refresh);
        Storage.remove(LsKeys.user);
        Storage.remove(LsKeys.organisations); 
        authenticationApi.setOrganisation(null);
    };

    setSignInRedirect = (routerState) => {
        this.signInRedirect = routerState;
    };

    signOut() {
        this.clearUser();
        this.rootStore.routerStore.goToState(signin);
    }

    get showAnalytics() {
        return this.selectedOrganisation && this.selectedOrganisation.id !== 'c8430b51-e024-48c3-bbab-9e376c99be90'
    }
}

