import React from 'react';

import { observer } from 'mobx-react'


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const PreviewUploadFiles = ({files}) => {
  
  const thumbs = files.map(file => {
    
    const isImage = file.type.startsWith("image/");
    if (isImage){
        const preview = URL.createObjectURL(file);
        file.preview = preview;
    }
    
    
    return <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {isImage ?
        <img
          src={file.preview}
          style={img}
          alt={file.name}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />: <span>{file.name}</span>}
      </div>
    </div>
    });

//   useEffect(() => {
//     // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
//     return () => files.forEach(file => URL.revokeObjectURL(file.preview));
//   }, [files]);

  return (
    <section className="container">
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </section>
  );
}

export default observer(PreviewUploadFiles);