import * as React from 'react';

import TableCell from '@material-ui/core/TableCell';

import ObjectSelectWidget from 'kiini/ObjectSelectWidget';
import { useRootStore } from 'contexts';

import { MapConfigLink } from 'model/contentObjects';

const mapConfigColumns = ({ sort }) => {
    return [
        {
            width: 400,
            label: 'Name',
            dataKey: 'name',
            sort: () => sort('name')
        }

    ]
};

const getRowDataFn = ({store}) => {
    return ({ index }) => {
        const resultObject = store.selectObjectsState.objects[index];

        const instance = resultObject.instance;
        const id = instance.id;
        const name = instance.name;
        return { id, name, resultObject }
    }
}

const selectedRowToValue = (rowData) => {
    return new MapConfigLink(null, {mapConfigId: rowData.id, name: rowData.name});
}


const mapConfigTableCellRenderer = ({ cellData, columnIndex, rowData }) => {
    const rowHeight = 48;
    const align = 'right'; //(columnIndex != null && experienceColumns[columnIndex].numeric) || false ? 'right' : 'left'
    return (
        <TableCell
            style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box', flex: 1, height: rowHeight }}
            component="div"
            variant="body"
            //          style={{  }}
            align={align}
        >
            {cellData}
        </TableCell>
    );
};



const SelectMapConfigWidget = ({ id, label, instance, fieldName }) => {

    const rootStore = useRootStore();

    const valueToLabel = (value) => {
        return value.name ? value.name : "default";
    }

    const objectSelectDialogProps = {
        store: rootStore.mapConfigObjectsStore,
        columnsCreator: mapConfigColumns, // function
        cellRenderer: mapConfigTableCellRenderer, 
        getRowDataFn: getRowDataFn,
        selectedRowToValueFn: selectedRowToValue,
        label: "Select a map configuration"
    }

    return <ObjectSelectWidget 
                id={id} 
                label={label}
                instance={instance} 
                fieldName={fieldName}
                objectSelectDialogProps={objectSelectDialogProps}
                valueToLabel={valueToLabel}
            />
};

export default SelectMapConfigWidget;
