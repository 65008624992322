import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';


const useFormStyles = makeStyles((theme) =>
	createStyles({
		group: {
			backgroundColor: theme.palette.primary.light,
			height: 40,
			padding: 8,
			color: "white"

		}
	})
);


export const GroupPanel = (props) => {
    const classes = useFormStyles();
    return <Paper className={classes.group} elevation={0} variant="outlined" square >
		<Typography variant="h6" color="inherit">
			{props.groupName}
		</Typography>
	</Paper>
}


