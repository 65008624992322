import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';





//import TablePagination from '@material-ui/core/TablePagination';

//import IconButton from '@material-ui/core/Button';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
//import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined'



import { observer } from 'mobx-react'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});




const TopAggregationTable = observer(({ results }) => {

    //const rowsPerPage = store.selectObjectsState.count;
    //const results = store.selectObjectsState.objects;
    //   const meta = store.selectObjectsState.meta;
    //   const count = meta ? meta.numFound : 0;
    //   const page = meta ? getPage(meta) : 0;

    //   const handlePageChange = (event, page) => {
    //     store.fetchPage(page)
    //   };

    // const selectRow = (rowData) => {
    //     onSelectObject(rowData.id);
    // }

    const classes = useStyles();

    if (results) {
        return (

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((result) => (
                            <TableRow key={result.content_object_id}>
                                <TableCell component="th" scope="row">
                                    {result.content_name}
                                </TableCell>
                                <TableCell align="right">{result.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return null;
    }
});


// <div>
// <TablePagination
//   component="div"
//   count={count}
//   onChangePage={handlePageChange}
//   //onChangeRowsPerPage={handleRowsPerPageChange}
//   page={page}
//   rowsPerPage={rowsPerPage}
//   rowsPerPageOptions={[]}
// />
// </div>

export default TopAggregationTable;