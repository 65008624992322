import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import { observer } from 'mobx-react'

import Transition from './DialogTransition';
import ObjectsVirtualizedTable from 'components/ObjectsVirtualizedTable';

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        padding: 32,
        flexGrow: 1,
        flexDirection: 'column',
        display: 'flex'
    },
}));


const BaseSelectDialog = observer(({ store, clearable, label, open, handleCancel, handleClose, handleClear, columnsCreator, cellRenderer, getRowDataFn, selectedRowToId }) => {

    const classes = useStyles();

    store.loadObjects();

    const onSearchInputChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            store.setFilterPart({ "search.all": null });
        } else {
            store.setFilterPart({ "search.all": value });
        }
    }

    const searchTerm = store.selectObjectsState.filter["search.all"] ? store.selectObjectsState.filter["search.all"] : "";

    const onSelectObject = async({ event, index, rowData }) => {
        const id = selectedRowToId(rowData);
        const selectedObject = await store.selectObjectAsync(id);
        handleClose(selectedObject);
    }

    const  getRowData = getRowDataFn({ store });

    const sort = (column) => {
        store.setSort(column);
    }
    
    const columns = columnsCreator({ sort });
    

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar position='sticky'>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    {clearable ? <Button variant="contained" color="secondary" onClick={handleClear}>Clear selection</Button> : null}
                    <Typography variant="h3" className={classes.title}>
                        {label ? label : ""}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                <TextField label="Search" variant="outlined" value={searchTerm} onChange={(value => onSearchInputChange(value))} />
                <ObjectsVirtualizedTable 
                    store={store}
                    cellRenderer={cellRenderer}
                    getRowData={getRowData}
                    onSelectObject={onSelectObject}
                    columns={columns} />
                
            </div>
        </Dialog>
    )
});

export default BaseSelectDialog;
