import React from 'react';
//import clsx from 'clsx';
//import PropTypes from 'prop-types';
//import moment from 'moment';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  //CardActions,
  //CardContent,
  //Table,
  //TableBody,
  //TableCell,
  //TableHead,
  //TableRow,
  //Typography,
  //TablePagination
} from '@material-ui/core';

import IconButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import CheckIcon from '@material-ui/icons/Check';
import Remove from '@material-ui/icons/Remove';
import Autorenew from '@material-ui/icons/Autorenew'
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
//import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';

import {green, orange, grey} from '@material-ui/core/colors'

//import { observer } from 'mobx-react'

import {getImageRendition} from 'model/content-object-utils';

const useCardStyles = makeStyles({
  root: {
    width: 200,
  },
  media: {
    height: 80,
  },
});

export const ExperienceTableCard = ({resultObject, onClick}) => {
  const classes = useCardStyles();
  const instance = resultObject.object
  const imageUrl = getImageRendition(instance.renditions,"image.w500");

  return <Card className={classes.root} onClick={onClick}>
      <CardActionArea>
        {imageUrl ? <CardMedia
          className={classes.media}
          image={imageUrl}
          title={instance.name}  
        />: null}
      </CardActionArea>
    </Card>;
}


export const  InlineExperienceMenu = (
    {
      publishingData, 
      contentObjectId, 
      name, 
      organisationId, 
      onCreateDraft, 
      onRepackage, 
      onPublish, 
      onCopy, 
      onDelete, 
      onRemovePublished, 
      onShowQR
    }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateDraft = () => {
    setAnchorEl(null);
    onCreateDraft(contentObjectId)
  };

  const handleCreateRedraft = () => {
    setAnchorEl(null);
    onRepackage(contentObjectId);
  } 

  const handlePublish = () => {
    setAnchorEl(null);
    onPublish(contentObjectId);
  }

  const handleRemovedPublished = () => {
    setAnchorEl(null);
    onRemovePublished(contentObjectId);
  }


  const handleCopy = () => {
    setAnchorEl(null);
    onCopy(contentObjectId);
  }

  const handleDelete = () => {
    setAnchorEl(null);
    onDelete(contentObjectId);
  }

  const handleShowQR = () => {
    setAnchorEl(null);
    const isPublished = publishingData.isPublished;
    onShowQR({contentObjectId, name, organisationId, isPublished});
  }
  //if (publishingData.isPublished && publishingData.publishedVersionUpToDate){


  return (
    <div>
       <IconButton aria-label="delete" onClick={handleClick}>
          <MoreHorizOutlined fontSize="small" />
        </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleShowQR}>Share Storytrails (QR, link)</MenuItem>
        {publishingData.canCreateDraft ? <MenuItem onClick={handleCreateDraft}>Create Draft</MenuItem> : 
        <MenuItem onClick={handleCreateRedraft}>Force New Draft</MenuItem>}
        {publishingData.hasDraft ? <MenuItem onClick={handlePublish}>Publish Live</MenuItem>: null}
        {publishingData.isPublished ? <MenuItem onClick={handleRemovedPublished}>Remove published version</MenuItem>: null}
        <MenuItem onClick={handleCopy}>Copy</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

export const DraftStateIcon = ({publishingData}) => {

  if (publishingData.hasDraft && publishingData.draftVersionUpToDate){
    return <CheckIcon  style={{ color: green[500] }}/>
  }else if  (publishingData.canCreateDraft){
    return <CheckIcon  style={{ color: orange[500] }}/>
  }else {
    return <Remove  style={{ color: grey[500] }}/>
  }
}

export const PublishedStateIcon = ({publishingData}) => {
  if (publishingData.isPublished && publishingData.publishedVersionUpToDate){
    return <CheckIcon  style={{ color: green[500] }}/>
  }else if  (publishingData.isPublished && !publishingData.publishedVersionUpToDate){
    return <CheckIcon  style={{ color: orange[500] }}/>
  }else {
    return <Remove  style={{ color: grey[500] }}/>
  }
}

export const ProcessingStateIcon = ({publishingData}) => {
  if (publishingData.isPreparing){
    return <Autorenew  style={{ color: grey[800] }}/>
  }
  return null;
} 