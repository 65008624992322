import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '../Select';

import { observer } from "mobx-react";


import {inputLabelStyle, formControlStyle } from '../Style';



const SelectionField = observer(({id, label, instance, fieldName, selectOptions, ...rest }) =>{

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();
    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
        </InputLabel>
        <Select
          id={id}
          label={label}
          fieldName={fieldName}
          instance={instance}
          selectOptions={selectOptions}
        />
         
      </FormControl>
});

export default SelectionField;