import React from 'react';

import { Player } from 'video-react';

import {getDefaultImage} from 'model/content-object-utils';


import 'video-react/dist/video-react.css'; 




const ImageRenderer = (props) => {

    console.log("fileasset url ", props.fileAsset)
    return <img src={props.fileAsset.url} alt={props.fileAsset.name} style={{ width: "100%", margin: "8px" }} />
}

const VideoRenderer = (props) => {

    const image = getDefaultImage(props.fileAsset);

    return <Player playsInline
        poster={image.url}
        src={props.fileAsset.url} />
}


const AssetRenderMapping = {
    "content_objects.model.Image": ImageRenderer,
    "content_objects.model.Video": VideoRenderer,
    "content_objects.model.Audio": VideoRenderer
}



const FileAssetRenderer = (props) => {
    const fileAsset = props.fileAsset;
    const clazzName = fileAsset.$classDescription.clazzName;
    const Renderer = AssetRenderMapping[clazzName];
    if (Renderer){
        return <Renderer fileAsset={fileAsset} />
    }
    return <div> No renderer found for file asset type {clazzName} </div>
}

export default FileAssetRenderer;