import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import IconButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined'

import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import VirtualizedTable from 'components/VirtualizedTable';

import moment from 'moment';


import { observer } from 'mobx-react'



import { getImageRendition } from 'model/content-object-utils';

const useCardStyles = makeStyles({
  root: {
    width: 200,
  },
  media: {
    height: 80,
  },
});

export const ContentObjectRepresenationTableCard = ({ resultObject, onClick }) => {

  const classes = useCardStyles();
  const instance = resultObject.instance
  const imageUrl = getImageRendition(instance.renditions, "image.w500");

  return <Card className={classes.root} onClick={onClick}>
    <CardActionArea>
      {imageUrl ? <CardMedia
        className={classes.media}
        image={imageUrl}
        title={instance.name}
      /> : null}
    </CardActionArea>
  </Card>;
}


const InlineStoryBlockMenu = ({ contentObjectId, onCopy, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleCopy = () => {
    setAnchorEl(null);
    onCopy(contentObjectId);
  }

  const handleDelete = () => {
    setAnchorEl(null);
    onDelete(contentObjectId);
  }

  //if (publishingData.isPublished && publishingData.publishedVersionUpToDate){


  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreHorizOutlined fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCopy}>Copy</MenuItem>
      </Menu>
    </div>
  );
}

//        <MenuItem onClick={handleDelete}>Delete</MenuItem>



const getPage = (meta) => {
  return Math.floor(meta.start / meta.count);
}

const StoryBlocksTableVirtualized = observer(({ storyBlocksStore, onSort, onSelectObject, onCopy, onDelete, className, ...rest }) => {

  const rowsPerPage = storyBlocksStore.selectObjectsState.count;
  const results = storyBlocksStore.selectObjectsState.objects;
  const meta = storyBlocksStore.selectObjectsState.meta;
  const count = meta ? meta.numFound : 0;
  const page = meta ? getPage(meta) : 0;

  const getRowData = ({ index }) => {
    const contentObjectResult = results[index];
    const name = contentObjectResult.instance.name;
    const id = contentObjectResult.instance.id;
    const locale = contentObjectResult.instance.locale;
    const modificationTimeStr = moment(contentObjectResult.instance.meta.modificationTime).format('DD/MM/YYYY');
    return { name, modificationTimeStr, id, contentObjectResult, locale }
  }

  const selectRow = (rowData) => {
    onSelectObject(rowData.id);
  }

  const handlePageChange = (event, page) => {
    storyBlocksStore.fetchPage(page)
  };


  const sort = (column) => {
    if (onSort) {
      onSort(column)
    }
  }


  const columns = [
    // Not all renditions are setted now, so repair this first
    // {
    //   width: 400,
    //   label: 'Image',
    //   dataKey: 'contentObjectResult',
    //   renderCell: ({ rowData, cellData }) => (<ContentObjectRepresenationTableCard resultObject={cellData} onClick={() => selectRow(rowData)}/>),
    //   rowClick: true,
    // },
    {
      width: 400,
      label: 'Name',
      dataKey: 'name',
      sort: () => sort('name'),
      rowClick: true
    },
    {
      width: 200,
      label: 'Last updated',
      dataKey: 'modificationTimeStr',
      rowClick: true,
    },
    {
      width: 80,
      label: 'Locale',
      dataKey: 'locale',
      rowClick: true,
    },
    // {
    //   width: '100%',
    //   label: 'Actions',
    //   dataKey: 'id',
    // },
    {
      width: 200,
      label: 'Actions',
      dataKey: 'id',
      renderCell: ({ rowData, cellData }) => (<InlineStoryBlockMenu
        contentObjectId={rowData.id}
        name={rowData.name}
        organisationId={rowData.organisationId}
        onCopy={onCopy}
        onDelete={null}/>),
      rowClick: false,
    },
  ]

  // const onDelete = (contentObjectId) => {
  //   console.log("delete ", contentObjectId);
  // }


  const cellRenderer = ({ cellData, columnIndex, rowData, ...rest }) => {

    const rowHeight = 48;

    const columnData = columns[columnIndex]
    const widget = columnData.renderCell ? columnData.renderCell({ rowData, cellData }) : null;
    const rowClick = columnData.rowClick;

    return (
      <TableCell
        onClick={rowClick ? () => selectRow(rowData): null}
        style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box', flex: 1, height: rowHeight }}
        component="div"
        variant="body"
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
      >
        {widget ? widget : cellData}
      </TableCell>
    );
  };


  return (
    <React.Fragment>
      <div>
        <TablePagination
          component="div"
          count={count}
          onChangePage={handlePageChange}
          //onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </div>
      <Paper style={{ height: '100%', width: '100%', flex: 1 }}>
        <VirtualizedTable
          rowCount={results.length}
          rowGetter={getRowData}
          //onRowClick={selectRow}
          cellRenderer={cellRenderer}
          columns={columns}
        />

      </Paper>
      <div>
        <TablePagination
          component="div"
          count={count}
          onChangePage={handlePageChange}
          //onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </div>
    </React.Fragment>

  );
});

export default StoryBlocksTableVirtualized;