import * as React from 'react';

import {FormWidget, FormConfigurations} from 'kiini/Forms';
import GeoFenceWidget from './GeoFenceWidget';

import Button from '@material-ui/core/Button';

const formConfigurationData = {
       "content_objects.model.GeoTrigger": {
        groups:  [
            {
                    label: "Triggering",
                    fields: [
                        {fieldName: "geoFence", label: "Trigger circle radius", widget: GeoFenceWidget}
                    ]   
            },
        ]
    }
}

const formConfiguration = new FormConfigurations(formConfigurationData);

const GeoTriggerForm = ({instance, experienceEditorStore}) => {

    const handleClick = (evt) => {
        experienceEditorStore.removeTrigger(instance.id)
    }

    return <React.Fragment>
        <FormWidget instance={instance} formConfigurations={formConfiguration} store={experienceEditorStore}/>
        <Button onClick={handleClick}>Remove Trigger</Button>
    </React.Fragment> 
}


export default GeoTriggerForm;