import React from 'react';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { observer } from "mobx-react";

import BaseInput from '../BaseInput';


const Select = observer(({ id, label, instance, store, fieldName, selectOptions, ...rest }) => {


  const onChange = (evt) => {
    instance.setValue(fieldName, evt.target.value);
  }

  const menuItems = selectOptions ? selectOptions.map((entry, index) => <MenuItem key={index} value={entry.value}>{entry.label}</MenuItem>) : null;
  const field = instance.$fields.get(fieldName);
  const value = field.value ? field.value : "";

  return <MUISelect
    id={id}
    value={value}
    onChange={onChange}
    label={label}
    input={<BaseInput />}
  >
    {menuItems}
  </MUISelect>
});

export default Select;