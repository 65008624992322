import {IMAGE_ASSET_TYPE, AUDIO_ASSET_TYPE, VIDEO_ASSET_TYPE, IMAGE_CLAZZ, VIDEO_CLAZZ, AUDIO_CLAZZ} from "./const"

import { 
      OBJECT_TYPE_PARKING_SPOT,
      OBJECT_TYPE_BUS_STOP,
      OBJECT_TYPE_RAILWAY_STATION,
   
} from 'model/const';

export const ASSET_TYPE_TO_CLAZZ_MAP = {
      [IMAGE_ASSET_TYPE]: IMAGE_CLAZZ,
      [AUDIO_ASSET_TYPE]: AUDIO_CLAZZ,
      [VIDEO_ASSET_TYPE]: VIDEO_CLAZZ,
}

export const getClazzForAssetType = (assetType) => {
      return ASSET_TYPE_TO_CLAZZ_MAP[assetType];
}

export const isImage = (contentObjectRepresentation) =>{
      return contentObjectRepresentation.representedClazz === IMAGE_CLAZZ;
}

export const getDefaultImage = (contentObject) => {

      return contentObject.renditions.length > 0 ?
            contentObject.renditions[0].defaultImage : null;
}

export const getImageRendition = (renditions, variantName) => {

      if (renditions && renditions.length > 0) {
            const renditionVariant = renditions[0].variants[variantName];
            return renditionVariant ? renditionVariant.url : renditions[0].defaultImage.url;
      }
      return null;
}

export const getFirstImageRendition = (renditions, variantName) => {

      if (renditions && renditions.length > 0) {

            const renditionVariant = variantName ? renditions[0].variants[variantName] : renditions[0].defaultImage;
            return renditionVariant;
      }
      return null;
}


export const getDefaultImageUrlSize = (contentObject, variantName) => {

      const renditionVariant = getFirstImageRendition(contentObject.renditions, variantName);
      return renditionVariant ? { url: renditionVariant.url, size: renditionVariant.size } : { url: null, size: null };
}


export const getDefaultImageAttributes = (contentObject, variantName) => {
      const id = contentObject.id;
      const alt = contentObject.name;
      const name = contentObject.name
      const defaultImage = getDefaultImage(contentObject);
      const usedVariantName = isImage(contentObject) ? variantName: null; // @TODO temporary fix for no w500 variant in audio/video
      const { url, size } = getDefaultImageUrlSize(contentObject, usedVariantName);
      const width = size ? size.width : 1;
      const height = size ? size.height : 1;
     
      return { id, url, width, height, alt, name, defaultImage }
}


export const getContentObjectLink = (experience, contentObjectLinkId) => {
      const indx = experience.contentObjectLinks.findIndex(item => (item.id === contentObjectLinkId));
      const contentObjectLink = experience.contentObjectLinks[indx];
      return contentObjectLink;
}

export const getGeoTrigger = (experience, contentObjectLinkId) => {
      const indx = experience.geoTriggers.findIndex(geoTrigger => (geoTrigger.contentObjectLinkId === contentObjectLinkId));
      if (indx >= 0) {
            return experience.geoTriggers[indx];
      }
      return null;
}

export const isSpotContentObjectLink = (contentObjectLink) => (contentObjectLink.$classDescription.clazzName === "content_objects.model.SpotContentObjectLink")

export const AMENITY_SPOT_TYPES = [ 
      OBJECT_TYPE_PARKING_SPOT,
      OBJECT_TYPE_BUS_STOP,
      OBJECT_TYPE_RAILWAY_STATION,
]
  
export const isAmenitySpotTypeRoleName = (objectType) => (AMENITY_SPOT_TYPES.indexOf(objectType) >= 0);
  

export const hasOneRoleAmenitySpotType = (spotRoles) => {
      return spotRoles && spotRoles.length === 1 && AMENITY_SPOT_TYPES.indexOf(spotRoles[0]._clazz) >= 0;
}

export const getRoleFromJsonObject = (roles, clazzName) => {
      return roles ? roles.find( role => role._clazz === clazzName) : undefined;
}


export const editableToPublishedId = (editableId) => (editableId.substring(2));

export const getStoryPointUrl = (storyBlock, sharingHost) => {

      const encodedName = encodeURIComponent(storyBlock.name);
      const organisationId = storyBlock.ownerLink.id;
      const editableId = storyBlock.id;
      const publishedId = editableToPublishedId(editableId);
      const url = `${sharingHost}/storypoint/${encodedName}/${organisationId}/${publishedId}`; 
      return url;
}