import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MainLayout from 'layouts/Main';
import FixedToolbar from 'components/FixedToolbar'

import Box from '@material-ui/core/Box';
//import Switch from '@material-ui/core/Switch';
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';

import BoxHeader from 'components/BoxHeader';
import TimeLine from 'components/analytics/TimeLine'
import TopAggregationTable from 'components/analytics/TopAggregationTable'
import SelectPeriodMenu from 'components/analytics/SelectPeriodMenu';

//import moment from 'moment';

import { observer } from 'mobx-react'

import {getTimeSeriesExportData, getAggregationExportData} from 'analytics/model';

import Papa from 'papaparse';

//import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

import {periodToLabel} from 'analytics/model';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const saveAsCsv = (blob, fileName) =>{

  const saveLinkElement = document.getElementById('analytics-save-link') ? document.getElementById('analytics-save-link') : document.createElement('a');;
  saveLinkElement.setAttribute("id", "analytics-save-link");

  const url = URL.createObjectURL(blob, { type: 'text/csv' });
  saveLinkElement.href = url;
  saveLinkElement.download = fileName;
  document.body.appendChild(saveLinkElement);
  saveLinkElement.click();
}

const saveTimeSeries = (timeseries, countName, fileName) => {
    const exportDataAndFields = getTimeSeriesExportData(timeseries, countName);
    const csv = Papa.unparse(exportDataAndFields);
    const blob = new Blob([csv]);
    saveAsCsv(blob, fileName);
}

const saveAggregates = (aggregates, fileName) => {
    const exportDataAndFields = getAggregationExportData(aggregates);
    const csv = Papa.unparse(exportDataAndFields);
    const blob = new Blob([csv]);
    saveAsCsv(blob, fileName);
}

const AnalyticsOverviewScreen = observer(() => {
    const classes = useStyles();

    const rootStore = useRootStore();
    //  const routerStore = useRouterStore();
    const { analyticsStore } = rootStore;

    const publisherName = rootStore.authStore.selectedOrganisation.name.value;
    //   const onExperienceSelect = (id) => {
    //     routerStore.goTo('experience', { params: { id } });
    //   }

    const toolbar = <FixedToolbar>
    </FixedToolbar>

    const downloadTimeseries = analyticsStore.downloadTimeseries;
    const appSessionTimeseries = analyticsStore.appSessionTimeseries;
    const runStartTimeseries = analyticsStore.runStartTimeseries;
    const analyticsDownloadAggregatates = analyticsStore.downloadAggregates;
    const runStartsAggregates = analyticsStore.runStartAggregates;

    const platformDownloadTimeseries = analyticsStore.platformDownloadTimeseries;
    const platformRunStartTimeseries = analyticsStore.platformRunStartTimeseries;

    const onSelectPeriod = (selectedPeriod) => {
        analyticsStore.setPeriod(selectedPeriod);
    }

    const periodLabel = periodToLabel(analyticsStore.selectedPeriod.value);

    const saveDownloadsButton =  <IconButton onClick={()=> saveTimeSeries(downloadTimeseries,'downloads','downloads.csv')} aria-label="downloads" component="span">
              <DownloadIcon />
    </IconButton>

    const saveNrOfSessionsButton =  <IconButton onClick={()=> saveTimeSeries(appSessionTimeseries,'nr sessions','appsessions.csv')} aria-label="downloads" component="span">
        <DownloadIcon />
    </IconButton>

    const saveStartsOnlocationButton =  <IconButton onClick={()=> saveTimeSeries(runStartTimeseries,'starts','startOnLocation.csv')} aria-label="downloads" component="span">
              <DownloadIcon />
    </IconButton>

    const saveAggregatesTrailsDownloadsButton = <IconButton onClick={()=> saveAggregates(analyticsDownloadAggregatates,'trailDownloads.csv')} aria-label="downloads" component="span">
        <DownloadIcon />
    </IconButton>

    const saveAggregatesStartsLocationButton = <IconButton onClick={()=> saveAggregates(runStartsAggregates,'trailsStartsDownloads.csv')} aria-label="downloads" component="span">
        <DownloadIcon />
    </IconButton>

    return (
        <MainLayout toolbar={toolbar}>
            <div className={classes.root}>
                <div className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header="Period selection"
                                    subHeader=""
                                />
                                <SelectPeriodMenu selectedPeriod={analyticsStore.selectedPeriod}  onSelectPeriod={onSelectPeriod} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header={`Downloads Storytrails Platform`} 
                                    subHeader={`Downloads over period: ${periodLabel}`}
                                    
                                />
                                <TimeLine label="Downloads" timeseries={platformDownloadTimeseries} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header={`Starts on location Storytrails Platform`} 
                                    subHeader={`Downloads over period: ${periodLabel}`}
                                    
                                />
                                <TimeLine label="Starts" timeseries={platformRunStartTimeseries} />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header="App Sessions Storytrails Platform"
                                    subHeader={`App sessions over period: ${periodLabel}`}
                                    rightMenu={saveNrOfSessionsButton}
                                />
                                <TimeLine label="App Sessions" timeseries={appSessionTimeseries} />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header={`Downloads trails ${publisherName}`} 
                                    subHeader={`Downloads over period: ${periodLabel}`}
                                    rightMenu={saveDownloadsButton}
                                />
                                <TimeLine label="Downloads" timeseries={downloadTimeseries} />
                            </Box>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header={`Starts on location ${publisherName}`} 
                                    subHeader={`Starts over period: ${periodLabel}`}
                                    rightMenu={saveStartsOnlocationButton}
                                />
                                <TimeLine label="Starts on location" timeseries={runStartTimeseries} />
                            </Box>
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header={`Top downloads for ${publisherName}`}
                                    subHeader={`Nr downloads per storytrails  over period: ${periodLabel}`}
                                    rightMenu={saveAggregatesTrailsDownloadsButton}
                                />
                                <Box>
                                    <TopAggregationTable results={analyticsDownloadAggregatates.results} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                bgcolor="background.paper"
                                margin={2}
                                padding={2}
                            >
                                <BoxHeader
                                    header={`Top starts on location for ${publisherName}`}
                                    subHeader={`Nr starts on location per storytrails  over period: ${periodLabel}`}
                                    rightMenu={saveAggregatesStartsLocationButton}
                                />
                                <Box>
                                    <TopAggregationTable results={runStartsAggregates.results} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </MainLayout>
    );
});

export default AnalyticsOverviewScreen;
