import { observable, makeObservable } from 'mobx';
import * as Yup from 'yup';

import  { BaseModel, ClassDescription, FieldMeta, parseMapValues, isNotUndefined } from "./core"; // eslint-disable-line no-unused-vars


import {Source as KcpSource} from "./kiini";
import {Meta as KcpMeta} from "./kiini";
import {Name as KcpName} from "./kiini";
import {ObjectHierarchyLink as KcpObjectHierarchyLink} from "./kiini";
import {Role as KcpRole} from "./kiini";
import {Location as KcpLocation} from "./kiini";
import {Description as KcpDescription} from "./kiini";
import {Image as KcpImage} from "./kiini";
import {ObjectLink as KcpObjectLink} from "./kiini";
import {AnyObject as KcpAnyObject} from "./kiini";



export const sourceClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Source",
        verboseName: "None",
        validationSchemas: {
        
            "name": Yup.string().max(200),
            "id": Yup.string().max(125),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 200, "blank": false, "verbose_name": "name", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "name", "auto_created": true},
            {"max_length": 125, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
        ],

    }
);

export class Source extends KcpSource{

    $classDescription = sourceClassDescription;
    

    constructor(parent, { name,id }){
        super(parent,{  name ,  id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Source(null,data);
    }
}

export const metaClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Meta",
        verboseName: "None",
        validationSchemas: {
        
            "owner": Yup.string().max(120),
            "creator": Yup.string().max(120),
            "creationTime": Yup.date(),
            "modificator": Yup.string().max(120),
            "modificationTime": Yup.date(),
            "version": Yup.number().integer().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 120, "blank": false, "verbose_name": "owner", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "owner", "auto_created": true},
            {"max_length": 120, "blank": false, "verbose_name": "creator", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "creator", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "creationTime", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.DateTimeField", "choices": [], "meta": null, "name": "creationTime", "auto_created": true},
            {"max_length": 120, "blank": false, "verbose_name": "modificator", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "modificator", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "modificationTime", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.DateTimeField", "choices": [], "meta": null, "name": "modificationTime", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "version", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.IntegerField", "choices": [], "meta": null, "name": "version", "auto_created": true},
        ],

    }
);

export class Meta extends KcpMeta{

    $classDescription = metaClassDescription;
    

    constructor(parent, { owner,creator,creationTime,modificator,modificationTime,version }){
        super(parent,{  owner ,  creator ,  creationTime ,  modificator ,  modificationTime ,  version  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Meta(null,data);
    }
}

export const nameClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Name",
        verboseName: "None",
        validationSchemas: {
        
            "language": Yup.string().max(2).required(),
            "translated": Yup.string().max(10).nullable(),
            "value": Yup.string().max(80).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 2, "blank": false, "verbose_name": {"en": "Language", "nl": "Taal"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [["nl", {"nl": "Nederlands"}], ["de", {"nl": "Duits"}], ["en", {"nl": "Engels"}], ["fr", {"nl": "Frans"}], ["fy", {"nl": "Fries"}]], "meta": null, "name": "language", "auto_created": false},
            {"max_length": 10, "blank": true, "verbose_name": {"en": "Translated", "nl": "Vertaald"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "translated", "auto_created": false},
            {"max_length": 80, "blank": false, "verbose_name": {"en": "(Organisation) Name", "nl": "Organisatie Naam"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "value", "auto_created": false},
        ],

    }
);

export class Name extends KcpName{

    $classDescription = nameClassDescription;
    

    constructor(parent, { language,translated,value }){
        super(parent,{  language ,  translated ,  value  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Name(null,data);
    }
}

export const objectHierarchyLinkClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.ObjectHierarchyLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "rel": Yup.string(),
            "href": Yup.string(),
            "_path": Yup.string(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 40, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "rel", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "rel", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "href", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "href", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": " path", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "_path", "auto_created": true},
        ],

    }
);

export class ObjectHierarchyLink extends KcpObjectHierarchyLink{

    $classDescription = objectHierarchyLinkClassDescription;
    

    constructor(parent, { id,rel,href,_path }){
        super(parent,{  id ,  rel ,  href ,  _path  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ObjectHierarchyLink(null,data);
    }
}

export const roleClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Role",
        verboseName: "None",
        validationSchemas: {
        
            "_id": Yup.string().max(125),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 125, "blank": false, "verbose_name": " id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "_id", "auto_created": true},
        ],

    }
);

export class Role extends KcpRole{

    $classDescription = roleClassDescription;
    

    constructor(parent, { _id }){
        super(parent,{  _id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Role(null,data);
    }
}

export const organisationRoleClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.organisation_roles.OrganisationRole",
        verboseName: "None",
        validationSchemas: {
        
            "_id": Yup.string().max(125),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 125, "blank": false, "verbose_name": " id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "_id", "auto_created": true},
        ],

    }
);

export class OrganisationRole extends Role{

    $classDescription = organisationRoleClassDescription;
    

    constructor(parent, { _id }){
        super(parent,{  _id  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new OrganisationRole(null,data);
    }
}

export const partyClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.model.Party",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(36),
            "source": Yup.mixed().required(),
            "meta": Yup.mixed().required(),
            "name": Yup.mixed().required(),
            "parentOrganisation": Yup.mixed().nullable(),
            "roles": Yup.array().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 36, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "source", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Source", "name": "source", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": "meta", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Meta", "name": "meta", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": {"en": "Organisation Name", "nl": "Organisatie Naam"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Name", "name": "name", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "parentOrganisation", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.ObjectHierarchyLink", "name": "parentOrganisation", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": {"en": "Roles", "nl": "Rollen"}, "is_group": false, "min_length": null, "null": true, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.organisation_roles.OrganisationRole", "name": "roles", "auto_created": false},
        ],

    }
);

export class Party extends BaseModel{

    $classDescription = partyClassDescription;
    
    id = null; 
    source = null; 
    meta = null; 
    name = null; 
    parentOrganisation = null; 
    roles = []; 

    constructor(parent, { id,source,meta,name,parentOrganisation,roles }){
        super(parent,{  });
        
        this.id = id ? id : null;
        this.source = source ? sourceFromJson(this, source): null;
        this.meta = meta ? metaFromJson(this, meta): null;
        this.name = name ? nameFromJson(this, name): null;
        this.parentOrganisation = parentOrganisation ? objectHierarchyLinkFromJson(this, parentOrganisation): null;
        this.roles = roles ? roles.map( item => organisationRoleFromJson(this, item)): [];
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            id: observable,  
            source: observable,  
            meta: observable,  
            name: observable,  
            parentOrganisation: observable,  
            roles: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Party(null,data);
    }
}

export const addressClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Address",
        verboseName: "None",
        validationSchemas: {
        
            "street": Yup.string().max(120).required(),
            "zipcode": Yup.string().max(8).required(),
            "localityName": Yup.string().max(60).required(),
            "country": Yup.string().max(2).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 120, "blank": false, "verbose_name": {"en": "Street", "nl": "Straat"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "street", "auto_created": false},
            {"max_length": 8, "blank": false, "verbose_name": {"en": "Zipcode", "nl": "Postcode"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "zipcode", "auto_created": false},
            {"max_length": 60, "blank": false, "verbose_name": {"en": "City", "nl": "Plaats"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "localityName", "auto_created": false},
            {"max_length": 2, "blank": true, "verbose_name": {"en": "Country", "nl": "Land"}, "default": "NL", "is_group": false, "min_length": null, "null": true, "init_value": "\"NL\"", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [["NL", {"nl": "Nederland"}], ["DE", {"nl": "Duitsland"}], ["PT", {"nl": "Portugal"}]], "meta": null, "name": "country", "auto_created": false},
        ],

    }
);

export class Address extends BaseModel{

    $classDescription = addressClassDescription;
    
    street = null; 
    zipcode = null; 
    localityName = null; 
    country = "NL"; 

    constructor(parent, { street,zipcode,localityName,country }){
        super(parent,{  });
        
        this.street = street ? street : null;
        this.zipcode = zipcode ? zipcode : null;
        this.localityName = localityName ? localityName : null;
        this.country = country ? country : "NL";
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            street: observable,  
            zipcode: observable,  
            localityName: observable,  
            country: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Address(null,data);
    }
}

export const baseContactInfoClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.BaseContactInfo",
        verboseName: "None",
        validationSchemas: {
        
            "phone": Yup.string().max(30).nullable(),
            "fax": Yup.string().max(25).nullable(),
            "email": Yup.string().max(60).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 30, "blank": true, "verbose_name": {"en": "Phonenumber", "nl": "Telefoonnummer"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "phone", "auto_created": false},
            {"max_length": 25, "blank": true, "verbose_name": {"en": "Fax", "nl": "Fax"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "fax", "auto_created": false},
            {"max_length": 60, "blank": true, "js_validate_regex": "^(([^<>()[\\]\\.,;:\\s@\"]+(\\.[^<>()[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$", "verbose_name": {"en": "Email", "nl": "Email"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "email", "auto_created": false},
        ],

    }
);

export class BaseContactInfo extends BaseModel{

    $classDescription = baseContactInfoClassDescription;
    
    phone = null; 
    fax = null; 
    email = null; 

    constructor(parent, { phone,fax,email }){
        super(parent,{  });
        
        this.phone = phone ? phone : null;
        this.fax = fax ? fax : null;
        this.email = email ? email : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            phone: observable,  
            fax: observable,  
            email: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BaseContactInfo(null,data);
    }
}

export const brandingClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Branding",
        verboseName: "None",
        validationSchemas: {
        
            "listLogo": Yup.string().max(300).required(),
            "logo": Yup.string().max(300).required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 300, "blank": false, "js_widgetclass": "uso.persistent.widgets.ImageUrlWidget", "verbose_name": "listLogo", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "listLogo", "auto_created": false},
            {"max_length": 300, "blank": false, "js_widgetclass": "uso.persistent.widgets.ImageUrlWidget", "verbose_name": "logo", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "logo", "auto_created": false},
        ],

    }
);

export class Branding extends BaseModel{

    $classDescription = brandingClassDescription;
    
    listLogo = null; 
    logo = null; 

    constructor(parent, { listLogo,logo }){
        super(parent,{  });
        
        this.listLogo = listLogo ? listLogo : null;
        this.logo = logo ? logo : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            listLogo: observable,  
            logo: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Branding(null,data);
    }
}

export const organisationClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.model.Organisation",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(36),
            "source": Yup.mixed().required(),
            "meta": Yup.mixed().required(),
            "name": Yup.mixed().required(),
            "parentOrganisation": Yup.mixed().nullable(),
            "roles": Yup.array().nullable(),
            "address": Yup.mixed().nullable(),
            "postalAddress": Yup.mixed().nullable(),
            "contactInfo": Yup.mixed().required(),
            "branding": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 36, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "source", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Source", "name": "source", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": "meta", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Meta", "name": "meta", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": {"en": "Organisation Name", "nl": "Organisatie Naam"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Name", "name": "name", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "parentOrganisation", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.ObjectHierarchyLink", "name": "parentOrganisation", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": {"en": "Roles", "nl": "Rollen"}, "is_group": false, "min_length": null, "null": true, "init_value": "[]", "is_super_class_field": true, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.organisation_roles.OrganisationRole", "name": "roles", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "address", "is_group": true, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Address", "name": "address", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": {"en": "Postal address", "nl": "Post adres"}, "is_group": true, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Address", "name": "postalAddress", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "contactInfo", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.BaseContactInfo", "name": "contactInfo", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "branding", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Branding", "name": "branding", "auto_created": false},
        ],

    }
);

export class Organisation extends Party{

    $classDescription = organisationClassDescription;
    
    address = null; 
    postalAddress = null; 
    contactInfo = null; 
    branding = null; 

    constructor(parent, { id,source,meta,name,parentOrganisation,roles,address,postalAddress,contactInfo,branding }){
        super(parent,{  id ,  source ,  meta ,  name ,  parentOrganisation ,  roles  });
        
        this.address = address ? addressFromJson(this, address): null;
        this.postalAddress = postalAddress ? addressFromJson(this, postalAddress): null;
        this.contactInfo = contactInfo ? baseContactInfoFromJson(this, contactInfo): null;
        this.branding = branding ? brandingFromJson(this, branding): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            address: observable,  
            postalAddress: observable,  
            contactInfo: observable,  
            branding: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Organisation(null,data);
    }
}

export const locationClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Location",
        verboseName: "None",
        validationSchemas: {
        
            "lat": Yup.number().required(),
            "lng": Yup.number().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": null, "blank": false, "verbose_name": {"en": "Latitude", "nl": "Latitude"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.FloatField", "choices": [], "meta": null, "name": "lat", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": {"en": "Longitude", "nl": "Longitude"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.FloatField", "choices": [], "meta": null, "name": "lng", "auto_created": false},
        ],

    }
);

export class Location extends KcpLocation{

    $classDescription = locationClassDescription;
    

    constructor(parent, { lat,lng }){
        super(parent,{  lat ,  lng  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Location(null,data);
    }
}

export const descriptionClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Description",
        verboseName: "None",
        validationSchemas: {
        
            "language": Yup.string().max(2).required(),
            "translated": Yup.string().max(10).nullable(),
            "value": Yup.string().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 2, "blank": false, "verbose_name": {"en": "Language", "nl": "Taal"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [["nl", {"nl": "Nederlands"}], ["de", {"nl": "Duits"}], ["en", {"nl": "Engels"}], ["fr", {"nl": "Frans"}], ["fy", {"nl": "Fries"}]], "meta": null, "name": "language", "auto_created": false},
            {"max_length": 10, "blank": true, "verbose_name": {"en": "Translated", "nl": "Vertaald"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "translated", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": {"en": "Description", "nl": "Omschrijving"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "value", "auto_created": false},
        ],

    }
);

export class Description extends KcpDescription{

    $classDescription = descriptionClassDescription;
    

    constructor(parent, { language,translated,value }){
        super(parent,{  language ,  translated ,  value  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Description(null,data);
    }
}

export const imageClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.Image",
        verboseName: "None",
        validationSchemas: {
        
            "thumbnailUrl": Yup.string().max(300).required(),
            "imageUrl": Yup.string().max(300).required(),
            "description": Yup.string().max(400).nullable(),
            "metadata": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 300, "blank": false, "verbose_name": "thumbnailUrl", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "thumbnailUrl", "auto_created": false},
            {"max_length": 300, "blank": false, "verbose_name": "imageUrl", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "imageUrl", "auto_created": false},
            {"max_length": 400, "blank": true, "verbose_name": "description", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "description", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": {"en": "metadata", "nl": "metadata"}, "is_group": false, "min_length": null, "null": true, "init_value": "{}", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "{}", "name": "metadata", "auto_created": false},
        ],

    }
);

export class Image extends KcpImage{

    $classDescription = imageClassDescription;
    
    metadata = {}; 

    constructor(parent, { thumbnailUrl,imageUrl,description,metadata }){
        super(parent,{  thumbnailUrl ,  imageUrl ,  description  });
        
        this.metadata = metadata ? metadata : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            metadata: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Image(null,data);
    }
}

export const objectLinkClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.ObjectLink",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().max(40),
            "rel": Yup.string(),
            "href": Yup.string(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 40, "blank": false, "verbose_name": "id", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "rel", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "rel", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "href", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.TextField", "choices": [], "meta": null, "name": "href", "auto_created": true},
        ],

    }
);

export class ObjectLink extends KcpObjectLink{

    $classDescription = objectLinkClassDescription;
    

    constructor(parent, { id,rel,href }){
        super(parent,{  id ,  rel ,  href  });
        
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ObjectLink(null,data);
    }
}

export const contactInfoClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.ContactInfo",
        verboseName: "None",
        validationSchemas: {
        
            "phone": Yup.string().max(30).nullable(),
            "fax": Yup.string().max(25).nullable(),
            "email": Yup.string().max(60).nullable(),
            "websiteUrl": Yup.string().max(256).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 30, "blank": true, "verbose_name": {"en": "Phonenumber", "nl": "Telefoonnummer"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "phone", "auto_created": false},
            {"max_length": 25, "blank": true, "verbose_name": {"en": "Fax", "nl": "Fax"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "fax", "auto_created": false},
            {"max_length": 60, "blank": true, "js_validate_regex": "^(([^<>()[\\]\\.,;:\\s@\"]+(\\.[^<>()[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$", "verbose_name": {"en": "Email", "nl": "Email"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "email", "auto_created": false},
            {"max_length": 256, "blank": true, "js_validate_regex": "^(((ftp|http|https)(:[/][/]){1})|((www.){1}))[-a-zA-Z0-9@:%_\\+.~#?&//=]+$", "verbose_name": {"en": "Website", "nl": "Website"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "websiteUrl", "auto_created": false},
        ],

    }
);

export class ContactInfo extends BaseContactInfo{

    $classDescription = contactInfoClassDescription;
    
    websiteUrl = null; 

    constructor(parent, { phone,fax,email,websiteUrl }){
        super(parent,{  phone ,  fax ,  email  });
        
        this.websiteUrl = websiteUrl ? websiteUrl : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            websiteUrl: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new ContactInfo(null,data);
    }
}

export const socialMediaClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.data_aspects.SocialMedia",
        verboseName: "{'nl': 'Social media', 'de': 'Social media', 'en': 'Social media'}",
        validationSchemas: {
        
            "twitterPage": Yup.string().max(256).nullable(),
            "facebookPage": Yup.string().max(256).nullable(),
            "pinterestPage": Yup.string().max(256).nullable(),
            "youtubePage": Yup.string().max(256).nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": 256, "blank": true, "js_validate_regex": "^(((ftp|http|https)(:[/][/]){1})|((www.){1}))[-a-zA-Z0-9@:%_\\+.~#?&//=]+$", "verbose_name": {"en": "Twitter page", "nl": "Twitter pagina"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "twitterPage", "auto_created": false},
            {"max_length": 256, "blank": true, "js_validate_regex": "^(((ftp|http|https)(:[/][/]){1})|((www.){1}))[-a-zA-Z0-9@:%_\\+.~#?&//=]+$", "verbose_name": {"en": "Facebook page", "nl": "Facebook pagina"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "facebookPage", "auto_created": false},
            {"max_length": 256, "blank": true, "js_validate_regex": "^(((ftp|http|https)(:[/][/]){1})|((www.){1}))[-a-zA-Z0-9@:%_\\+.~#?&//=]+$", "verbose_name": {"en": "Pinterest page", "nl": "Pinterest pagina"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "pinterestPage", "auto_created": false},
            {"max_length": 256, "blank": true, "js_validate_regex": "^(((ftp|http|https)(:[/][/]){1})|((www.){1}))[-a-zA-Z0-9@:%_\\+.~#?&//=]+$", "verbose_name": {"en": "Youtube page", "nl": "Youtube pagina"}, "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "youtubePage", "auto_created": false},
        ],

    }
);

export class SocialMedia extends BaseModel{

    $classDescription = socialMediaClassDescription;
    
    twitterPage = null; 
    facebookPage = null; 
    pinterestPage = null; 
    youtubePage = null; 

    constructor(parent, { twitterPage,facebookPage,pinterestPage,youtubePage }){
        super(parent,{  });
        
        this.twitterPage = twitterPage ? twitterPage : null;
        this.facebookPage = facebookPage ? facebookPage : null;
        this.pinterestPage = pinterestPage ? pinterestPage : null;
        this.youtubePage = youtubePage ? youtubePage : null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            twitterPage: observable,  
            facebookPage: observable,  
            pinterestPage: observable,  
            youtubePage: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new SocialMedia(null,data);
    }
}

export const baseSpotClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.model.BaseSpot",
        verboseName: "None",
        validationSchemas: {
        
            "location": Yup.mixed().required(),
            "name": Yup.array().min(1).required(),
            "description": Yup.array().min(1).required(),
            "images": Yup.array().nullable(),
            "roles": Yup.array().nullable(),
            "organisation": Yup.mixed().nullable(),
            "contactInfo": Yup.mixed().nullable(),
            "social_media": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": null, "blank": false, "verbose_name": {"en": "Location", "nl": "Locatie"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Location", "name": "location", "auto_created": false},
            {"max_length": null, "blank": false, "js_widgetclass": "uso.persistent.widgets.LocalizedNameWidget", "verbose_name": {"en": "Name", "nl": "Naam"}, "is_group": false, "min_length": 1, "null": false, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Name", "name": "name", "auto_created": false},
            {"max_length": null, "blank": false, "js_widgetclass": "uso.persistent.widgets.LocalizedDescriptionWidget", "verbose_name": {"en": "Omschrijving", "de": "Beschreibung", "nl": "Omschrijving"}, "is_group": false, "min_length": 1, "null": false, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Description", "name": "description", "auto_created": false},
            {"max_length": null, "blank": true, "js_widgetclass": "uso.persistent.widgets.ImagesWidget", "verbose_name": {"en": "Images", "nl": "Afbeeldingen"}, "is_group": false, "min_length": null, "null": true, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Image", "name": "images", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": {"en": "Roles", "nl": "Rollen"}, "is_group": false, "min_length": null, "null": true, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Role", "name": "roles", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "organisation", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.ObjectLink", "name": "organisation", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "contactInfo", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.ContactInfo", "name": "contactInfo", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "social media", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.SocialMedia", "name": "social_media", "auto_created": false},
        ],

    }
);

export class BaseSpot extends BaseModel{

    $classDescription = baseSpotClassDescription;
    
    location = null; 
    name = []; 
    description = []; 
    images = []; 
    roles = []; 
    organisation = null; 
    contactInfo = null; 
    social_media = null; 

    constructor(parent, { location,name,description,images,roles,organisation,contactInfo,social_media }){
        super(parent,{  });
        
        this.location = location ? locationFromJson(this, location): null;
        this.name = name ? name.map( item => nameFromJson(this, item)): [];
        this.description = description ? description.map( item => descriptionFromJson(this, item)): [];
        this.images = images ? images.map( item => imageFromJson(this, item)): [];
        this.roles = roles ? roles.map( item => roleFromJson(this, item)): [];
        this.organisation = organisation ? objectLinkFromJson(this, organisation): null;
        this.contactInfo = contactInfo ? contactInfoFromJson(this, contactInfo): null;
        this.social_media = social_media ? socialMediaFromJson(this, social_media): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            location: observable,  
            name: observable,  
            description: observable,  
            images: observable,  
            roles: observable,  
            organisation: observable,  
            contactInfo: observable,  
            social_media: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new BaseSpot(null,data);
    }
}

export const rootMetaClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.model.RootMeta",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().nullable(),
            "usoId": Yup.string().max(36),
            "meta": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": null, "blank": true, "verbose_name": "id", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": 36, "blank": false, "verbose_name": "usoId", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "usoId", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "meta", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Meta", "name": "meta", "auto_created": false},
        ],

    }
);

export class RootMeta extends KcpAnyObject{

    $classDescription = rootMetaClassDescription;
    
    usoId = null; 
    meta = null; 

    constructor(parent, { id,usoId,meta }){
        super(parent,{  id  });
        
        this.usoId = usoId ? usoId : null;
        this.meta = meta ? metaFromJson(this, meta): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            usoId: observable,  
            meta: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RootMeta(null,data);
    }
}

export const rootSourceMetaClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.model.RootSourceMeta",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().nullable(),
            "usoId": Yup.string().max(36),
            "meta": Yup.mixed().required(),
            "source": Yup.mixed().required(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": null, "blank": true, "verbose_name": "id", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": 36, "blank": false, "verbose_name": "usoId", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "usoId", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "meta", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Meta", "name": "meta", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": "source", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Source", "name": "source", "auto_created": false},
        ],

    }
);

export class RootSourceMeta extends RootMeta{

    $classDescription = rootSourceMetaClassDescription;
    
    source = null; 

    constructor(parent, { id,usoId,meta,source }){
        super(parent,{  id ,  usoId ,  meta  });
        
        this.source = source ? sourceFromJson(this, source): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            source: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new RootSourceMeta(null,data);
    }
}

export const spotClassDescription = new ClassDescription(
    {
        clazzName : "uso.db.model.Spot",
        verboseName: "None",
        validationSchemas: {
        
            "id": Yup.string().nullable(),
            "usoId": Yup.string().max(36),
            "meta": Yup.mixed().required(),
            "source": Yup.mixed().required(),
            "location": Yup.mixed().required(),
            "name": Yup.array().min(1).required(),
            "description": Yup.array().min(1).required(),
            "images": Yup.array().nullable(),
            "roles": Yup.array().nullable(),
            "organisation": Yup.mixed().nullable(),
            "contactInfo": Yup.mixed().nullable(),
            "social_media": Yup.mixed().nullable(),
        },
        arrayItemValidationSchema: {
        
        },
        fields: [ 
            {"max_length": null, "blank": true, "verbose_name": "id", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "id", "auto_created": true},
            {"max_length": 36, "blank": false, "verbose_name": "usoId", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.fields.CharField", "choices": [], "meta": null, "name": "usoId", "auto_created": true},
            {"max_length": null, "blank": false, "verbose_name": "meta", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Meta", "name": "meta", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": "source", "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": true, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Source", "name": "source", "auto_created": false},
            {"max_length": null, "blank": false, "verbose_name": {"en": "Location", "nl": "Locatie"}, "is_group": false, "min_length": null, "null": false, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Location", "name": "location", "auto_created": false},
            {"max_length": null, "blank": false, "js_widgetclass": "uso.persistent.widgets.LocalizedNameWidget", "verbose_name": {"en": "Name", "nl": "Naam"}, "is_group": false, "min_length": 1, "null": false, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Name", "name": "name", "auto_created": false},
            {"max_length": null, "blank": false, "js_widgetclass": "uso.persistent.widgets.LocalizedDescriptionWidget", "verbose_name": {"en": "Omschrijving", "de": "Beschreibung", "nl": "Omschrijving"}, "is_group": false, "min_length": 1, "null": false, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Description", "name": "description", "auto_created": false},
            {"max_length": null, "blank": true, "js_widgetclass": "uso.persistent.widgets.ImagesWidget", "verbose_name": {"en": "Images", "nl": "Afbeeldingen"}, "is_group": false, "min_length": null, "null": true, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Image", "name": "images", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": {"en": "Roles", "nl": "Rollen"}, "is_group": false, "min_length": null, "null": true, "init_value": "[]", "is_super_class_field": false, "type": "persistent.related.ListOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.Role", "name": "roles", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "organisation", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.ObjectLink", "name": "organisation", "auto_created": true},
            {"max_length": null, "blank": true, "verbose_name": "contactInfo", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.ContactInfo", "name": "contactInfo", "auto_created": false},
            {"max_length": null, "blank": true, "verbose_name": "social media", "is_group": false, "min_length": null, "null": true, "init_value": "null", "is_super_class_field": false, "type": "persistent.related.OneOf", "choices": [], "meta": null, "relation_clazz": "uso.db.data_aspects.SocialMedia", "name": "social_media", "auto_created": false},
        ],

    }
);

export class Spot extends RootSourceMeta{

    $classDescription = spotClassDescription;
    
    location = null; 
    name = []; 
    description = []; 
    images = []; 
    roles = []; 
    organisation = null; 
    contactInfo = null; 
    social_media = null; 

    constructor(parent, { id,usoId,meta,source,location,name,description,images,roles,organisation,contactInfo,social_media }){
        super(parent,{  id ,  usoId ,  meta ,  source  });
        
        this.location = location ? locationFromJson(this, location): null;
        this.name = name ? name.map( item => nameFromJson(this, item)): [];
        this.description = description ? description.map( item => descriptionFromJson(this, item)): [];
        this.images = images ? images.map( item => imageFromJson(this, item)): [];
        this.roles = roles ? roles.map( item => roleFromJson(this, item)): [];
        this.organisation = organisation ? objectLinkFromJson(this, organisation): null;
        this.contactInfo = contactInfo ? contactInfoFromJson(this, contactInfo): null;
        this.social_media = social_media ? socialMediaFromJson(this, social_media): null;
        this.$fieldsMeta = this.$classDescription.createFieldsMeta(this,this.$fieldsMeta);
        
        makeObservable(this, { 
            location: observable,  
            name: observable,  
            description: observable,  
            images: observable,  
            roles: observable,  
            organisation: observable,  
            contactInfo: observable,  
            social_media: observable,  
        })
        
        this.isValid = this.validate();
    }

    deepCopy = () => {
        const data = this.toJSON();
        return new Spot(null,data);
    }
}


export function sourceFromJson(parent, data){

    if (data){ 
        return new Source(parent, data);
    }else{
        return null;
    }
}
export function metaFromJson(parent, data){

    if (data){ 
        return new Meta(parent, data);
    }else{
        return null;
    }
}
export function nameFromJson(parent, data){

    if (data){ 
        return new Name(parent, data);
    }else{
        return null;
    }
}
export function objectHierarchyLinkFromJson(parent, data){

    if (data){ 
        return new ObjectHierarchyLink(parent, data);
    }else{
        return null;
    }
}
export function organisationRoleFromJson(parent, data){

    if (data){ 
        return new OrganisationRole(parent, data);
    }else{
        return null;
    }
}
export function addressFromJson(parent, data){

    if (data){ 
        return new Address(parent, data);
    }else{
        return null;
    }
}
export function baseContactInfoFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "uso.db.data_aspects.ContactInfo":
                return new ContactInfo(parent, data);
            case "uso.db.data_aspects.BaseContactInfo":
             return new BaseContactInfo(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function brandingFromJson(parent, data){

    if (data){ 
        return new Branding(parent, data);
    }else{
        return null;
    }
}
export function organisationFromJson(parent, data){

    if (data){ 
        return new Organisation(parent, data);
    }else{
        return null;
    }
}
export function locationFromJson(parent, data){

    if (data){ 
        return new Location(parent, data);
    }else{
        return null;
    }
}
export function descriptionFromJson(parent, data){

    if (data){ 
        return new Description(parent, data);
    }else{
        return null;
    }
}
export function imageFromJson(parent, data){

    if (data){ 
        return new Image(parent, data);
    }else{
        return null;
    }
}
export function roleFromJson(parent, data){

    if (data){ 
        const _clazz = data._clazz;
        switch (_clazz) { 
            case "uso.db.organisation_roles.OrganisationRole":
                return new OrganisationRole(parent, data);
            case "uso.db.data_aspects.Role":
             return new Role(parent, data);
            default:
                console.log("unknown type");
        }
    }else{
        return null;
    }
}
export function objectLinkFromJson(parent, data){

    if (data){ 
        return new ObjectLink(parent, data);
    }else{
        return null;
    }
}
export function contactInfoFromJson(parent, data){

    if (data){ 
        return new ContactInfo(parent, data);
    }else{
        return null;
    }
}
export function socialMediaFromJson(parent, data){

    if (data){ 
        return new SocialMedia(parent, data);
    }else{
        return null;
    }
}