import * as React from 'react';

import Box from '@material-ui/core/Box';

import { FormWidget, FormConfigurations } from 'kiini/Forms';
import TextField from 'kiini/TextField';
import LocaleField from 'kiini/LocaleField';
import LocationField from 'kiini/LocationField';
import BooleanField from 'kiini/BooleanField';
import SelectionChoicesField from 'kiini/SelectionChoicesField';
import OptionalOneOfField from 'kiini/OptionalOneOfField';
import {
    Copyrights,
    copyrightsClassDescription
} from 'model/contentObjects';
import TagsWidget from 'components/contentObjects/TagsWidget';


const baseFileAssetConfiguration = {
    groups: [
        {
            label: "Common",
            fields: [
                { fieldName: "name", label: "Name", widget: TextField },
                { fieldName: "description", label: "Description", widget: TextField, widgetProps: { multiline: true, rows: 5 } },
                { fieldName: "locale", label: "Locale", widget: LocaleField },
                { fieldName: "isInLibrary", label: "In library", widget: BooleanField },
                { fieldName: "location", label: "Location", widget: LocationField },
                {
                    fieldName: "copyrights",
                    label: "Copyrights",
                    widget: OptionalOneOfField,
                    widgetProps: {
                        createItem: () => (new Copyrights(null, {}))
                    }
                },
               
            ]
        },
        {
            label: "Tags",
            fields: [
                {
                    fieldName: "tags",
                    label: "Tags",
                    widget: TagsWidget
                },
            ]
        }
    ]
}


const fileAssetFormConfigurationsData = {
    "content_objects.model.Image": baseFileAssetConfiguration,
    "content_objects.model.Audio": baseFileAssetConfiguration,
    "content_objects.model.Video": baseFileAssetConfiguration,
    "content_objects.model.Copyrights": {
        fields: [
            { fieldName: "attribution", label: "Attribution", widget: TextField },
            {
                fieldName: "licenceType",
                label: "Type",
                widget: SelectionChoicesField,
                widgetProps: {
                    clazzDescription: copyrightsClassDescription,
                }
            }
        ]
    }

}

const fileAssetFormConfigurations = new FormConfigurations(fileAssetFormConfigurationsData);

const FileAssetForm = ({ instance, store }) => {
    return <FormWidget
                instance={instance}
                store={store}
                formConfigurations={fileAssetFormConfigurations} />
}

export default FileAssetForm;