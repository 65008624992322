import React from 'react';

import BaseInput from '../BaseInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import { observer } from "mobx-react";


import {inputLabelStyle, formControlStyle } from '../Style';

const LocaleOptionsData = [
	{ label: 'Alle', value: '*' },
	{ label: 'Nederlands', value: 'nl' },
	{ label: 'Engels', value: 'en' },
	{ label: 'Duits', value: 'de' },
	{ label: 'Deens', value: 'da' },
	{ label: 'Frans', value: 'fr' },
	{ label: 'Portugees', value: 'pt' },
	{ label: 'Spaans', value: 'es' },

];

const LocaleField = observer(({id, label, instance, fieldName, ...rest }) =>{

    const formControlClasses = formControlStyle();
    const inputLabelClasses = inputLabelStyle();

    const onChange = (evt) => {
        instance.setValue(fieldName, evt.target.value);
    } 

    const menuItems = LocaleOptionsData.map( (entry,index) =>  <MenuItem key={index} value={entry.value}>{entry.label}</MenuItem>);
    const value = instance[fieldName] ? instance[fieldName]: "";

    // return <Select label={props.label} name={props.name} required={props.required} formControlProps={{variant:"outlined"}} data={LocaleOptionsData} />
    return <FormControl fullWidth classes={formControlClasses}>
        <InputLabel shrink={false} classes={inputLabelClasses} htmlFor={id}>
                {label}
        </InputLabel>
        <Select
          id={id}
          value={value}
          onChange={onChange}
          label={label}
          input={<BaseInput />}
        >
          {menuItems}
        </Select>
      </FormControl>
});

export default LocaleField;