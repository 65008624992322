import BaseStore from "jimbo/store";

import { accountsPackageClassFactory } from "schema/accounts";

export default class ResetPasswordStore  extends BaseStore {

    
    constructor({rootStore, baseApi, authenticationApi}) {
        super({rootStore, baseApi});
        this.authStore = rootStore.authStore;
        this.authenticationApi = authenticationApi;
    }

    fromJsonToObject = (data) => {
        const passwordReset =  accountsPackageClassFactory.fromJson(data);
        return passwordReset;
    }

    doCreateNewObject =  ({initValues}) => {
        return null; // not expected to call
    }

    afterSave = () => {
        const access = this.selectedObject.getFieldValue('access');
        if (access){
            const refresh = this.selectedObject.getFieldValue('refresh');
            this.authStore.setAccess(access, refresh);      
            this.authenticationApi.getUserData().then(({ user, organisations}) => {
                this.authStore.setUser(user, organisations,{redirect: false})
            });
        }
    }

}

