import axios from 'axios';
//import moment from 'moment';

import BaseApi from 'jimbo/api';

// defaults
let jimbogo_service_api_host =  'https://api.jimbogo.com'
let jimbogo_api_host =  'https://jimbogo.com';

if(window.location.hostname === 'creator.test.storytrails.eu'){
    jimbogo_service_api_host =  'https://api.test.jimbogo.com';
    jimbogo_api_host = 'https://test2.jimbogo.com';
}else if(window.location.hostname === 'localhost'){
    jimbogo_service_api_host =  'http://localhost:8000';
    jimbogo_api_host = 'http://127.0.0.1:18000';

    //jimbogo_service_api_host =  'https://api.test.jimbogo.com';
    //jimbogo_api_host = 'https://test2.jimbogo.com';
}

console.log("host is", jimbogo_service_api_host);

export const JIMBOGO_SERVICE_API_HOST = jimbogo_service_api_host;
export const JIMBOGO_API_V1_HOST = jimbogo_api_host;
export const JIMBO_APP_ANALYTICS_HOST = 'https://analytics.jimbogo.com'


const CONTENT_OBJECTS_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/content-objects`;
const CONTENT_OBJECTS_TAGS_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/tags/content-objects`;

const EXPERIENCES_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/experiences`;
const EXPERIENCES_DRAFTS_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/drafts/experiences`;
const EXPERIENCES_REPACKAGE_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/repackages/experiences`;

const EXPERIENCES_PUBLISH_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/publish/experiences`;
const EXPERIENCES_REMOVEDPUBLISH_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/remove-published/experiences`;

const CONTENT_OBJECTS_SEARCH_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/content-objects-search`;
const JWT_TOKEN_URL = `${JIMBOGO_SERVICE_API_HOST}/api/token/`;
export const JWT_REFRESH_TOKEN_URL = `${JIMBOGO_SERVICE_API_HOST}/api/token/refresh/`;
const USER_DATA_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/accounts/user-organisation`;
// const ACCOUNT_ACTIVATION_ENDPOINT =  `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/accounts/activation`;
const ACCOUNT_FORGOT_PASSWORD_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/accounts/forgot-password/`;
const ACCOUNT_RESET_PASSWORD_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/accounts/reset-password/`;


const NOTIFICATIONS_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/restricted/content-objects-notifications`
const RATINGS_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/published-content-objects-ratings/organisation`
const PUBLISHED_CONTENT_OBJECTS_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/published-content-objects-search/`
const MAPCONFIG_OBJECTS_ENDPOINT = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/maps/map-configs/`

const SPOT_OBJECTS_FAST_ENTRY_ENDPOINT =  `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/spots/fast-entry`;


const ANALYTICS_TIME_SERIES_ENDPOINT = `${JIMBO_APP_ANALYTICS_HOST}/api/v1/stats/timeseries`;
const ANALYTICS__CONTENT_OBJECT_AGGREGATES_API = `${JIMBO_APP_ANALYTICS_HOST}/api/stats/content_object_aggregates`;



const queryMapToQueryString = (queryObject) => {

    let queryArgs = [];

    const addQueryArgs = (value, key) => {
        if (Array.isArray(value)) {
            const notNullValues = value.filter(value => value != null); 
            queryArgs = queryArgs.concat(notNullValues.map(part => `${key}=${encodeURIComponent(part)}`));
        } else {
            if (value != null){
                queryArgs.push(`${key}=${encodeURIComponent(value)}`)
            }
        }
    }

    const queryMap = new Map(Object.entries(queryObject));

    if (queryMap.has("search.all")){
        const searchAllValue = queryMap.get("search.all");
        if (searchAllValue){
            queryMap.set("search.all", searchAllValue + "*")
        }else{
            queryMap.delete("search.all");
        }
    }

    queryMap.forEach(addQueryArgs)
    return queryArgs.join('&');
}






function contentObjectFilterToQuery(start, count, filter, sort) {
    let params = { start, count, ...filter };
    if (sort){
        params = {...params, ...sort}; 
    }
    const query = '?' + queryMapToQueryString(params);
    return query
}







class AuthenticationApi {

    constructor() {
        if (!AuthenticationApi.instance) {
            AuthenticationApi.instance = this;
        }
        return AuthenticationApi.instance;
    }

    authenticate = async (username, password) => {
        const res = await axios.post(JWT_TOKEN_URL, { username, password });
        this.accessToken = res.data.access;        
        return {
            access: res.data.access,
            refresh: res.data.refresh,
        }
    }

    getRefreshToken = (refresh) => {
        return axios.post(JWT_REFRESH_TOKEN_URL,{refresh});
    }

    getUserData = async() => {
        const detailEndpoint = `${USER_DATA_ENDPOINT}/`;
        let res = await axios.get(detailEndpoint);
        const userData = res.data;

        console.log( "userData ", userData)
        if(userData.organisations && userData.organisations.length > 0){
            this.organisation = userData.organisations[0];

        }
        return userData;
    }

    setOrganisation = (organisation) => {
        this.organisation = organisation
    }

    getOrganisationId = () => {
        return this.organisation.id;
    }

}




// Object.freeze(authenticationApi);



class AnalyticsApi {


    filterToQuery = (start, count, filter, sort) =>{
        let params = { 
            start, 
            count, ...filter 
        };
        return queryMapToQueryString(params);
        
    }

    toFilterQueryPart = (filter) => {
        const filterArray = Object.entries(filter).map(([k,v]) => `${k}==${v}`);
        return filterArray.join(";")
    }

    getTimeSeries = async(params, filter) =>{

        const filterParam = this.toFilterQueryPart(filter);
        params['filters'] = filterParam;
        const query = queryMapToQueryString(params)
        const resourceUrl = `${ANALYTICS_TIME_SERIES_ENDPOINT}?${query}`;
        let res = await axios.get(resourceUrl);
        return res.data;
    }

    getContentObjectAggregates = async(params, filter) => {
        const filterParam = this.toFilterQueryPart(filter);
        params['filters'] = filterParam;
        const query = queryMapToQueryString(params)
        const resourceUrl = `${ANALYTICS__CONTENT_OBJECT_AGGREGATES_API}?${query}`;
        let res = await axios.get(resourceUrl);
        return res.data;
    }

}


class SpotsApi {

    //bbox.sw.lat=53.221940179557464&bbox.sw.lng=6.558365821838379&bbox.ne.lng=6.568440198898315&bbox.ne.lat=53.224580171404355

    getEndpoint = () => {
        return `${JIMBOGO_API_V1_HOST}/api/jimbo/v1.1/spots/`;
    }

    getObject = async (id) => {
        const endPoint = this.getEndpoint();
        const spotEndpoint = `${endPoint}/${id}/?representation=flat`;
        let res = await axios.get(spotEndpoint);
        return res.data;
    }

    getObjects = async (start, count, filter, sort) => {

        const query = contentObjectFilterToQuery(start, count, filter, sort)

        const objectsEndpoint = this.getEndpoint();
        const resourceUrl = `${objectsEndpoint}/${query}`;

        const res = await axios(resourceUrl);
        return res.data;
    }

}

class SpotObjectsFastEntryApi {

    constructor(authenticationApi) {
        this.authenticationApi = authenticationApi;
    }

    save = async (fastSpotEntryJsonObject) => {
        if (fastSpotEntryJsonObject.spot.id) {
            return this.put(fastSpotEntryJsonObject)
        } else {
            return this.post(fastSpotEntryJsonObject)
        }
    }

    put = async (fastSpotEntryJsonObject) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const updateEndpoint = `${SPOT_OBJECTS_FAST_ENTRY_ENDPOINT}/${organisationId}/${fastSpotEntryJsonObject.spot.id}/`;
        let res = await axios.put(updateEndpoint, fastSpotEntryJsonObject);
        return res.data;
    }

    post = async (fastSpotEntryJsonObject) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        
        const updateEndpoint = `${SPOT_OBJECTS_FAST_ENTRY_ENDPOINT}/${organisationId}/`;
        let res = await axios.post(updateEndpoint, fastSpotEntryJsonObject);
        return res.data;
    }

    get = async(id) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        
        const detailEndpoint = `${SPOT_OBJECTS_FAST_ENTRY_ENDPOINT}/${organisationId}/${id}/`;
        let res = await axios.get(detailEndpoint);
        return res.data;
    }

    delete = async (id) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const detailEndpoint = `${SPOT_OBJECTS_FAST_ENTRY_ENDPOINT}/${organisationId}/${id}/`;
        await axios.delete(detailEndpoint);
        return id;
    }

}


class ObjectsApi {

    constructor(authenticationApi) {
        this.authenticationApi = authenticationApi;
    }


    getObjectsEndpoint = () => {
        return `${CONTENT_OBJECTS_SEARCH_ENDPOINT}`;
    }

    getObjectsBase = async (start, count, filter, sort) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const extendedFilter = {...filter, organisationId};
        const query = contentObjectFilterToQuery(start, count, extendedFilter, sort)

        const objectsEndpoint = this.getObjectsEndpoint();
        const resourceUrl = `${objectsEndpoint}/${query}`;

        const res = await axios(resourceUrl);
        return res.data;
    }


    getIndexedObjects = async (ids) => {
        const qids = ids.join(',')
        const query = `?ids=${qids}`
        let resourceUrl = `${CONTENT_OBJECTS_SEARCH_ENDPOINT}/${query}`;
        const res = await axios(resourceUrl);
        return res.data;
    }

    newObject = async () => {
        throw Error("must be implemented by subclass")
    }


    getObject = async (id) => {
        console.log("get object")
        const organisationId = this.authenticationApi.getOrganisationId();
        console.log("get object ", organisationId);
        
        const detailEndpoint = `${CONTENT_OBJECTS_ENDPOINT}/${organisationId}/${id}/`;
        let res = await axios.get(detailEndpoint);
        return res.data;
    }


    get = async (id) => {
        const object = await this.getObject(id);
        console.log("retrieved ", object)
        return await this.syncAfterUpdateLocalObject(object)
    }

    delete = async (id) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const detailEndpoint = `${CONTENT_OBJECTS_ENDPOINT}/${organisationId}/${id}/`;
        await axios.delete(detailEndpoint);
        return id;
    }


    save = async (contentObject) => {
        if (contentObject.id) {
            return this.put(contentObject)
        } else {
            return this.post(contentObject)
        }
    }


    putObject = async (contentObject) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const updateEndpoint = `${CONTENT_OBJECTS_ENDPOINT}/${organisationId}/${contentObject.id}/`;
        let res = await axios.put(updateEndpoint, contentObject);
        return res.data;
    }

    put = async (contentObject) => {
        const object = await this.putObject(contentObject)
        return await this.syncAfterUpdateLocalObject(object);
    }



    postObject = async (contentObject) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        
        const updateEndpoint = `${CONTENT_OBJECTS_ENDPOINT}/${organisationId}/`;
        let res = await axios.post(updateEndpoint, contentObject);
        return res.data;
    }

    post = async (contentObject) => {
        
        const object = await this.postObject(contentObject)
        return await this.syncAfterUpdateLocalObject(object);
    }

    /** 
    * Default implementation just returns the object
    * 
    **/
    syncAfterUpdateLocalObject = async (contentObject) => {
        return new Promise((resolve, reject) => {
            resolve(contentObject)
        })
    }

};



class TagsApi{
    
    constructor(authenticationApi) {
            this.authenticationApi = authenticationApi;
    }

    getObjects = async(filter) => { 
        const query = '?' + queryMapToQueryString(filter);
        const organisationId = this.authenticationApi.getOrganisationId();
        const contentObjectTagsEndpoint = `${CONTENT_OBJECTS_TAGS_ENDPOINT}/${organisationId}/`;
        const url = `${contentObjectTagsEndpoint}/${query}`;
        const res = await axios(url);
        if (res){
            return res.data;
        }else{
            // in case of signout because 
            console.log("res is null, because of signout for resouceUrl ", url)
            return null;
        }
    }

}    


class LibraryObjectsApi extends ObjectsApi {

    getObjects = async (start, count, filter, sort) => {
        const filterWithBaseType = { ...filter, baseType: "content-object"}
        return this.getObjectsBase(start, count, filterWithBaseType, sort)
    }

}


class FileAssetsApi extends ObjectsApi {

    getObjects = async (start, count, filter, sort) => {
        const filterWithBaseType = { ...filter, baseType: "file-asset" }
        return this.getObjectsBase(start, count, filterWithBaseType, sort)
    }

}

class RunnableObjectsApi extends ObjectsApi {
    getObjects = async (start, count, filter, sort) => {
        const filterWithBaseType = { ...filter, baseType: "runnable-object" }
        return this.getObjectsBase(start, count, filterWithBaseType, sort)
    }

    uploadFile = async(file, instance, onUploadProgress)=>{
        
        const dataJsonStr = JSON.stringify(instance); 
        const organisationId = this.authenticationApi.getOrganisationId();
        
        const formData = new FormData()
        formData.append("file", file);
        formData.append("instanceAsJsonStr", dataJsonStr);


        const response = await axios.post(
            `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/runnable-objects/${organisationId}/`, 
            formData, 
            {
              headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress
            }
        );
        return response.data;
    }

    uploadFiles = async({files, instance, onUploadProgress})=>{
        
        const dataJsonStr = JSON.stringify(instance); 
        const organisationId = this.authenticationApi.getOrganisationId();
        const formData = new FormData()

        files.forEach( file => { formData.append("file", file)});
        formData.append("instanceAsJsonStr", dataJsonStr);
        formData.append("organisationId", organisationId)
        
        const response = await axios.post(
            `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/store-runnable-package/`, 
            formData, 
            {
              headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress
            }
        );
        return response.data;
    }
}


class ContentObjectApi extends ObjectsApi {



    getObjects = async (start, count, filter, sort) => {
        const filterWithBaseType = { ...filter, baseType: "content-object" }
        return this.getObjectsBase(start, count, filterWithBaseType, sort)
    }

    newObject = async () => {
        return new Promise((resolve, reject) => {
            throw Error("deprected")
            //resolve(null);
        })
    }

    copy = async (id) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const createCopyEndpoint = `${EXPERIENCES_ENDPOINT}/${organisationId}/${id}/copy/`;
        let res = await axios.post(createCopyEndpoint);
        return res.data;
    }



};

//const DEFAULT_IMAGE_URL = `${JIMBOGO_SERVICE_API_HOST}/static/content_objects/img/experience_default.png`




class BaseOrganisationApi extends BaseApi{

    constructor({authenticationApi, endPoint}) {
        super({endPoint})
        this.authenticationApi = authenticationApi;
    }

    getObjectsEndpoint = () =>{
        const organisationId = this.authenticationApi.getOrganisationId();
        const objectsEndpoint = `${this.endPoint}/${organisationId}/`;
        return objectsEndpoint;
    }

}


class ExperiencesApi extends ObjectsApi {

    constructor(authenticationApi, contentObjectsApi) {
        super(authenticationApi);
        this.contentObjectsApi = contentObjectsApi;
    }

    getObjectsEndpoint = () => {
        return `${EXPERIENCES_ENDPOINT}`;
    }

    getObjectsBase = async (start, count, filter, sort) => {

        //const organisationId = filter.organisationId;
        const organisationId = this.authenticationApi.getOrganisationId();

        const query = contentObjectFilterToQuery(start, count, filter, sort)

        const objectsEndpoint = this.getObjectsEndpoint();
        const resourceUrl = `${objectsEndpoint}/${organisationId}/${query}`;

        console.log("getting ", resourceUrl)

        const res = await axios(resourceUrl);
        if (res){
            return res.data;
        }else{
            // in case of signout because 
            console.log("res is null, because of signout for resouceUrl ", resourceUrl)
            return null;
        }
    }

    getObjects = async (start, count, filter, sort) => {
        const filterWithBaseType = { ...filter, baseType: "experience" }
        return this.getObjectsBase(start, count, filterWithBaseType, sort)
    }

    // newObject = async () => {
    //     // const organisationId = this.authenticationApi.getOrganisationId();
        
    //     return new Promise((resolve, reject) => {
    //         const newInstance = getNewEditableExperience(DEFAULT_IMAGE_URL);
    //         resolve(newInstance)
    //     })
    // }


    createDraft = async (id) => {

        const organisationId = this.authenticationApi.getOrganisationId();
        const createDraftsEndpoint = `${EXPERIENCES_DRAFTS_ENDPOINT}/${organisationId}/${id}`;
        let res = await axios.post(createDraftsEndpoint, {});
        return res.data;
    }

    repackage = async (id) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const repackageEndpoint = `${EXPERIENCES_REPACKAGE_ENDPOINT}/${organisationId}/${id}`;
        let res = await axios.post(repackageEndpoint, {});
        return res.data;
    }

    publish = async (id) => {

        const organisationId = this.authenticationApi.getOrganisationId();
        const createDraftsEndpoint = `${EXPERIENCES_PUBLISH_ENDPOINT}/${organisationId}/${id}`;
        let res = await axios.post(createDraftsEndpoint, {});
        return res.data;
    }

    removePublished = async (id)=> {
        const organisationId = this.authenticationApi.getOrganisationId();
        const removePublishedEndpoint = `${EXPERIENCES_REMOVEDPUBLISH_ENDPOINT}/${organisationId}/${id}`;
        let res = await axios.post(removePublishedEndpoint, {});
        return res.data;
    }

    copy = async (id) => {
        const organisationId = this.authenticationApi.getOrganisationId();
        const createDraftsEndpoint = `${EXPERIENCES_ENDPOINT}/${organisationId}/${id}/copy/`;
        let res = await axios.post(createDraftsEndpoint);
        return res.data;
    }

    syncAfterUpdateLocalObject = async (experience) => {

        const organisationId = this.authenticationApi.getOrganisationId();
        
        console.log("syncing ", experience);

        try {

            // const getContentObject = async (id) => {
            //     return this.contentObjectsApi.get(id)
            // }

            const getSpots = async(ids) => {
                const count = ids.length;
                const idsQueryValue= ids.join(';')
                const spotsEndpoint = `${JIMBOGO_API_V1_HOST}/api/jimbo/v1.1/spots/?ids=${idsQueryValue}&count=${count}`;
                const res = await axios.get(spotsEndpoint);
                const spots = res.data.spots.map(resultObject => { 
                    const spot = resultObject.spot;
                    spot._clazz = spot.address ? "uso.db.model.AddressableSpot" : "uso.db.model.Spot";
                    return spot
                });
                return spots;
            }

            // const getSpot = async (id) => {
            //     const spotEndpoint = `${JIMBOGO_API_V1_HOST}/api/jimbo/v1.1/spots/${id}/?representation=flat`;
                
            //     try{
            //         let res = await axios.get(spotEndpoint);
            //         return res.data;
            //     }catch(error){
            //         console.log(error);
            //         return null;
            //     }
            // }


            const getLinkedContentObjects = async( experienceId, organisationId) => {
                //const headers = { 'Authorization': `Bearer ${accessToken}` }
                const linkedContentObjectsEndporint = `${JIMBOGO_SERVICE_API_HOST}/api/jimbo/v1.1/content-objects/${organisationId}/${experienceId}/linked-objects/`;
                let res = await axios.get(linkedContentObjectsEndporint);
                return res.data;
            }

            // retrieve the content objects that are linked.
            const contentIdsToRetrieve =
                experience.contentObjectLinks.filter(contentObjectLink => contentObjectLink._clazz === "content_objects.model.ContentObjectLink" && !contentObjectLink['_cachedContentObject'])
                    .map(contentObjectLink => contentObjectLink.contentObjectId);

            const spotContentIdsToRetrieve =
                experience.contentObjectLinks.filter(contentObjectLink => contentObjectLink._clazz === "content_objects.model.SpotContentObjectLink" && !contentObjectLink['_cachedContentObject'])
                    .map(contentObjectLink => contentObjectLink.contentObjectId);

            if (contentIdsToRetrieve.length === 0 && spotContentIdsToRetrieve.length === 0) {
                // no objects to return
                return new Promise((resolve, reject) => {
                    resolve({experience,spotsMappedOnId: new Map(), contentObjectsMappedOnId: new Map()})
                })
            }


            // const spotIdsToRetrievers = spotContentIdsToRetrieve.map(objectId => {
            //     return getSpot(objectId)
            // });

            // const retrievedSpots = await Promise.all(spotIdsToRetrievers);

            // const spots = retrievedSpots.filter(item => item !== null);


            const spots = spotContentIdsToRetrieve.length > 0 ? await getSpots(spotContentIdsToRetrieve): [];
            const spotsMappedOnId = spots.reduce((accSpotMap, spot) => {
                accSpotMap.set(spot.id,spot);
                return accSpotMap;
            }, new Map());
            
            const contentObjectsListResult = await getLinkedContentObjects(experience.id, organisationId);
            
            var contentObjectsMappedOnId = contentObjectsListResult.objects.reduce(
                (mapped, objectResult) => {
                    mapped.set(objectResult.instance.id, objectResult.instance);
                    return mapped;
                }, new Map());

            console.log("after sync ", experience)

            return {experience, spotsMappedOnId, contentObjectsMappedOnId};
        } catch (error) {
            console.error("error here ", error);
            throw error;
        }
    }
};


export const authenticationApi = new AuthenticationApi();
export const contentObjectsApi = new ContentObjectApi(authenticationApi);
export const fileAssetsApi = new FileAssetsApi(authenticationApi);
export const runnableObjectsApi = new RunnableObjectsApi(authenticationApi); 
export const experiencesApi = new ExperiencesApi(authenticationApi, contentObjectsApi);
export const libraryObjectsApi = new LibraryObjectsApi(authenticationApi);
export const notificationsApi = new BaseOrganisationApi({authenticationApi, endPoint: NOTIFICATIONS_ENDPOINT});
export const ratingsApi = new BaseOrganisationApi({authenticationApi, endPoint: RATINGS_ENDPOINT});
export const publishedContentObjectsApi = new BaseApi({endPoint: PUBLISHED_CONTENT_OBJECTS_ENDPOINT})
export const mapConfigObjectsApi = new BaseApi({endPoint: MAPCONFIG_OBJECTS_ENDPOINT})
export const spotsApi = new SpotsApi();
export const spotObjectsFastEntryApi = new SpotObjectsFastEntryApi(authenticationApi);
export const analyticsApi = new AnalyticsApi();
//export const accountActivationApi = new BaseApi({endPoint: ACCOUNT_ACTIVATION_ENDPOINT});
export const forgotPasswordApi = new BaseApi({endPoint: ACCOUNT_FORGOT_PASSWORD_ENDPOINT});
export const resetPasswordApi = new BaseApi({endPoint: ACCOUNT_RESET_PASSWORD_ENDPOINT});
export const tagsApi = new TagsApi(authenticationApi);